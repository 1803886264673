import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes, css } from "styled-components";
import { search, youtube, arrow, user_help, spotify_icon, info_icon, error_icon } from '../../assets/images';
import {
    getToken
  } from "../utils";
  import { clearToken } from "../utils";
  import config from "../../config";
  import { useNavigate } from "react-router-dom";
  import Import_playlist_songs from "./import_playlist_songs"; // Import the second popup

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
}

function YouTubesearch1({ onClose1,data }) {
  const windowWidth = useWindowWidth();
  const navigate = useNavigate();
  const [playlistData, setPlaylistData] = useState(null);
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [showImportSongsPopup, setShowImportSongsPopup] = useState(false);
  const isMobile = windowWidth <= 991;
  const token = getToken();

  if (token == null) {
    navigate("/");
  }

  const handleGoClick = async (e) => {
    e.preventDefault();
    if (!youtubeUrl.trim()) {
      return;
    }

    let playlistId = null;
    try {
      const urlParams = new URLSearchParams(new URL(youtubeUrl).search);
      playlistId = urlParams.get("list");

      if (!playlistId) {
        return;
      }
    } catch (error) {
      console.error("Invalid URL", error);
      return;
    }

    try {
      setLoading(true);

      const response = await fetch(`${config.apiUrl}/import_playlist/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ playlist_id: playlistId }),
      });

      if (response.ok) {
        const data = await response.json();
        setPlaylistData(JSON.stringify(data));
        // localStorage.setItem("playlist_songs", JSON.stringify(data)); // Make sure to stringify the JSON data
        setShowImportSongsPopup(true); // Show the Import_playlist_songs popup
      }
    } catch (error) {
      console.error("Error fetching playlist", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle closing the Import_playlist_songs popup
  const handleCloseImportSongs = () => {
    setShowImportSongsPopup(false);
    onClose1(); // Close the parent popup as well
  };

  if (showImportSongsPopup) {
    return <Import_playlist_songs onClose={handleCloseImportSongs} data={playlistData} />;
  }

  return (
    <PopupOverlay>
      {loading && (
        <Overlay loading={loading}>
          <Spinner />
        </Overlay>
      )}
      <Container>
        <CloseButton onClick={onClose1}>×</CloseButton>
        <ContentWrapper>
        
          <>
          {data === "YouTube" ? (
          <>
            <InputIcon1 loading="lazy" src={youtube} />
            <MainTitle>
              Paste the YouTube URL link
              {isMobile ? (
                <>
                  <br /> to import the Youtube
                  <br /> Playlist
                </>
              ) : (
                <> to import the<br /> Youtube Playlist</>
              )}
            </MainTitle>
          </>
        ) : (
          <>
            <InputIcon1 loading="lazy" src={spotify_icon} /> {/* Spotify Image */}
            <MainTitle>
              Paste the Spotify Playlist URL link
              {isMobile ? (
                <>
                  <br /> to import the
                  <br /> Playlist
                </>
              ) : (
                <> to import the<br /> Playlist</>
              )}
            </MainTitle>
          </>
        )}

<MainTitle1>For performance and privacy reasons, we only support importing public playlists.</MainTitle1>
           
            <FormContainer>
              <InputGroup>
                <InputIcon loading="lazy" src={search} />
                <InputField
                  type="text"
                  id="youtubeLink"
                  value={youtubeUrl}
                  placeholder={`Paste your ${data === "YouTube" ? "YouTube" : "Spotify"} link here`}
                  onChange={(e) => setYoutubeUrl(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleGoClick(e);
                    }
                  }}
                />
              </InputGroup>

              <Button type="button" onClick={handleGoClick}>
                Go
              </Button>
            </FormContainer>
          </>
        </ContentWrapper>
      </Container>
    </PopupOverlay>
  );
}

// All your styled components remain the same
const PopupOverlay = styled.div`
    position: "fixed";
    height: "100vh";
    width: "100vw";
    zIndex: 10;
    display: "flex";
    justifyContent: "center";
    alignItems: "center";
    background: "#282828";
`

const Container = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background-color: #282828;
  min-height: 464px;
  max-height: 80vh;
  width: 700px;
  max-width: 100%; /* Add this to ensure it never exceeds viewport width */
  padding: 20px;
  overflow-y: auto;
  margin: 0 auto; /* Center the container horizontally */
  box-sizing: border-box; /* Ensure padding is included in the width calculation */

  @media (max-width: 991px) {
    align-items: center;
    justify-content: center;
    padding: 30px;
    height: 100%;
    margin: 0 auto;
    width: 100%;
    min-width: 100%; /* Use viewport width unit to respect device width */
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
`;

const ContentWrapper = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  &::-webkit-scrollbar{
    display: none;
  }
`;

const MainTitle = styled.h1`
  color: #fff;
  text-align: center;
  letter-spacing: -0.28px;
  align-self: center;
  font: 600 28px/120% Poppins, sans-serif;

  @media (max-width: 991px) {
    font: 600 18px/120% Poppins, sans-serif;
  }
`;


const MainTitle1 = styled.div`
  color: #fff;
  text-align: center;
  letter-spacing: -0.28px;
  align-self: center;
  font: 400 14px Poppins, sans-serif;

  @media (max-width: 991px) {
    font: 400 12px Poppins, sans-serif;
  }
`;

const FormContainer = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 14px;
  gap: 24px;
  width:80%;

  @media (max-width: 991px) {
    flex-wrap: wrap;
    margin-top: 20px;
    width:100%;
    flex-direction: column;
    margin-left:0px;
  }
`;

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
  gap: 10px;
  color: #51606f;
  font-weight: 300;
  padding: 14px 12px;
  width:100%;

  @media (max-width: 991px) {
    display: flex;
    padding: 16px 12px;
    width:90%;
    flex-direction: row;
  }
`;

const InputIcon = styled.img`
  aspect-ratio: 1;
  width: 32px;
  
`;

const InputIcon1 = styled.img`
  margin-top:20px;
  align-items: center;
  width: 120px;
  @media (max-width: 991px) {
    width: 70px;
  }
`;

const InputField = styled.input`
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: transparent;
  width: 100%;
`;

const Button = styled.button`
  font-family: Poppins, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #0072D5;
  color: #fff;
  font-weight: 600;
  font-size:16px;
  padding: 14px 12px;
  height:64px;
  width: 160px;
  cursor: pointer;
  border:none;

  @media (max-width: 991px) {
    padding-left: 20px;
    height:54px;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 999; /* Ensure it's above other content */
  display: ${props => (props.loading ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

export default YouTubesearch1;