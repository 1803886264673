import React from "react";
import styled from "styled-components";
import { 
  landing_experience_bg, 
  landing_experience_icon1, 
  landing_experience_icon2, 
  landing_experience_icon3, 
  landing_experience_icon4 
} from "../../../assets/images";
import useWindowSize from "../../useWindowSize"



const Container = styled.section`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 56px;
  background-image: url(${landing_experience_bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 991px) {
    max-width: 991px;
  }

  @media (max-width: 640px) {
    max-width: 640px;
    padding: 24px;
  }
`;

const Container1 = styled.section`
  max-width: 100%;
  width:100%
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 12px;
  background-image: url(${landing_experience_bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

 
`;

const Title = styled.h2`
  color: #000;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  font-weight: 700;
  margin: 0;

  @media (max-width: 640px) {
    font-size: 28px;
  }
`;

const Title11 = styled.h2`
  color: #000;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  font-weight: 600;
  margin: 0;

 
`;

const FeaturesGrid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  flex-wrap: nowrap;
  width: 100%;

  @media (max-width: 991px) {
    overflow-x: auto;
    justify-content: flex-start;
  }

  @media (max-width: 640px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const FeaturesGrid1 = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); // 2 cards per row
  gap: 12px;
  width: 100%;
  margin-left:0px;
`;



const Title1 = styled.h3`
  color: #fff;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  margin: 0;
  transition: transform 0.3s ease;
`;

const Title2 = styled.div`
  color: #fff;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 700;
  width: 100%;
  margin: 0;
  transition: transform 0.3s ease;
`;

const Icon = styled.img`
  transition: transform 0.3s ease;
`;

const Icon1 = styled.img`
  transition: transform 0.3s ease;
`;

const Description = styled.p`
  position: absolute;
  bottom: 15px;
  color: #fff;
  text-align: center;
  margin-left:10px;
  margin-right:10px;
  font-size: 14px;
  

  transition: opacity 0.3s ease, transform 0.3s ease;
`;

const Description1 = styled.div`
  position: absolute;
  bottom: 15px;
  color: #fff;
  text-align: center;
  margin-left:10px;
  margin-right:10px;
  font-size: 10px;
  

  transition: opacity 0.3s ease, transform 0.3s ease;
`;

const hoverColors = ["linear-gradient(to right, #fb923c, #ec4899, #3b82f6)", "linear-gradient(to bottom, #60a5fa, #93c5fd)", "linear-gradient(to bottom, #ec4899, #9333ea)", "linear-gradient(to bottom, #ec4899, #a855f7)", "linear-gradient(to bottom, #ec4899, #a855f7)"];

const Card = styled.article`
  display: flex;
  width: 248px;
  height: 244px;
  padding: 31px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 8px;
  background-color: #12171c;
  transition: background-color 0.3s ease, transform 0.3s ease;
  position: relative;
  overflow: hidden;
  cursor:pointer;

  &:hover {
  background: ${({ hoverColor }) => hoverColor};
}


  &:hover ${Title1}, &:hover ${Icon} {
    transform: translateY(-50px);
  }

  &:hover ${Description} {
    opacity: 1;
    transform: translateY(-30px);
  }

  @media (max-width: 991px) {
    width: 220px;
    height: 240px;
  }

  @media (max-width: 640px) {
    width: 100%;
    height: 200px;
  }
`;

const Card1 = styled.article`
  display: flex;
  width: 140px;
  height: 140px;
  padding: 41px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
 
  transition: background-color 0.3s ease, transform 0.3s ease;
  position: relative;
  overflow: hidden;
  cursor:pointer;

 
  background: ${({ hoverColor }) => hoverColor};






`;



const PremiumFeatures = () => {
    const { windowWidth, windowHeight, isMobile, isPortrait,isLandscape } = useWindowSize();
  const features = [
    { 
      icon: landing_experience_icon1, 
      title: "Upload Any Song", 
      description: "Drop your favorite track and get instant chord recognition. Words with any audio format" 
    },
    { 
      icon: landing_experience_icon2, 
      title: "Create Custom Versions", 
      description: "Personalize your music with custom chord simplified versions. Make any song fit your unique playing style." 
    },
    { 
      icon: landing_experience_icon3, 
      title: "Loop & Practice", 
      description: "Master tricky parts with seamless repetition. Create custom practice loops for any section of your favorite songs and perfect your skills at your own place." 
    },
    { 
      icon: landing_experience_icon4, 
      title: "Create Your Playlist", 
      description: "Get studio-quality sheet music PDFs with chord diagrams and lyrics." 
    },
    { 
      icon: landing_experience_icon4, 
      title: "Download PDF", 
      description: "Get studio-quality sheet music PDFs with chord diagrams and lyrics." 
    },
  ];

  return (
    <>
      {isMobile ? (
        <Container1>
          <Title11>Experience Premium For Free</Title11>
          <FeaturesGrid1>
            {features.slice(0, 4).map((feature, index) => ( 
              <Card1 key={index} hoverColor={hoverColors[index % hoverColors.length]}>
                <Icon1 src={feature.icon} alt={feature.title} />
                <Title2>{feature.title}</Title2>
                <Description1>{feature.description}</Description1>
              </Card1>
            ))}
          </FeaturesGrid1>
        </Container1>
      ) : (
        <Container>
          <Title>Experience Premium For Free</Title>
          <FeaturesGrid>
            {features.map((feature, index) => ( 
              <Card key={index} hoverColor={hoverColors[index % hoverColors.length]}>
                <Icon src={feature.icon} alt={feature.title} />
                <Title1>{feature.title}</Title1>
                <Description>{feature.description}</Description>
              </Card>
            ))}
          </FeaturesGrid>
        </Container>
      )}
    </>
  );
  
};

export default PremiumFeatures;
