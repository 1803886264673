// import React from "react";
// import styled from "styled-components";
// import Pre_nav from "../navigation/premium_nav";
// import Footer from "../footer_lan";
// import { contact_us } from "../../assets/images";


// const Contact = () => {
//   return (
//     <MainContainer>
//     <Pre_nav />
//     <Section>
    

//        <Container>
//       <Content>
//         <Title>Get in Touch with Us</Title>
//         <Description>
//           Use the contact form to get in touch with us or email us at{" "}
//           <EmailLink href="mailto:info@jammin.ai">info@jammin.ai</EmailLink>
//           <br />
//           We'll get back to you as soon as possible.
//         </Description>
//         <Image loading="lazy" src={contact_us} alt="Contact illustration" />
//       </Content>
//     </Container>


//     <Container1>
//       <FormWrapper>
//         <FormDescription>
//           Have a question? Need some help? We're here for you!
//           <br />
//           Fill out the form below and we'll get back to you as soon as possible.
//         </FormDescription>
//         <form>
//           <InputField>
           
//             <input type="text" id="fullName" placeholder="Full Name" />
//           </InputField>
//           <InputField>
           
//             <input type="email" id="emailAddress" placeholder="Email Address" />
//           </InputField>
//           <InputField>
           
//             <textarea id="message" placeholder="What would you like to chat about?" rows="7"></textarea>
//           </InputField>
//           <SubmitButton type="submit">Send Message</SubmitButton>
//         </form>
//       </FormWrapper>
//     </Container1>

    
   
     
//     </Section>
//      <Footer />
//      </MainContainer>
//   );
// };

// const MainContainer = styled.main`
 
//   align-items: center;
//   flex-direction: column;
 
//    background-color:#121212;

//   @media (max-width: 991px) {
//     padding: 0 0px;
//     max-width: 100%;
//     margin-right:0px;
//   }
// `;


// const Container1 = styled.div`
//   display: flex;
//   flex-direction: column;
//   line-height: normal;
//   width: 40%;
//   margin-top:30px;
//   margin-left: 0px;
//   @media (max-width: 991px) {
//     width: 100%;
//   }
// `;

// const FormWrapper = styled.div`
//   display: flex;
//   flex-grow: 1;
//   flex-direction: column;
//   font-size: 15px;
//   color: #ababab;
//   font-weight: 500;
//   padding: 0 20px 32px;
//   @media (max-width: 991px) {
//     max-width: 100%;
//     margin-top: 40px;
//   }
// `;

// const FormDescription = styled.p`
//   letter-spacing: 0.13px;
//   color: #fff;
//   font: 600 16px/22px Poppins, sans-serif;
//   margin-bottom: 20px;
//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

// const InputField = styled.div`
//   margin-bottom: 24px;

//   input, textarea {
//     font-family: Poppins, sans-serif;
//     border-radius: 4px;
//     background-color: #fff;
//     padding: 17px 16px;
//     border: 1px solid #ececec;
//     width: 100%;
//     color: #ababab;
//     font-size: 15px;
//     font-weight: 500;
//   }
// `;

// const SubmitButton = styled.button`
//   border-radius: 4px;
//   background-color: #428bca;
//   align-self: center;
//   width: 262px;
//   max-width: 100%;
//   color: #fff;
//   padding: 14px 12px;
//   font: 700 18px Source Sans Pro, sans-serif;
//   border: none;
//   cursor: pointer;
//   @media (max-width: 991px) {
//     margin-top: 40px;
//     padding: 14px 20px;
//   }
// `;

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   line-height: normal;
//   width: 50%;
//   margin-left: 0;
//   @media (max-width: 991px) {
//     width: 100%;
//   }
// `;

// const Content = styled.div`
//   display: flex;
//   flex-grow: 1;
//   flex-direction: column;
//   padding: 0 20px 54px;
//   @media (max-width: 991px) {
//     max-width: 100%;
//     margin-top: 40px;
//   }
// `;

// const Title = styled.h2`
//   letter-spacing: -0.32px;
//   color: #fff;
//   font: 700 32px Montserrat, sans-serif;
//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

// const Description = styled.p`
//   letter-spacing: -0.14px;
//   color: #d4d4d4;
//   margin-top: 4px;
//   font: 400 14px/20px Poppins, sans-serif;
//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

// const EmailLink = styled.a`
//   font-weight: 600;
//   text-decoration: underline;
//   color: #428bca;
// `;

// const Image = styled.img`
//   aspect-ratio: 1.43;
//   object-fit: auto;
//   object-position: center;
//   width: 80%;
//   margin-top: 12px;
//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;


// const Section = styled.section`
//   display: flex;
//   gap: 20px;
//   padding:30px;
//   margin-left:30px;
//      background-color:#121212;
//   @media (max-width: 991px) {
//     flex-direction: column;
//     align-items: stretch;
//     gap: 0;
//   }
// `;

// export default Contact;




import * as React from "react";
import styled from "styled-components";
import Footer from '../footer_lan';
import Navbar from "../landing/nav_bar";
import Pre_nav from "../navigation/premium_nav";
import Pre_nav_offline from "../navigation/pre_nav_offline";

import { getToken } from "../utils";

function Contact() {
  const token = getToken();
  return (
    <>
     {token ? (
        <Pre_nav />
      ) : (
        <Pre_nav_offline />
      )}
      <Main>
        {/* <FormSection> */}
        {/* <FormTitle>Contact Us</FormTitle> */}
        <IframeContainer>
            <Iframe
              src="https://easy-donate.ck.page/contactus"
              frameBorder="0"
              allowFullScreen
            />
          </IframeContainer>
        {/* </FormSection> */}
      </Main>
      <><Footer/></>
      
      
    </>
  );
}



const Main = styled.main`
  display: flex;
  justify-content: center;
  padding: 0px;
  overflow-x: hidden; 
  overflow-y: hidden;
  
 
  background-color: #f0eceb;
  @media (max-width: 991px) {
   
  }
`;

const FormTitle = styled.h1`
  font-family: 'Montserrat', sans-serif;
  font-size: 32px;
  font-weight: 700;
  color: #020f12;
  text-align: center;
  letter-spacing: -0.32px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  `;

const FormSection = styled.section`
  background-color: #fff;
  border-radius: 8px;
  max-width: 596px;
  width: 100%;
  display: flex;
  flex-direction: column;
  
`;

const IframeContainer = styled.div`
  
  width: 100%;
  height: 740px;  
  display: flex;
  justify-content: center;
  overflow:hidden;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
`;












export default Contact;
