import React, { useEffect, useRef, useState, useMemo, useCallback } from "react";
import axios from "axios";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled, { keyframes, css } from "styled-components";
import Cookies from "js-cookie";
import FeedbackPopup from "../user-page/feedback";
import RetryPopup from "../user-page/retry_popup";
import {
  new_logo_white, video_play_button, video_pause_button, restart1,green_tick1,white_cancel,  playlist_pdf, playlist_youtube,
  playlist_retry, playlist_add_playlist, playlist_share, playlist_delete,high_light_chords_enable, high_light_chords_white, create_save_as, create_coopy,
  loop_dis, loop_en,down_drop_arrow,arrow,edit_chords,back_arraow_chords,edt_variant,del_variant,mp3_thumbnail,error_icon,tick_snack_bar,
} from "../../assets/images";

import config from "../../config";
import Footer from "../footer_lan";
import Pre_nav from "../navigation/premium_nav";
import PlaylistForm from "../playlist/add_playlist_popup";
import CreatePlaylistPopup from "../user-page/playlist_popup";
import useSubscription from "../user-page/subscription";
import useFreeTrial from "../user-page/freeTrial";
import { clearToken, getToken, languages } from "../utils";
import VideoPlayer from "./VideoPlayer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getfirstname, getlastname } from "../utils";
import ShareLinkPopup from "./share_popup";
import { Pencil, GripHorizontal } from "lucide-react";



const CustomToast = ({ closeToast }) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    {/* <FaUser style={{ marginRight: '10px' }} /> */}
    <img
      src={tick_snack_bar}
      alt="check icon"
      style={{ marginRight: "8px", width: "24px", height: "24px" }}
    />

    <span style={{ flex: 1 }}>link copied</span>
  </div>
);
const showToast1 = () => {
  toast(<CustomToast1 message={"Playlist Updated Successfully"} />, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const CustomToast1 = ({ message }) => {
  return (
    <div>
      <p>{message}</p>
    </div>
  );
};

const showToast = () => {
  toast(<CustomToast />, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};


const useWindowSize = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    // Detect if the device is mobile
    const checkIfMobile = () => {
      const isMobileDevice = window.matchMedia("(max-width: 800px)").matches;
      setIsMobile(isMobileDevice);
    };

    checkIfMobile(); // Initial check
    window.addEventListener("resize", handleResize);
    window.addEventListener("resize", checkIfMobile); // Update on resize

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  const isPortrait = windowHeight > windowWidth;
  const isLandscape = windowWidth > windowHeight;

  return { windowWidth, windowHeight, isMobile, isPortrait, isLandscape };
};

const MusicApp = () => {
  const { youtube_id } = useParams();
  const { initials } = useParams();
  const { windowWidth, windowHeight, isMobile, isPortrait, isLandscape } = useWindowSize();
  const token = getToken();
  const navigate = useNavigate();
  if (token == null) {
    navigate("/");
  }
   const [lastitem, setlastitem] = useState("");
  const location = useLocation();
  const [playlists, setPlaylists] = useState([]);
  const [timesArray, settimesArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [title1, settitle1] = useState("");
  const [thumb_img, setthumb_img] = useState("");
  const [isUploaded, setIsUploaded] = useState(true);

  const audioInterval = useRef(null)
  const [mp3Url, setMp3Url] = useState(null);
  const [mp3Player, setMp3Player] = useState(null);
  const [Text1, setText1] = useState("");
  const [snackbar_name, setsnackbar_name] = useState("");
  const [Percentage, setPercentage] = useState("");
  const [result, setresult] = useState("");
  const [chordsTs, setchordsTs] = useState([]);
  const [state, setstate] = useState([]);
  const [wordsTs, setwordsTs] = useState([]);
  const wordRef = useRef(null);
  const wordRef2 = useRef(null);

  const [uniqueChords, setUniqueChords] = useState([]);
  const [chordsImages, setChordsImages] = useState([]);
  const [url, seturl] = useState("");
  const [id, setid] = useState("");
  const first_name = getfirstname();
  const last_name = getlastname();
  const [selectedOption, setSelectedOption] = useState("Scroll: Auto Tempo");
  const playlist_added_successfully = localStorage.getItem("playlist_added_successfully");
  const [retryPopupVisible, setRetryPopupVisible] = useState(false);

  const [open, setOpen] = useState(false);
  const [down, setDown] = useState(false);
  const [transposedValue, setTransposedValue] = useState(0);
  const [activeButton, setActiveButton] = useState(null);
  const scrollIntervalRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [combinedRowsWithMaxWidths, setCombinedRowsWithMaxWidths] = useState([]);
  const [myVariable, setMyVariable] = useState([]);

  const [selectedName, setSelectedName] = useState("formattedName");
  const [showplaylist_popup, setshowplaylist_popup] = useState(false);

  const hiddenParam = location.state?.hiddenParam;
  const variantName_share_url = location.state?.variantName;
  const [showDropdown, setShowDropdown] = useState(false);
  const [scrollSpeed, setScrollSpeed] = useState(0);
  
  const [visitCount, setVisitCount] = useState(0);
  const [show_feedback, setshow_feedback] = useState(false);
  const [share_popup, setshare_popup] = useState(false);
  const [share_url1, setshare_url1] = useState("");
  const isFirstMount = useRef(true);
  
  const [isActive, setIsActive] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showversion_title_popup, setshowversion_title_popup] = useState(false);
  const [saveas_original, setsaveas_original] = useState(false);
  
  const [showdel_chords_popup, setshowdel_chords_popup] = useState(false);
  const [showdel_words_popup, setshowdel_words_popup] = useState(false);
  

  const [showversion_edit_popup, setshowversion_edit_popup] = useState(false);
  const [showversion_del_popup, setshowversion_del_popup] = useState(false);
  const [edt_name, setedt_name] = useState("");
  const [del_name, setdel_name] = useState("");
  const [variants, setVariants] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const playerRef = useRef(null);
  const playerRef2 = useRef(null);
  const [viewType, setViewType] = useState('lyrics');
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoop, setisLoop] = useState(false);
  const [edt2, setedt2] = useState(false);

  const [highlight_chords, sethighlight_chords] = useState(false);
  const [translate, setTranslate] = useState(null);
  const [translationData, setTranslationData] = useState([]);
   const [selectedValue, setSelectedValue] = useState("original");

  const [edit_chords_lyrics, setedit_chords_lyrics] = useState(false);

  const [version_select, setversion_select] = useState(null);
  const song_added_using_url = localStorage.getItem("song_added_using_url");
  const [hasScrolledToPlay1, setHasScrolledToPlay1] = useState(false);
  const scrollTimeoutRef = useRef(null);
  const [isManualScrolling, setIsManualScrolling] = useState(false);



  const timeoutRef = useRef(null);
  const timeoutRef1 = useRef(null);
  const toastId = useRef(null);


  const [editable, setEditable] = useState({ row: null, index: null });
  const [rowIndex, setrowIndex] = useState("");
  const [itemIndex, setitemIndex] = useState("");
  const [displayedWord, setdisplayedWord] = useState("");
  const [wordIndex, setwordIndex] = useState("");
  const [updatedText, setupdatedText] = useState("");

  const [finalRows, setFinalRows] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const [isClicked1, setIsClicked1] = useState(false);
  const [downvoted, setdownvoted] = useState("");
  const [downvotes, setdownvotes] = useState("");
  const [upvoted, setupvoted] = useState("");
  const [upvotes, setupvotes] = useState("");
  const contentEditableRef = useRef(null);
  const contentEditableRef1 = useRef(null);

  const [word_empty_time, setword_empty_time] = useState("");
  const [chord_empty_time, setchord_empty_time] = useState("");

  const [totalDuration, setTotalDuration] = useState("");
  const timelineRef = useRef(null);
  const timelineRef2 = useRef(null);

  const [SECONDS_PER_LINE1, set_SECONDS_PER_LINE1] = useState(3);
  // const SECONDS_PER_LINE = 5;
  const LINE_HEIGHT = 50;
  const [editingChord, setEditingChord] = useState(null);
  const [editedChordValue, setEditedChordValue] = useState("");
  const [validationError, setValidationError] = useState(null);

  const [editingWord, setEditingWord] = useState(null);
  const [editedWordValue, setEditedWordValue] = useState("");
  const [wordValidationError, setWordValidationError] = useState(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isTranslateDropdownOpen, setIsTranslateDropdownOpen] = useState(false);

  const [width, setWidth] = useState("auto");

  const hiddenTextRef = useRef(null);
  const hiddenTextRef1 = useRef(null);
  const [newChords, setNewChords] = useState([]); // New chords state
  const [newWord, setNewWord] = useState([]); // New chords state

  const [activeView, setActiveView] = useState('simpleView');

  const [currentTime, setCurrentTime] = useState(0);

  const [loopStart, setLoopStart] = useState(null);
  const [loopEnd, setLoopEnd] = useState(null);
  const [loopIntervalId, setLoopIntervalId] = useState(null);
  const [isStartSet, setIsStartSet] = useState(false);

  const [isInsideCj2, setIsInsideCj2] = useState(false);

  const cj1Ref = useRef(null);
  const cj2Ref = useRef(null);


  const containerRef = useRef(null);
  const headerSpaceRef = useRef(null);
  const dropdownRef = useRef(null);
  const dropdownButtonRef = useRef(null);

  const [typedText, setTypedText] = useState("");
  const [filteredLanguages, setFilteredLanguages] = useState(languages);

  const [shouldCalculate, setShouldCalculate] = useState(false);
  const countRef = useRef(false);

  // Handle key press when dropdown is open
  useEffect(() => {
    if (!isTranslateDropdownOpen) return;

    const handleKeyPress = (event) => {
      const char = event.key.toLowerCase();

      if (char.length === 1 && /[a-z]/.test(char)) {
        setTypedText((prev) => prev + char);

        // Filter languages based on typed text
        const filtered = languages.filter((lang) =>
          lang.language.toLowerCase().startsWith(typedText + char)
        );

        setFilteredLanguages(filtered.length > 0 ? filtered : languages);
      }

      if (event.key === "Backspace") {
        setTypedText((prev) => prev.slice(0, -1));

        const filtered = languages.filter((lang) =>
          lang.language.toLowerCase().startsWith(typedText.slice(0, -1))
        );

        setFilteredLanguages(filtered.length > 0 ? filtered : languages);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [isTranslateDropdownOpen, typedText]);

  const handle_highlight_chords = () => {
    sethighlight_chords((prevState) => !prevState);
  };

  const handleAddChord = (rowIndex, startTime) => {
    setNewChords([{ chord: "New Chord", time: startTime, row: rowIndex }]);
    setEditingChord({ rowIndex, chordIndex: 0 }); // Enable edit mode
  };


  const handleAddword = (rowIndex, startTime) => {
    setNewWord([
      {
        word: "New word",
        start: startTime,
        row: rowIndex,
      },
    ]);
  };

  const handleDragStart1 = (e, item, type, rowIndex) => {
    e.dataTransfer.setData("text/plain", "");

    const itemData = {
      ...item,
      type,
      sourceRowIndex: rowIndex,
      originalTime: type === "word" ? item.start : item.time,
    };
    draggedItemRef.current = itemData; // Store dragged item in the ref

    const dragImage = document.createElement("div");
    dragImage.textContent = type === "chord" ? item.chord : item.word;
    dragImage.style.position = "absolute";
    dragImage.style.top = "-1000px";
    document.body.appendChild(dragImage);
    e.dataTransfer.setDragImage(dragImage, 0, 0);
    document.body.removeChild(dragImage);
  };
  const handleDragEnd = (e, item, type, rowIndex) => {
    setNewChords([]);
    setNewWord([]);
  };

  const toggleDropdown1 = () => setIsDropdownOpen((prevState) => !prevState); // Toggle dropdown open/close

  const isValidChord = (chord) => {
    return chord && chord.trim().length > 0;
  };

  const handleEditWordStart = (word) => {
    setEditingWord({
      ...word,
      originalIndex: word.originalIndexes[0], // Ensure originalIndex is set
    });
    setEditedWordValue(word.word); // Set the edited word value
    setWordValidationError(null); // Reset any previous validation error
  };

  const handleWordSave = (text) => {
    if (!editedWordValue.trim()) {
      setWordValidationError("Word cannot be empty.");
      return;
    }

    const wordIndex = editingWord?.originalIndex;
    handleLyricsUpdate(wordIndex, text);
  };

  const handleWordCancel = () => {
    setEditingWord(null);
    setEditedWordValue("");
    setWordValidationError(null);
  };

  const handleEditChordStart = (chord) => {
    setEditingChord(chord);
    setEditedChordValue(chord.chord);
    setValidationError(null);
  };

  const handleChordSave = (text) => {
    if (!isValidChord(text)) {
      setValidationError("Chord cannot be empty");
      return;
    }

    const updatedChordsTs = [...chordsTs];
    updatedChordsTs[editingChord.originalIndex] = {
      ...updatedChordsTs[editingChord.originalIndex],
      chord: text,
    };


    setchordsTs(updatedChordsTs);
    submit_chords_and_words1(updatedChordsTs);
    setEditingChord(null);
    setEditedChordValue("");
    setValidationError(null);
  };

  const handleChordCancel = () => {
    setEditingChord(null);
    setEditedChordValue("");
    setValidationError(null);
  };

  const [currentChord, setCurrentChord] = useState({
    chord: "",
    time: "",
    index: null,
  });
  const [currentword, setCurrentword] = useState({
    word: "",
    time: "",
    index: null,
  });

  const SECONDS_list = ["3", "4", "5", "6", "7", "8", "9", "10"];

  const handleScroll = () => {
    if (cj1Ref.current && containerRef.current) {
      let left = 0;
      const offset = activeView === "orgChordsView" ? 70 : 95;
      const containerTop = containerRef.current.offsetTop;
      
      // Get the Container_simple element
      const containerSimple = containerRef.current.querySelector('.Container_simple');
      
      if (dropdownButtonRef.current) {
        const rect = dropdownButtonRef.current.getBoundingClientRect();
        left = rect.left;
      }
      
      if (window.scrollY > containerTop - offset) {
        cj1Ref.current?.classList.add("fixed");
        cj1Ref.current.style.width = `${containerRef.current.offsetWidth}px`;
        
        if (dropdownRef.current) {
          dropdownRef.current.classList.add("fixed");
          dropdownRef.current.style.left = `${left + window.scrollX}px`;
        }
        
        headerSpaceRef.current?.classList.add("with-height");
      } else {
        headerSpaceRef.current?.classList.remove("with-height");
        cj1Ref.current.style.width = '95%';
        
        if (dropdownRef.current) {
          dropdownRef.current.classList.remove("fixed");
          dropdownRef.current.style.left = '0px';
        }
        
        cj1Ref.current?.classList.remove("fixed");
      }
    }
  };
  
  // Create a new handler for wheel events
  const handleWheel = (e) => {
    if (!containerRef.current) return;
    
    const containerSimple = timelineRef2.current;
    if (!containerSimple) return;
    
    const containerRect = containerRef.current.getBoundingClientRect();
    const containerTop = containerRect.top;
    const containerBottom = containerRect.bottom;
    const windowHeight = window.innerHeight;
    
    // When container is fully in view
    if (containerTop <= 0 && containerBottom >= windowHeight) {
      // Get scroll position and limits
      const scrollTop = containerSimple.scrollTop;
      const scrollHeight = containerSimple.scrollHeight;
      const clientHeight = containerSimple.clientHeight;
      
      // Check scroll direction
      if (e.deltaY > 0) { // Scrolling down
        // If container has more to scroll, prevent default page scroll
        if (scrollTop < scrollHeight - clientHeight) {
          e.preventDefault();
          containerSimple.scrollTop += e.deltaY;
        }
      } else if (e.deltaY < 0) { // Scrolling up
        // If container has more to scroll up, prevent default page scroll
        if (scrollTop > 0) {
          e.preventDefault();
          containerSimple.scrollTop += e.deltaY;
        }
      }
    }
    
    // When container top edge is near viewport top
    if (containerTop > -50 && containerTop < 50 && e.deltaY > 0) {
      e.preventDefault();
      containerSimple.scrollTop += e.deltaY;
    }
    
    // When container is scrolled to bottom and user tries to scroll up
    if (containerSimple.scrollTop + containerSimple.clientHeight >= containerSimple.scrollHeight - 5) {
      if (e.deltaY < 0) {
        // Allow containerSimple to scroll up first
        containerSimple.scrollTop += e.deltaY;
      }
    }
  };
  
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("wheel", handleWheel, { passive: false });
    
    if (cj1Ref.current) {
      cj1Ref.current.addEventListener("scroll", handleScroll);
    }
    
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("wheel", handleWheel);
      
      if (cj1Ref.current) {
        cj1Ref.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [activeView]);

  useEffect(() => {
    handleScroll();
  }, [isDropdownOpen])
 
  useEffect(() => {
    if (selectedValue != "original") {
      get_variant_votes();
      setversion_select(false);
    } else {
      setversion_select(true);
    }
  }, [selectedValue]);

  const toggleThumbColor = () => {
    if (selectedValue === "original") {
      if (isClicked1) deletedownvote();

      if (isClicked) {
        deleteupvote();
      } else {
        clickupvote();
      }
    } else {
      if (isClicked1) deletedownvote_variant();
      if (isClicked) {
        deleteupvote_variant();
      } else {
        clickupvote_variant();
      }
    }
  };

  const toggleThumbColor1 = () => {
    if (selectedValue === "original") {
      if (isClicked) deleteupvote();

      if (isClicked1 == true) {
        deletedownvote();
      } else {
        clickdownvote();
      }
    } else {
      if (isClicked) deleteupvote_variant();
      if (isClicked1 == true) {
        deletedownvote_variant();
      } else {
        clickdownvote_variant();
      }
    }
  };

  const showToast4 = (data) => {
    if (toastId.current) {
      toast.update(toastId.current, {
        render: <CustomToast4 message={data} />,
        // autoClose: 5000, // Keep the toast open but automatically close after 5 seconds of inactivity
      });
    } else {
      toastId.current = toast(<CustomToast4 message={data} />, {
        position: "bottom-left",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        autoClose: false, // Keep the toast open until manually closed or updated
      });
    }
  };

  const CustomToast4 = ({ message }) => {
    return (
      <div>
        <p>{message}</p>
      </div>
    );
  };

  const showToast5 = (data) => {
    toast(<CustomToast1 message={data} />, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const CustomToast1 = ({ message }) => {
    return (
      <div>
        <p>{message}</p>
      </div>
    );
  };

  const handleyouClick_retryversion1 = () => {
    setshowversion_title_popup(false);
  };

  const handle_save_as_original_popup = () => {
    setsaveas_original(true);
  };

  const handle_save_as_original_popup1 = () => {
    setsaveas_original(false);
  };

 const handleyouClick_delchords = () => {
    setshowdel_chords_popup(true);
  };

  const handleyouClick_delchords1 = () => {
    setshowdel_chords_popup(false);
  };

  const handleyouClick_delwords = () => {
    setshowdel_words_popup(true);
  };

  const handleyouClick_delwords1 = () => {
    setshowdel_words_popup(false);
  };

  const retry_with_name = async (lang_code = "") => {

    setLoading(true);
    let title = "";
    let maxCopyNumber = 0;
    variants.forEach(variant => {
      const copyMatch = variant.name.match(/Copy (\d+)/);
      if (copyMatch) {
        const copyNumber = parseInt(copyMatch[1]);
        maxCopyNumber = Math.max(maxCopyNumber, copyNumber);
      }
    });

    const nextCopyNumber = maxCopyNumber + 1;
    title = `${title1} - Copy ${nextCopyNumber}`;
    if (title.trim() === "") {
      setLoading(false);
      // console.error("No valid variant names found");
      return;
    }

    const encodedRequestId1 = encodeURIComponent(id);

    try {
      const response = await axios.post(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/retry_variant`,
        {
          name: title,
          lang_code,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        get_retry_version(id);

        setSelectedValue(title);
        get_variant(title);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          clearToken();
          navigate("/");
        } else if (error.response.status === 400) {
          setLoading(false);
         } else {
          setLoading(false);
         }
      }
    }
  };



  const handleSelectChange_version_name = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === "original") {
      get_original_variant();
    } else {
      get_variant(selectedValue);
    }
  };

  const handleEdit = (variantName) => {
    setedt_name(variantName);
    setIsOpen(false);
    setshowversion_edit_popup(true);
  };

  const handleEdit_close = () => {
    setedt_name("");
    setshowversion_edit_popup(false);
  };

  const handleDelete = (variantName) => {
    setdel_name(variantName);
    setIsOpen(false);
    setshowversion_del_popup(true);
  };

  const handleDelete_close = () => {
    setdel_name("");
    setshowversion_del_popup(false);
  };

  useEffect(() => {
    let player = null;

    if (mp3Url) {
      player = new Audio(mp3Url)
      setMp3Player(player)
    }

    return () => {
      player?.pause()
      clearInterval(audioInterval.current)
    }
  }, [mp3Url])

  useEffect(() => {
    if (Text1) {
      const text2 = `${snackbar_name} - ${Text1}`;
      showToast4(text2);

      // Close the toast if Text1 is "Complete"
      if (Text1 === "Finalizing") {
        setTimeout(() => {
          if (toastId.current) {
            toast.dismiss(toastId.current); // Dismiss the toast after 5 seconds
            toastId.current = null; // Clear the toast ID
          }
        }, 7000); // 5000 ms = 5 seconds
      }
    }
  }, [Text1]);

  useEffect(() => {
    const maxChordTime = chordsTs.reduce(
      (max, chord) => Math.max(max, chord.time),
      0
    );
    const maxLyricEnd = wordsTs.reduce(
      (max, lyric) => Math.max(max, lyric.end),
      0
    );
    setTotalDuration(Math.ceil(Math.max(maxChordTime, maxLyricEnd)));
  }, [chordsTs, wordsTs]);

  const handleSelectChange4 = (eventOrValue) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); // Clear any existing timeout
    }

    const selectedValue =
      typeof eventOrValue === "string"
        ? eventOrValue
        : eventOrValue.target.value;

    setSelectedValue(selectedValue);

    if (selectedValue === "original") {
      setIsEditing(false);
      setEditable({ row: null, index: null });
      get_original_variant();
    } else {
      get_variant(selectedValue);
    }
    setIsOpen(false);
  };


  useEffect(() => {
    if (song_added_using_url) {
     localStorage.removeItem("song_added_using_url");
    }
  }, [song_added_using_url]);

  useEffect(() => {
    if (variantName_share_url) {
      setSelectedValue(variantName_share_url);
      handleSelectChange4(variantName_share_url); // Call the handleSelectChange4 function with variantName_share_url
    }
  }, [variantName_share_url]);

  const get_original_votes = async (id) => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);

      const response = await axios.get(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/votes`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const { upvoted, downvoted, upvotes, downvotes } = response.data;

        setupvotes(upvotes);
        setdownvotes(downvotes);
        setupvoted(upvoted);
        setdownvoted(downvoted);

        setIsClicked(upvoted);
        setIsClicked1(downvoted);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const get_variant_votes = async () => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);

      const response = await axios.get(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${selectedValue}/votes`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const { upvoted, downvoted, upvotes, downvotes } = response.data;

        setupvotes(upvotes);
        setdownvotes(downvotes);
        setupvoted(upvoted);
        setdownvoted(downvoted);

        setIsClicked(upvoted);
        setIsClicked1(downvoted);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const clickupvote = async () => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);

      const response = await fetch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/upvote`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        get_original_votes(id);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const deleteupvote = async () => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);

      const response = await fetch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/upvote`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        get_original_votes(id);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const clickdownvote = async () => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);

      const response = await fetch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/downvote`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        get_original_votes(id);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const deletedownvote = async () => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);

      const response = await fetch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/downvote`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        get_original_votes(id);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const clickdownvote_variant = async () => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);

      const response = await fetch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${selectedValue}/downvote`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        get_variant_votes();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const deletedownvote_variant = async () => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);

      const response = await fetch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${selectedValue}/downvote`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        get_variant_votes();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const clickupvote_variant = async () => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);

      const response = await fetch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${selectedValue}/upvote`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        get_variant_votes();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const deleteupvote_variant = async () => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);

      const response = await fetch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${selectedValue}/upvote`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        get_variant_votes();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

 const get_variant = async (name) => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);

      const response = await axios.get(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${name}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setLoading(false);
        let hasInProgress = false;

        setsnackbar_name(name);

        setstate(response.data.state);
        setchordsTs(response.data.chords_ts);
        setwordsTs(response.data.words_ts_newline);

        if (
          Array.isArray(response.data.chords_ts) &&
          response.data.chords_ts.length === 0
        ) {
          if (
            response.data.state === "pending" ||
            response.data.state === "in_progress"
          ) {
            setLoading1(true);

            const step = response.data.processing_step;
            let progressPercentage;
            let text;

            if (step === "generating_mp3") {
              progressPercentage = 10;
              text = "Generating Mp3";
              setText1(text);
              setPercentage(progressPercentage);
            } else if (step === "generating_midi") {
              progressPercentage = 25;
              text = "Generating Midi";
              setText1(text);
              setPercentage(progressPercentage);
            } else if (step === "transcribing_audio") {
              progressPercentage = 50;
              text = "Transcribing Audio";
              setText1(text);
              setPercentage(progressPercentage);
            } else if (step === "generating_chords") {
              progressPercentage = 75;
              text = "Generating Chords";
              setText1(text);
              setPercentage(progressPercentage);
            } else if (step === "finalizing") {
              progressPercentage = 95;
              text = "Finalizing";
              setText1(text);
              setPercentage(progressPercentage);
            } else if (step === "complete") {
              progressPercentage = 100;
              text = "Complete";
              setText1(text);
              setPercentage(progressPercentage);
            }

            timeoutRef.current = setTimeout(() => get_variant(name), 5000);
          }
        } else {
          setTimeout(() => {
            if (toastId.current) {
              toast.dismiss(toastId.current); // Dismiss the toast after 5 seconds
              toastId.current = null; // Clear the toast ID
            }
          }, 100);
          setTimeout(() => {
            setLoading1(false);
          }, 2000);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const get_original_variant = async () => {
    try {
      const encodedRequestId1 = encodeURIComponent(youtube_id);
      const response = await axios.get(
        `${config.apiUrl}/url_requests/youtube/${encodedRequestId1}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setchordsTs(response.data.chords_ts);
        setwordsTs(response.data.words_ts_newline);

        get_original_votes(response.data.request_id);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false;

      const today = new Date().toISOString().split("T")[0]; // Get the current date (YYYY-MM-DD)

     const lastVisitDate = Cookies.get("lastVisitDate");

      const visits = Cookies.get("chordsPageVisitCount")
        ? parseInt(Cookies.get("chordsPageVisitCount"))
        : 0;

      if (lastVisitDate !== today) {
        Cookies.set("chordsPageVisitCount", 0, { expires: 7 });
        Cookies.set("lastVisitDate", today, { expires: 7 });
        setVisitCount(0);
      } else {
        // Use the existing visit count
        setVisitCount(visits);

        if (visits >= 3) {
          const feedbackSubmitted = Cookies.get("feedbackSubmitted");
          const feedbackClosedWithoutSubmit = Cookies.get(
            "feedbackClosedWithoutSubmit"
          );

          if (!feedbackSubmitted) {
            // 24 hrs full days
            if (feedbackClosedWithoutSubmit) {
              const lastClosedDate = new Date(feedbackClosedWithoutSubmit);
              const currentDate = new Date();

              const isSameDate =
                lastClosedDate.toDateString() === currentDate.toDateString();

              if (!isSameDate) {
                const timeDifference = currentDate - lastClosedDate;

                const oneDayInMilliseconds = 1000 * 60 * 60 * 24;

                if (timeDifference >= oneDayInMilliseconds) {
                  setshow_feedback(true);
                }
              }
            }

            else {
              setshow_feedback(true);
            }
          }
        }
      }

      // Increment visit count and save it to cookies
      const newVisitCount = visits + 1;
      Cookies.set("chordsPageVisitCount", newVisitCount, { expires: 7 });
      setVisitCount(newVisitCount);
    }
  }, []);

  const handleFeedbackSubmit = () => {
    Cookies.set("feedbackSubmitted", "true", { expires: 7 });
    setshow_feedback(false);
  };

  const handleFeedbackClose = () => {
    const currentDate = new Date().toISOString();
    Cookies.set("feedbackClosedWithoutSubmit", currentDate, { expires: 1 });
    setshow_feedback(false);
  };

  useEffect(() => {
    if (playlist_added_successfully) {
      showToast1();
      localStorage.removeItem("playlist_added_successfully");
    }
  }, [playlist_added_successfully]);

  const toggleDropdown = () => {
    if (!showDropdown) {
      get_playlist(); // Call get_playlist if dropdown is hidden
    } else {
      setShowDropdown(false); // Hide the dropdown if it's currently visible
    }
  };

  const handleyouClick_playlist = () => {
    setshowplaylist_popup(true);
  };

  const Go_back = () => {
    window.history.back();
  };

  const handleyouClick_playlist1 = () => {
    setshowplaylist_popup(false);
  };



  const increaseScrollSpeed = () => {
    scrollToCj1();
    const CJ2Container = cj2Ref.current;
    if (CJ2Container) {
      // Increase the scrollTop value based on the scrollSpeed
      CJ2Container.scrollTop += 10 * scrollSpeed; // Adjust multiplier as needed
      setScrollSpeed((prevSpeed) => Math.min(prevSpeed + 1, 5)); // Increase speed but cap it
    }
  };

  const decreaseScrollSpeed = () => {
    setScrollSpeed((prevSpeed) => Math.max(prevSpeed - 1, 0));
  };

  const scrollToCj1 = () => {
    cj1Ref.current?.scrollIntoView({ behavior: 'smooth' });
    setIsInsideCj2(false); // Reset to indicate we're at `cj1`
  };

  useEffect(() => {
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
    }

    if (scrollSpeed > 0) {
      scrollIntervalRef.current = setInterval(() => {
        if (!isInsideCj2) {
          scrollToCj1();
          setIsInsideCj2(true); // Once scrolled to cj1, allow cj2 scrolling
        } else if (cj2Ref.current) {
          cj2Ref.current.scrollTop += scrollSpeed; // Scroll within `cj2`
        }
      }, 100);
    }

    return () => {
      if (scrollIntervalRef.current) {
        clearInterval(scrollIntervalRef.current);
        scrollIntervalRef.current = null;
      }
    };
  }, [scrollSpeed, isInsideCj2]);

  const downgrade1 = () => {
    setDown(true);
  };

  // let videoUrl = `https://www.youtube.com/embed/${youtube_id}`;
  let videoUrl = `https://www.youtube.com/embed/${youtube_id}?enablejsapi=1&playsinline=1`;
  let videoId;

  const get_id_url = async () => {
    try {
      const encodedRequestId1 = encodeURIComponent(youtube_id);
      const response = await axios.get(
        `${config.apiUrl}/url_requests/youtube/${encodedRequestId1}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        settitle1(response.data.title);
        const thumbnailUrl = response.data.thumbnail_url.split("?")[0];
        setthumb_img(thumbnailUrl);
        setresult(response.data.result);
        setchordsTs(response.data.chords_ts);
        setwordsTs(response.data.words_ts_newline);
        seturl(response.data.url);
        setid(response.data.request_id);
        get_retry_version(response.data.request_id);
        setIsUploaded(response.data.url?.startsWith("https://jammin-upload-stage.s3.amazonaws.com"))
        setMp3Url(response.data.mp3_url)


        const allFilteredNames = new Set(); // Create a new Set to store all filtered names

        response.data.chords.forEach((chord) => {
         allFilteredNames.add(chord);  });

        setUniqueChords(Array.from(allFilteredNames));


        if (url.includes("=")) {
          const parts = url.split("=");
          videoId = parts[1];
        } else {
          const parts = url.split("/");
          videoId = parts[parts.length - 1];
        }

        videoUrl = `https://www.youtube.com/embed/${youtube_id}`;
        if (selectedValue === "original") {
          get_original_votes(response.data.request_id);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const get_retry_version = async (id) => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);
      const response = await axios.get(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/variants`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const variantData = response.data.variants.map((variant) => ({
          requestId: variant.request_id,
          name: variant.name,
          state: variant.state,
        }));
        setVariants(variantData);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  useEffect(() => {
    setLoading(true);

    const fetchPlaylists = async () => {
      try {
        const response = await axios.get(
          `${config.apiUrl}/playlists/__default_playlist__?sort_direction=desc`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const records = response.data.records;
          if (records.length === 0) {
            // setCount(0);
          } else {
            const mappedPlaylists = records
              .filter((record) => record.state === "complete")
              .map((record) => {
                const thumbnailUrl = record.thumbnail_url.split("?")[0];
                return {
                  title: record.title,
                  url: record.url,
                  id: record.request_id,
                  image: thumbnailUrl,
                };
              });

            setPlaylists(mappedPlaylists.slice(0, 8));
          }
          setLoading(false);
        }
       
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        }
      }
    };

    fetchPlaylists();
    get_id_url();

  }, []);

  const get_playlist = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/playlists/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const playlistsData = response.data.map((item) => ({
          name: item.name,
        }));
        setPlaylists(playlistsData);
        setShowDropdown(true); // Show the dropdown after data is fetched
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const [PopupVisible, setPopupVisible] = useState(false);

  const closepopup = () => {
    setPopupVisible(false);
    window.location.reload();
  };
  let startIndex = "";
  let endIndex = "";
  if (result.includes("```")) {
    startIndex = result.indexOf("```") + 3;
    endIndex = result.lastIndexOf("```");
  } else {
    startIndex = result.indexOf("") + 3;
    endIndex = result.lastIndexOf("");
  }

  let lines = [];
  if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
    const extractedText = result.substring(startIndex, endIndex).trim();
    lines = extractedText.split("\n");
  }
  let downlines = [];

  const checkAndColorLines = (line) => {
    const hasMoreThan3Spaces = line.match(/\s{3,}/);
    const segments = [];
    let currentSegment = "";
    for (let i = 0; i < line.length; i++) {
      if (line[i] === " ") {
        if (currentSegment !== "") {
          segments.push(currentSegment);
          currentSegment = "";
        }
        segments.push(" ");
      } else {
        currentSegment += line[i];
      }
    }
    if (currentSegment !== "") {
      segments.push(currentSegment);
    }
    if (hasMoreThan3Spaces || line.length < 2) {
      downlines.push(line);
      // let spaceCount = segments.filter(segment => segment === ' ').length;
      return (
        <Verse>
          {segments.map((segment, index) =>
            segment === " " ? (
              <React.Fragment key={index}>&nbsp;</React.Fragment>
            ) : (
              <span
                key={index}
                style={{
                  backgroundColor: "#F0984B",
                  padding: "2px 4px",
                  borderRadius: "4px",
                  color: "#FFFFFF",
                }}
              >
                {segment}
              </span>
            )
          )}
        </Verse>
      );
    } else {
      downlines.push(line);
      return <Verse style={{ color: "#FFFFFF" }}>{line}</Verse>;
    }
  };

  const generatePDF = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    const logo = `<img src="${new_logo_white}" alt="Logo" style="width: 250px;">`; // Adjust width as needed

    const newWindow = window.open(
      "",
      "_blank",
      `width=${width},height=${height}`
    );

    const cj2Content = document.getElementById("cj2").innerHTML;

    const styles = Array.from(document.styleSheets)
      .map((styleSheet) => {
        try {
          return Array.from(styleSheet.cssRules)
            .map((rule) => rule.cssText)
            .join("");
        } catch (error) {
          return ""; // Skip any stylesheets that can't be accessed
        }
      })
      .join("\n");

    if (newWindow) {
      newWindow.document.write(`
      <html>
        <head>
          <title>${title1}</title>
          <style>
            body { font: 550 15px Poppins, sans-serif; white-space: pre-wrap; margin: 0; padding: 0; }
            ${styles}
            /* Logo styling */
            #logo { display: flex; justify-content: center; margin-bottom: 15px; }
            /* Title styling */
            #title1 { font-size: 20px; margin-bottom: 20px; text-align: left; margin-left: 3px; }
            /* cj2 content styling */
            #cj2, #cj2 * { color: black !important; margin-left: 3px; 
              page-break-inside: avoid;}
          </style>
          <!-- Load html2pdf.js -->
          <script src="https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.9.2/html2pdf.bundle.min.js"></script>
        </head>
        <body>
          <div id="logo">${logo}</div>
          <div id="title1">${title1}</div>
          <div id="cj2">${cj2Content}</div>
          <script>
            // Convert the content to PDF and download
            window.onload = () => {
              const element = document.body; // Capture the full body
              html2pdf()
                .set({ filename: '${title1}.pdf' })
                .from(element)
                .save();
            };
          </script>
        </body>
      </html>
    `);

       newWindow.document.close();
    }
  };

  const handleSongCardClick = async (requestId, url) => {
    setLoading(true);
    try {
      const encodedRequestId = encodeURIComponent(requestId);
      const response_status = await axios.get(
        `${config.apiUrl}/url_requests/${encodedRequestId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response_status.status === 200) {
         if (response_status.data.state === "complete") {
          visitchords(
            encodedRequestId,
            response_status.data,
            response_status.data.youtube_id
          );
         } else if (
          response_status.data.state === "pending" ||
          response_status.data.state === "in_progress"
        ) {
          setTimeout(() => handleSongCardClick(requestId, url), 1000);
        } else {
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const visitchords = async (requestId, responsed, youtube_id) => {
    try {
      const response = await fetch(
        `${config.apiUrl}/playlists/__default_playlist__/${requestId}/visit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        window.scrollTo(0, 0);

        navigate(`/chord/${initials}/youtube/${youtube_id}`);
        }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };
  const formatChordName = (chord) => {
    let processedChord = chord
      .replace(/Major/g, "maj")
      .replace(/major/g, "maj")
      .replace(/Minor/g, "min")
      .replace(/minor/g, "min")
      .replace(/diminished/g, "dim")
      .replace(/augmented/g, "aug")
      .replace(/add/g, "add")

      .replace(/m/g, "min")
      .replace(/#/g, "__")
      .replace(/dimin/g, "dim")
      .replace(/\/[0-9]+/g, "_")
      .replace(/minaj/g, "maj");

    if (
      processedChord.length === 1 || processedChord.length === 2 ||
      (processedChord.length === 3 && processedChord.includes("__"))
    ) {
      processedChord += "maj";
    }

    return processedChord;
  };


  const for_display_ChordName = (chord) => {
    let processedChord = chord
      .replace(/maj/g, "")
      .replace(/min/g, "m")
      .replace(/min7/g, "m7")
      .replace(/maj7/g, "7")
      .replace(/min6/g, "m6")
      .replace(/min7b5/g, "m7b5")
      .replace(/min9/g, "m9");
    return processedChord;
  };

  const importAll = (r) => {
    let images = {};
    r.keys().forEach((item) => {
      images[item.replace("./", "").replace(".svg", "")] = r(item);
    });

    return images;
  };

  const images = importAll(
    require.context("../../assets/cords", false, /\.svg$/)
  );
  const View_all_Songs = (event) => {
    window.scrollTo(0, 0);
    navigate("/all_songs");
  };

 const share_button = async () => {
    const encodedRequestId1 = encodeURIComponent(id);
    if (selectedValue === "original") {
      try {
        setLoading(true);
        const response = await fetch(
          `${config.apiUrl}/url_requests/${encodedRequestId1}/share_url`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const data = await response.json();
          const share_url = data.share_url;
          setshare_url1(share_url);
          setLoading(false);
          setshare_popup(true);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        }
        setLoading(false);
      }

    } else {
      try {
        setLoading(true);
        const response = await fetch(
          `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${selectedValue}/share_url`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const data = await response.json();
          const share_url = data.share_url;
          setshare_url1(share_url);
          setLoading(false);
          setshare_popup(true);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        }
        setLoading(false);
      }
    }


  };

  const handleCopyLink = () => {
    const textToCopy = url;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        showToast();
      })
      .catch((err) => { });
  };
 

  const add_playlist1 = async (playlistName) => {
    try {
      const response = await axios.post(
        `${config.apiUrl}/playlists/${playlistName}/add`,
        { records: [id] }, // Replace `id1` with your actual record ID(s)
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const textToCopy = `Added into ${playlistName} playlist`;

        // Copy text to clipboard
        navigator.clipboard
          .writeText(textToCopy)
          .then(() => {
            // Show toast after successful copy with a dynamic message
            // showToast1(textToCopy); // Pass the message to the toast
          })
          .catch((err) => { });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

 
  const edit_chord = () => {
    if (selectedValue === "original") {
      setIsEditing(false);
      setEditable({ row: null, index: null });
      handle_save_as_original_popup();
    } else {
      //
      const groupedChords = {};
      chordsTs.forEach((chord, chordIndex) => {
        const chordTime = Math.round(chord.time || 0.1);
        groupedChords[chordTime] = {
          time: chord.time,
          chord: chord.chord,
          originalIndex: chordIndex,
        };
      });

      const gp = Object.values(groupedChords).map((chord) => ({
        time: chord.time,
        chord: chord.chord,
        originalIndex: chord.originalIndex,
      }));
      setchordsTs(gp);
      submit_chords_and_words1(gp);
      //
      setIsEditing((prevIsEditing) => !prevIsEditing);
    }
  };

  const exit_edit_mode = () => {
    setedit_chords_lyrics(false);
  };


  const edit_chord_lyricss = () => {
    setedit_chords_lyrics(true);
  };

  const ToggleEditMode = () => {


    const handleToggle = () => {
      setedit_chords_lyrics((prevState) => !prevState); // Toggles state correctly

    };

    return (
      <Container>
        <Label3>Edit Mode</Label3>
        <ToggleSwitch>
          <ToggleInput
            type="checkbox"
            checked={edit_chords_lyrics}
            onChange={handleToggle}
          />
          <Slider3 active={edit_chords_lyrics} />
        </ToggleSwitch>
      </Container>
    );
  };




  const save_changes = async () => {
    handle_save_as_original_popup();
  };

  useEffect(() => {
    if (timesArray.length > 0) {
    }
  }, [timesArray]);

  const processRows = () => {
    const rows = [];
    const groupedChords = {};
    let translateLineRef = 0;
  
    // Combine all items and sort by start time
    const allItems = [
      ...wordsTs.map((word, index) => ({
        ...word,
        type: 'word',
        originalIndex: index,
        start: parseFloat(word.start),
        end: parseFloat(word.end)
      })),
      ...chordsTs.map((chord, index) => ({
        type: 'chord',
        start: parseFloat(chord.time),
        end: parseFloat(chord.time),
        value: chord.chord,
        originalIndex: index,
      })),
    ].sort((a, b) => a.start - b.start);
  
    let currentChordsRow = [];
    let currentWordsRow = [];
    let currentWordIndices = [];
    let currentChordIndices = [];
    let finalRows = [];
    let processedChordTimes = new Set(); // Track processed chord times
  
    allItems.forEach((item, idx) => {
      if (item.type === 'word' && item.word.includes('\n')) {
        const wordParts = item.word.split('\n');
  
        if (wordParts[0]) {
          // Find chords for the first word part
          const associatedChords = chordsTs.filter(chord => {
            const chordTime = parseFloat(chord.time);
            const timeDiff = Math.abs(chordTime - ((item.start + item.end) / 2));
            return (
              chordTime >= item.start && 
              chordTime <= item.end && 
              !processedChordTimes.has(chordTime)
            );
          });
  
          currentWordsRow.push(wordParts[0].trim());
          currentWordIndices.push(item.originalIndex);
          
          // Add associated chords
          if (associatedChords.length > 0) {
            associatedChords.forEach(chord => {
              processedChordTimes.add(parseFloat(chord.time));
              currentChordsRow.push(chord.chord);
              currentChordIndices.push(chordsTs.findIndex(c => 
                c.time === chord.time && c.chord === chord.chord
              ));
            });
          }
  
          finalRows.push({
            type: 'chords',
            row: [...currentChordsRow],
            originalIndices: [...currentChordIndices],
          });
          finalRows.push({
            type: 'words',
            row: [...currentWordsRow],
            originalIndices: [...currentWordIndices],
          });
  
          // Add translation if available
          if (translate && translationData && translationData.length > 0 && translateLineRef < translationData.length) {
            finalRows.push({
              type: 'translation',
              row: translationData[translateLineRef].line.split(" "),
              originalIndices: [-1],
            });
            translateLineRef++;
          }
  
          // Reset for next iteration
          currentChordsRow = [];
          currentWordsRow = [];
          currentWordIndices = [];
          currentChordIndices = [];
  
          // Handle second part of split word if exists
          if (wordParts[1]) {
            currentWordsRow.push(wordParts[1].trim());
            currentWordIndices.push(item.originalIndex);
          }
        }
      } else if (item.type === 'chord') {
        // Add chord if not processed before
        if (!processedChordTimes.has(parseFloat(item.start))) {
          currentChordsRow.push(item.value);
          currentWordsRow.push('');
          currentWordIndices.push(-1);
          currentChordIndices.push(item.originalIndex);
          processedChordTimes.add(parseFloat(item.start));
        }
      } else if (item.type === 'word' && item.word !== '') {
        // Find chords for this word
        const associatedChords = chordsTs.filter(chord => {
          const chordTime = parseFloat(chord.time);
          return (
            chordTime >= item.start && 
            chordTime <= item.end && 
            !processedChordTimes.has(chordTime)
          );
        });
  
        currentWordsRow.push(item.word.trim());
        currentWordIndices.push(item.originalIndex);
        
        // Add associated chords
        if (associatedChords.length > 0) {
          associatedChords.forEach(chord => {
            processedChordTimes.add(parseFloat(chord.time));
            currentChordsRow.push(chord.chord);
            currentChordIndices.push(chordsTs.findIndex(c => 
              c.time === chord.time && c.chord === chord.chord
            ));
          });
        } else {
          currentChordsRow.push('');
          currentChordIndices.push(-1);
        }
      }
    });
  
    // Handle any remaining rows
    if (currentChordsRow.length > 0 || currentWordsRow.length > 0) {
      finalRows.push({
        type: 'chords',
        row: currentChordsRow,
        originalIndices: currentChordIndices,
      });
      finalRows.push({
        type: 'words',
        row: currentWordsRow,
        originalIndices: currentWordIndices,
      });
    }
  
    setFinalRows(finalRows);
    return finalRows;
};

 const calculateWidth = (text) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = "15px Arial"; // Set the font size and style to match your UI
    const metrics = context.measureText(text);
    return metrics.width;
  };

  const sharpToFlatRootMap = {
    "C#": "Db",
    "D#": "Eb",
    "F#": "Gb",
    "G#": "Ab",
    "A#": "Bb",
  };

  const convertSharpToFlat = (chord) => {
    const displayChord = chord
      .replace(/maj(?![a-zA-Z0-9])/gi, "") // Remove "maj" suffix
      .replace(/minor(?![a-zA-Z0-9])/gi, "m") // Replace "minor" with "m"
      .replace(/min(?![a-zA-Z0-9])/gi, "m") // Replace "min" with "m" for consistency
      .replace(/m7(?![a-zA-Z0-9])/gi, "m7")
      .replace(/min7(?![a-zA-Z0-9])/gi, "m7");
      
    // Extract the root note (including # if present)
    let root = "";
    if (chord.startsWith("A#") || chord.startsWith("C#") || chord.startsWith("D#") || 
        chord.startsWith("F#") || chord.startsWith("G#")) {
      root = chord.substring(0, 2);
    } else {
      root = chord.substring(0, 1);
    }
    
    const rest = chord.substring(root.length);
    const flatRoot = sharpToFlatRootMap[root] || root;
    return flatRoot + rest;
  };


  const convertFlatToSharp = (chord) => {
    // Extract the root note (including b if present)
    let root = "";
    if (chord.startsWith("Ab") || chord.startsWith("Bb") || chord.startsWith("Db") || 
        chord.startsWith("Eb") || chord.startsWith("Gb")) {
      root = chord.substring(0, 2);
    } else {
      root = chord.substring(0, 1);
    }
    
    const rest = chord.substring(root.length);
    const sharpRoot = flatToSharpRootMap[root] || root;
    return sharpRoot + rest;
  };

  const flatToSharpRootMap = {
    Db: "C#",
    Eb: "D#",
    Gb: "F#",
    Ab: "G#",
    Bb: "A#",
  };

  const handleChordUpdate = (index, newChord) => {
    const updatedChordsTs = [...chordsTs];
    updatedChordsTs[index] = {
      ...updatedChordsTs[index],
      chord: newChord,
    };
    setchordsTs(updatedChordsTs);
    submit_chords_and_words1(updatedChordsTs);
  };

  const handledelete_Chord = (time) => {
    setchordsTs((prevChords) => {
      const roundedTime = Math.round(time || 0.1);
      const updatedChordsTs = prevChords.filter(
        (chord) => Math.round(chord.time || 0.1) !== roundedTime
      );

      submit_chords_and_words1(updatedChordsTs);

      return updatedChordsTs;
    });
};

  const handledelete_Words = (time, index, word) => {
    setwordsTs((prevWords) => {
      const updatedWordsTs = prevWords.filter((word, i) => i !== index);

      submit_chords_and_words(updatedWordsTs);

      return updatedWordsTs;
    });
};

  const handleLyricsUpdate = (index, newLyrics) => {
    let newword;
    if (lastitem !== "") {
      newword = newLyrics + "\n";
    } else {
      newword = newLyrics;
    }
    const updatedWordsTs = [...wordsTs];
    updatedWordsTs[index] = {
      ...updatedWordsTs[index],
      word: newword,
    };

    setwordsTs(updatedWordsTs);
    submit_chords_and_words(updatedWordsTs);
    setEditingWord(null);
  };

  const handleBlur1 = async (rowIndex, itemIndex, event) => {
   
      const finalRow = finalRows[rowIndex];
      if (!finalRow) return;
      
      let timeToSet = null;
      
      if (finalRow.type === "chords") {
        const originalIndex = finalRow.originalIndices[itemIndex];
        if (originalIndex !== -1 && chordsTs && chordsTs[originalIndex] && chordsTs[originalIndex].time !== undefined) {
          timeToSet = parseFloat(chordsTs[originalIndex].time);
        }
      } else if (finalRow.type === "words") {
        const originalIndex = finalRow.originalIndices[itemIndex];
        if (originalIndex !== -1 && wordsTs && wordsTs[originalIndex] && wordsTs[originalIndex].start !== undefined) {
          timeToSet = parseFloat(wordsTs[originalIndex].start);
        }
      }
      
      if (timeToSet !== null && !isNaN(timeToSet)) {
        setCurrentTime(timeToSet);
        lastUpdateTime = timeToSet;

        if (mp3Player) {
          try {
            mp3Player.currentTime = timeToSet;
          } catch (error) {
           
          }
        }
    
        if (playerRef.current) {
          try {
            playerRef.current.seekTo(timeToSet, "seconds");
          } catch (error) {
           
          }
        }
      }
      
      return;
  };
  
 const draggedItemRef = useRef(null);
  const TimelineChordTooltip = () => {
    if (!tooltipData.visible || !tooltipData.imgSrc) return null;

    const getStyles = () => {
      const baseStyles = {
        position: 'absolute',
        zIndex: 1000,
        background: 'rgba(0, 0, 0, 0.8)',
        borderRadius: '5px',
        padding: '8px',
        pointerEvents: 'none',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)',
        textAlign: 'center'
      };

      switch (tooltipData.placement) {
        case 'top':
          return {
            ...baseStyles,
            left: `${tooltipData.position.x - 30}px`,
            top: `${tooltipData.position.y - 45}px`,
          };
        case 'bottom':
          return {
            ...baseStyles,
            left: `${tooltipData.position.x}px`,
            top: `${tooltipData.position.y}px`,
          };
        case 'left':
          return {
            ...baseStyles,
            left: `${tooltipData.position.x}px`,
            top: `${tooltipData.position.y}px`,
          };
        case 'right':
          return {
            ...baseStyles,
            left: `${tooltipData.position.x - 10}px`,
            top: `${tooltipData.position.y}px`,
          };
        default:
          return baseStyles;
      }
    };

    return (
      <div style={getStyles()}>
        <StyledImage
          src={tooltipData.imgSrc}
          alt={tooltipData.chord}
        />
        <p style={{ color: 'white', fontSize: '12px', marginTop: '4px', marginBottom: '0' }}>
          {tooltipData.chord}
        </p>
      </div>
    );
  };

  const handleTimelineChordMouseEnter = (event, chord) => {
    if (!mouseActive) return;
    if (!chord || chord.trim() === '') return;

    const formattedName = formatChordName(chord);
    const displayChord = formattedName.includes("/")
      ? formattedName.split("/")[0]
      : formattedName;

    const imgSrc = images[displayChord];

    if (imgSrc) {
      const rect = event.currentTarget.getBoundingClientRect();
      const containerRect = timelineRef.current.getBoundingClientRect();

      const spaceAbove = rect.top - containerRect.top;
      const spaceBelow = containerRect.bottom - rect.bottom;
      const spaceLeft = rect.left - containerRect.left;
      const spaceRight = containerRect.right - rect.right;

      const tooltipHeight = 140;
      const tooltipWidth = 100;

      let placement = 'top';
      let posX = rect.left + (rect.width / 2) - (tooltipWidth / 2);
      let posY = rect.top - tooltipHeight - 10;

      if (spaceAbove < tooltipHeight) {
        // Not enough space above, try below
        if (spaceBelow >= tooltipHeight) {
          placement = 'bottom';
          posY = rect.bottom + 5;
        }
        // If not enough space below either, try left or right
        else if (spaceLeft >= tooltipWidth && spaceLeft > spaceRight) {
          placement = 'left';
          posX = rect.left - tooltipWidth - 10;
          posY = rect.top + (rect.height / 2) - (tooltipHeight / 2);
        }
        else if (spaceRight >= tooltipWidth) {
          placement = 'right';
          posX = rect.right + 10;
          posY = rect.top + (rect.height / 2) - (tooltipHeight / 2);
        }
        // If no good option, default to above but clamped to container
        else {
          placement = 'top';
          posY = Math.max(containerRect.top + 10, rect.top - tooltipHeight - 10);
        }
      }

      posX = Math.max(containerRect.left + 10, Math.min(containerRect.right - tooltipWidth - 10, posX));

      posX = posX - containerRect.left + timelineRef.current.scrollLeft;
      posY = posY - containerRect.top + timelineRef.current.scrollTop;

      setTooltipData({
        visible: true,
        chord: chord,
        position: { x: posX, y: posY },
        placement: placement,
        imgSrc: imgSrc
      });
    }
  };

  const handleTimelineChordMouseLeave = () => {
    setTooltipData(prev => ({ ...prev, visible: false }));
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsManualScrolling(true);
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }

      scrollTimeoutRef.current = setTimeout(() => {
        setIsManualScrolling(false);
      }, 4000);
    };

    const attachScrollListener = () => {
      const scrollElement = timelineRef.current;
      if (scrollElement) {
        scrollElement.addEventListener("scroll", handleScroll);
      } else {
       }
    };

    attachScrollListener();

     const observer = new MutationObserver(() => {
      if (timelineRef.current) {
        attachScrollListener();
        observer.disconnect();
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      const scrollElement = timelineRef.current;
      if (scrollElement) {
        scrollElement.removeEventListener("scroll", handleScroll);
      }
      clearTimeout(scrollTimeoutRef.current); // Clear timeout on unmount
      observer.disconnect();
    };
  }, []);

const TimeSegmentRenderer = ({
    startTime,endTime,index,chordsTs,wordsTs,handleyouClick_delchords,handleyouClick_delwords,
    totalDuration, currentTime, loopStart, loopEnd, isLoop, 
  }) => {
    const [draggedItem, setDraggedItem] = useState(null);
    const containerRef = useRef(null);
    const [hoveredItem, setHoveredItem] = useState(null);

    const groupedChords = {};
    chordsTs.forEach((chord, chordIndex) => {
      const chordTime = chord.time; //Math.round(chord.time || 0.1);
      groupedChords[chordTime] = {
        time: chord.time,
        chord: chord.chord,
        originalIndex: chordIndex,
      };
    });

    const gp = Object.values(groupedChords).map((chord) => ({
      time: chord.time,
      chord: chord.chord,
      originalIndex: chord.originalIndex,
    }));

    const handleDragStart = (e, item, type, rowIndex) => {
      e.dataTransfer.setData("text/plain", "");

      const itemData = {
        ...item,
        type,
        sourceRowIndex: index,
        originalTime: type === "word" ? item.start : item.time,
      };
      draggedItemRef.current = itemData; // Store dragged item in the ref

      const dragImage = document.createElement("div");
      dragImage.textContent = type === "chord" ? item.chord : item.word;
      dragImage.style.position = "absolute";
      dragImage.style.top = "-1000px";
      document.body.appendChild(dragImage);
      e.dataTransfer.setDragImage(dragImage, 0, 0);
      document.body.removeChild(dragImage);
    };

    const handleDragOver = (e) => {
      e.preventDefault();

      if (!draggedItem) return;

      const timelineContainer = document.querySelector(
        ".w-full.border.border-gray-700"
      );
      if (!timelineContainer) return;

      const timelineRect = timelineContainer.getBoundingClientRect();
      const relativeY = e.clientY - timelineRect.top;
      const totalRows = Math.ceil(totalDuration / SECONDS_PER_LINE1);
      const rowHeight = timelineRect.height / totalRows;
      e.dataTransfer.dropEffect = "move";
    };

    useEffect(() => {
      if (hiddenTextRef.current) {
        setWidth(`${hiddenTextRef.current.offsetWidth + 5}px`); // Added padding/margin to account for space
      }
    }, [editedWordValue]);
     useEffect(() => {
      if (hiddenTextRef1.current) {
        setWidth(`${hiddenTextRef1.current.offsetWidth + 5}px`); // Added padding/margin to account for space
      }
    }, [editedChordValue]);

    const handleDrop = (e) => {
      e.preventDefault();
      const draggedItem = draggedItemRef.current;
      if (!draggedItem) {
        return;
      }
      const timelineContainer = document.querySelector(
        ".w-full.border.border-gray-700"
      );
      if (!timelineContainer) {
        return;
      }
      const timelineRect = timelineContainer.getBoundingClientRect();

      const relativeY = e.clientY - timelineRect.top;
      const totalRows = Math.ceil(totalDuration / SECONDS_PER_LINE1);
      const rowHeight = timelineRect.height / totalRows;
      const targetRowIndex = Math.floor(relativeY / rowHeight);

      const segmentRect = containerRef.current.getBoundingClientRect();
      const relativeX = e.clientX - segmentRect.left;
      const horizontalPercent = Math.max(
        0,
        Math.min(1, relativeX / segmentRect.width)
      );

      const rowStartTime = targetRowIndex * SECONDS_PER_LINE1;
      const timeWithinRow = horizontalPercent * SECONDS_PER_LINE1;
      const newTime = Math.min(
        rowStartTime + timeWithinRow,
        totalDuration - 0.1
      );
      if (draggedItem.type === "word") {
        const updatedWords = [...wordsTs];
        if (draggedItem.originalIndexes) {
          draggedItem.originalIndexes
            .sort((a, b) => b - a)
            .forEach((index) => {
              updatedWords.splice(index, 1);
            });
          updatedWords.push({
            word: draggedItem.word,
            start: newTime,
            end: newTime + 0.1,
          });
        } else {
          updatedWords.splice(draggedItem.originalIndex, 1);
          updatedWords.push({
            word: draggedItem.word,
            start: newTime,
            end: newTime + 0.1,
          });
        }
        setwordsTs(updatedWords);
        submit_chords_and_words(updatedWords);
        handleDragEnd();
      } else if (draggedItem.type === "chord") {
        const updatedChords = [...chordsTs];
        updatedChords.splice(draggedItem.originalIndex, 1);
        updatedChords.push({
          chord: draggedItem.chord,
          time: newTime,
        });
        setchordsTs(updatedChords);
        submit_chords_and_words1(updatedChords);
        handleDragEnd();
      }
      setDraggedItem(null);
    };

    const handleTouchStart = (e, item, type) => {
      if (e.targetTouches.length === 1) {
        draggedItemRef.current = {
          ...item,
          type,
          sourceRowIndex: index,
          originalTime: type === "word" ? item.start : item.time,
        };
      }
    };

    const handleTouchMove = (e) => {
      e.preventDefault();
      if (!draggedItemRef.current) return;

      const timelineContainer = containerRef.current;
      const rect = timelineContainer.getBoundingClientRect();
      const touch = e.touches[0];
      const relativeX = touch.clientX - rect.left;
      const relativeY = touch.clientY - rect.top;
      const scrollThreshold = 50; // Adjust as needed
      if (relativeY < scrollThreshold) {
        timelineContainer.scrollTop -= 10; // Adjust scroll speed
      } else if (relativeY > rect.height - scrollThreshold) {
        timelineContainer.scrollTop += 10; // Adjust scroll speed
      }

      const newTime =
        Math.min(relativeX / rect.width, 1) * SECONDS_PER_LINE1 + startTime;
      draggedItemRef.current.newTime = newTime;
    };

    const handleTouchEnd = () => {
      if (!draggedItemRef.current) return;

      const draggedItem = draggedItemRef.current;
      if (draggedItem.type === "chord") {
        const updatedChords = [...chordsTs];
        updatedChords.splice(draggedItem.originalIndex, 1);
        updatedChords.push({ ...draggedItem, time: draggedItem.newTime });
        setchordsTs(updatedChords);
      } else if (draggedItem.type === "word") {
        const updatedWords = [...wordsTs];
        updatedWords.splice(draggedItem.originalIndex, 1);
        updatedWords.push({ ...draggedItem, start: draggedItem.newTime });
        setwordsTs(updatedWords);
      }
      draggedItemRef.current = null;
    };

    const [smoothCurrentTime, setSmoothCurrentTime] = useState(currentTime);
    const isActiveRow = currentTime >= startTime && currentTime < endTime;
    const smoothLeftPosition = useMemo(() => {
      // Only perform the calculation if shouldCalculate is true
      if (!shouldCalculate){
        return null;
      } else if(!isActiveRow){
        return '0%';
      }else{
        // console.log("One :", currentTime);
        const relativeTime = Math.max(0, smoothCurrentTime - startTime);
        const position = (relativeTime / (endTime - startTime)) * 100;
        return `${Math.min(position, 100)}%`;
      }
     
    }, [shouldCalculate, isActiveRow, smoothCurrentTime, startTime, endTime]);
   
    // Then in your condition
    if (countRef.current) {
      // console.log("One second has passed, performing special rendering at time:", currentTime);
      countRef.current = false;
     
      // Set shouldCalculate to true to trigger the calculation in useMemo
      setShouldCalculate(true);
     
      // Now you can use smoothLeftPosition here
    }

    const handleRowClick = (e) => {
      e.stopPropagation();
      e.preventDefault();

      if (!containerRef.current) return;

      const containerRect = containerRef.current.getBoundingClientRect();
      const clickX = e.clientX - containerRect.left;
      const rowWidth = containerRect.width;
      const clickedTime = startTime + (clickX / rowWidth) * (endTime - startTime);
      if (!edit_chords_lyrics) {
        setSmoothCurrentTime(clickedTime);
        setCurrentTime(clickedTime);
        if (mp3Player) {
          try {
            mp3Player.currentTime = clickedTime;
          } catch (error) {
           
          }
        }
    
        if (playerRef.current) {
          try {
            playerRef.current.seekTo(clickedTime, "seconds");
          } catch (error) {
           
          }
        }
      }

      if (isLoop) {
        if (!loopStart || !loopEnd) {
          if (!isStartSet && clickedTime <= (loopEnd || endTime)) {
            setLoopStart(clickedTime);
            setIsStartSet(true);
            return;
          } else if (isStartSet && clickedTime > loopStart) {
            setLoopEnd(clickedTime);
            setIsStartSet(false);
            return;
          }
        }
        if (clickedTime < loopStart || clickedTime > loopEnd) return;
      }
      

      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
        scrollTimeoutRef.current = null;
      }
      setIsManualScrolling(false);
      wordRef.current = null;
     };

    return (
      <TimeSegment
        key={index}
        ref={containerRef}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={(event) => {
          // console.log("TimeSegment clicked!", { index, event });
          handleRowClick(event);
        }}
        style={{
          position: 'relative',
          overflow: 'hidden',
          cursor: 'pointer', // Ensures it's clickable
          pointerEvents: 'auto'
        }}
        className="timeline-row"
      >
        {isActiveRow && (
          <div
            style={{
              position: 'absolute',top: 10,left: smoothLeftPosition,height: '100%',width: '2px',backgroundColor: 'red', zIndex: 50,
            }}
          />
        )}

        {loopStart && (
          <div
            style={{
              position: "absolute",left: `${((loopStart - startTime) / (endTime - startTime)) * 100}%`, top: 0, bottom: 0, width: "4px",
              backgroundColor: "#F0984B", cursor: "ew-resize", zIndex: 1000,
            }}
          />
        )}

        {loopEnd && (
          <div
            style={{
              position: "absolute", left: `${((loopEnd - startTime) / (endTime - startTime)) * 100}%`,  top: 0,bottom: 0, width: "4px",
              backgroundColor: "#F0984B",cursor: "ew-resize", zIndex: 1000,
            }}
          />
        )}

        {loopStart && loopEnd && (
          <div
            style={{
              position: "absolute",left: `${((loopStart - startTime) / (endTime - startTime)) * 100}%`, top: 0, bottom: 0,
              width: `${((loopEnd - loopStart) / (endTime - startTime)) * 100}%`, backgroundColor: "#747474", zIndex: 0, // Ensure it appears behind the markers
            }}
          />
        )}

       {[...Array(Math.ceil((endTime - startTime) / 1))].map((_, i) => {
          const timeLabel = Math.floor(startTime + i);
          return (
            <TimeLabel
              key={`time-label-${timeLabel}`}
              style={{
                left: (i / (endTime - startTime)) * 100 + "%",
              }}
            >
              {timeLabel} {"s"}
            </TimeLabel>
          );
        })}

        {startTime % 1 === 0 && startTime !== 0 && <Divider />}

        {gp
          .filter((chord) => chord.time >= startTime && chord.time < endTime)
          .map((chord, chordIndex) => {
            const isHighlighted = !isManualScrolling && (
              currentTime >= chord.time - 0.5 &&
              currentTime <= chord.time + 0.5);
            return (
              <div onMouseMove={handleMouseActivity}>
              <ItemContainer
                key={`chord-${index}-${chordIndex}`}
                left={((chord.time - startTime) / SECONDS_PER_LINE1) * 100}
                top={25}
                type="chord"
                isHighlighted={isHighlighted}
                ref={!isManualScrolling && isHighlighted ? wordRef : null}
                onMouseEnter={(e) => {
                  if (!edit_chords_lyrics) {
                    handleTimelineChordMouseEnter(e, chord.chord);
                  } else {setHoveredItem(`chord-${index}-${chordIndex}`); }
                }}
                onMouseLeave={() => {
                  if (!edit_chords_lyrics) {
                    handleTimelineChordMouseLeave();
                  } else {setHoveredItem(null); }
               }}
              >
               
                {hoveredItem === `chord-${index}-${chordIndex}` &&
                  !editingChord &&
                  !edt2 &&
                  !version_select && edit_chords_lyrics && (
                    <GripButton
                      draggable
                      onDragStart={(e) =>
                        handleDragStart(e, chord, "chord", index)
                      }
                      onTouchStart={(e) => handleTouchStart(e, chord, "chord")}
                      onTouchMove={handleTouchMove}
                      onTouchEnd={handleTouchEnd}
                      style={{ position: "absolute", left: -25, cursor: "grab" }}
                    >
                      <GripHorizontal size={14} />
                    </GripButton>
                  )}

               {editingChord?.originalIndex === chord.originalIndex && !edt2 && edit_chords_lyrics && !version_select ? (

                  <InputText20>
                    <InputText2
                      ref={contentEditableRef1}
                      contentEditable
                      suppressContentEditableWarning
                      onKeyDown={(e) => {
                        if (e.key === 'Backspace') {
                          e.preventDefault();
                          const selection = window.getSelection();
                          if (!selection.rangeCount) return;

                          const range = selection.getRangeAt(0);
                          const { startContainer, startOffset } = range;

                          if (startOffset > 0 && startContainer.nodeType === Node.TEXT_NODE) {
                            const text = startContainer.textContent;
                            startContainer.textContent =
                              text.slice(0, startOffset - 1) + text.slice(startOffset);
                            range.setStart(startContainer, startOffset - 1);
                            range.collapse(true);
                            selection.removeAllRanges();
                            selection.addRange(range);
                          } else if (startOffset === 0 && startContainer.previousSibling) {
                            // Handle merging with the previous node if the cursor is at the start
                            const prevNode = startContainer.previousSibling;
                            if (prevNode.nodeType === Node.TEXT_NODE) {
                              const prevText = prevNode.textContent;
                              const currText = startContainer.textContent;
                              prevNode.textContent = prevText + currText;
                              startContainer.parentNode.removeChild(startContainer);
                              range.setStart(prevNode, prevText.length);
                              range.collapse(true);
                              selection.removeAllRanges();
                              selection.addRange(range);
                            }
                          }
                          return;
                        }
                        if (e.key.length === 1) {
                          e.preventDefault();

                          const selection = window.getSelection();
                          const range = selection.getRangeAt(0);
                          const textNode = document.createTextNode(e.key);
                          range.insertNode(textNode);

                          range.setStartAfter(textNode);
                          range.setEndAfter(textNode);
                          selection.removeAllRanges();
                          selection.addRange(range);
                        }
                      }}
                      onInput={(e) => {
                        const text = contentEditableRef1.current.textContent || '';
                        setEditedChordValue(text);
                      }}
                      style={{
                        userSelect: 'text',
                        WebkitUserSelect: 'text',
                        cursor: 'text',
                        outline: 'none',
                        caretColor: 'white'
                      }}
                    >
                      {editedChordValue}
                    </InputText2>

                    <HiddenText ref={hiddenTextRef1}>{editedChordValue}</HiddenText>
                    <IconButton40
                      onClick={() => {
                        const text = contentEditableRef1.current?.textContent || '';
                        handleChordSave(text);
                      }}
                      disabled={!editedChordValue.trim()}
                      aria-label="Save"
                    >
                      <img src={green_tick1} alt="Save" />
                    </IconButton40>
                    <IconButton42 onClick={handleChordCancel}
                      aria-label="Cancel"
                    >
                      <img src={white_cancel} alt="Cancel" />
                    </IconButton42>
                  </InputText20>
                ) : (
                  <span>{chord.chord}</span>
                )}

                {editingChord?.originalIndex === chord.originalIndex &&
                  validationError && edit_chords_lyrics && (
                    <div className="text-red-500 text-xs mt-1">
                      {validationError}
                    </div>
                  )}

                {hoveredItem === `chord-${index}-${chordIndex}` &&
                  !editingChord && !edt2 && !version_select && edit_chords_lyrics && (
                    <>
                      <Pencil
                        size={14}
                        style={{ cursor: "pointer", marginLeft: "1px" }}
                        onClick={() => handleEditChordStart(chord)}
                      />
                      <Trash2
                        src={del_variant}
                        size={14}
                        style={{ cursor: "pointer", marginLeft: "1px" }}
                        onClick={() => {
                          setCurrentChord({
                            chord: chord.chord,
                            time: chord.time,
                            index: chord.originalIndex,
                          });
                          handleyouClick_delchords();
                        }}
                      />
                    </>
                  )}
              </ItemContainer>
              </div>
            );
          })}

        {Object.values(
          wordsTs
            .map((word, wordIndex) => ({ ...word, originalIndex: wordIndex }))
            .reduce((acc, word) => {
              const { start } = word;
              if (!acc[start]) {
                acc[start] = {
                  word: word.word,
                  start: word.start,
                  originalIndexes: [word.originalIndex],
                };
              } else {
                acc[start].word += ` ${word.word}`;
                acc[start].originalIndexes.push(word.originalIndex);
              }
              return acc;
            }, {})
        )
          .filter((group) => group.start >= startTime && group.start < endTime)
          .map((word, index) => {
            const isHighlighted = !isManualScrolling && (
              currentTime >= word.start - 0.5 &&
              currentTime <= word.start + 0.5);

            return (
              <ItemContainer
                key={`word-${index}-${word.originalIndexes[0]}`}
                top={57}
                left={((word.start - startTime) / SECONDS_PER_LINE1) * 100}
                type="word"
                isHighlighted={isHighlighted}
                ref={!isManualScrolling && isHighlighted ? wordRef : null}

                onMouseEnter={() =>
                  setHoveredItem(`word-${word.originalIndexes[0]}`)
                }
                onMouseLeave={() => setHoveredItem(null)}
              >
                {hoveredItem === `word-${word.originalIndexes[0]}` &&
                  !editingWord && !edt2 && !version_select && edit_chords_lyrics && (
                    <><GripButton
                      draggable
                      onDragStart={(e) =>
                        handleDragStart(e, word, "word", index)
                      }
                      style={{
                        position: "absolute",
                        left: -25,
                        cursor: "grab",
                      }}
                    >
                      <GripHorizontal size={14} />
                    </GripButton>
                    </>
                  )}

                {editingWord?.originalIndex === word.originalIndexes[0] && !edt2 && edit_chords_lyrics && !version_select ? (
                  <><InputText20>
                    <InputTextword
                      ref={contentEditableRef}
                      contentEditable
                      suppressContentEditableWarning
                      onKeyDown={(e) => {
                        if (e.key === 'Backspace') {
                          e.preventDefault();

                          const selection = window.getSelection();
                          if (!selection.rangeCount) return;

                          const range = selection.getRangeAt(0);
                          const { startContainer, startOffset } = range;

                          if (startOffset > 0 && startContainer.nodeType === Node.TEXT_NODE) {
                            // Delete character from the text node
                            const text = startContainer.textContent;
                            startContainer.textContent =
                              text.slice(0, startOffset - 1) + text.slice(startOffset);

                            // Set the cursor position
                            range.setStart(startContainer, startOffset - 1);
                            range.collapse(true);
                            selection.removeAllRanges();
                            selection.addRange(range);
                          } else if (startOffset === 0 && startContainer.previousSibling) {
                            // Handle merging with the previous node if the cursor is at the start
                            const prevNode = startContainer.previousSibling;
                            if (prevNode.nodeType === Node.TEXT_NODE) {
                              const prevText = prevNode.textContent;
                              const currText = startContainer.textContent;
                              prevNode.textContent = prevText + currText;
                              startContainer.parentNode.removeChild(startContainer);

                              range.setStart(prevNode, prevText.length);
                              range.collapse(true);
                              selection.removeAllRanges();
                              selection.addRange(range);
                            }
                          }
                          return;
                        }
                        if (e.key.length === 1) {
                          e.preventDefault();

                          const selection = window.getSelection();
                          const range = selection.getRangeAt(0);

                          // Insert the character manually at the current cursor position
                          const textNode = document.createTextNode(e.key);
                          range.insertNode(textNode);

                          // Move the cursor after the inserted text
                          range.setStartAfter(textNode);
                          range.setEndAfter(textNode);
                          selection.removeAllRanges();
                          selection.addRange(range);

                        }
                      }}
                      onInput={(e) => {
                        const text = e.target.textContent || '';

                        setEditedWordValue(text);
                      }}
                      style={{
                        userSelect: 'text',
                        WebkitUserSelect: 'text',
                        cursor: 'text',
                        outline: 'none',
                        caretColor: 'white',
                      }}
                    >
                      {editedWordValue}
                    </InputTextword>
                    <HiddenText ref={hiddenTextRef}>
                      {editedWordValue}
                    </HiddenText>
                    <IconButton40

                      onClick={() => {
                        const text = contentEditableRef.current?.textContent || '';
                        handleWordSave(text);
                      }}
                      disabled={!editedWordValue.trim()}
                      aria-label="Save"
                    >
                      <img src={green_tick1} alt="Save" />
                    </IconButton40>
                    <IconButton42
                      onClick={handleWordCancel}
                      aria-label="Cancel"
                    >
                      <img src={white_cancel} alt="Cancel" />
                    </IconButton42>
                  </InputText20>
                  </>
                ) : (
                  <span>{word.word}</span>
                )}

                {hoveredItem === `word-${word.originalIndexes[0]}` &&
                  !editingWord && !edt2 && !version_select && edit_chords_lyrics && (
                    <>
                      <Pencil
                        size={14}
                        style={{ cursor: "pointer", marginLeft: "1px" }}
                        onClick={() => {
                          setCurrentword({
                            word: word.word,
                            time: word.start,
                            index: word.originalIndexes[0],
                          });
                          handleEditWordStart(word);
                        }}
                      />
                      <Trash2
                        src={del_variant}
                        size={14}
                        style={{ cursor: "pointer", marginLeft: "1px" }}
                        onClick={() => {
                          setCurrentword({
                            word: word.word,
                            time: word.start,
                            index: word.originalIndexes[0],
                          });
                          handleyouClick_delwords();
                        }}
                      />
                    </>
                  )}
              </ItemContainer>
            );
          })}

        <TextContainer>
          {newChords
            .filter((chord) => chord.row === index)
            .map((chord, chordIndex) => (
              <ItemContainer1
                key={`new-chord-${index}-${chordIndex}`}
                left={((chord.time - 0) / SECONDS_PER_LINE1) * 100}
                top={35}
                type="chord"
                onMouseEnter={() =>
                  setHoveredItem(`new-chord-${index}-${chordIndex}`)
                }
                onMouseLeave={() => setHoveredItem(null)}
              >
                {hoveredItem === `new-chord-${index}-${chordIndex}` &&
                  !editingChord && edit_chords_lyrics && (
                    <GripButton
                      draggable
                      onDragStart={(e) =>
                        handleDragStart1(e, chord, "chord", index)
                      }
                      style={{
                        position: "absolute",
                        left: -25,
                        cursor: "grab",
                      }}
                    >
                      <GripHorizontal size={14} />
                    </GripButton>
                  )}

                {/* Editable chord name */}
                {editingChord?.rowIndex === index && editingChord?.chordIndex === chordIndex ? (
                  <InputText209>
                    <input
                      type="text"
                      value={chord.chord}
                      onChange={(e) => {
                        const updatedChords = [...newChords];
                        updatedChords[chordIndex].chord = e.target.value;
                        setNewChords(updatedChords);
                      }}
                      autoFocus
                      style={{
                        outline: "none",
                        border: "1px solid white",
                        background: "transparent",
                        color: "white",
                        padding: "5px",
                        borderRadius: "4px",
                        width: "60%",
                      }}
                    />
                    <IconButton40
                      onClick={() => setEditingChord(null)}
                      aria-label="Save"
                    >
                      <img src={green_tick1} alt="Save" />
                    </IconButton40>
                    <IconButton42
                      onClick={() => setEditingChord(null)}
                      aria-label="Cancel"
                    >
                      <img src={white_cancel} alt="Cancel" />
                    </IconButton42>
                  </InputText209>
                ) : (
                  <span>{chord.chord}</span>
                )}


                {/* Pencil and Delete icons */}
                {hoveredItem === `new-chord-${index}-${chordIndex}` &&
                  !editingChord && edit_chords_lyrics && (
                    <>
                      <Pencil
                        size={14}
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                        onClick={() => setEditingChord({ rowIndex: index, chordIndex })}
                      />
                      <Trash2
                        src={del_variant}
                        size={14}
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                        onClick={() => {
                          setNewChords((prevChords) =>
                            prevChords.filter((_, i) => i !== chordIndex)
                          );
                        }}
                      />
                    </>
                  )}
              </ItemContainer1>
            ))}


          {newWord
            .filter((word) => word.row === index)
            .map((word, wordIndex) => (
              <ItemContainer1
                key={`new-word-${index}-${wordIndex}`}
                left={((word.time - 0) / SECONDS_PER_LINE1) * 100}
                top={35}
                type="word"
                onMouseEnter={() =>
                  setHoveredItem(`new-word-${index}-${wordIndex}`)
                }
                onMouseLeave={() => setHoveredItem(null)}>
                {hoveredItem === `new-word-${index}-${wordIndex}` && !editingWord && edit_chords_lyrics && (
                  <GripButton
                    draggable
                    onDragStart={(e) =>
                      handleDragStart1(e, word, "word", index)
                    }
                    style={{
                      position: "absolute",
                      left: -25,
                      cursor: "grab",
                    }}
                  >
                    <GripHorizontal size={14} />
                  </GripButton>
                )}

                {/* Editable word */}
                {editingWord?.rowIndex === index && editingWord?.wordIndex === wordIndex ? (
                  <InputText209>
                    <input
                      type="text"
                      value={word.word}
                      onChange={(e) => {
                        const updatedWords = [...newWord];
                        updatedWords[wordIndex].word = e.target.value;
                        setNewWord(updatedWords);
                      }}
                      autoFocus
                      style={{
                        outline: "none",
                        border: "1px solid white",
                        background: "transparent",
                        color: "white",
                        padding: "5px",
                        borderRadius: "4px",
                        width: "60%",
                      }}
                    />
                    <IconButton40
                      onClick={() => setEditingWord(null)}
                      aria-label="Save"
                    >
                      <img src={green_tick1} alt="Save" />
                    </IconButton40>
                    <IconButton42
                      onClick={() => setEditingWord(null)}
                      aria-label="Cancel"
                    >
                      <img src={white_cancel} alt="Cancel" />
                    </IconButton42>
                  </InputText209>
                ) : (
                  <span>{word.word}</span>
                )}

                {hoveredItem === `new-word-${index}-${wordIndex}` &&
                  !editingWord && edit_chords_lyrics && (
                    <>
                      <Pencil
                        size={14}
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                        onClick={() => setEditingWord({ rowIndex: index, wordIndex })}
                      />
                      <Trash2
                        src={del_variant}
                        size={14}
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                        onClick={() => {
                          setNewWord((prevWords) =>
                            prevWords.filter((_, i) => i !== wordIndex)
                          );
                        }}
                      />
                    </>
                  )}

              </ItemContainer1>
            ))}
          {!editingChord &&
            !edt2 && !version_select && edit_chords_lyrics && (
              <>
                <BoxedSpan

                  onClick={() =>
                    handleAddChord(index, startTime + SECONDS_PER_LINE1 / 2)
                  }
                >
                  +C
                </BoxedSpan>
                <BoxedSpan
                  onClick={() => {
                    handleAddword(index, startTime + SECONDS_PER_LINE1 / 2);
                  }}
                >
                  +L
                </BoxedSpan>
              </>
            )}

        </TextContainer>
      </TimeSegment>
    );
  };

  // Update the parent component to pass totalDuration
  const renderTimeSegment = (startTime, endTime, index) => {
    return (
      <TimeSegmentRenderer
        startTime={startTime}
        endTime={endTime}
        index={index}
        chordsTs={chordsTs}
        wordsTs={wordsTs}
        handleyouClick_delchords={handleyouClick_delchords}
        handleyouClick_delwords={handleyouClick_delwords}
        totalDuration={totalDuration} // Add this prop
        currentTime={currentTime}
        loopStart={loopStart}
        loopEnd={loopEnd}
        isLoop={isLoop}
      />
    );
  };

  const [mouseActive, setMouseActive] = useState(true);
  const mouseActivityTimeoutRef = useRef(null);
  const lastMousePositionRef = useRef({ x: 0, y: 0 });
  
  // Add this function to track mouse movement and manage activity state
  const handleMouseActivity = (event) => {
    const { clientX, clientY } = event;
    
    // Store the current mouse position
    lastMousePositionRef.current = { x: clientX, y: clientY };
    
    // Clear any existing timeout
    if (mouseActivityTimeoutRef.current) {
      clearTimeout(mouseActivityTimeoutRef.current);
    }
    
    // Mark mouse as active
    setMouseActive(true);
    
    // Set timeout to mark mouse as inactive after 2 seconds without movement
    mouseActivityTimeoutRef.current = setTimeout(() => {
      setMouseActive(false);
    }, 2000); // 2 seconds of inactivity
  };
  
  // Update your component to clean up the timeout
  useEffect(() => {
    // Cleanup function
    return () => {
      if (mouseActivityTimeoutRef.current) {
        clearTimeout(mouseActivityTimeoutRef.current);
      }
    };
  }, []);

 const [tooltipData, setTooltipData] = useState({
    visible: false,
    chord: '',
    position: { x: 0, y: 0 },
    placement: 'top', // 'top', 'bottom', 'left', or 'right'
    imgSrc: null
  });

   const ChordTooltip = () => {
    if (!tooltipData.visible || !tooltipData.imgSrc) return null;

    // Different style based on placement
    const getStyles = () => {
      const baseStyles = {
        position: 'absolute',
        zIndex: 1000,
        background: 'rgba(0, 0, 0, 0.8)',
        borderRadius: '5px',
        padding: '8px',
        pointerEvents: 'none',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)',
        textAlign: 'center'
      };

      switch (tooltipData.placement) {
        case 'top':
          return {
            ...baseStyles,
            left: `${tooltipData.position.x - 30}px`,
            top: `${tooltipData.position.y - 45}px`,
          };
        case 'bottom':
          return {
            ...baseStyles,
            left: `${tooltipData.position.x}px`,
            top: `${tooltipData.position.y}px`,
          };
        case 'left':
          return {
            ...baseStyles,
            left: `${tooltipData.position.x}px`,
            top: `${tooltipData.position.y}px`,
          };
        case 'right':
          return {
            ...baseStyles,
            left: `${tooltipData.position.x - 10}px`,
            top: `${tooltipData.position.y}px`,
          };
        default:
          return baseStyles;
      }
    };

    return (
      <div style={getStyles()}>
        <StyledImage
          src={tooltipData.imgSrc}
          alt={tooltipData.chord}
        />
        <p style={{ color: 'white', fontSize: '12px', marginTop: '4px', marginBottom: '0' }}>
          {tooltipData.chord}
        </p>
      </div>
    );
  };

 const isMouseOverChordRef = useRef(false);

// Maintain a list of active chord elements for tracking
const activeChordElementsRef = useRef(new Set());

// Enhanced mouse enter handler
const handleChordMouseEnter = (event, chord) => {
  // Only show tooltip if mouse is active
  if (!mouseActive) return;
  
  if (!chord || chord.trim() === '') return;
  
  // Mark that mouse is over chord element
  isMouseOverChordRef.current = true;
  
  // Add this element to active elements
  activeChordElementsRef.current.add(event.currentTarget);
  
  const formattedName = formatChordName(chord);
  const displayChord = formattedName.includes("/")
    ? formattedName.split("/")[0]
    : formattedName;

  const imgSrc = images[displayChord];

  if (imgSrc) {
    const rect = event.currentTarget.getBoundingClientRect();
    const containerRect = timelineRef2.current.getBoundingClientRect();

    // Same positioning logic as before
    const spaceAbove = rect.top - containerRect.top;
    const spaceBelow = containerRect.bottom - rect.bottom;
    const spaceLeft = rect.left - containerRect.left;
    const spaceRight = containerRect.right - rect.right;

    const tooltipHeight = 140;
    const tooltipWidth = 100;

    let placement = 'top';
    let posX = rect.left + (rect.width / 2) - (tooltipWidth / 2);
    let posY = rect.top - tooltipHeight - 10;

    if (spaceAbove < tooltipHeight) {
      if (spaceBelow >= tooltipHeight) {
        placement = 'bottom';
        posY = rect.bottom + 5;
      }
      else if (spaceLeft >= tooltipWidth && spaceLeft > spaceRight) {
        placement = 'left';
        posX = rect.left - tooltipWidth - 10;
        posY = rect.top + (rect.height / 2) - (tooltipHeight / 2);
      }
      else if (spaceRight >= tooltipWidth) {
        placement = 'right';
        posX = rect.right + 10;
        posY = rect.top + (rect.height / 2) - (tooltipHeight / 2);
      }
      else {
        placement = 'top';
        posY = Math.max(containerRect.top + 10, rect.top - tooltipHeight - 10);
      }
    }

    posX = Math.max(containerRect.left + 10, Math.min(containerRect.right - tooltipWidth - 10, posX));

    posX = posX - containerRect.left + timelineRef2.current.scrollLeft;
    posY = posY - containerRect.top + timelineRef2.current.scrollTop;

    setTooltipData({
      visible: true,
      chord: chord,
      position: { x: posX, y: posY },
      placement: placement,
      imgSrc: imgSrc
    });
  }
};

// Enhanced mouse leave handler
const handleChordMouseLeave = (event) => {
  // Remove this element from active elements
  activeChordElementsRef.current.delete(event.currentTarget);
  
  // If no elements are active, mark mouse as not over any chord
  if (activeChordElementsRef.current.size === 0) {
    isMouseOverChordRef.current = false;
    
    // Set a short timeout to prevent flickering when moving between elements
    setTimeout(() => {
      // Only hide if still not over any chord
      if (!isMouseOverChordRef.current) {
        setTooltipData(prev => ({ ...prev, visible: false }));
      }
    }, 50);
  }
};

// Global mouse move handler to detect when mouse moves outside all chord elements
const handleGlobalMouseMove = (e) => {
  // Return if we don't have the timeline reference
  if (!timelineRef2.current) return;
  
  // If tooltip is visible but mouse is outside the container, hide tooltip
  if (tooltipData.visible) {
    const containerRect = timelineRef2.current.getBoundingClientRect();
    
    const isOutsideContainer = 
      e.clientX < containerRect.left || 
      e.clientX > containerRect.right ||
      e.clientY < containerRect.top || 
      e.clientY > containerRect.bottom;
    
    if (isOutsideContainer) {
      isMouseOverChordRef.current = false;
      activeChordElementsRef.current.clear();
      setTooltipData(prev => ({ ...prev, visible: false }));
    }
  }
};

// Use effect to add/remove global mouse tracking
useEffect(() => {
  document.addEventListener('mousemove', handleGlobalMouseMove);
  
  return () => {
    document.removeEventListener('mousemove', handleGlobalMouseMove);
  };
}, [tooltipData.visible]);
   
  const createMyVariable = (rows) => {
    const allChordTimestamps = [];
    rows.forEach(row => {
      if (row.type === "chords") {
        row.row.forEach((item, itemIndex) => {
          if (item.trim() !== "") {
            const originalIndex = row.originalIndices[itemIndex];
            if (originalIndex !== -1 && chordsTs && chordsTs[originalIndex] && chordsTs[originalIndex].time !== undefined) {
              const chordTime = parseFloat(chordsTs[originalIndex].time);
              if (!isNaN(chordTime)) {
                allChordTimestamps.push({
                  time: chordTime,
                  originalIndex: originalIndex
                });
              }
            }
          }
        });
      }
    });
  
    allChordTimestamps.sort((a, b) => a.time - b.time);
  
    let activeChordIndex = -1;
    for (let i = 0; i < allChordTimestamps.length; i++) {
      if (currentTime >= allChordTimestamps[i].time) {
        activeChordIndex = allChordTimestamps[i].originalIndex;
        if (i < allChordTimestamps.length - 1 && currentTime >= allChordTimestamps[i + 1].time) {
          continue;
        }
      } else {
        break;
      }
    }
  
    return (
      <div onMouseMove={handleMouseActivity}>
        {rows.map((row, rowIndex) => {
          return (
            <Line key={rowIndex} type={row.type}>
              {row.row.length === 0 || !row.row.some(Boolean) ? (
                <Box
                  key={`empty-${rowIndex}`}
                  type={row.type}
                  width={row.widths[0] || 0}
                  editMode={isEditing}
                  isGrouped={row.grouped}
                  style={{ height: "20px", fontSize: "15px" }}
                />
              ) : (
                row.row.map((item, itemIndex) => {
                  let isWordHighlighted = false;
                  let isChordHighlighted = false;
  
                  if (row.type === "chords" && !highlight_chords && item.trim() !== "") {
                    const originalChordIndex = row.originalIndices[itemIndex];
  
                    isChordHighlighted = (originalChordIndex === activeChordIndex);
                  }
  
                  if (highlight_chords && row.type === "words") {
                    const wordObj = wordsTs[row.originalIndices[itemIndex]];
                    const wordTime = wordObj ? parseFloat(wordObj.start) : null;
  
                    isWordHighlighted =
                      wordTime &&
                      item.trim() !== "" &&
                      currentTime >= wordTime - 0.5 &&
                      currentTime < wordTime + 0.5;
                  }
  
                  const isHighlighted = isWordHighlighted || isChordHighlighted;
                  const highlightColor = row.type === "chords" ? "white" : "#F0984B";
  
                  const chordEvents = row.type === "chords" && item.trim() !== "" ? {
                    onMouseEnter: (e) => handleChordMouseEnter(e, item),
                    onMouseLeave: handleChordMouseLeave,
                  } : {};
  
                  return (
                    <Box
                      key={itemIndex}
                      type={row.type}
                      width={row.widths[itemIndex]}
                      editMode={isEditing}
                      isGrouped={row.grouped}
                      ref={isHighlighted ? wordRef2 : null}
                      style={{
                        fontSize: "15px",
                        cursor: "pointer",
                        backgroundColor: isHighlighted ? highlightColor : "transparent",
                        transition: "background-color 0.2s ease",
                      }}
                      onClick={(event) => handleBlur1(rowIndex, itemIndex, event)}
                      {...chordEvents}
                    >
                      {item}
                    </Box>
                  );
                })
              )}
            </Line>
          );
        })}
      </div>
    );
  };


  const submit_chords_and_words = async (updatedWordsTs) => {
    const encodedRequestId1 = encodeURIComponent(id);

    try {
      const response = await axios.patch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${selectedValue}`,
        {
          chords_ts: chordsTs,
          words_ts_newline: updatedWordsTs,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // handleyouClick_edittext1();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      } else if (error.response && error.response.status === 400) {
        
      } 
    }
  };

  const submit_chords_and_words1 = async (updatedChordsTs) => {
    const encodedRequestId1 = encodeURIComponent(id);

    try {
      const response = await axios.patch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${selectedValue}`,
        {
          chords_ts: updatedChordsTs,
          words_ts_newline: wordsTs,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // handleyouClick_edittext1();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      } else if (error.response && error.response.status === 400) {
       
      } 
    }
  };

  const sharp_to_flat = () => {
    const updatedRows = combinedRowsWithMaxWidths.map((row) => {
      if (row.type === "chords") {
        const updatedChords = row.row.map((chord) => {
          const rootNote = Object.keys(sharpToFlatRootMap).find((sharp) =>
            chord.startsWith(sharp)
          );
          if (rootNote) {
            return chord.replace(rootNote, sharpToFlatRootMap[rootNote]);
          }
          return chord;
        });

        return { ...row, row: updatedChords };
      }
      return row;
    });

    setCombinedRowsWithMaxWidths(updatedRows);
    setMyVariable(createMyVariable(updatedRows));
  };

  const flat_to_sharp = () => {
    const updatedRows = combinedRowsWithMaxWidths.map((row) => {
      if (row.type === "chords") {
        const updatedChords = row.row.map((chord) => {
          const rootNote = Object.keys(flatToSharpRootMap).find((flat) =>
            chord.startsWith(flat)
          );
          if (rootNote) {
            return chord.replace(rootNote, flatToSharpRootMap[rootNote]);
          }
          return chord;
        });

        return { ...row, row: updatedChords };
      }
      return row;
    });

    setCombinedRowsWithMaxWidths(updatedRows);
    setMyVariable(createMyVariable(updatedRows));
  };

  useEffect(() => {
    const combinedRows = processRows();
    const combinedRowsWithMaxWidths = [];
    let isGroupedChords = false;

    combinedRows.forEach((row, index, array) => {
      if (row.type === "chords") {
        const nextRow = array[index + 1];
        const isNextChordsWithoutWords =
          nextRow &&
          nextRow.type === "chords" &&
          (!array[index + 2] || array[index + 2].type !== "words");

        if (!row.row.some(Boolean)) {
          combinedRowsWithMaxWidths.push({
            type: "chords",
            row: [""], // Add an empty string to signify an empty row
            widths: [0], // Optional: Set a width of 0 for the empty row
            grouped: false,
            originalIndices: [-1],
          });
          return; // Skip to the next iteration
        }

        if (isNextChordsWithoutWords) {
          isGroupedChords = true;
        } else {
          const combinedWidths = row.row.map(calculateWidth);

          combinedRowsWithMaxWidths.push({
            type: "chords",
            row: row.row,
            widths: combinedWidths,
            grouped: isGroupedChords,
            originalIndices: row.originalIndices,
          });

          isGroupedChords = false;
        }
      } else if (row.type === "words") {
        const wordWidths = row.row.map((word) => calculateWidth(word));

        const prevRow =
          combinedRowsWithMaxWidths[combinedRowsWithMaxWidths.length - 1];
        if (prevRow && prevRow.type === "chords") {
          const chordWidths = prevRow.row.map((chord) => calculateWidth(chord));
          const maxWidths = wordWidths.map((wordWidth, i) =>
            Math.max(wordWidth, chordWidths[i] || 0)
          );
          prevRow.widths = maxWidths;
          row.widths = maxWidths;
        } else {
          row.widths = wordWidths;
        }

        combinedRowsWithMaxWidths.push(row);
      } else if (row.type === "translation") {
        const wordWidths = row.row.map((word) => calculateWidth(word));
        row.widths = wordWidths;
        combinedRowsWithMaxWidths.push(row);
      }
    });

    setCombinedRowsWithMaxWidths(combinedRowsWithMaxWidths);
    setMyVariable(createMyVariable(combinedRowsWithMaxWidths));
  }, [chordsTs, wordsTs, isEditing,  translationData]);

  useEffect(() => {
    setMyVariable(createMyVariable(combinedRowsWithMaxWidths));
  }, [ combinedRowsWithMaxWidths, isEditing, currentTime]);

  useEffect(() => {
    const allChords = combinedRowsWithMaxWidths
      .filter((row) => row.type === "chords")
      .flatMap((row) => row.row);
    const uniqueChordSet = new Set(allChords);
    }, [combinedRowsWithMaxWidths]); // Include isEditing in dependency array

  const showErrorToast = (message) => {
    toast(<div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={error_icon}
        alt="check icon"
        style={{ marginRight: "8px", width: "24px", height: "24px" }}
      />
      <span style={{ flex: 1 }}>{message}</span>
    </div>, { position: "bottom-left" }
    )
  }

  const fetchTranslationLines = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${config.apiUrl}/translate/`, {
        text: wordsTs,
        target_language: translate
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },)


      if (response.status === 200) {
        setTranslationData(response.data.translated_text)
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        clearToken();
        navigate("/");
      } else {
        showErrorToast("Unable to Translate Song")
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (translate) {
      fetchTranslationLines()
    } else {
      setTranslationData([])
    }
  }, [translate])

  downlines.push(myVariable);

// Define your scales
const sharpScale = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"];
const flatScale = ["C", "Db", "D", "Eb", "E", "F", "Gb", "G", "Ab", "A", "Bb", "B"];

// Define your transpose options
const transposeOptions = [
  { label: "- 1", value: -1 },
  { label: "+ 1", value: 1 },
];

// You should initialize these variables immediately when the component mounts
// or when the chord data is first loaded - BEFORE any transposition happens
const [originalChords, setOriginalChords] = useState(null);
const [originalUniqueChords, setOriginalUniqueChords] = useState(null);

// Run this effect only on component mount or initial data load
useEffect(() => {
  if (chordsTs && chordsTs.length > 0) {
    // Create deep copies to ensure no references are shared
    setOriginalChords(JSON.parse(JSON.stringify(chordsTs)));
  }
  
  if (uniqueChords && uniqueChords.length > 0) {
    // Create deep copies to ensure no references are shared
    setOriginalUniqueChords(JSON.parse(JSON.stringify(uniqueChords)));
  }
}, []); // Empty dependency array ensures this runs only once on mount

function transposeChord(chord, semitones) {
  const regex = /^([A-G][b#]?)(.*)$/;
  const matches = chord.match(regex);

  if (!matches) {
    return chord;
  }

  const root = matches[1];
  const suffix = matches[2];

  // Determine if the original chord uses flats or sharps
  const usesFlat = root.includes('b');
  
  // Choose the appropriate scale based on the original notation
  const scale = usesFlat ? flatScale : sharpScale;
  
  // Find the index in the appropriate scale
  const index = scale.indexOf(root);
  
  if (index === -1) return chord;
  
  // Calculate new index with proper wrapping
  const newIndex = (index + semitones + scale.length) % scale.length;
  
  return scale[newIndex] + suffix;
}

function transposeChords(chords, semitones) {
  return chords.map((chordData) => ({
    time: chordData.time,
    chord: transposeChord(chordData.chord, semitones),
  }));
}

function transposeChordsArray1(chords, semitones) {
  return chords.map((chord) => transposeChord(chord, semitones));
}

const handleClick = async (value) => {
  let newTransposedValue;

  if (value === 1) {
    newTransposedValue = transposedValue + 1;
    if (newTransposedValue > 11) {
      newTransposedValue = 0;
    }
  } else if (value === -1) {
    newTransposedValue = transposedValue - 1;
    if (newTransposedValue < -11) {
      newTransposedValue = 0;
    }
  }

  // Set the new transposed value
  setTransposedValue(newTransposedValue);

  if (newTransposedValue === 0) {
    // Reset to original chords when transposition is 0
    setActiveButton(null);
    
    // Make sure we're using the saved original values
    if (originalChords && originalUniqueChords) {
      setchordsTs(JSON.parse(JSON.stringify(originalChords)));
      setUniqueChords(JSON.parse(JSON.stringify(originalUniqueChords)));
    }
  } else {
    setActiveButton(value);
    
    // If we're doing the first click, make sure we're transposing from the originals
    // This handles the case when going from 0 to +1 or -1
    if (transposedValue === 0) {
      if (value === -1) {
        const transposedUp = transposeChords(originalChords, -1);
        const transup = transposeChordsArray1(originalUniqueChords, -1);
        
        setUniqueChords(transup);
        setchordsTs(transposedUp);
      } else if (value === 1) {
        const transposedDown = transposeChords(originalChords, 1);
        const transdown = transposeChordsArray1(originalUniqueChords, 1);
        
        setUniqueChords(transdown);
        setchordsTs(transposedDown);
      }
    } else {
      // Normal transposition from current value
      if (value === -1) {
        await minus_one2();
      } else if (value === 1) {
        await plus_one2();
      }
    }
  }
};

const minus_one2 = async () => {
  let transposedUp = transposeChords(chordsTs, -1);
  let transup = transposeChordsArray1(uniqueChords, -1);
  
  setUniqueChords(transup);
  setchordsTs(transposedUp);
};

const plus_one2 = async () => {
  let transposedDown = transposeChords(chordsTs, 1);
  let transdown = transposeChordsArray1(uniqueChords, 1);
  
  setUniqueChords(transdown);
  setchordsTs(transposedDown);
};

  const [position, setPosition] = useState({
    x: window.innerWidth / 2 - 100, // Center horizontally
    y: window.innerHeight - 100, // 100px from the bottom
  });

 useEffect(() => {
    let timer;

    // Auto-scrolling logic
    if (isActive) {
      timer = setInterval(() => {
        setElapsedTime((prev) => {
          const newTime = prev + 1;

          if (currentIndex < timesArray.length) {
            const nextScrollTime = timesArray[currentIndex];
            const timeUntilNextScroll = nextScrollTime - newTime;
          }

          if (
            currentIndex < timesArray.length &&
            newTime >= timesArray[currentIndex]
          ) {
            try {
              const newPosition = window.pageYOffset + 70;

              window.scrollTo({
                top: newPosition,
                behavior: "smooth",
              });

              setScrollPosition(newPosition);
              setCurrentIndex((prev) => prev + 1);
            } catch (error) { }
          }

          return newTime;
        });
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isActive, currentIndex, scrollPosition]);

  const handleToggle = () => {
    if (!isActive) {
      const cj2Element = document.getElementById("cj2");
      const element = document.getElementById("cj1");
      if (element) {
        const rect = element.getBoundingClientRect();
        const elementTop = rect.top + window.pageYOffset;


        window.scrollTo({
          top: elementTop - 100,
          behavior: "smooth",
        });

          setTimeout(() => {
          setCurrentIndex(0);
          setElapsedTime(0);
          setScrollPosition(window.pageYOffset);
          setIsActive(true);
        }, 500); // 500ms delay to allow scroll to complete
      }
    } else {
      setIsActive(false);
    }
  };

  const Deleteedit_chords = ({ onClose }) => {
    const handleDeleteClick = (event) => {
      event.preventDefault();

      const { chord, time, index } = currentChord;
      if (chord) {
        handledelete_Chord(time); // Use edt_text1 for the new chord
        onClose();
      }
    };

    const ActionButton = ({ text, primary, onClick }) => {
      return (
        <Button4 primary={primary} onClick={onClick}>
          {text}
        </Button4>
      );
    };

    return (
      <FormContainer4>
        <Form>
          <HeaderContainer4>
            <Title01>Warning</Title01>
            <CloseButton onClick={onClose}>×</CloseButton>
          </HeaderContainer4>

          <Content4>
            Are you sure you want to delete these Chords? This action is
            permanent and cannot be undone.
          </Content4>
          <ActionButton
            text="Delete"
            primary
            onClick={(e) => handleDeleteClick(e)}
          />

          <ActionButton text="Cancel" onClick={onClose} />

        </Form>
      </FormContainer4>
    );
  };

  const Deleteedit_words = ({ onClose }) => {
    const handleDeleteClick = (event) => {
      event.preventDefault();

      const { word, time, index } = currentword;
      if (word) {
        handledelete_Words(time, index, word); // Use edt_text1 for the new chord
        onClose();
      }
    };

    const ActionButton = ({ text, primary, onClick }) => {
      return (
        <Button4 primary={primary} onClick={onClick}>
          {text}
        </Button4>
      );
    };

    return (
      <FormContainer4>
        <Form>
          <HeaderContainer4>
            <Title01>Warning</Title01>
            <CloseButton onClick={onClose}>×</CloseButton>
          </HeaderContainer4>

          <Content4>
            Are you sure you want to delete these Words? This action is
            permanent and cannot be undone.
          </Content4>
          <ActionButton
            text="Delete"
            primary
            onClick={(e) => handleDeleteClick(e)}
          />

          <ActionButton text="Cancel" onClick={onClose} />

        </Form>
      </FormContainer4>
    );
  };

   const NewVersionForm = ({ onClose, id }) => {
    const navigate = useNavigate();
    const token = getToken();

    const [versionTitle, setversionTitle] = useState("");
    const [errormsg, setErrormsg] = useState(""); // Only using errormsg for error display

    const handleCreateClick = (event) => {
      event.preventDefault();

      if (versionTitle.trim() === "") {
        setErrormsg("Please enter a title");
      } else {
        setErrormsg(""); // Clear previous errors
        add_retry_version(versionTitle);
      }
    };

    const add_retry_version = async (title) => {
      const encodedRequestId1 = encodeURIComponent(id);

      try {
        const response = await axios.post(
          `${config.apiUrl}/url_requests/${encodedRequestId1}/retry_variant`,
          {
            name: title,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          get_retry_version(id);
          const ver = "Generating New Version";
          const title01 = title + " - " + ver; // Concatenate using +
          showToast5(title01);

          onClose();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        } else if (error.response && error.response.status === 400) {
          setErrormsg(error.response.data.message); // Display the actual error message
        } else {
          setErrormsg("An unknown error occurred"); // Handle any other errors
        }
      }
    };

    return (
      <FormContainer>
        <Form>
          <HeaderContainer>
            <Title01>Enter Version name</Title01>
            <CloseButton onClick={onClose}>×</CloseButton>
          </HeaderContainer>

          <InputContainer>
            <Label htmlFor="playlistTitle">Title</Label>
            <InputWrapper>
              <Input
                id="VersionTitle"
                type="text"
                placeholder="Version title"
                aria-label="Enter Version title"
                value={versionTitle}
                onChange={(e) => setversionTitle(e.target.value)}
              />
            </InputWrapper>
            {errormsg && <ErrorMessage>{errormsg}</ErrorMessage>}{" "}
            {/* Show error message only if present */}
          </InputContainer>

          <ButtonContainer01>
            <Button01 onClick={handleCreateClick}>Create</Button01>
          </ButtonContainer01>
        </Form>
      </FormContainer>
    );
  };

  const SaveAsForm = ({ onClose }) => {
    const navigate = useNavigate();
    const token = getToken();

    const [versionTitle, setversionTitle] = useState("");
    const [errormsg, setErrormsg] = useState("");

    const handleCreateClick = (event) => {
      event.preventDefault();

      if (versionTitle.trim() === "") {
        setErrormsg("Please enter a title");
      } else {
        setErrormsg(""); // Clear previous errors
        add_retry_version(versionTitle);
      }
    };

    const add_retry_version = async (title) => {
      const encodedRequestId1 = encodeURIComponent(id);

      try {
        const response = await axios.post(
          `${config.apiUrl}/url_requests/${encodedRequestId1}/variants`,
          {
            name: title,
            words_ts_formatted: [],
            chords_ts_formatted: [],
            words_ts_newline: wordsTs,
            chords_ts: chordsTs,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          setIsEditing(false);
          setEditable({ row: null, index: null });
          get_retry_version(id);
          // get_variant(title);
          const ver = "Generating New Version";
          const title01 = title + " - " + ver; // Concatenate using +
          showToast5(title01);

          onClose();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        } else if (error.response && error.response.status === 400) {
          setErrormsg(error.response.data.message); // Display the actual error message
        } else {
          setErrormsg("An unknown error occurred"); // Handle any other errors
        }
      }
    };

    return (
      <FormContainer>
        <Form>
          <HeaderContainer>
            <Title01>Enter Version name</Title01>
            <CloseButton onClick={onClose}>×</CloseButton>
          </HeaderContainer>

          <InputContainer>
            <Label htmlFor="playlistTitle">Title</Label>
            <InputWrapper>
              <Input
                id="VersionTitle"
                type="text"
                placeholder="Version title"
                aria-label="Enter Version title"
                value={versionTitle}
                onChange={(e) => setversionTitle(e.target.value)}
              />
            </InputWrapper>
            {errormsg && <ErrorMessage>{errormsg}</ErrorMessage>}{" "}
            {/* Show error message only if present */}
          </InputContainer>

          <ButtonContainer01>
            <Button01 onClick={handleCreateClick}>Create</Button01>
          </ButtonContainer01>
        </Form>
      </FormContainer>
    );
  };

  const DeleteVersionForm = ({ onClose, id, name }) => {
    const navigate = useNavigate();
    const token = getToken();

    const [versionTitle, setversionTitle] = useState("");
    const [errormsg, setErrormsg] = useState(""); // Only using errormsg for error display

    const delete_version = async (e) => {
      e.preventDefault();
      const encodedRequestId1 = encodeURIComponent(id);
      try {
        const response = await axios.delete(
          `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${name}`,

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          get_retry_version(id);

          onClose();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        } else if (error.response && error.response.status === 400) {
          setErrormsg(error.response.data.message); // Display the actual error message
        } else {
          setErrormsg("An unknown error occurred"); // Handle any other errors
        }
      }
    };

    const ActionButton = ({ text, primary, onClick }) => {
      return (
        <Button4 primary={primary} onClick={onClick}>
          {text}
        </Button4>
      );
    };

    return (
      <FormContainer4>
        <Form>
          <HeaderContainer4>
            <Title01>Warning</Title01>
            <CloseButton onClick={onClose}>×</CloseButton>
          </HeaderContainer4>

          <Content4>
            Are you sure you want to delete this version? This action cannot be
            undone, and all data associated with this version will be
            permanently lost.
          </Content4>
          <ActionButton
            text="Delete"
            primary
            onClick={(e) => delete_version(e)}
          />

          <ActionButton text="Cancel" onClick={onClose} />

          {/* <ButtonContainer01>
            <Button01 onClick={handleCreateClick}>Create</Button01>
          </ButtonContainer01> */}
        </Form>
      </FormContainer4>
    );
  };

  const EditVersionForm = ({ onClose, id, name }) => {
    const navigate = useNavigate();
    const token = getToken();

    const [versionTitle, setVersionTitle] = useState(name); // Initialize with name
    const [errormsg, setErrormsg] = useState("");

    useEffect(() => {
      setVersionTitle(name); // Set versionTitle to the existing name when the component mounts
    }, [name]);

    const handleCreateClick = (event) => {
      event.preventDefault();

      if (versionTitle.trim() === "") {
        setErrormsg("Please enter a title");
      } else {
        setErrormsg("");
        edit_name(versionTitle);
      }
    };

    const edit_name = async (edt_name) => {
      const encodedRequestId1 = encodeURIComponent(id);
      try {
        const response = await axios.post(
          `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${name}/rename`,
          {
            name: edt_name,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          get_retry_version(id);
          onClose();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        } else if (error.response && error.response.status === 400) {
          setErrormsg(error.response.data.message);
        } else {
          setErrormsg("An unknown error occurred");
        }
      }
    };

    return (
      <FormContainer>
        <Form>
          <HeaderContainer>
            <Title01>Edit Version name</Title01>
            <CloseButton onClick={onClose}>×</CloseButton>
          </HeaderContainer>

          <InputContainer>
            <Label htmlFor="versionTitle">Title</Label>
            <InputWrapper>
              <Input
                id="VersionTitle"
                type="text"
                placeholder="Version title"
                aria-label="Enter Version title"
                value={versionTitle} // Use versionTitle as the value
                onChange={(e) => setVersionTitle(e.target.value)} // Update versionTitle on change
              />
            </InputWrapper>
            {errormsg && <ErrorMessage>{errormsg}</ErrorMessage>}
          </InputContainer>

          <ButtonContainer01>
            <Button01 onClick={handleCreateClick}>Confirm</Button01>
          </ButtonContainer01>
        </Form>
      </FormContainer>
    );
  };

  const handleLoop = () => {
    setisLoop(!isLoop);
    if (!isLoop) {
      const activeRowStartTime = Math.floor(currentTime / SECONDS_PER_LINE1) * SECONDS_PER_LINE1;
     } else {
      setLoopStart(null);
      setLoopEnd(null);
    }
  };

  const setAudioTime = () => {
    const newTime = parseFloat(mp3Player.currentTime.toFixed(1)); // Get current time with one decimal
  
    if (isLoop && loopStart && loopEnd) {
      if (newTime < loopStart || newTime > loopEnd) {
        mp3Player.currentTime = loopStart; // Reset to loop start
      } else {
        handleTimeUpdate(newTime); // Update every second
      }
    } else {
      if (mp3Player.ended) {
        setCurrentTime(0);
        setIsPlaying(false);
        clearInterval(audioInterval.current);
      } else {
        handleTimeUpdate(newTime);
      }
    }
  };
  
  const playAudio = () => {
    if (mp3Player) {
      if (isPlaying) {
        mp3Player.pause();
        clearInterval(audioInterval.current);
      } else {
        mp3Player.play();
        audioInterval.current = setInterval(setAudioTime, 500); // Run every 1 second
      }
      setIsPlaying(!isPlaying);
    }
  };

 const play = () => {
    if (playerRef.current) {
      if (isPlaying && !isUploaded) {
        playerRef.current.pauseVideo(); // Pause the video
      } else {
        const element = document.getElementById("cj1");
        if (element) {
          const rect = element.getBoundingClientRect();
          const elementTop = rect.top + window.pageYOffset;

          window.scrollTo({
            top: elementTop - 0,
            behavior: "smooth",
          });

           setTimeout(() => {
            if (isLoop) {
              const currentTime = playerRef.current.getCurrentTime();
              if (currentTime < loopStart || currentTime >= loopEnd) {
                playerRef.current.seekTo(loopStart, true);
              }

              const loopCheckInterval = setInterval(() => {
                const currentTime = playerRef.current.getCurrentTime();
                if (currentTime >= loopEnd) {
                  playerRef.current.seekTo(loopStart, true);
                }
              }, 100); // Check every 100ms

              setLoopIntervalId(loopCheckInterval);
            }
            if (!isUploaded) {
              playerRef.current.playVideo();
            }
          }, 500);
        } else {
          if (isLoop) {
            const currentTime = playerRef.current.getCurrentTime();
            if (currentTime < loopStart || currentTime >= loopEnd) {
              playerRef.current.seekTo(loopStart, true);
            }

            const loopCheckInterval = setInterval(() => {
              const currentTime = playerRef.current.getCurrentTime();
              if (currentTime >= loopEnd) {
                playerRef.current.seekTo(loopStart, true);
              }
            }, 100);

            setLoopIntervalId(loopCheckInterval);
          }
          if (!isUploaded && !isMobile) {
            playerRef.current.playVideo();
          }
        }
      }
      setIsPlaying(!isPlaying);

      if (isPlaying && loopIntervalId) {
        clearInterval(loopIntervalId);
        setLoopIntervalId(null);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (loopIntervalId) {
        clearInterval(loopIntervalId);
      }
    };
  }, [loopIntervalId]);

   useEffect(() => {
    if (!isLoop && loopIntervalId) {
      clearInterval(loopIntervalId);
      setLoopIntervalId(null);
    }
  }, [isLoop]);

 const restart11 = () => {
   if (isUploaded && mp3Player) {
      mp3Player.currentTime = 0;
      if (!isPlaying) {
        setIsPlaying(true)
        mp3Player.play()
        audioInterval.current = setInterval(setAudioTime, 100)
      }
    } else {
      if (playerRef.current) {
        playerRef.current.seekTo(0);
      
      }
    }
  };

  useEffect(() => {

    const filteredChords = uniqueChords.map((chord) => {
      const formattedName = formatChordName(chord);
      const displayChord = formattedName.includes("/")
        ? formattedName.split("/")[0]
        : formattedName;
      const processedChord = displayChord.replace(/_+/g, "#");
      const flatName = convertSharpToFlat(processedChord);
      const imgSrc = images[displayChord]; // Use the processed chord with sharp for image lookup
      const isSharpChord = processedChord.includes("#");

      const processedChord1 = for_display_ChordName(processedChord);
      const chordDisplay = isSharpChord
        ? `${processedChord1}/${flatName}`
        : processedChord1;

      return { chord, imgSrc, displayChord, isSharpChord, flatName, chordDisplay }
    })
    setChordsImages(filteredChords)
  }, [uniqueChords])



  useEffect(() => {
    let interval;
    if (isPlaying) {
      interval = setInterval(() => {
      }, 0);

      setedt2(true);
    } else {
      clearInterval(interval);
      setedt2(false);
    }
    return () => clearInterval(interval);
  }, [isPlaying]);


  useEffect(() => {
    if (wordRef.current && timelineRef.current) {
      const highlightedWord = wordRef.current;
      const container = timelineRef.current;

      const containerRect = container.getBoundingClientRect();
      const wordRect = highlightedWord.getBoundingClientRect();

      const offset =
        wordRect.top -
        containerRect.top -
        container.clientHeight / 2 +
        wordRect.height / 2;

      container.scrollTo({
        top: container.scrollTop + offset,
        behavior: "smooth",
      });
    }
  }, [currentTime]);


  useEffect(() => {
    if (wordRef2.current && timelineRef2.current) {
      const highlightedElement = wordRef2.current;
      const container = timelineRef2.current;

      const containerRect = container.getBoundingClientRect();
      const elementRect = highlightedElement.getBoundingClientRect();

      const offset =
        elementRect.top -
        containerRect.top -
        container.clientHeight / 2 +
        elementRect.height / 2;

      container.scrollTo({
        top: container.scrollTop + offset,
        behavior: "smooth",
      });
    }
  }, [currentTime, highlight_chords]);

  var timeThreshold = 0.1; // Threshold to update time (in seconds)
let lastUpdateTime = 0; // Track when we last updated

const handleTimeUpdate = (newTime) => {
  // Check if at least 1 second has passed since the last update
  if (newTime - lastUpdateTime >= timeThreshold) {
    setCurrentTime(newTime); // Update current time
    // console.log("Time updated:", newTime); // Print the updated time to console
    lastUpdateTime = newTime; // Remember when we updated
    countRef.current = true;
  }else if (lastUpdateTime > newTime){
    lastUpdateTime = newTime;
    countRef.current = true;
  }
};

 return (
    <>
      <Pre_nav />
      <Overlay loading={loading}>
        <Spinner />
      </Overlay>
      <AppContainer1>
        <SongSection>
          <Button_back src={back_arraow_chords} onClick={Go_back} />
          <AlbumCover src={isUploaded ? mp3_thumbnail : thumb_img} alt="Album cover" />
          <SongInfo>
            <SongTitle>{title1}</SongTitle>

            <SongActions>
              <StyledDownloadButton>
                <ButtonImage0 onClick={handleyouClick_playlist} src={playlist_add_playlist} alt="Download Free" />
                <Tooltip>
                  Add to Playlist
                </Tooltip>
                <Imagearrow src={arrow} alt="Arrow" />
              </StyledDownloadButton>

              <StyledDownloadButton>
                <ButtonImage0 onClick={generatePDF} src={playlist_pdf} alt="Download Free" />
                <Tooltip>
                  Download pdf
                </Tooltip>
                <Imagearrow src={arrow} alt="Arrow" />
              </StyledDownloadButton>
             
              {!isUploaded &&
                <StyledDownloadButton>
                  <ButtonImage0 onClick={handleCopyLink} src={playlist_youtube} alt="Download Free" />
                  <Tooltip>
                    Copy Youtube Url
                  </Tooltip>
                  <Imagearrow src={arrow} alt="Arrow" />
                </StyledDownloadButton>}

              <StyledDownloadButton>
                <ButtonImage0 onClick={downgrade1} src={playlist_delete} alt="Download Free" />
                <Tooltip>
                  Delete Song
                </Tooltip>
                <Imagearrow src={arrow} alt="Arrow" />
              </StyledDownloadButton>

              <StyledDownloadButton>
                <ButtonImage0 onClick={() => { setRetryPopupVisible(true) }} src={playlist_retry} alt="Download Free" />
                <Tooltip>
                  Retry Song
                </Tooltip>
                <Imagearrow src={arrow} alt="Arrow" />
              </StyledDownloadButton>

              <StyledDownloadButton>
                <ButtonImage0 onClick={share_button} src={playlist_share} alt="Download Free" />
                <Tooltip>
                  Share
                </Tooltip>
                <Imagearrow src={arrow} alt="Arrow" />
              </StyledDownloadButton>

            </SongActions>

            <Header8>
              Not quite right? Click 'Retry' for a fresh take on chords and
              lyrics. It's quick and easy to get a better match!
            </Header8>
          </SongInfo>
        </SongSection>


        {PopupVisible && (
          <PopupOverlay>
            <CreatePlaylistPopup onClose={closepopup} />
          </PopupOverlay>
        )}
        {down && (
          <PopupOverlay>
            <Downgrade
              onClose={() => setDown(false)}
              id1={id}
              hiddenParam={hiddenParam}
            />
          </PopupOverlay>
        )}
        {showDropdown && (
          <DropdownMenu>
            {playlists.map((playlist, index) => (
              <MenuItem
                key={index}
                onClick={() => add_playlist1(playlist.name)}
              >
                {playlist.name}
              </MenuItem>
            ))}
          </DropdownMenu>
        )}

        {showversion_title_popup && (
          <PopupOverlay>
            <NewVersionForm onClose={handleyouClick_retryversion1} id={id} />
          </PopupOverlay>
        )}

        {saveas_original && (
          <PopupOverlay>
            <SaveAsForm onClose={handle_save_as_original_popup1} />
          </PopupOverlay>
        )}

        {showdel_chords_popup && (
          <PopupOverlay>
            <Deleteedit_chords onClose={handleyouClick_delchords1} />
          </PopupOverlay>
        )}

        {showdel_words_popup && (
          <PopupOverlay>
            <Deleteedit_words onClose={handleyouClick_delwords1} />
          </PopupOverlay>
        )}

       
        {showversion_del_popup && (
          <PopupOverlay>
            <DeleteVersionForm
              onClose={handleDelete_close}
              id={id}
              name={del_name}
            />
          </PopupOverlay>
        )}

        {retryPopupVisible && (
          <PopupOverlay>
            <RetryPopup setPopupVisible={setRetryPopupVisible} handlePopupSubmit={retry_with_name} />
          </PopupOverlay>
        )}

        {showversion_edit_popup && (
          <PopupOverlay>
            <EditVersionForm
              onClose={handleEdit_close}
              id={id}
              name={edt_name}
            />
          </PopupOverlay>
        )}

        {showplaylist_popup && (
          <PopupOverlay>
            <PlaylistForm onClose={handleyouClick_playlist1} id1={id} selectedValue={selectedValue} />
          </PopupOverlay>
        )}

        {show_feedback && (
          <>
            {isMobile ? (
              <PopupOverlay3>
                <FeedbackPopup onClose={() => setshow_feedback(false)} />
              </PopupOverlay3>
            ) : (
              <PopupOverlay1>
                <FeedbackPopup onClose={() => setshow_feedback(false)} />
              </PopupOverlay1>
            )}
          </>
        )}

        {share_popup && (
          <PopupOverlay>
            <ShareLinkPopup
              onClose={() => setshare_popup(false)}
              url={share_url1}
            />
          </PopupOverlay>
        )}


        <AppContainer >
          <MainContent>
            <LyricsSection>

              <Rows>
                <SelectWrapper_header>
                  <SelectButton_header onClick={() => setIsOpen(!isOpen)}>
                    {selectedValue === "original"
                      ? "Original Version"
                      : (() => {
                        let displayName = selectedValue;

                        if (selectedValue.includes("Copy")) {
                          const copyMatch =
                            selectedValue.match(/Copy (\d+)/); // Extract copy number
                          const copyNumber = copyMatch
                            ? `Copy ${copyMatch[1]}`
                            : "Copy 1";

                          const baseName = selectedValue
                            .split("-")[0]
                            .trim(); // Get the base part (e.g., "Celine")
                          displayName = `${baseName.slice(
                            0,
                            8
                          )}..- ${copyNumber}`;
                        } else if (selectedValue.length > 20) {
                          displayName = `${selectedValue.slice(
                            0,
                            20
                          )}...`;
                        }
                     return displayName;
                      })()}
                  </SelectButton_header>

                  {isOpen && (
                    <DropdownContainer_header>
                      <CustomOption
                        onClick={() => handleSelectChange4("original")}
                      >
                        <OptionText_header>Original Version</OptionText_header>
                      </CustomOption>

                      {variants.map((variant, index) => {
                        let displayName = variant.name;

                        if (variant.name.includes("Copy")) {
                          const copyMatch =
                            variant.name.match(/Copy (\d+)/); // Extract copy number
                          const copyNumber = copyMatch
                            ? `Copy ${copyMatch[1]}`
                            : "Copy 1";

                           const baseName = variant.name
                            .split("-")[0]
                            .trim(); // Get the base part (e.g., "Celine")
                          displayName = `${baseName.slice(
                            0,
                            6
                          )}..- ${copyNumber}`;
                        }

                        return (
                          <CustomOption key={index}>
                            <OptionText_header
                              onClick={() =>
                                handleSelectChange4(variant.name) } >
                              {displayName}
                            </OptionText_header>
                            <IconsContainer>
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEdit(variant.name);
                                }}
                                style={{ color: "#2684ff" }}
                              >
                                <Edit src={edt_variant} />
                              </IconButton>
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDelete(variant.name);
                                }}
                                style={{ color: "#dc3545" }}
                              >
                                <Trash2 src={del_variant} />
                              </IconButton>
                            </IconsContainer>
                          </CustomOption>
                        );
                      })}
                    </DropdownContainer_header>
                  )}
                </SelectWrapper_header>
                <DropdownContainer>
                  <Dropdownlabel>Translate</Dropdownlabel>
                  <CustomDropdown>
                    <SelectButton_1
                      width={130}
                      onClick={() => {
                        setIsTranslateDropdownOpen((prev) => !prev);
                        setFilteredLanguages(languages);
                        setTypedText("");
                      }}
                    >
                      {translate || "None"}
                    </SelectButton_1>

                    {isTranslateDropdownOpen && (
                      <DropdownContainer60 style={{ width: "128px" }}>
                        {filteredLanguages.map((item, index) => (
                          <CustomOption key={index}>
                            <OptionText
                              onClick={() => {
                                setTranslate(item.language);
                                setIsTranslateDropdownOpen(false);
                              }}
                            >
                              {item.language}
                            </OptionText>
                          </CustomOption>
                        ))}
                      </DropdownContainer60>
                    )}
                  </CustomDropdown>
                </DropdownContainer>
                <Header1
                  style={{
                    height: '40px'
                  }}
                  onClick={() => {
                    if (selectedValue === "original") {
                      save_changes();
                    } else {
                      save_changes();
                    }
                  }}
                  src={selectedValue === "original" ? create_coopy : create_save_as}
                  alt="Edit Chords"
                />
              </Rows>
              <>
                <Chords_container>
                  <Header>Chords used : </Header>
                  <Chordsrow>
                    <ChordList>
                      <div
                        className="chord-container"
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "left",
                          gap: "15px",
                          marginLeft: "15px",
                          marginTop: "8px",
                        }}
                      >
                        {uniqueChords.map((chord, index) => {
  const formattedName = formatChordName(chord);
  const displayChord = formattedName.includes("/")
    ? formattedName.split("/")[0]
    : formattedName;

  const processedChord = displayChord.replace(/_+/g, "#");
  const flatName = convertSharpToFlat(processedChord);
  
  // Check if this is a flat chord
  const isFlatChord = Object.values(sharpToFlatRootMap).some(flatNote => 
    flatName.startsWith(flatNote)
  );
  
  // Get the sharp equivalent if it's a flat chord
  const sharpName = isFlatChord ? convertFlatToSharp(flatName) : processedChord;
  
  const isSharpChord = processedChord.includes("#") || isFlatChord;
  
  // Format for display
  const displaySharpName = for_display_ChordName(sharpName);
  const displayFlatName = for_display_ChordName(flatName);

  return (
    <div
      className="chord-item"
      key={index}
      style={{ textAlign: "center" }}
    >
      <p
        style={{
          color: "white",
          fontSize: "14px",
          marginTop: "8px",
        }}
      >
        {isSharpChord ? (
          <>
            {isFlatChord ? (
              // Flat first format (e.g., "Abm/G#m")
              <>
                <span
                  onClick={() => {
                    sharp_to_flat();
                    setSelectedName("flatName");
                  }}
                  style={{
                    fontSize: selectedName === "flatName" ? "14px" : "14px",
                    fontWeight: selectedName === "flatName" ? "bold" : "normal",
                    cursor: "pointer",
                  }}
                >
                  {displayFlatName}
                </span>
                /
                <span
                  onClick={() => {
                    flat_to_sharp();
                    setSelectedName("sharpName");
                  }}
                  style={{
                    fontSize: selectedName === "sharpName" ? "14px" : "14px",
                    fontWeight: selectedName === "sharpName" ? "bold" : "normal",
                    cursor: "pointer",
                  }}
                >
                  {displaySharpName}
                </span>
              </>
            ) : (
              // Sharp first format (e.g., "F#/Gb")
              <>
                <span
                  onClick={() => {
                    flat_to_sharp();
                    setSelectedName("sharpName");
                  }}
                  style={{
                    fontSize: selectedName === "sharpName" ? "14px" : "14px",
                    fontWeight: selectedName === "sharpName" ? "bold" : "normal",
                    cursor: "pointer",
                  }}
                >
                  {displaySharpName}
                </span>
                /
                <span
                  onClick={() => {
                    sharp_to_flat();
                    setSelectedName("flatName");
                  }}
                  style={{
                    fontSize: selectedName === "flatName" ? "14px" : "14px",
                    fontWeight: selectedName === "flatName" ? "bold" : "normal",
                    cursor: "pointer",
                  }}
                >
                  {displayFlatName}
                </span>
              </>
            )}
          </>
        ) : (
          <span style={{ fontSize: "14px" }}>{for_display_ChordName(processedChord)}</span>
        )}
      </p>
    </div>
  );
})}
                      </div>

                    </ChordList>
                  </Chordsrow>
                </Chords_container>
               </>

              <Linees>
                 <div style={{ marginTop: '0px' }}>
                  {selectedValue === "original" ? (
                    <Retry2>
                      This is the original version, and it cannot be edited.
                    </Retry2>
                  ) : (
                    <Retry2>
                      This song is an edited version.
                    </Retry2>
                  )}
                </div>

                {isMobile ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Thumbs1>
                      <ThumbUpIcon
                        width="28"
                        height="28"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={toggleThumbColor}
                      >
                        <circle
                          cx="14"
                          cy="14"
                          r="14"
                          fill={isClicked ? "#20C997" : "white"}
                        />
                        <path
                          d="M20.3381 11.4479C20.6849 11.4479 20.9913 11.5809 21.2572 11.8469C21.5232 12.1129 21.6562 12.4192 21.6562 12.7661V13.9438C21.6562 14.0196 21.6483 14.1013 21.6323 14.1892C21.6164 14.2771 21.5926 14.3589 21.5609 14.4348L19.4714 19.365C19.3668 19.5987 19.1916 19.796 18.9457 19.9567C18.6999 20.1175 18.4442 20.1979 18.1786 20.1979H12.2751C11.9115 20.1979 11.6009 20.0692 11.3434 19.8116C11.0859 19.554 10.9571 19.2434 10.9571 18.8798V11.9933C10.9571 11.8176 10.9931 11.6487 11.0651 11.4866C11.137 11.3243 11.2328 11.1833 11.3525 11.0636L15.21 7.23132C15.355 7.094 15.5228 7.00941 15.7135 6.97757C15.9042 6.94585 16.0869 6.97435 16.2617 7.06307C16.4365 7.1519 16.5627 7.2823 16.6403 7.45426C16.7179 7.62635 16.7334 7.80913 16.6866 8.0026L15.9013 11.4479H20.3381ZM8.39103 20.1979C8.02742 20.1979 7.71679 20.0692 7.45915 19.8116C7.20163 19.554 7.07288 19.2434 7.07288 18.8798V12.7661C7.07288 12.4025 7.20163 12.0918 7.45915 11.8342C7.71679 11.5767 8.02742 11.4479 8.39103 11.4479H8.54525C8.90886 11.4479 9.21948 11.5767 9.47712 11.8342C9.73464 12.0918 9.8634 12.4025 9.8634 12.7661V18.8869C9.8634 19.2505 9.73464 19.5599 9.47712 19.8151C9.21948 20.0703 8.90886 20.1979 8.54525 20.1979H8.39103Z"
                          fill={isClicked ? "white" : "#7F8384"}
                        />
                      </ThumbUpIcon>
                    </Thumbs1>
                    <Thumbup_num isClicked={isClicked}>{upvotes}</Thumbup_num>
                    <Thumbs1>
                      <ThumbdownIcon
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={toggleThumbColor1}
                      >
                        <circle
                          cx="14"
                          cy="14"
                          r="14"
                          fill={isClicked1 ? "#e31021" : "white"}
                        />
                        <path
                          d="M7.66194 16.5521C7.3151 16.5521 7.00873 16.4191 6.74283 16.1531C6.4768 15.8871 6.34379 15.5808 6.34379 15.2339V14.0562C6.34379 13.9804 6.35175 13.8987 6.36767 13.8108C6.38359 13.7229 6.40741 13.6411 6.43913 13.5652L8.52855 8.63498C8.63319 8.40128 8.80843 8.20404 9.05429 8.04326C9.30014 7.88248 9.55583 7.80209 9.82137 7.80209H15.7249C16.0885 7.80209 16.3991 7.93085 16.6566 8.18836C16.9141 8.446 17.0429 8.75663 17.0429 9.12024V16.0067C17.0429 16.1824 17.0069 16.3513 16.9349 16.5134C16.863 16.6757 16.7672 16.8167 16.6475 16.9364L12.79 20.7687C12.645 20.906 12.4772 20.9906 12.2865 21.0224C12.0958 21.0541 11.9131 21.0256 11.7383 20.9369C11.5635 20.8481 11.4373 20.7177 11.3597 20.5457C11.2821 20.3737 11.2666 20.1909 11.3134 19.9974L12.0987 16.5521H7.66194ZM19.609 7.80209C19.9726 7.80209 20.2832 7.93085 20.5408 8.18836C20.7984 8.446 20.9271 8.75663 20.9271 9.12024V15.2339C20.9271 15.5975 20.7984 15.9082 20.5408 16.1658C20.2832 16.4233 19.9726 16.5521 19.609 16.5521H19.4548C19.0911 16.5521 18.7805 16.4233 18.5229 16.1658C18.2654 15.9082 18.1366 15.5975 18.1366 15.2339V9.11313C18.1366 8.74952 18.2654 8.44011 18.5229 8.1849C18.7805 7.92969 19.0911 7.80209 19.4548 7.80209H19.609Z"
                          fill={isClicked1 ? "white" : "#7F8384"}
                        />
                      </ThumbdownIcon>
                    </Thumbs1>
                    <Thumbdown_num isClicked1={isClicked1}>
                      {downvotes}
                    </Thumbdown_num>
                  </div>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ThumbUpIcon
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={toggleThumbColor}
                    >
                      <circle
                        cx="14"
                        cy="14"
                        r="14"
                        fill={isClicked ? "#20C997" : "white"}
                      />
                      <path
                        d="M20.3381 11.4479C20.6849 11.4479 20.9913 11.5809 21.2572 11.8469C21.5232 12.1129 21.6562 12.4192 21.6562 12.7661V13.9438C21.6562 14.0196 21.6483 14.1013 21.6323 14.1892C21.6164 14.2771 21.5926 14.3589 21.5609 14.4348L19.4714 19.365C19.3668 19.5987 19.1916 19.796 18.9457 19.9567C18.6999 20.1175 18.4442 20.1979 18.1786 20.1979H12.2751C11.9115 20.1979 11.6009 20.0692 11.3434 19.8116C11.0859 19.554 10.9571 19.2434 10.9571 18.8798V11.9933C10.9571 11.8176 10.9931 11.6487 11.0651 11.4866C11.137 11.3243 11.2328 11.1833 11.3525 11.0636L15.21 7.23132C15.355 7.094 15.5228 7.00941 15.7135 6.97757C15.9042 6.94585 16.0869 6.97435 16.2617 7.06307C16.4365 7.1519 16.5627 7.2823 16.6403 7.45426C16.7179 7.62635 16.7334 7.80913 16.6866 8.0026L15.9013 11.4479H20.3381ZM8.39103 20.1979C8.02742 20.1979 7.71679 20.0692 7.45915 19.8116C7.20163 19.554 7.07288 19.2434 7.07288 18.8798V12.7661C7.07288 12.4025 7.20163 12.0918 7.45915 11.8342C7.71679 11.5767 8.02742 11.4479 8.39103 11.4479H8.54525C8.90886 11.4479 9.21948 11.5767 9.47712 11.8342C9.73464 12.0918 9.8634 12.4025 9.8634 12.7661V18.8869C9.8634 19.2505 9.73464 19.5599 9.47712 19.8151C9.21948 20.0703 8.90886 20.1979 8.54525 20.1979H8.39103Z"
                        fill={isClicked ? "white" : "#7F8384"}
                      />
                    </ThumbUpIcon>
                    <Thumbup_num isClicked={isClicked}>{upvotes}</Thumbup_num>

                    <ThumbdownIcon
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={toggleThumbColor1}
                    >
                      <circle
                        cx="14"
                        cy="14"
                        r="14"
                        fill={isClicked1 ? "#e31021" : "white"}
                      />
                      <path
                        d="M7.66194 16.5521C7.3151 16.5521 7.00873 16.4191 6.74283 16.1531C6.4768 15.8871 6.34379 15.5808 6.34379 15.2339V14.0562C6.34379 13.9804 6.35175 13.8987 6.36767 13.8108C6.38359 13.7229 6.40741 13.6411 6.43913 13.5652L8.52855 8.63498C8.63319 8.40128 8.80843 8.20404 9.05429 8.04326C9.30014 7.88248 9.55583 7.80209 9.82137 7.80209H15.7249C16.0885 7.80209 16.3991 7.93085 16.6566 8.18836C16.9141 8.446 17.0429 8.75663 17.0429 9.12024V16.0067C17.0429 16.1824 17.0069 16.3513 16.9349 16.5134C16.863 16.6757 16.7672 16.8167 16.6475 16.9364L12.79 20.7687C12.645 20.906 12.4772 20.9906 12.2865 21.0224C12.0958 21.0541 11.9131 21.0256 11.7383 20.9369C11.5635 20.8481 11.4373 20.7177 11.3597 20.5457C11.2821 20.3737 11.2666 20.1909 11.3134 19.9974L12.0987 16.5521H7.66194ZM19.609 7.80209C19.9726 7.80209 20.2832 7.93085 20.5408 8.18836C20.7984 8.446 20.9271 8.75663 20.9271 9.12024V15.2339C20.9271 15.5975 20.7984 15.9082 20.5408 16.1658C20.2832 16.4233 19.9726 16.5521 19.609 16.5521H19.4548C19.0911 16.5521 18.7805 16.4233 18.5229 16.1658C18.2654 15.9082 18.1366 15.5975 18.1366 15.2339V9.11313C18.1366 8.74952 18.2654 8.44011 18.5229 8.1849C18.7805 7.92969 19.0911 7.80209 19.4548 7.80209H19.609Z"
                        fill={isClicked1 ? "white" : "#7F8384"}
                      />
                    </ThumbdownIcon>
                    <Thumbdown_num isClicked1={isClicked1}>
                      {downvotes}
                    </Thumbdown_num>
                  </div>
                )}


              </Linees>

              <ViewSelector>
                <div className={viewType === 'lyrics' ? 'active' : 'inactive'} onClick={() => { setViewType('lyrics') }} >Lyrics</div>
                <div className={viewType === 'lyrics_with_timing' ? 'active' : 'inactive'} onClick={() => { setViewType('lyrics_with_timing') }} >Lyrics with Timing</div>
              </ViewSelector>
              {viewType === 'lyrics' ?
                <>
                  <Container34 >
                    <Rows id="cj1" ref={cj1Ref} style={{ gap: '24px', paddingTop: '12px', paddingBottom: '10px' }}>
                      <Header_rows_row>
                        <Play_control>
                          <Header1100
                            onClick={restart11}
                            src={restart1}
                          />
                          <Header110
                            onClick={isUploaded ? playAudio : play}
                            src={isPlaying ? video_pause_button : video_play_button}
                          />
                        </Play_control>
                        <Header_bottom_row_loop>Play Control</Header_bottom_row_loop>
                      </Header_rows_row>
                       <Header_rows_row>
                        <Header61 onClick={handleToggle} isActive={isActive}>
                          {isActive ? "Stop" : "Start"} {/* Toggle text */}
                        </Header61>
                        <Header_bottom_row>Auto Scroll</Header_bottom_row>
                      </Header_rows_row>

                          <Header_rows_row>
                              <ButtonContainer>
                          {transposeOptions.map((option) => (
                            <TransposeButton
                              key={option.value}
                              isActive={activeButton === option.value}
                              onClick={() => handleClick(option.value)}
                            >
                              {option.label}
                            </TransposeButton>
                          ))}
                        </ButtonContainer>

                        <Header_bottom_row_2>Transpose: {transposedValue}</Header_bottom_row_2>
                      </Header_rows_row>

                        <Header_rows_row>
                        <Header_top_row
                          onClick={handle_highlight_chords}
                          src={highlight_chords ? high_light_chords_enable : high_light_chords_white}
                        />
                        <Header_bottom_row>Highlight Lyrics</Header_bottom_row>
                      </Header_rows_row>

                    </Rows>

                    <div id="cj">
                      {loading1 ? (
                        <CJContainer>
                          <Overlay1 loading={loading1}>
                            <Spinner />
                            </Overlay1>
                        </CJContainer>
                      ) : (
                        <>
                          <HeaderSpace ref={headerSpaceRef} />
                          <div id="cj2" ref={containerRef}>
                           
                            <ViewContainer>
                              <div className="view simpleView">
                                <Container_simple
                                  ref={timelineRef2}
                                  isPortrait={isMobile && isPortrait}
                                  isLandscape={isMobile && isLandscape}
                                >
                                  {myVariable} {/* Render your myVariable content here */}
                                  <ChordTooltip tooltipData={tooltipData} />
                                </Container_simple>
                              </div>
                            </ViewContainer>
                           
                          </div>
                        </>
                        )}
                    </div>
                  </Container34>
                </>
                :

                <Container34 >
                  <Rows id="cj1" ref={cj1Ref} style={{ gap: '24px', paddingTop: '12px', paddingBottom: '10px' }}>
                    <Header_rows_row>
                      <Play_control style={{ opacity: edit_chords_lyrics ? 0.5 : 1 }}>
                        <Header1100
                          onClick={!edit_chords_lyrics ? restart11 : undefined}
                          src={restart1}
                          style={{ pointerEvents: edit_chords_lyrics ? 'none' : 'auto', cursor: edit_chords_lyrics ? 'not-allowed' : 'pointer' }}
                        />
                        <Header110
                          onClick={!edit_chords_lyrics ? (isUploaded ? playAudio : play) : undefined}
                          src={isPlaying ? video_pause_button : video_play_button}
                          style={{ pointerEvents: edit_chords_lyrics ? 'none' : 'auto', cursor: edit_chords_lyrics ? 'not-allowed' : 'pointer' }}
                        />
                      </Play_control>
                      <Header_bottom_row_loop>Play Control</Header_bottom_row_loop>
                    </Header_rows_row>

                    <Header_rows_row>
                      <Header_top_row_loop
                        onClick={!edit_chords_lyrics ? handleLoop : undefined}
                        src={isLoop ? loop_dis : loop_en}
                        style={{
                          opacity: edit_chords_lyrics ? 0.5 : 1,
                          pointerEvents: edit_chords_lyrics ? 'none' : 'auto',
                          cursor: edit_chords_lyrics ? 'not-allowed' : 'pointer',
                        }}
                      />
                      <Header_bottom_row_loop>Loop</Header_bottom_row_loop>
                    </Header_rows_row>

                    <Header_rows_row>

                      <ButtonContainer>
                        {transposeOptions.map((option) => (
                          <TransposeButton
                            key={option.value}
                            isActive={activeButton === option.value}
                            onClick={() => handleClick(option.value)}
                          >
                            {option.label}
                          </TransposeButton>
                        ))}
                      </ButtonContainer>

                      <Header_bottom_row_2>Transpose: {transposedValue}</Header_bottom_row_2>
                    </Header_rows_row>

                    <Header_rows_row>
                      <SelectWrapper1110>
                        <SelectButton_1 ref={dropdownButtonRef} width={80} onClick={toggleDropdown1}>
                          {selectedValue === "Select Time"
                            ? "Select Time"
                            : SECONDS_PER_LINE1} secs
                        </SelectButton_1>

                        {isDropdownOpen && (
                          <DropdownContainer60 ref={dropdownRef}>
                            {SECONDS_list.map((seconds, index) => (
                              <CustomOption key={index}>
                                <OptionText
                                  onClick={() => {
                                    set_SECONDS_PER_LINE1(seconds);
                                    setIsDropdownOpen(false); // Close dropdown after selecting
                                  }}
                                >
                                  {seconds} sec
                                </OptionText>
                              </CustomOption>
                            ))}
                          </DropdownContainer60>
                        )}
                      </SelectWrapper1110>
                      <Header_bottom_row_1 id="lyrics_with_chords">Seconds per line</Header_bottom_row_1>
                    </Header_rows_row>
                     <RightSection>
                      {!version_select ? (<ToggleEditMode />) : (null)}
                    </RightSection>
                    </Rows>

                  <div id="cj">
                    {loading1 ? (
                      <CJContainer>
                        <Overlay1 loading={loading1}>
                          <Spinner />
                           </Overlay1>
                      </CJContainer>
                    ) : (
                      <>
                        <HeaderSpace ref={headerSpaceRef} />
                        <div id="cj2" ref={containerRef}>
                          <div className="view orgChordsView" id="timeline-container">
                            <Container100
                              isPortrait={isMobile && isPortrait}
                              isLandscape={isMobile && isLandscape}
                              className="Container100"
                              ref={timelineRef}
                            >
                              <div

                                className="w-full border border-gray-700 rounded-lg overflow-hidden bg-black"
                              >
                                {Array.from(
                                  { length: Math.ceil(totalDuration / SECONDS_PER_LINE1) },
                                  (_, i) =>
                                    renderTimeSegment(
                                      i * SECONDS_PER_LINE1,
                                      (i + 1) * SECONDS_PER_LINE1,
                                      i
                                    )
                                )}
                              </div>
                              <TimelineChordTooltip tooltipData={tooltipData} />
                            </Container100>
                          </div>

                        </div>
                      </>

                    )}
                  </div>
                </Container34>
              }
            </LyricsSection>
          </MainContent>
          {!isEditing ? (
            <Sidebar hidden={isMobile}>

 <Ad hidden={isUploaded}>
                <VideoPlayer videoUrl={videoUrl} onTimeUpdate={handleTimeUpdate} playerRef={playerRef} isPlaying={setIsPlaying} playerRef2={playerRef2} />
                
              </Ad>
              <Header3>Recently Played songs</Header3>
              <RecentlyPlayedList>
                {playlists.map(
                  (song, index) =>
                    song.title !== title1 && (
                      <div
                        key={index}
                        onClick={() => {
                          setLoading(true);
                          handleSongCardClick(song.id, song.url);
                        }}
                      >
                        <RecentlyPlayedItem>
                          <SongThumbnail src={song.image || mp3_thumbnail} alt={song.title} />
                          <SongDetails>
                            <SongName>{song.title}</SongName>
                          </SongDetails>
                        </RecentlyPlayedItem>
                      </div>
                    )
                )}
                <ViewAllLink onClick={View_all_Songs}>View all</ViewAllLink>
              </RecentlyPlayedList>
            </Sidebar>
          ) : null}
        </AppContainer>
      </AppContainer1>
      <ToastContainer />

      <Footer />
      </>
  );
};


function Downgrade({ onClose, id1, hiddenParam }) {
  const token = getToken();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const delete_song = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${config.apiUrl}/playlists/__default_playlist__/remove`,
        { records: [id1] },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        if (hiddenParam == "all_songs") {
          navigate("/all_songs");
        } else if (hiddenParam == "user") {
          navigate("/user");
        } else {
          navigate("/all_songs");
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };

  const ActionButton = ({ text, primary, onClick }) => {
    return (
      <Button4 primary={primary} onClick={onClick}>
        {text}
      </Button4>
    );
  };

  return (
     <FormContainer4>
      <Form>
        <HeaderContainer4>
          <Title01>Warning</Title01>
          <CloseButton onClick={onClose}>×</CloseButton>
        </HeaderContainer4>

        <Content41>
          Are you sure you want to delete this song?
        </Content41>
        <ActionButton text="Confirm" primary onClick={delete_song} />
        <ActionButton text="Cancel" onClick={onClose} />

      </Form>
    </FormContainer4>
);
}

const ErrorMessage = styled.p`
  color: red;
  margin-top: 5px;
  font-size: 12px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 35px;
  right: 30px;
  background: none;
  border: none;
  color: #fff;
  font-size: 34px;
  cursor: pointer;
`;

const ButtonContainer01 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; /* This centers the button vertically if needed */

  @media (max-width: 991px) {
    width: 90%; /* Ensures the container spans the full width on mobile */
    margin: 0 auto; /* Centers the container horizontally */
  }
`;

const InputContainer = styled.section`
  align-self: center;
  display: flex;
  margin-top: 32px;
  width: 100%;
  max-width: 396px;
  flex-direction: column;
  @media (max-width: 991px) {
    width: 90%;
  }
`;

const Label = styled.label`
  color: #fff;
  font: 600 16px Inter, sans-serif;
`;

const InputWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  min-height: 56px;
`;

const Input = styled.input`
  flex: 1;
  border-radius: 4px;
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 17px 16px;
  border: 1px solid #ececec;
  color: #414141;
  font: 400 13px/32px Poppins, sans-serif;
`;

const Button01 = styled.button`
  align-self: stretch;
  border-radius: 4px;
  background-color: #0072d5;
  margin-top: 55px;
  min-height: 60px;
  margin-bottom: 40px;
  width: 400px;
  color: #fff;
  text-align: center;
  padding: 0 30px;
  font: 600 16px Poppins, sans-serif;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #005bb0;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 114, 213, 0.3);
  }
`;

const HeaderContainer = styled.header`
  position: relative;
  display: flex;
  min-height: 68px;
  align-items: center;
  justify-content: center;
  padding: 21px 16px;
  color: #fff;
  text-align: center;
  letter-spacing: -0.2px;
  font: 600 20px/1.3 Poppins, sans-serif;
`;

const HeaderContainer4 = styled.header`
  position: relative;
  display: flex;
  min-height: 68px;
  align-items: center;
  justify-content: center;
  padding: 21px 16px;
  color: #fff;
  text-align: center;
  letter-spacing: -0.2px;
  font: 600 20px/1.3 Poppins, sans-serif;
`;

const Title01 = styled.div`
  z-index: 0;
  margin: 0;
  font: 600 25px/1.3 Poppins, sans-serif;
  @media (max-width: 991px) {
    font: 600 23px/1.3 Poppins, sans-serif;
  }
`;


const FormContainer = styled.main`
  border-radius: 12px;

  display: flex;
  width: 428px;
  flex-direction: column;
  overflow: hidden;
  @media (max-width: 991px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const FormContainer4 = styled.main`
  border-radius: 12px;

  display: flex;
  width: 428px;
  flex-direction: column;
  overflow: hidden;
  @media (max-width: 991px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const Form = styled.form`
  border-radius: 12px;
  background-color: #312f2f;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Content4 = styled.p`
  color: white;
  letter-spacing: 0.1px;
  margin-top: 0px;
  font: 500 14px Poppins, sans-serif;
  line-height: 22.4px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
`;

const Content41 = styled.div`
  color: white;
  letter-spacing: 0.1px;
  margin-top: 0px;
  margin-bottom: 15px;
  font: 600 16px Poppins, sans-serif;
  line-height: 22.4px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
`;


const Button4 = styled.button`
  font-family: Poppins, sans-serif;
  border-radius: 4px;
  background-color: ${(props) => (props.primary ? "#0072D5" : "transparent")};
  align-self: center;
  margin-top: ${(props) => (props.primary ? "15px" : "5px")};
  width: 100%;
  max-width: 296px;
  color: ${(props) => (props.primary ? "#fff" : "#fff")};
  white-space: nowrap;
  padding: 17px 30px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px; 
`;

const TransposeButton = styled.button`
  display: inline-flex; /* Ensures proper alignment of text */
  justify-content: center;
  align-items: center; /* Centers the content */
  width: 42px;
  height: 42px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => (props.isActive ? "#F0984B" : "#333333")};
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  line-height: 1; /* Prevents any spacing issues */
  transition: background-color 0.3s;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  @media (max-width: 991px) {
    font-size: 10px;
  }
`;


const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 18px; /* Reduced size to fit inside */
    width: 18px; /* Reduced size to fit inside */
    left: 0px; /* Adjusted to sit inside the slider */
    bottom: 0px;

    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;


const ViewAllLink = styled.a`
  color: #428bca;
  margin: auto 0;
  font: 16px Poppins, sans-serif;
  margin-right: 35px;
  margin-top: 16px;
  cursor: pointer;
  @media (max-width: 991px) {
    margin-right: 0px;
    flex-direction: row;
  }
`;
const AppContainer = styled.div`
  background-color: #282828;
  display: flex;
  padding-bottom: 10px;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  overflow-x: auto; /* Enable horizontal scroll */
  overflow-y: hidden; /* Disable vertical scroll */
  white-space: nowrap; /* Prevent shrinking or wrapping */
  width: 100%; /* Set width to 100% */

  /* Add this media query for mobile responsiveness */
  @media (max-width: 991px) {
    justify-content: flex-start;
  }
  
  @media (max-width: 800px){
    overflow-x: auto;
  
  }

   ${({ disableScroll }) =>
    disableScroll &&
    css`
      overflow-x: hidden; 
    `}
  
  &::-webkit-scrollbar{
    display: none;
  }
`;

const AppContainer1 = styled.div`
  flex-direction: column;
  background-color: #282828;
  display: flex;

  gap: 0px;
  align-items: flex-start;
  overflow: hidden;
`;

const MainContent = styled.main`
  display: flex;
  padding: 0 20px;
  width: calc(100vw - 480px);
  flex-direction: column;
  @media (max-width: 800px) {
    width: 95%;
  }
  @media (min-width: 801px) and (max-width: 1400px) {
    width: 100%;
  }
`;

const ViewSelector = styled.div`
  display: flex;
  border-radius: 2px;
  overflow: hidden;
  margin: 12px 0px;
  div{
    height: 40px;
    width: 150px;
    color: #ffffff;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.active{
      background: #F0984B;
    }
    &.inactive{
      background: #333333;
    }
  }
@media (max-width: 425px){
    margin: 24px auto;
  }
`

const Header = styled.div`
  color: #fff;
   font: 700 20px Poppins, sans-serif;
  @media (max-width: 991px) {
    margin-left: 0px;
    font: 700 18px Poppins, sans-serif;
    margin-top:5px;
  }
`;

const Chords_container = styled.div`
  display: flex;
  align-items: center;
   margin-top:10px;
`;


const Header_mob = styled.h3`
  color: #fff;
  @media (max-width: 991px) {
    margin-left: 0px;
  }
`;

const Header30 = styled.h4`
  color: #fff;
  margin-left: 10px;
  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;

const Header1 = styled.img`
  cursor: pointer;
  margin-left: 30px;
  height:41px;
`;

const Thumbup_num = styled.div`
  color: ${({ isClicked }) => (isClicked ? "#20C997" : "#fff")};

  font: 550 15px Poppins, sans-serif;

  align-items: center;
  margin-left: 10px;
  @media (max-width: 991px) {
    font-size: 15px;
    margin-left: 5px;
  }
`;

const Thumbdown_num = styled.div`
  color: ${({ isClicked1 }) => (isClicked1 ? "#e31021" : "#fff")};
  font: 550 15px Poppins, sans-serif;

  align-items: center;
  margin-left: 10px;
  @media (max-width: 991px) {
    font-size: 15px;
    margin-left: 5px;
  }
`;

const Linees = styled.div`
 display:flex;
 flex-direction:row;

 margin-top:10px;
 margin-bottom:0px;
 width: 100%;
 flex-wrap: wrap;
`;

const Container34 = styled.div`

`;


const ViewContainer = styled.div`
  .view {
    display: block;
  }

  .view.hidden {
    display: none;
  }
`;


const Header110 = styled.img`
  cursor: pointer;
  margin-left:5px;
  margin-right:8px;
  height:27px;
  width:27px;

`;


const Header1100 = styled.img`
  cursor: pointer;
  margin-left:5px;
  height:30px;
  width:30px;
  margin-top:2px;
`;

const Header200 = styled.img`
  cursor: pointer;
 
  height:40px;
 `;

const Header_rows_row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  height: 42px;
`;

const Dropdownlabel = styled.div`
  color: #fff;
  font: 700 12px Poppins, sans-serif;
  align-items: center;
  text-align: center;
  margin-left: 12px;
`

const CustomDropdown = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 42px;
  margin-left: 8px;
`;


const Header_bottom_row = styled.div`
  color: #fff;
  font: 700 12px Poppins, sans-serif;
  align-items: center;
  text-align: center;
  margin-top: 14px;
`;

const Header_bottom_row_loop = styled.div`
  color: #fff;
  font: 700 12px Poppins, sans-serif;
  align-items: center;
  text-align: center;
  margin-top: 14px;
`;
const Header_bottom_row_2 = styled.div`
  color: #fff;
  font: 700 12px Poppins, sans-serif;
  align-items: center;
  text-align: center;
  margin-top: 14px;
`;

const Header_bottom_row_1 = styled.div`
  color: #fff;
  font: 700 12px Poppins, sans-serif;
  align-items: center;
  text-align: center;
  margin-top: 14px;
`;

const Header_top_row = styled.img`
  cursor: pointer;
  height: 42px;
`;

const Header_top_row_loop = styled.img`
  cursor: pointer;
  height: 42px;
`;

const Header61 = styled.div`
  color: #fff;
  font: 700 12px Poppins, sans-serif;
  text-align: center; /* Center text horizontally */
  display: flex; /* Use flexbox to center content */
  justify-content: center; /* Horizontal centering in flexbox */
  align-items: center; /* Vertical centering in flexbox */

  border-radius: 4px; /* Add border-radius */
  width: 42px;
  height: 42px;
  background-color: ${({ isActive }) => (isActive ? "#F0984B" : "#333333")};

 `;


const Header8 = styled.div`
  color: #fff;
  font: 400 12px Poppins, sans-serif;
  margin-top: 10px;
  letter-spacing: 0.28px;
  align-items: center;
  margin-left: 10px;
  @media (max-width: 991px) {
    font-size: 8px;
    margin-left: 5px;
  }
`;

const CJContainer = styled.div`
  position: relative; /* Make the parent position relative for absolute positioning of overlay */
  min-height: 350px;

  min-width: 900px;
  width: 100%;
  background-color: #282828;
  @media (max-width: 991px) {
    min-width: 600px;
  }
`;

const HeaderSpace = styled.div`
  width: 100%;
  &.with-height{
    height: 100px;
  }
`

// Styled component for the overlay
const Overlay1 = styled.div`
  position: absolute; /* Position it absolutely within the CJContainer */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  right: 0; /* Stretch to the right */
  bottom: 0; /* Stretch to the bottom */
  display: flex; /* Center the spinner */
  align-items: center; /* Vertically center */
  justify-content: center; /* Horizontally center */
  background-color: #282828;
  z-index: 1; /* Ensure it's above other content */
`;


const Rows = styled.div`
  display: flex;
  flex-direction: row;
  background: #282828;
  margin-top:10px;

  align-items: center; /* Aligns items vertically in the center */
  width: 95%; /* Optional: makes Rows take full width */

  &.fixed{
    position: fixed;
    top: 0;
    z-index: 100;
    overflow-x: auto;
    overflow-y: hidden;
      margin-top:0px;
  }

  &::-webkit-scrollbar{
    display: none;
  }

  @media (max-width: 800px){
    width: 95%;
  }
`;

const RightSection = styled.div`
  display: flex;
  gap: 8px; /* Adjust gap between items if needed */
  align-items: center;
  margin-left: auto; /* Pushes RightSection to the far right */
`;


const Play_control = styled.div`
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top:0px;
  background-color:#333232;
  border-radius: 4px;
  height: 36px;
  width: auto;
  padding: 2px;
`;


const SwitchInputChecked = styled(SwitchInput)`
  &:checked + ${Slider} {
    background-color: #f0984b;
  }

  &:checked + ${Slider}:before {
    transform: translateX(26px);
  }
`;

const Header3 = styled.h3`
  color: #fff;
`;

const SongSection = styled.section`
  display: flex;
  
  margin-top: 10px;
  @media (max-width: 991px) {
    flex-direction: row;
  }
`;
const AlbumCover = styled.img`
  max-width: 350px;
  height: 131px;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100px;
    height: 70px;
    margin-left: 10px;
  }
`;

const Button_back = styled.img`
  max-width: 80px;
  cursor: pointer;
  margin-left: 20px;
  @media (max-width: 991px) {
    display: none;
  }
`;

const SongInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
   @media (max-width: 991px) {
    margin-top: 5px;
  }
`;

const SongTitle = styled.h1`
  color: #fff;
  font: 600 18px/140% Poppins, sans-serif;
  letter-spacing: -0.28px;
  margin-left: 10px;
  @media (max-width: 991px) {
    font: 600 14px/140% Poppins, sans-serif;
    width: 70%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2; // Adjust line height if needed
    max-height: calc(1.2em * 2); // Line height * number of lines
    margin-top: 0px;
    margin-left: 10px;
  }
`;

const SongActions = styled.div`
  display: flex;
  align-items: center;
  gap: 0px; /* No gap between buttons */
  margin-top: 0px;
`;


const DownloadButton0 = styled.button`
  display: flex;
  align-items: center;
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
  position: relative; /* Needed for positioning the tooltip */
  margin-left:5px;

`;

const ButtonImage0 = styled.img`
  cursor: pointer; /* Ensures pointer cursor on hover */
   width:35px;
  height:35px;
   @media (max-width: 991px) {
    width:25px;
  height:25px;
  }
`;

const Tooltip = styled.div`
  position: absolute;
  bottom: 150%; /* Position above the button, adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: black;
  border: 2px solid #fff;
  padding: 8px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  z-index: 1;
  font-size: 10px;
`;

const Imagearrow = styled.img`
  width: 20px;
  height: 20px;
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute; /* Position relative to the button */
  top: -30px; /* Adjust position as needed */
  left: 50%;
  transform: translateX(-50%);
`;

const StyledDownloadButton = styled(DownloadButton0)`
  &:hover ${Tooltip} {
    opacity: 1;
  }

  &:hover ${Imagearrow} {
    opacity: 1;
  }
`;

const Tooltip1 = styled.div`
  position: absolute;
  top: 110%; /* Position below the button */
  left: 50%;
  transform: translateX(-50%);
   background-color: #333232;
  color: white;
  border: 1px solid #fff;
  padding: 4px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  z-index: 1000;
  font-size: 10px;
`;

const Imagearrow1 = styled.img`
  width: 13px;
  height: 15px;
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute; /* Position relative to the button */
  top: 53px; /* Adjust position to be below the tooltip */
  left: 40%;
  transform: translateX(-50%) rotate(180deg); /* Added rotation here */
`;

const LyricsSection = styled.section`
  margin-top: 10px;
`;

const LyricsSection1 = styled.section`
  margin-top: 32px;
  display: none;
`;

const ChordList = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  margin-top: 0px;
  margin-left: 0px;

  @media (max-width: 991px) {
   
    width: 100%; /* Ensure it stays full width on mobile */
  }
`;

const Verse = styled.div`
  margin-top: 24px;
  margin-right: 0px;
  color: #fff;
`;

const Sidebar = styled.aside`
  background-color: #312f2f;
  width: 400px;
  padding: 20px;
  margin-left: 0px;
  justify-content: right;
  margin-top: 80px;
  // margin-right: 20px;
  
  display: ${({ hidden }) => hidden ? 'none' : 'block'}
  @media (max-width: 991px) {
    width: 0px;
    display: none;
  }
`;

const Ad = styled.div`
  background-color: #fff;
  border-radius: 2px;
  display: ${({ hidden }) => hidden ? 'none' : 'block'}
`;

const RecentlyPlayedList = styled.ul`
  list-style: none;
  padding: 0;
  overflow-x: hidden;
  margin: 12px 0 0;
`;

const RecentlyPlayedItem = styled.li`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 12px;
  cursor: pointer;
`;

const SongThumbnail = styled.img`
  width: 78px;
  height: auto;
`;

const SongDetails = styled.div`
  flex-direction: column;
  justify-content: top;
`;

const SongName = styled.p`
  color: #fff;
  font: 600 14px/19px Poppins, sans-serif;
  letter-spacing: -0.2px;
  margin: 5px;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; /* Ensure text stays on a single line */
`;

const TransposeButton1 = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => (props.isActive ? "#F0984B" : "#242323")};
  color: ${(props) => (props.isActive ? "#fff" : "#fff")};
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  @media (max-width: 991px) {
    padding: 8px 12px;
    font-size: 10px;
    white-space: nowrap;
  }
`;

const ButtonContainer1 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  margin-left: 5px;
  margin-right: 10px;
`;

const Header51 = styled.div`
  color: #fff;
  font: 550 15px Poppins, sans-serif;

  align-items: center;
  margin-left: 10px;
  @media (max-width: 991px) {
    font-size: 12px;
    margin-left: 10px;
    margin-top: 7px;
    white-space: nowrap;
  }
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
`;

const PopupOverlay1 = styled.div`
  z-index: 1000; /* Ensure it's above other content */
`;

const PopupOverlay3 = styled.div`
  position: fixed; /* Ensures the overlay covers the entire screen */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  width: 100vw; /* Full screen width */
  height: 100vh; /* Full screen height */
  z-index: 1000; /* Ensure it's above other content */
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* Semi-transparent black for dimming effect */
  display: flex;
  justify-content: center; /* Center the popup horizontally */
  align-items: center; /* Center the popup vertically */
`;

export const spin = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`;
export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 999; /* Ensure it's above other content */
  display: ${(props) => (props.loading ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
`;
export const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

const StyledImage = styled.img`
  display: inline-block;
  max-width: 120px;
  height: auto;
  margin: 0 10px;

  @media (max-width: 768px) {
    max-width: 80px;
  }
`;

const TextContainer = styled.div`
  position: absolute;
  top: 0.5rem; // Adjust as needed
  right: 1rem;
  font-size: 0.75rem;
  color: white;
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const BoxedSpan = styled.span`
  cursor: pointer;
  background-color: #333232;
  padding: 0.25rem 0.5rem; // Padding for the box
  border: none; // Rounded corners
  font-weight: bold; // Optional for emphasis
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease; // Smooth transition effect on hover

  &:hover {
    background-color: rgba(255, 255, 255, 0.4); // Darken on hover
  }
`;

const TimeSegment = styled.div`
  position: relative;
  height: 65px;
  background-color: #282828;
  padding-bottom: 2rem;
  width: 95%;
  overflow: visible; /* Ensure elements don't get clipped */
`;

const Divider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: white;
  z-index: 1;
`;

const TimeLabel = styled.div`
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
  font-size: 0.75rem;
  color: white;
`;

const ItemContainer1 = styled.div`
  left: ${(props) => props.left}%;
  top: ${(props) => props.top}px;
  padding: 0.25rem 0.5rem;
  font-size: 15px;
  background: ${(props) => (props.isActive1 ? "#282828" : "#282828")};
  color: ${(props) => (props.type === "chord" ? "#F0984B" : "#ffffff")};
  z-index: 1; /* Base z-index */

  &:hover {
    z-index: 10; /* Increase z-index on hover */
  }
`;

const ItemContainer = styled.div`
  position: absolute;
  margin-left: 10px;
  left: ${(props) => props.left}%;
  top: ${(props) => props.top}px;
  padding: 0.25rem 0.5rem;
  font-size: 15px;
  background: ${(props) => (props.isActive1 ? "#282828" : "#282828")};
  color: ${(props) => (props.type === "chord" ? "#F0984B" : "#ffffff")};
  border: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  z-index: 1; /* Base z-index */

  &:hover {
    z-index: 10; /* Increase z-index on hover */
  }
`;


const GripButton = styled.button`
  cursor: grab;
  opacity: 1;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;

const Container_simple = styled.div`
  display: inline-block;
  width: 100%;
  flex-direction: column;
  min-width: 900px;
  min-height: 750px;
  max-height: 750px;
  overflow-y: auto;
  position: relative;
  
  ${({ isPortrait }) =>
    isPortrait &&
    css`
      overflow-x: auto;
      min-width: 900px;
      width: 100%;
    `}

  ${({ isLandscape }) =>
    isLandscape &&
    css`
      min-width: 100px;
      width: 100%;
    `}

  /* Hide scrollbars */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;


const Container100 = styled.div`
  display: inline-block; /* Ensures content stays inline */
  width: 100%;
  min-width: 900px;
  min-height: 350px;
   max-height: 600px; /* Set a maximum height for the container */
   overflow-y: auto; /* Enable vertical scrolling */
  position: relative; /* Ensure proper positioning for scroll */

  /* Hide scrollbars */
  &::-webkit-scrollbar {
    display: none; /* For WebKit browsers */
  }
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  scrollbar-width: none; /* For Firefox */
`;

const Line = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${(props) => (props.bottomMargin ? "10px" : "")};
`;

const Box = styled.div`
  font-size: ${(props) => (props.type === "translation" ? 12 : 15)}px;
  width: ${(props) => props.width}px;
  color: ${(props) => (props.type === "words" ? "#FFFFFF" : props.type === "translation" ? "#ACACAC" : "#F0984B")};
  display: inline-block;
  padding: 3px;
  border: none;
  margin-bottom: ${(props) => (props.isGrouped ? "14px" : "4px")};
  white-space: nowrap;
  background-color: ${(props) => props.highlighted && "#F0984B"}; // Apply highlight color
`;


const DropdownMenu = styled.div`
  position: absolute;
  top: 185px;
  left: 200px;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10;
`;

const MenuItem = styled.div`
  padding: 12px 24px;
  cursor: pointer;
  color: #333;
  &:hover {
    background-color: #f0f0f0;
  }
`;


const SelectWrapper_header = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 180px;
  margin: 0 8px;
  height:36px;
`;


const SelectWrapper1110 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 42px;
  margin-left: 15px;
`;

const CustomOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  cursor: pointer;
   @media (max-width: 991px) {
    font: 700 12px Poppins, sans-serif;
  }

  &:hover {
    background-color: #f0984b; /* Changes to orange on hover */
  }
`;

const DropdownContainer_header = styled.div`
  position: absolute;
  top: 35px;
  left: 0; /* Align with the left side of SelectButton */
  width: 100%;
  background-color: #333232;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
`;

const DropdownContainer60 = styled.div`
  position: absolute;
  top: calc(100% - 4px);
  left: 0; /* Align with the left side of SelectButton */
  min-width: 80px;
  background-color: #333232;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
  max-height: 50vh;
  overflow-y: auto;

  &.fixed{
    position: fixed;
    top: 60px;
    z-index: 1000;
  }

  &::-webkit-scrollbar{
    display: none;
  }
`;

const OptionText = styled.span`
  flex-grow: 1;
  color: white;
`;

const OptionText_header = styled.span`
  flex-grow: 1;
  color: white;
  font-size:12px;
`;

const IconsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 4px;

  &:hover {
    background-color: #e0e0e0;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const SelectButton_1 = styled.button`
  height: 42px;
  padding: 0 12px;
  font: 700 12px Poppins, sans-serif;
  border-radius: 2px;
  border: 1px solid #333232;
  background-color: #333232;
  color: white;
  text-align: left;
  width:${({ width }) => `${width}px`};
  appearance: none;
  cursor: pointer;

  /* Custom arrow */
  background-image: url(${down_drop_arrow}); /* Path to your arrow image */
  background-repeat: no-repeat;
  background-position: right 8px center; /* Position the arrow on the right */
  background-size: 12px; /* Size of the arrow */

  /* Flexbox for consistent alignment */
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:focus {
    border: none;
    outline: none;
  }
`;

const SelectButton_header = styled.button`

   font: 700 12px Poppins, sans-serif;
  padding:12px 24px 12px 12px;
  
  border-radius: 2px;
  border: 1px solid #fff;
  background-color: #333232;
  color: white;
  text-align: center;
  width: 100%;
  appearance: none; /* Remove default arrow */
  cursor: pointer;

  /* Position the text and arrow */
  background-image: url(${down_drop_arrow}); /* Path to your arrow image */
  background-repeat: no-repeat;
  background-position: right 8px center; /* Adjust the position of the arrow */
  background-size: 12px; /* Size of the arrow */

  /* Flexbox for content alignment */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  &:focus {
    border-color: #fff;
    outline: none;
  }
`;


const InputText20 = styled.div`
  display: flex; 
  align-items: center;
  background-color: #333232;
  width:auto;
  border: 1px solid #444;
  border-radius: 4px;
  overflow: hidden; /* Prevent page scrolling */
  max-width: 100%; /* Prevent overflow horizontally */
`;
const InputText209 = styled.div`
  display: flex; 
  align-items: center;
  background-color: #333232;
  width:170px;
  border: 1px solid #444;
  border-radius: 4px;
  overflow: hidden; /* Prevent page scrolling */
  max-width: 80%; /* Prevent overflow horizontally */
`;

const commonStyles = `
  display: inline-block;
  background-color: #333232;
  font-size: 15px;
  color: #f0984b;
  padding: 1px;
 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
`;

const InputText2 = styled.div`
  ${commonStyles}
  width: auto;
  max-width: 100%;
  word-wrap: break-word;
  
  /* Ensure content stays within container */
  overflow: hidden; 
  text-overflow: ellipsis;
  
  /* Remove default focus outline */
  outline: none;
  
  /* Prevent accidental scrolling */
  overscroll-behavior: contain;
`;

const commonStyles1 = `
  display: inline-block;
  background-color: #333232;
  font-size: 15px;
  color: #fff;
  padding: 1px;
 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
`;

const InputTextword = styled.div`
  ${commonStyles1}
  width: auto;
  max-width: 100%;
  word-wrap: break-word;
  
  /* Ensure content stays within container */
  overflow: hidden; 
  text-overflow: ellipsis;
  
  /* Remove default focus outline */
  outline: none;
  
  /* Prevent accidental scrolling */
  overscroll-behavior: contain;
`;

const HiddenText = styled.div`
  ${commonStyles}
  visibility: hidden;
  position: absolute;
  white-space: nowrap;
  pointer-events: none;
`;

const IconButton40 = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  margin-left:10px;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5; /* Indicate disabled state visually */
  }

  img {
    width: 20px;
    height: 20px;
   
  }
`;

const IconButton42 = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  margin-left:5px;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5; /* Indicate disabled state visually */
  }

  img {
    width: 22px;
    height: 22px;
   
  }
`;

const Edit = styled.img``;

const Trash2 = styled.img``;

const ThumbUpIcon = styled.svg`
  cursor: pointer;
  margin-left: 10px;
`;

const ThumbdownIcon = styled.svg`
  cursor: pointer;
  margin-left: 30px;
`;

const Thumbs1 = styled.div`
  margin-top: 4px;
`;

const Chordsrow = styled.div`
 
  width: 100%;
  overflow-x: auto;
`;

const Retry2 = styled.div`
 
  color:white;
  font-size:14px;
  margin-bottom:10px;
  margin-top:3px;
   @media (max-width: 991px) {
    margin-top:8px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right:30px;
`;

const Label3 = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: white;
`;

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
`;

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const Slider3 = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => (props.active ? "orange" : "#ccc")};
  transition: 0.3s;
  border-radius: 34px;

  &:before {
    content: "";
    position: absolute;
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: 0.3s;
    border-radius: 50%;
    transform: ${(props) => (props.active ? "translateX(26px)" : "translateX(0)")};
  }
`;

export default MusicApp;