import React, { useState,useEffect } from "react";
import styled from "styled-components";
import {
  new_logo} from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { getToken, clearToken, getimgurl,getfirstname,getlastname } from "../utils";


function Pre_nav() {
  const navigate = useNavigate();
  const token = getToken();
  const img_url = getimgurl();
  const firstName = getfirstname();
  const lastName = getlastname();
  const [showDropdown, setShowDropdown] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [imageSrc, setImageSrc] = useState();

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
    
  };

   useEffect(() => {
      const adminStatus = sessionStorage.getItem("isadmin");
      if (adminStatus === "true") {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }, []);

  const logout = () => {
  
    localStorage.clear(); 
    sessionStorage.clear();
    clearToken();
    navigate("/");
    setShowDropdown(false);
  };

  const myprofile = () => {
    navigate("/myprofile");
  };

  const mybilling = () => {
    navigate("/mybilling");
  };

  const mysetting = () => {
    navigate("/settings");
  };

  const versionControl = () => {
    navigate("/version_control");
  };

  const user_navigate = () => {
   
    navigate("/user");
    window.scrollTo(0, 0);
  };

  const initials = firstName && lastName ? `${firstName[0]}${lastName[0]}`.toUpperCase() : 'U';

  return (
    <Header>
      <Nav1>
        <Logo
          loading="lazy"
          src={new_logo}
          alt="Logo"
          onClick={user_navigate}
        />
        </Nav1>
      
       <div
        onClick={toggleDropdown}
        style={{
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          backgroundColor: img_url ? "transparent" : "#0072D5", // Blue background if no image
          display: "flex",
          width: "48px",
          height: "48px",
          marginTop: "18px",
          marginRight: "15px",
          color: img_url ? "transparent" : "#FFFFFF", // White text color if no image
          cursor: "pointer",
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "16px",
          backgroundImage: img_url ? `url(${img_url})` : "none",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {!img_url && initials}
      </div>
      {showDropdown && (
        <DropdownMenu>
          <MenuItem onClick={myprofile}>My Profile</MenuItem>
          {isAdmin && <MenuItem onClick={versionControl}>Songs Version Control</MenuItem>}
          <MenuItem onClick={mysetting}>Account Settings</MenuItem>
          <MenuItem onClick={logout}>Logout</MenuItem>
        </DropdownMenu>
      )}
    </Header>
  );
}

const Header = styled.header`
  background-color: #312f2f;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80px;
  justify-content: space-between;
  padding: 0;
  @media (max-width: 991px){
    max-width: 100%;
    flex-wrap: wrap;
    padding: 0;
    background-color: #312f2f;
    flex-direction: row;
  }
`;

const Nav1 = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0px;
  @media (max-width: 991px) {
    flex-direction: row;
  }
`;

const Logo = styled.img`
  object-position: center;
  width: 138px;
  fill: #fff;
  max-width: 100%;
  margin-left: 20px;
  padding: 0;
  cursor: pointer;
  @media (max-width: 991px) {
     margin-left: 10px;
  }
`;





const DropdownMenu = styled.div`
  position: absolute;
  top: 70px;
  right: 15px;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10;
`;

const MenuItem = styled.div`
  padding: 12px 24px;
  cursor: pointer;
  color: #333;
  &:hover {
    background-color: #f0f0f0;
  }
`;

export default Pre_nav;
