import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { thank_you_popup, white_close } from "../../assets/images";
import { getToken, getsubscription_date, getemail } from "../utils";
import { useNavigate } from "react-router-dom";
import { clearToken } from "../utils";
import axios from "axios";
import config from "../../config";

function ThankYou() {
  const token = getToken();
  const navigate = useNavigate();
  const email = getemail();
  const [referralCode, setReferralCode] = useState('');
  const [error, setError] = useState("");

  // Initialize state for subscription values
  const [subscriptionDetails, setSubscriptionDetails] = useState({
    period_start: "",
    period_end: "",
    status1: "",
    subscription_price: "",
    subscription_type: "",
    subscribed_at: "",
  });

  // Redirect if no token is available
  if (token == null) {
    navigate("/");
  }

  // Fetch subscription details
  const fetchSubscription = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/subscription/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        // Update the state with subscription details
        setSubscriptionDetails({
          period_start: response.data.period_start,
          period_end: response.data.period_end,
          status1: response.data.status,
          subscription_price: response.data.subscription_price,
          subscription_type: response.data.subscription_type,
          subscribed_at: response.data.subscribed_at,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  useEffect(() => {
    fetchSubscription();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);

    // Get the day and apply the ordinal suffix
    const day = date.getDate();
    const suffix = (day) => {
      if (day > 3 && day < 21) return "th"; // Catch all for 11th-13th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    // Format the current date
    const monthDay = date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
    const formattedDate = `${monthDay}${suffix(day)}, ${date.getFullYear()}`;

    // Calculate the next date
    const nextDate = new Date(date);
    nextDate.setDate(date.getDate() + 1); // Increment the day by 1

    // Format the next date
    const nextDay = nextDate.getDate();
    const nextMonthDay = nextDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
    const nextFormattedDate = `${nextMonthDay}${suffix(
      nextDay
    )}, ${nextDate.getFullYear()}`;

    return { formattedDate, nextFormattedDate };
  };

  // Function to calculate the renewal date
  const calculateRenewalDate = (subscribedAt, subscriptionType) => {
    const subscribedDate = new Date(subscribedAt);

    if (subscriptionType === "monthly") {
      // Return only the day of the month for monthly subscriptions
      return subscribedDate.getDate();
    } else {
      // Add one year for annual subscriptions
      subscribedDate.setFullYear(subscribedDate.getFullYear() + 1);
      const options = { day: "numeric", month: "long", year: "numeric" };
      return subscribedDate.toLocaleDateString(undefined, options);
    }
  };

  const renewalDate = calculateRenewalDate(
    subscriptionDetails.subscribed_at,
    subscriptionDetails.subscription_type
  );

  // const Date1 = formatDate(subscriptionDetails.period_end);
  const { formattedDate, nextFormattedDate } = formatDate(subscriptionDetails.period_end);

  const handleThankClose = () => {
    navigate("/user");
  };

  const apply_code = async () => {
    try {
      const response = await axios.post(`${config.apiUrl}/users/refer`, 
        {
          "code": referralCode,
        },
        {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        navigate("/user");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }else if (error.response && error.response.status === 400) {
        setError(
          error.response?.data?.message || "You already have already referred a user!"
        );
      }
    }
  };

  return (
    <PopupContainer>
      <ConfirmationCard>
        <HeaderWrapper>
          <CloseButton
            src={white_close}
            alt="Close button"
            onClick={handleThankClose}
          />
          <LogoContainer>
            <MainLogo loading="lazy" src={thank_you_popup} alt="Main logo" />
          </LogoContainer>
        </HeaderWrapper>

        {subscriptionDetails.subscription_type === "monthly" &&
        subscriptionDetails.status1 === "trial" ? (
          <>
            <Message>Thank you for becoming a customer of Jammin.AI.</Message>

            <BillingDetails>
              Your free trial will expire on <EmailStyled>{formattedDate}</EmailStyled>.
              After that, your subscription will automatically start on {" "}
              {renewalDate}th of each
              <br /> month for <EmailStyled>$9.99</EmailStyled> unless you
              cancel before {formattedDate}. <br />
              A copy of the invoice has been sent to <br />{" "}
              <EmailStyled>{email}</EmailStyled>
            </BillingDetails>
            <EmailStyled1>Were you referred by someone? If Yes Enter Referral code</EmailStyled1>
            <TextInput
                type="text"
                placeholder="Apply Referral Code if any"
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
              />
               {error && <ErrorToast>{error}</ErrorToast>}
                <StyledActionButton  onClick={apply_code}>Apply</StyledActionButton>
                <EmailStyled2  onClick={handleThankClose}>Skip</EmailStyled2>
          </>
        ) :subscriptionDetails.subscription_type === "yearly" &&
        subscriptionDetails.status1 === "trial" ? (
          <>
            <Message>Thank you for becoming a customer of Jammin.AI.</Message>

            <BillingDetails>
              Your free trial will expire on <EmailStyled>{formattedDate}</EmailStyled>.
              After that, your subscription will automatically start on {" "}
              {nextFormattedDate} for <EmailStyled>$79.99</EmailStyled> unless you cancel
              before {formattedDate}. <br />
              A copy of the invoice has been sent to <br />{" "}
              <EmailStyled>{email}</EmailStyled>
            </BillingDetails>
            <EmailStyled1>Were you referred by someone? If Yes Enter Referral code</EmailStyled1>
            <TextInput
                type="text"
                placeholder="Apply Referral Code if any"
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
              />
               {error && <ErrorToast>{error}</ErrorToast>}
                <StyledActionButton  onClick={apply_code}>Apply</StyledActionButton>
                <EmailStyled2  onClick={handleThankClose}>Skip</EmailStyled2>
          </>
        ) : null}

        {subscriptionDetails.subscription_type === "monthly" &&
        subscriptionDetails.status1 === "active" ? (
          <>
            <Message>
              Thank you for becoming a paid
              <br /> customer of Jammin.AI.
            </Message>

            <BillingDetails>
              You will be billed on the {renewalDate}th of every month. A copy
              of the
              <br />
              invoice has been sent to <EmailStyled>{email}</EmailStyled>
            </BillingDetails>
            <EmailStyled1>Were you referred by someone? If Yes Enter Referral code</EmailStyled1>
            <TextInput
                type="text"
                placeholder="Apply Referral Code if any"
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
              />
               {error && <ErrorToast>{error}</ErrorToast>}
                <StyledActionButton  onClick={apply_code}>Apply</StyledActionButton>
                <EmailStyled2  onClick={handleThankClose}>Skip</EmailStyled2>
          </>
        ) : subscriptionDetails.subscription_type === "yearly" &&
          subscriptionDetails.status1 === "active" ? (
          <>
            <Message>
              Thank you for becoming a paid
              <br /> customer of Jammin.AI.
            </Message>
            <BillingDetails>
              Your subscription renews on {renewalDate}. A copy of the
              <br />
              invoice has been sent to <EmailStyled>{email}</EmailStyled>
            </BillingDetails>
            <EmailStyled1>Were you referred by someone? If Yes Enter Referral code</EmailStyled1>
            <TextInput
                type="text"
                placeholder="Apply Referral Code if any"
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
              />
               {error && <ErrorToast>{error}</ErrorToast>}
                <StyledActionButton  onClick={apply_code}>Apply</StyledActionButton>
                <EmailStyled2  onClick={handleThankClose}>Skip</EmailStyled2>
          </>
        ) : null}
      </ConfirmationCard>
    </PopupContainer>
  );
}

const ErrorToast = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 4px;
   @media (max-width: 991px) {
   margin-left:10px;
   
  }
`;

const TextInput = styled.input`
  border: 1px solid #212529;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 10px;
  padding: 12px 0px 12px 14px; /* Add padding-right to make space for the icon */
  color: #212529;
  margin-right: 10px;
  width:350px;
  font: 400 12px/22px Poppins, sans-serif;
  @media (max-width: 991px) {
    gap: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const PopupContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Optional: for a dark overlay effect */
  z-index: 9999; /* Ensure it appears on top of other content */
`;

const BillingDetails = styled.section`
  letter-spacing: 0.18px;
  text-align: center;
  color: #121212;
  margin-top: 8px;
  font: 500 14px/20px Poppins, sans-serif;
`;

const EmailStyled = styled.span`
  font: 700 14px/20px Poppins, sans-serif;
`;

const EmailStyled1 = styled.span`
  font: 700 14px/20px Poppins, sans-serif;
  margin-top:10px;
`;


const EmailStyled2 = styled.span`
  font: 500 16px/20px Poppins, sans-serif;
  margin-top:10px;
  color: #0072D5;
  margin-bottom:12px;
   cursor: pointer;

`;
const StyledActionButton = styled.button`
  font-family: "Poppins", sans-serif;
  display: flex;
  font-weight: 500;
  font-size: 16px;
  border: none;
  cursor: pointer;
  justify-content: center;
  border-radius: 4px;
  background-color:#0072D5;
  align-items: center;
  color: #FFFFFF;
  width: 200px;
  height: 48px;
  white-space: nowrap;
  margin: auto 0;
  padding: 14px 12px;
  margin-top:12px;
 
  @media (max-width: 991px) {
    white-space: normal;
    padding: 0 20px;
    margin-right:10px;
     height: 45px;
  }
 
`;

const Message = styled.p`
  letter-spacing: 0.18px;
  text-align: center;
  color: #121212;
  margin-top: 20px;
  font: 700 14px/22px Poppins, sans-serif;
`;

const HeaderWrapper = styled.header`
  background-color: #ff8c39;
  width: 100%;
  padding: 0px;
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: 12px;
`;

const CloseButton = styled.img`
  position: absolute;
  top: 15px;
  right: 19px;
  width: 34px; /* Adjust size as needed */
  height: 34px; /* Adjust size as needed */
  cursor: pointer;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const MainLogo = styled.img`
  width: 100%;
  max-width: 440px;

  height: auto;

  margin-top: 12px;
`;

const ConfirmationCard = styled.div`
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  max-width: 440px;
  height: auto;
  width: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
`;

export default ThankYou;
