import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { main_app_image } from "../../../assets/images";
import SharePage1 from "../../chords/share_receive_signup_popup";


function Main1() {
      const share_id_added = localStorage.getItem("share_id_added");
      const [showsharePopup, setshowsharePopup] = useState(false);
    
      useEffect(() => {
        if (share_id_added) {
          setshowsharePopup(true);
        }
      }, [share_id_added]);
    
      
    
      const handleShowSharePopup = (value) => {
        setshowsharePopup(value);
      };

    return (
        <HeroSection>
            <HeroContainer>
                <BackgroundImage src={main_app_image} />
                <ContentWrapper>
                    <ContentContainer>
                        <Title>
                            Never Stop Playing <br />
                            with Jammin.ai 🎸
                        </Title>
                        <Description>
                            Unlock your musical potential with instant chords for any song. Join a
                            community of passionate musicians and start jamming today!
                        </Description>
                    </ContentContainer>
                </ContentWrapper>
            </HeroContainer>
             {showsharePopup && (
                    <PopupOverlay>
                      <SharePage1
                        onClose={() => handleShowSharePopup(false)}
                        id={share_id_added}
                      />
                    </PopupOverlay>
                  )}
        </HeroSection>
    );
}

const HeroSection = styled.section`
  width: 99vw;
  height: 100vh;
  overflow: hidden;  /* Prevent horizontal scrolling */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  text-align: center;
   @media (max-width: 991px) {
    max-width: 100%;
    width: 100vw;
    height:328px;
   
  }

  @media (max-width: 600px) {
   max-width: 100%;
    width: 100vw;
    height:328px;
   
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the full screen without overflow */
  object-position: center;
`;


const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
`;





const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 944px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
`;

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 436px;
  width: 100%;
  padding: 145px 80px;
  align-items: center;
  justify-content: center;
  text-align: center;
  
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 100px 20px;
  }

  @media (max-width: 600px) {
    padding: 60px 15px; /* Reduce padding for small screens */
  }
`;

const Title = styled.h1`
  font-size: 48px;
  font-family:
    Montserrat,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-weight: 700;

  @media (max-width: 991px) {
    font-size: 40px;
  }

  @media (max-width: 600px) {
    font-size: 30px; /* Reduce font size for mobile */
    line-height: 36px;
  }
`;

const Description = styled.p`
  font-size: 20px;
  font-family:
    Source Sans Pro,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-weight: 400;
  line-height: 28px;
  margin-top: 12px;
  width: 80%;
  max-width: 739px;

  @media (max-width: 600px) {
    font-size: 16px; /* Smaller text for mobile */
    line-height: 24px;
  }
`;


export default Main1;
