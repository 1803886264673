import { styled, keyframes } from "styled-components";
import axios from "axios";
import config from "../../config";
import {getToken } from "../utils";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect,useRef } from "react";
import { clearToken } from "../utils";
import {
 mp3_thumbnail
} from "../../assets/images";
import Pre_nav from "../navigation/premium_nav";
import { getfirstname, getlastname } from "../utils";


function View_all_recent() {
  const [songs, setSongs] = useState([]);
  const [nextKeys1, setNextKeys1] = useState("");
  const token = getToken();
  const navigate = useNavigate();
   const [allsongs, setallsongs] = useState("");
    const [loading, setLoading] = useState(true);

  if (token == null) {
    navigate("/");
  }

  useEffect(() => {
    fetchPlaylists();
  }, []);

  const fetchPlaylists = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/playlists/__default_playlist__?sort_direction=desc`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setallsongs(response.data.playlist.total_songs);
        const nextPageKey = response.headers["x-next-page-key"] || null;
        setNextKeys1(nextPageKey);

        const mappedRecords = response.data.records.map((record) => ({
          title: record.title,
          id: record.request_id,
          url: record.url,
          image: record.thumbnail_url.split("?")[0],
          state: record.state,
          created_at: formatDate(record.created_at),
        }));

        setSongs(mappedRecords);
        setLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const next_page = async () => {
    if (!nextKeys1) return;

    try {
      const response = await axios.get(
        `${config.apiUrl}/playlists/__default_playlist__?sort_direction=desc`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "x-page-key": nextKeys1,
          },
        }
      );

      if (response.status === 200) {
        const nextPageKey = response.headers["x-next-page-key"] || null;
        setNextKeys1(nextPageKey);

        const mappedRecords = response.data.records.map((record) => ({
          title: record.title,
          id: record.request_id,
          url: record.url,
          image: record.thumbnail_url.split("?")[0],
          created_at: formatDate(record.created_at),
        }));

        setSongs((prevSongs) => [...prevSongs, ...mappedRecords]);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

 

  function formatDate(dateString) {
    // Ensure the input is treated as UTC by appending 'Z' if missing
    const date = new Date(dateString.endsWith("Z") ? dateString : dateString + "Z");

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        return "Invalid Date";
    }

    // Convert to local time zone using toLocaleString
    const localDate = new Date(date.getTime());

    // Extract local date components
    const day = localDate.getDate();
    const month = localDate.getMonth();
    const year = localDate.getFullYear();

    // Month names array
    const monthNames = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    // Format the day with 'st', 'nd', 'rd', 'th'
    const dayWithSuffix =
        day + (
            day % 10 === 1 && day !== 11 ? "st" :
            day % 10 === 2 && day !== 12 ? "nd" :
            day % 10 === 3 && day !== 13 ? "rd" :
            "th"
        );

    // Get hours and minutes in local time
    let hours = localDate.getHours();
    const minutes = localDate.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    hours = hours % 12 || 12; // Convert 0 to 12 (midnight case)

    // Return formatted local time
    return `${monthNames[month]} ${dayWithSuffix} ${year} at ${hours}:${minutes} ${ampm}`;
}

useEffect(() => {
  if (songs.length === 0) return;

  const lastSongRef = document.querySelector("#last-song");
  if (!lastSongRef) return;

  const observer = new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting) {
        next_page(); // Load more when last item is visible
      }
    },
    { threshold: 1.0 }
  );

  observer.observe(lastSongRef);

  return () => observer.disconnect();
}, [songs]); // Runs when songs update

 const handleSongCardClick = async (requestId, url) => {
    console.log(requestId);
    try {
      const encodedRequestId = encodeURIComponent(requestId);
      const response_status = await axios.get(
        `${config.apiUrl}/url_requests/${encodedRequestId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response_status.status === 200) {
        if (response_status.data.state === "complete") {
          setLoading(true);
          visitchords(encodedRequestId, response_status.data, response_status.data.youtube_id);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };

   const first_name = getfirstname();
    const last_name = getlastname();
    const initials = first_name && last_name ? `${first_name}_${last_name}` : 'Unknown';

   const visitchords = async (requestId, responsed, youtube_id) => {
      setLoading(true);
      try {
        const response = await fetch(
          `${config.apiUrl}/playlists/__default_playlist__/${requestId}/visit`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.status === 200) {
          window.scrollTo(0, 0);
          // navigate("/chords", { state: { result1: responsed } });
          navigate(`/chords/${initials}/youtube/${youtube_id}`, { state: { hiddenParam: 'all_songs' } });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        }
      } finally {
        setLoading(false);
      }
    };




  return (
    <MainContainer>
      <Pre_nav />
      <Overlay loading={loading}>
              <Spinner />
      
            </Overlay>
      <SectionContainer_new>
        <ContentWrapper_new>
          <MainContent_new>
            <HeaderWrapper_new>
              <HeaderContent_new>
                <Horizontal_row>
                  <Title_new>Recently Played Songs</Title_new>
                  <Total_songs>Total songs : {allsongs}</Total_songs>
                </Horizontal_row>
              </HeaderContent_new>
            </HeaderWrapper_new>

            <GridContainer_new>
              {songs.map((song, index) => (
                <GridItem_new 
                key={`${song.title}-${index}`} 
                id={index === songs.length - 1 ? "last-song" : ""}
                onClick={() => handleSongCardClick(song.id, song.url)}
              >
                  <CardWrapper_new>
                    <CardContent_new>
                      <AlbumImage_new
                        src={song.image || mp3_thumbnail}
                        alt={`Album art for ${song.title}`}
                      />
                      <SongInfo_new>
                        <SongTitle_new>{song.title}</SongTitle_new>
                        <CreatedTime_new>
                          Created: {song.created_at}
                        </CreatedTime_new>
                      </SongInfo_new>
                    </CardContent_new>
                  </CardWrapper_new>
                </GridItem_new>
              ))}
            </GridContainer_new>
          </MainContent_new>
        </ContentWrapper_new>
      </SectionContainer_new>
    </MainContainer>
  );
}


const MainContainer = styled.main`
  height: 100%;
  background-color:#121212;
  min-height: 100vh;

  @media (max-width: 991px) {
    padding: 0 0px;
    max-width: 100%;
    margin-right:0px;
  }
`;

const SectionContainer_new = styled.main`
  display: flex;
  align-items: start;
  gap: 12px;
  font-weight: 600;
  justify-content: start;
  margin-top:20px;
  margin-left:15px;
  margin-right:15px;
  
  
`;

const ContentWrapper_new = styled.div`
  border-radius: 12px;
  background-color: rgba(51, 51, 51, 1);
  display: flex;
  min-width: 240px;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: stretch;
  
  flex: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  margin-bottom:15px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 20px;
  }
`;

const MainContent_new = styled.div`
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const HeaderWrapper_new = styled.header`
  display: flex;
  width: 100%;
  padding-right: 16px;
  align-items: start;
  gap: 12px;
  font-family: 'Poppins', sans-serif;
  color: rgba(255, 255, 255, 1);
  justify-content: start;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const HeaderContent_new = styled.div`
  display: flex;
  min-width: 240px;
  width: 100%;
  align-items: center;
  gap: 12px;
  justify-content: start;
  flex-wrap: wrap;
  flex: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  justify-content: space-between;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Title_new = styled.h2`
  font-size: 24px;
  line-height: 1.6;
  letter-spacing: -0.24px;
  align-self: stretch;
  margin: 0;
  flex: 1;
  font-family: 'Poppins', sans-serif;
  flex-shrink: 1;
  flex-basis: 0%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Total_songs = styled.div`
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.24px;
  font-family: 'Poppins', sans-serif;
  
`;


const Horizontal_row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px; /* Adjust spacing as needed */
`;


const Play_image = styled.img`
  cursor:pointer;
`;

const ViewAllButton_new = styled.button`
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: -0.16px;
  text-align: center;
  align-self: stretch;
  margin-top: auto;
  margin-bottom: auto;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 0;
`;

const CardWrapper_new = styled.article`
  background-color: rgba(34, 34, 34, 1);
  display: flex;
  min-width: 240px;
  width: 100%;
  padding: 12px;
  align-items: stretch;
  overflow: hidden;
  justify-content: start;
  flex: 1;
  flex-shrink: 1;
  flex-basis: 0%;
`;

const CardContent_new = styled.div`
  display: flex;
  min-width: 240px;
  width: 100%;
  align-items: stretch;
  gap: 8px;
  justify-content: start;
  height: 100%;
  flex: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  cursor:pointer;
`;

const AlbumImage_new = styled.img`
  aspect-ratio: 1.23;
  object-fit: contain;
  object-position: center;
  width: 64px;
  border-radius: 2px;
  margin-top: auto;
  margin-bottom: auto;
  flex-shrink: 0;
`;

const SongInfo_new = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  flex: 1;
  flex-shrink: 1;
  flex-basis: 0%;
`;

const SongTitle_new = styled.h3`
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: -0.14px;
  margin: 0;
   text-overflow: ellipsis;
  
`;

const CreatedTime_new = styled.time`
 font-family: 'Poppins', sans-serif;
  font-size: 11px;
  color: rgba(235, 235, 235, 1);
  letter-spacing: -0.11px;
  margin-top: 4px;
`;


const GridContainer_new = styled.section`
  display: grid;
  grid-template-columns:repeat(1, 1fr);
  gap: 12px;
  margin-top: 12px;
  width: 100%;

 
`;


const GridItem_new = styled.div`
  display: flex;
  width: 100%;
`;

const Overlay = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
z-index: 999; /* Ensure it's above other content */
display: ${props => (props.loading ? 'flex' : 'none')};
flex-direction: column;
justify-content: center;
align-items: center;
color: white;
font-size: 1.5em;
`;

 const spin = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`;


 const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

export default View_all_recent;

