import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import {
  getToken
} from "../utils";
import config from "../../config";
import { useNavigate } from "react-router-dom";

function Import_playlist_songs({ onClose ,data}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedSongs, setSelectedSongs] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [playlistName, setPlaylistName] = useState("");
  const [playlistData, setPlaylistData] = useState(null);
  const token = getToken();
   const [error, setError] = useState("");

  // Move all initialization logic to useEffect
  useEffect(() => {
    // Check token
    if (token == null) {
      navigate("/");
      return;
    }
    
    // Parse the JSON data from localStorage
    const rawPlaylistData = data;
    const parsedData = rawPlaylistData ? JSON.parse(rawPlaylistData) : null;
    
    // Set the playlist data
    if (parsedData && parsedData.songs) {
      setPlaylistData(parsedData);
      setPlaylistName(parsedData.playlist_name);
      setSelectedSongs(parsedData.songs.map(song => song.youtube_id));
      setSelectAll(true);
    }
  }, [navigate, token]);

  // Return null if data is not loaded yet
  if (!playlistData) {
    return null;
  }

  const handleClosePopup = () => {
   
    onClose(); // Call the existing close function
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    
    if (isChecked) {
      // Select all songs
      setSelectedSongs(playlistData.songs.map(song => song.youtube_id));
    } else {
      // Deselect all songs
      setSelectedSongs([]);
    }
  };

  const handleSongSelection = (youtube_id) => {
    if (selectedSongs.includes(youtube_id)) {
      // Remove from selected
      setSelectedSongs(selectedSongs.filter(id => id !== youtube_id));
      setSelectAll(false);
    } else {
      // Add to selected
      setSelectedSongs([...selectedSongs, youtube_id]);
      // Check if all songs are now selected
      if (selectedSongs.length + 1 === playlistData.songs.length) {
        setSelectAll(true);
      }
    }
  };


 



  const handleimport_playlist = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Only include selected songs in the request
    const selectedSongsData = playlistData.songs
      .filter(song => selectedSongs.includes(song.youtube_id))
      .map(song => ({ youtube_id: song.youtube_id }));

    const requestBody = {
      playlist_name: playlistName, // Use the edited playlist name
      songs: selectedSongsData, // Only include selected songs
      lang_code: "" // Assuming lang_code is empty
    };

    try {
      setLoading(true);
     const response = await fetch(`${config.apiUrl}/playlists/create_with_songs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

    

      if (response.status === 200) {
       
        const playlistData1="new";
        navigate('/user', {  state: { playlistData1 }
          });
          onClose(); 
      }else if(response.status === 400){
        const data = await response.json(); // Parse response JSON
        setError(data.message);
      }
    } catch (error) {
        setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PopupOverlay>
      {loading && (
        <Overlay loading={loading}>
          <Spinner />
        </Overlay>
      )}
      <Container>
        <HeaderContainer>
          <Title>Upload Playlist</Title>
          <CloseButton onClick={handleClosePopup}>×</CloseButton>
        </HeaderContainer>

        <PlaylistNameLabel>Add up to 500 songs from your YouTube Playlist</PlaylistNameLabel>
        <PlaylistNameInput 
          type="text" 
          value={playlistName} 
          onChange={(e) => setPlaylistName(e.target.value)} 
        />
         {error && <ErrorMessage>{error}</ErrorMessage>}

        <SelectAllContainer>
          <PlaylistCheckbox 
            type="checkbox" 
            checked={selectAll} 
            onChange={handleSelectAll} 
          />
          <SelectAllLabel>Select All</SelectAllLabel>
        </SelectAllContainer>

        <PlaylistContainer>
          {playlistData.songs.map((song) => (
            <PlaylistItem key={song.youtube_id}>
              <CheckboxContainer>
                <PlaylistCheckbox 
                  type="checkbox" 
                  checked={selectedSongs.includes(song.youtube_id)}
                  onChange={() => handleSongSelection(song.youtube_id)}
                />
              </CheckboxContainer>
              <PlaylistImage src={song.image_url} alt={song.title} />
              <Play>
                <PlaylistName>{ song.title.length > 40 ? song.title.slice(0, 40) + "..." : song.title}</PlaylistName>
              </Play>
            </PlaylistItem>
          ))}
        </PlaylistContainer>

        <ButtonContainer>
          <Button onClick={handleimport_playlist}>Import Playlist</Button>
        </ButtonContainer>
      </Container>
    </PopupOverlay>
  );
}

const ErrorMessage = styled.p`
  color: red;
  margin-top: 0px;
  font-size: 12px;
  text-align: left; /* Aligns text to the left */
`;


// Styled components
const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
`;

const Container = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background-color: #282828;
  max-height: 600px;
  
  width: 500px;
  padding: 20px;
  overflow-y: hidden;
  overflow-x: hidden;

  @media (max-width: 991px) {
    padding: 10px;
    max-width: 85%;
    height: 90%;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  
  right: 20px;
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 25px;
  position: relative;
  width: 100%;
`;

const Title = styled.div`
  color: white;
  margin: 0;
  font: 600 22px/1.3 Poppins, sans-serif;
  @media (max-width: 991px) {
    font: 600 20px/1.3 Poppins, sans-serif;
  }
`;

const PlaylistNameLabel = styled.div`
  color: white;
  font: 500 14px Poppins, sans-serif;
  align-self: center;
  margin-bottom: 5px;
`;

const PlaylistNameInput = styled.input`
  width: 95%;
  padding: 12px;
  margin-top:10px;
  
  border-radius: 8px;
  background-color: #3E3E3E;
  border: 1px solid #666;
  color: white;
  font: 500 16px Poppins, sans-serif;
  margin-bottom: 15px;
`;

const SelectAllContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

const SelectAllLabel = styled.span`
  color: white;
  font: 600 16px Poppins, sans-serif;
  margin-left: 8px;
`;

const PlaylistContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 10px;
  max-height: 350px;
  overflow-y: auto;
  padding: 4px;
  min-height: 350px;
  width: 100%;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
`;

const PlaylistImage = styled.img`
  width: 77px;
  height: 64px;
  border-radius: 10px;
  object-fit: cover;
`;

const PlaylistItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-radius: 10px;
  color: white;
  width: 100%;
`;

const Play = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  margin-left: 5px;
`;

const PlaylistName = styled.div`
  flex-grow: 1;
  font: 600 16px Poppins, sans-serif;
  margin-left: 5px;
  margin-bottom: 5px;
  @media (max-width: 991px) {
    font: 600 14px Poppins, sans-serif;
  }
`;

const PlaylistCheckbox = styled.input`
  width: 14px;
  height: 14px;
  transform: scale(1.5);
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 15px;
`;

const Button = styled.button`
  font-family: Poppins, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #0072D5;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  padding: 14px 12px;
  height: 54px;
  width: 160px;
  cursor: pointer;
  border: none;

  @media (max-width: 991px) {
    padding-left: 20px;
    height: 54px;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: ${props => (props.loading ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

export default Import_playlist_songs;