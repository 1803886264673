import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Pre_nav from "../navigation/premium_nav";
import { error_icon, next, pre, retry_icon, search_white, sort_icon } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { clearToken, getfirstname, getlastname, getToken } from "../utils";
import axios from "axios";
import config from "../../config";
import { toast, ToastContainer } from "react-toastify";

const VersionControl = () => {

    const navigate = useNavigate();
    const token = getToken();
    const [loading, setLoading] = useState(false);
    const [showNext, setShowNext] = useState(false);
    const [data, setData] = useState([]);
    const [searched, setSearched] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchData, setSearchData] = useState([]);
    const [pageKeys, setPageKeys] = useState([]);
    const [refIndex, setRefIndex] = useState(-1);
    const [regenerated, setRegenerated] = useState({});
    const [completed, setCompleted] = useState({});

    const first_name = getfirstname();
    const last_name = getlastname();
    const initials =
        first_name && last_name ? `${first_name}_${last_name}` : "Unknown";

    const showErrorToast = (message) => {
        toast(<div style={{ display: "flex", alignItems: "center" }}>
            <img
                src={error_icon}
                alt=""
                style={{ marginRight: "8px", width: "24px", height: "24px" }}
            />
            <span style={{ flex: 1 }}>{message}</span>
        </div>, { position: "bottom-left" }
        )
    }

    function getYouTubeID(url) {
        try {
            const parsedUrl = new URL(url);

            if (parsedUrl.hostname === "youtu.be") {
                return parsedUrl.pathname.substring(1);
            } else if (parsedUrl.hostname.includes("youtube.com")) {
                return parsedUrl.searchParams.get("v");
            }
        } catch (error) {
            showErrorToast("Invalid URL");
        }
        return null;
    }

    const searchSong = async () => {
        if (searchTerm.trim().length > 2) {
            try {
                let searchvalue = searchTerm;
                if (searchTerm.startsWith('https://')) {
                    searchvalue = getYouTubeID(searchTerm);
                }
                setLoading(true)
                const response = await axios.get(
                    `${config.apiUrl}/songs/search?term=${searchvalue.trim()}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setSearched(true);

                if (response.status === 200) {
                    setSearchData(response?.data?.songs)
                } else {
                    setSearchData([])
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    clearToken();
                    navigate("/");
                }
            } finally {
                setLoading(false);
            }
        }
    }

    const handleViewSong = async (youtube_id) => {
        try {
            const response = await axios.post(`${config.apiUrl}/songs/${youtube_id}/add_to_my`, {}, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            navigate(`/chords/${initials}/youtube/${youtube_id}`, {
                state: { hiddenParam: "user" },
            });
        } catch (error) {
            if (error.response && error.response.status === 401) {
                clearToken();
                navigate("/");
            }
        }
    }


    const fetchSongs = async (pageKey = null) => {
        const headers = pageKey ? {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "x-page-key": pageKey
        } : {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        }
        try {
            const response = await axios.get(`${config.apiUrl}/admin/songs`, {
                headers
            })
            if (response.status === 200) {
                const songs = response.data?.songs || [];
                const nextPageKey = response.headers["x-next-page-key"];
                return { songs, nextPageKey };
            }
        } catch (error) {
            throw error;
        }
    }

    const loadSongs = async (pageIndex = refIndex) => {
        setLoading(true);
        try {
            const pageKey = pageKeys[pageIndex] || null;
            const { songs, nextPageKey } = await fetchSongs(pageKey)

            setData(songs);

            if (nextPageKey) {
                setShowNext(true);
                setPageKeys(prev => Array.from(new Set([...prev, nextPageKey])));
            } else {
                setShowNext(false);
            }

            setRefIndex(pageIndex);
        } catch (error) {
            clearToken();
            navigate("/");
        } finally {
            setLoading(false);
        }
    }

    const handleNext = () => {
        if (refIndex < pageKeys.length) {
            loadSongs(refIndex + 1);
        }
    };

    const handlePrevious = () => {
        if (refIndex > -1) {
            setPageKeys((prev) => prev.slice(0, -1));
            loadSongs(refIndex - 1);
        }
    };

    const checkRegenerationStatus = async (id, req_id) => {
        let timeInterval;
        try {
            timeInterval = setInterval(async () => {
                const response = await axios.get(`${config.apiUrl}/admin/url_request/${encodeURIComponent(req_id)}`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                });
                if (response.status === 200) {
                    if (response.data.processing_step === 'complete') {
                        clearInterval(timeInterval);
                        setRegenerated(prev => ({ ...prev, [id]: 'complete' }));
                        setTimeout(() => {
                            setRegenerated(prev => ({ ...prev, [id]: false }));
                            setCompleted(prev => ({ ...prev, [id]: true }));

                            setTimeout(() => {
                                setCompleted(prev => ({ ...prev, [id]: false }));
                            }, 5000)
                        }, 1000)
                    } else {
                        setRegenerated(prev => ({ ...prev, [id]: response.data.processing_step }))
                    }
                }
            }, 5000)
        } catch (error) {
            clearInterval(timeInterval);
            clearToken();
            navigate("/");
        }
    }

    const handleRetry = async (e,id) => {
        e.stopPropagation();
        setRegenerated(prev => ({ ...prev, [id]: 'started' }))
        try {
            const response = await axios.post(`${config.apiUrl}/admin/song/${id}/retry`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
            if (response.status === 200) {
                checkRegenerationStatus(id, response.data.request_id);
            }
        } catch (error) {
            setRegenerated(prev => ({ ...prev, [id]: false }))
            if (error.response?.status === 400) {
                showErrorToast("Unable to regenerate this song")
            } else {
                clearToken();
                navigate("/");
            }
        }
    }


    useEffect(() => {
        if (searchTerm.trim().length <= 2) {
            setSearched(false);
            setSearchData([])
        }
    }, [searchTerm])

    useEffect(() => {
        loadSongs()
    }, [])

    return <Container>
        <Overlay loading={loading}>
            <Spinner />
        </Overlay>
        <Pre_nav />
        <TopContainer>
            <TitleContainer>
                <Title1>Songs Version Control</Title1>
                <Title2>Manage and review all edited versions of songs created by you</Title2>
            </TitleContainer>
            <SearchContainer>
                <SearchInputContainer>
                    <img src={search_white} alt="" />
                    <input
                        placeholder="Search by song name, or paste the YouTube Link"
                        value={searchTerm}
                        onChange={e => { setSearchTerm(e.target.value) }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                searchSong()
                            }
                        }}
                    />
                </SearchInputContainer>
                <SearchButton onClick={searchSong}>Search</SearchButton>
            </SearchContainer>
        </TopContainer>
        <TableContainer>
            <Table cellSpacing={0}>
                <thead>
                    <tr>
                        <th>Original Title <span><img src={sort_icon} alt="" /></span></th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {(searched ? searchData : data).map((row, index) => <tr style={{cursor: 'pointer'}} key={index.toString()} onClick={() => { handleViewSong(row.youtube_id) }}>
                        <td>{row.title}</td>
                        <td>
                            {completed[row.youtube_id]
                                ? <FinishedButton>Song Regenerated </FinishedButton> :
                                regenerated[row.youtube_id]
                                    ? <ProgressBar><ProgressFill step={regenerated[row.youtube_id]} /></ProgressBar>
                                    : <RetryButton onClick={(e) => { handleRetry(e, row.youtube_id) }}>
                                        <img src={retry_icon} alt="" />
                                        Retry
                                    </RetryButton>
                            }
                        </td>
                    </tr>)}
                    {
                        searched && searchData.length === 0 ?
                            <tr><td colSpan={4} style={{ textAlign: 'center' }}>No Data</td></tr>
                            : data.length === 0 ? <tr><td colSpan={4} style={{ textAlign: 'center' }}>No Data</td></tr> : null
                    }
                </tbody>
            </Table>
        </TableContainer>
        <ButtonContainer>
            {!searched && refIndex > -1 && <img src={pre} alt="" onClick={handlePrevious} />}
            {!searched && showNext && <img src={next} alt="" onClick={handleNext} />}
        </ButtonContainer>
        <ToastContainer />
    </Container>
}

export default VersionControl;

const Container = styled.div`
    height: 100vh;
    background: #282828;
    overflow-y: auto;
`

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    border-bottom: 1px solid #ABABAB;

    @media (max-width: 900px){
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
    }
`

const TitleContainer = styled.div`
    color: #ffffff;
`

const SearchContainer = styled.div`
    height: 45px;
    display: flex;
    border-radius: 8px;
    background: #454343;
    overflow: hidden;
`
const SearchInputContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px;
    input{
        background: transparent;
        border: none;
        width: 250px;
        font-size: 11px;
        color: #ffffff;
        &::placeholder{
            color: #ffffff;
            opacity: 0.7;
        }
        &:focus{
            outline: none;
        }
    }
`

const SearchButton = styled.button`
    background: #0072D5;
    color: #ffffff;
    border: none;
    padding: 12px;
`

const Title1 = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 38px;

    @media (max-width: 425px){
        font-size: 20px;
    }
`

const Title2 = styled.div`
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;

    @media (max-width: 425px){
        font-size: 12px;
    }
`

const TableContainer = styled.div`
    padding: 16px 20px;
    overflow-x: auto;
    &::-webkit-scrollbar{
        display: none;
    }
`

const Table = styled.table`
    width: 100%;
    min-width: 650px;
    border: 1px solid #ABABAB;
    border-bottom: none;

    thead{
        tr{
            background: #575757;
        }
    }

    th, td{
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;
        text-align: center;

        &:nth-child(1){
            text-align: left;
            width: 85%;
        }
    }
    
    th{
        padding: 12px 28px;
    }

    td{
        padding: 16px;
        border-bottom: 1px solid #ABABAB;
        text-wrap-mode: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }
`

const FinishedButton = styled.div`
    height: 40px;
    width: 120px;
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    background: #00D567;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    margin: auto;
    padding: 4px;
`
const ProgressBar = styled.div`
    height: 8px;
    width: 150px;
    border-radius: 40px;
    background: #BDBDBD;
    margin: 16px auto;
`

const ProgressFill = styled.div`
    height: 100%;
    width: ${({ step }) => step === 'complete' ? '100%' : step === 'generating_chords' ? '75%' : step === 'transcribing_audio' ? '50%' : '0'};
    border-radius: 40px;
    background: ${({ step }) => step === 'complete' ? '#00D567' : '#0072D5'};
    transition: width 0.5s ease;
`

const RetryButton = styled.div`
    height: 40px;
    width: 120px;
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    margin: auto;
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
    margin-bottom: 24px;
`

export const spin = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 999; /* Ensure it's above other content */
  display: ${({ loading }) => (loading ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;