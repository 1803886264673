import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { landing_playlist_right_img, discover_playlist_youtube, discover_playlist_spotify } from "../../../assets/images";
import useWindowSize from "../../useWindowSize"
import { useNavigate } from "react-router-dom";
import { getToken } from "../../utils";
import Login from "../../login/login";

const PlaylistImportWrapper = styled.section`
  display: flex;
  width: 100%;
  padding: 36px 56px;
  align-items: center;
  gap: 36px;
  overflow-x: hidden; /* Prevent any potential overflow */
  box-sizing: border-box; /* Include padding in the width calculation */

  @media (max-width: 991px) {
    flex-direction: column;
    padding: 24px;
  }

  @media (max-width: 640px) {
    padding: 16px;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 60%;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0px;
  width: 100%;
  max-height: 400px;

  @media (max-width: 991px) {
    max-height: none;
    align-items: center; /* Center content in mobile */
    text-align: center;
  }
`;

const Title = styled.h1`
  width: 100%;
  color: #121212;
  font-family: Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
  font-size: 36px;
  font-weight: 700;

  @media (max-width: 640px) {
    font-size: 21px;
    text-align: center; /* Centers title text */
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  width: 100%;
`;

const Description = styled.p`
  width: 100%;
  color: #121212;
  font-family: Poppins, 
Source Sans Pro;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;

  @media (max-width: 640px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const StyledImage = styled.img`
  width: 40%;
  max-width: 450px;
  flex-shrink: 0;
  object-fit: cover;
  margin-left: 30px;

  @media (max-width: 800px) {
    width: 100%;
    margin: 10px auto; /* Centers the image */
    display: block;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; /* Centers buttons */
  gap: 12px;
  margin-top: 20px;

  @media (max-width: 800px) {
    flex-direction: row;
    width: 100%;
  }
`;

const ButtonImage = styled.img`
  cursor: pointer;
  max-height: 52px; 
  width: auto;
  transition: transform 0.2s ease;
  
  &:hover {
    transform: scale(1.05);
  }
  
  @media (max-width: 800px) {
    width: auto; /* Prevents stretching */
    max-width: 45%; /* Ensures both buttons fit within width */
  }
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
`;

const PlaylistImport = () => {
  const { windowWidth, windowHeight, isMobile, isPortrait,isLandscape } = useWindowSize();
   const [isLoginPopupVisible, setLoginPopupVisible] = useState(false);
    const token = getToken();
    const navigate = useNavigate();

    const handleChordsClick = () => {
      if(token){
        window.scrollTo(0, 0);
        navigate("/user");
      }else{
       
        setLoginPopupVisible(true);
      }
      };

  return (
    <PlaylistImportWrapper>
       {isLoginPopupVisible && (
              <PopupOverlay>
              <Login onClose={() => setLoginPopupVisible(false)} />
               </PopupOverlay>
            )}
       <>
          {isMobile ? (  
 <Section>
     
 <ContentWrapper>
   <Title>Import Your Playlists from Spotify or YouTube</Title>
   <StyledImage
 src={landing_playlist_right_img}
 alt="Person using tablet"
/>

<ButtonGroup>
   <ButtonImage onClick={handleChordsClick}
     src={discover_playlist_spotify} 
     alt="Import from Spotify"
   />
   <ButtonImage onClick={handleChordsClick}
     src={discover_playlist_youtube} 
     alt="Import from YouTube"
   />
 </ButtonGroup>
 
   <TextContainer>
     <Description>
       Bring your favorite music into Jammin.ai with our seamless playlist
       import feature. Connect your Spotify account or YouTube channel to
       instantly transfer your curated playlists and access chord
       progressions for all your favorite songs in just a few clicks.
     </Description>
     <Description>
       Transform imported playlists into personalized practice sessions by
       adjusting tempos, transposing songs, and generating chord charts.
       With Jammin.ai, your existing music library becomes an interactive
       learning resource that evolves with your musical journey.
     </Description>
   </TextContainer>
 </ContentWrapper>

</Section>

) : (
  <>
  <Section>
     
  <ContentWrapper>
    <Title>Import Your Playlists from Spotify or YouTube</Title>
  
    <TextContainer>
      <Description>
        Bring your favorite music into Jammin.ai with our seamless playlist
        import feature. Connect your Spotify account or YouTube channel to
        instantly transfer your curated playlists and access chord
        progressions for all your favorite songs in just a few clicks.
      </Description>
      <Description>
        Transform imported playlists into personalized practice sessions by
        adjusting tempos, transposing songs, and generating chord charts.
        With Jammin.ai, your existing music library becomes an interactive
        learning resource that evolves with your musical journey.
      </Description>
    </TextContainer>
  </ContentWrapper>
  <ButtonGroup>
    <ButtonImage onClick={handleChordsClick}
      src={discover_playlist_spotify} 
      alt="Import from Spotify"
    />
    <ButtonImage onClick={handleChordsClick}
      src={discover_playlist_youtube} 
      alt="Import from YouTube"
    />
  </ButtonGroup>
</Section>
<StyledImage
  src={landing_playlist_right_img}
  alt="Person using tablet"
/>
</>
)}
    </>
     
    </PlaylistImportWrapper>
  );
};

export default PlaylistImport;