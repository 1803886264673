
import styled from "styled-components";
import { landing_discover_step1, landing_discover_step2, landing_discover_step3, landing_discover_right_img } from "../../../assets/images";
import useWindowSize from "../../useWindowSize"

const Discover = () => {
  const { windowWidth, windowHeight, isMobile, isPortrait, isLandscape } = useWindowSize();
  const steps = [
    {
      title: "Search for Song",
      description: "Unlock song chords. Instantly play the music you love today.",
      imageSrc: landing_discover_step1
    },
    {
      title: "Get the lyrics and Chords",
      description: "Unlock song chords. Instantly play the music you love today.",
      imageSrc: landing_discover_step2
    },
    {
      title: "Start Playing",
      description: "Unlock song chords. Instantly play the music you love today.",
      imageSrc: landing_discover_step3
    }
  ];


  return (
    <>
      {isMobile ? (
        <Container>
          <Header1>
      <Content1>
        <ContentWrapper1>
          <Title1>Discover Chords to your Favorite Music</Title1>
          <Description1>
            Unlock song chords. Instantly play the <br />
            music you love today.
          </Description1>
        </ContentWrapper1>
      </Content1>
      <HeroImage1
       src={landing_discover_right_img}
        alt="Music chord discovery illustration"
      />
    </Header1>

    <FeaturesSection1>
      {steps.map((feature, index) => (
        <Card1 >
            <StepImageContainer>
            <FeatureImage1 src={feature.imageSrc} />

            </StepImageContainer>
       
        <Content2>
          <Title2>{feature.title}</Title2>
          <Description2>{feature.description}</Description2>
        </Content2>
      </Card1>
      ))}
    </FeaturesSection1>


        </Container>
      ) : (
        <Container>
          <MainContent>
            <HeaderWrapper>
              <Title>Discover Chords to your Favorite Music</Title>
              <Subtitle>
                Unlock song chords. Instantly play the music you love today.
              </Subtitle>
            </HeaderWrapper>
            <StepsSection>
              <StepsContainer>
                {steps.map((step, index) => (
                  <CardWrapper key={index}>
                    <StepImageContainer>
                      <StepImage src={step.imageSrc} alt={step.title} />
                    </StepImageContainer>
                    <StepTitle>{step.title}</StepTitle>
                    <StepDescription>{step.description}</StepDescription>
                  </CardWrapper>
                ))}

              </StepsContainer>
            </StepsSection>
          </MainContent>
          <SideImage
            src={landing_discover_right_img}
            alt="Musical instrument illustration"
          />
        </Container>
      )}
    </>

  );
};


const Card1 = styled.article`
  display: flex;
  width: 100%;
  align-items: start;
  gap: 12px;
  justify-content: start;
  margin-top: ${(props) => (props.isFirst ? "0" : "16px")};
`;

const FeatureImage1 = styled.img`
  aspect-ratio: 3.68;
  object-fit: contain;
  object-position: center;
  width: 147px;
  flex-shrink: 0;
`;

const Content2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: auto;
`;

const Title2 = styled.h2`
  font-size: 14px;
  font-family:
    Montserrat,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-weight: 700;
  line-height: 18px;
`;

const Description2 = styled.p`
  font-size: 10px;
  font-family:
    Source Sans Pro,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-weight: 400;
  line-height: 12px;
  margin-top: 1px;
`;

const FeaturesSection1 = styled.section`
  margin-top: 24px;
  width: 100%;
`;


const Header1 = styled.header`
  align-self: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: start;
`;

const Content1 = styled.div`
  width: 100%;
`;

const ContentWrapper1 = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  text-align: center; /* Ensures text inside is centered */
`;

const Title1 = styled.h1`
  width: 100%;
  font-family:
    Montserrat,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-size: 24px;
  font-weight: 800;
`;

const Description1 = styled.p`
  font-size: 14px;
  font-family:
    Source Sans Pro,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-weight: 600;
  line-height: 18px;
  margin-top: 8px;
`;

const HeroImage1 = styled.img`
  aspect-ratio: 1.07;
  object-fit: contain;
  object-position: center;
  width: 308px;
  border-radius: 0px;
  margin-top: 12px;
  max-width: 100%;
`;






const StepImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StepImage = styled.img`
  height: 66px;
  width: auto;
  object-fit: contain;
`;

const CardWrapper = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 296px;
  min-width: 240px;
  margin-top: auto;
  margin-bottom: auto;
  color: rgba(18, 18, 18, 1);
`;


const StepText = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  aspect-ratio: 4.485;
  width: 100%;
  padding: 19px 30px;
  @media (max-width: 991px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const StepTitle = styled.h3`
  font-family:
    Montserrat,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  margin-top: 20px;
`;

const StepDescription = styled.p`
  font-size: 12px;
  font-family:
    Source Sans Pro,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  margin-top: 8px;
`;



const HeaderWrapper = styled.header`
  max-width: 100%;
  width: 944px;
  color: rgba(18, 18, 18, 1);
`;

const Title = styled.h1`
  width: 100%;
  font-family:
    Montserrat,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-size: 36px;
  font-weight: 700;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Subtitle = styled.p`
  font-size: 20px;
  font-family:
    Source Sans Pro,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-weight: 400;
  line-height: 1.4;
  margin-top: 8px;
  @media (max-width: 800px) {
    max-width: 100%;
  }
`;


const Container = styled.section`
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  padding: 16px 47px 24px 56px;
  align-items: start;
  gap: 12px;
  height:430px;
  overflow: hidden;
  justify-content: start;
  flex-wrap: wrap;
  @media (max-width: 800px) {
    padding: 20px;
    height:auto;
  }
`;

const MainContent = styled.div`
  min-width: 240px;
  flex-grow: 1;
  flex-shrink: 1;
  width: 868px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const StepsSection = styled.section`
  display: flex;
  margin-top: 56px;
  align-items: center;
  gap: 32px;
  justify-content: start;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const StepsContainer = styled.div`
  align-self: stretch;
  display: flex;
  min-width: 240px;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 32px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const SideImage = styled.img`
  aspect-ratio: 1.03;
  object-fit: contain;
  object-position: center;
  width: 305px;
  border-radius: 0;
  min-width: 240px;
  flex-grow: 1;
  flex-shrink: 1;
`;

export default Discover;
