import React, { useState } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from './config';
import Cookies from 'js-cookie';
import { checkAdmin } from "./components/navigation/check_admin";

const GoogleSignIn = () => { 
  const clientId =
    "137913848436-029djuv3nvae8h128ul7r7ruqrcuq6kg.apps.googleusercontent.com";
  const [Token, setToken] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const onSuccess = async(credentialResponse) => {
    setToken(credentialResponse.credential);
    try {
      const response = await axios.post(
        `${config.apiUrl}/users/auth/google`,
        {
          token: credentialResponse.credential,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (
        response.status === 200 &&
        response.data.user &&
        response.data.access_token
      ) {
        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem("user_img_url", response.data.user.image_url);
        localStorage.setItem("user_first_name", response.data.user.first_name);
        localStorage.setItem("user_last_name", response.data.user.last_name);
        localStorage.setItem("user_email", response.data.user.email);
        localStorage.setItem("terms_accepted", response.data.user.terms_accepted);
        localStorage.setItem("referrers", response.data.user.referrers);
        const currentTime = new Date().toISOString();
        Cookies.set('currentTime', currentTime);
        checkAdmin();
        navigate("/user");
        window.scrollTo(0, 0);
      } else {
        setError("Invalid email or password.");
      }
    } catch (error) {
      setError(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    }
  };
  
  const onError = () => {
   
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <GoogleLogin onSuccess={onSuccess} onError={onError} />
    </GoogleOAuthProvider>
  );
};

export default GoogleSignIn;
