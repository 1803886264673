import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { premium, orange_tick, ref_copy } from "../../assets/images";
import axios from "axios";
import config from "../../config";
import Pre_nav from "../navigation/premium_nav";
import Footer from "../footer_lan";
import { getfirstname, getemail, getimgurl, getlastname } from "../utils";
import { getToken, getsubscription, clearToken } from "../utils";

import Snackbar from "@mui/material/Snackbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



function Myaccount() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const subscription = getsubscription();
  const [selectedNavItem, setSelectedNavItem] = useState("/myprofile");
  const [refer, setrefer] = useState("");
  const img_url = getimgurl();
  const email = getemail();
  const first_name = getfirstname();
  const last_name = getlastname();
  const token = getToken();

  if (token == null) {
    navigate("/");
  }

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    const get_refer = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/users/ref_code`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          setrefer(response.data.code);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        }
      }
    };

    get_refer();
  }, []);

  const showToast1 = () => {
    // console.log("Showing toast");
    toast(<CustomToast1 message={"Referral code Copied Successfully"} />, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const CustomToast1 = ({ message }) => {
    return (
      <div>
        <p>{message}</p>
      </div>
    );
  };

  const [copied, setCopied] = useState(false);
  const textToCopy = refer;

  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopied(true);
      showToast1();
    });
  };

  
  

  return (
    <PageWrapper>
      <Pre_nav />
      <Overlay loading={loading}>
        <Spinner />
      </Overlay>
      <ContentWrapper>
        <Sidebar>
          <NavItem selected={selectedNavItem === "/myprofile"}>
            My Profile
          </NavItem>
          {/* <NavItem onClick={() => navigate("/mybilling")}>
            Billing and Subscription
          </NavItem> */}
          <NavItem onClick={() => navigate("/settings")}>Settings</NavItem>
        </Sidebar>
        <MainContent>
          <ProfileSection>
            <img
              src={img_url}
              style={{
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                backgroundColor: "#FF8C39",
                display: "flex",
                width: "68px",
                height: "68px",
                marginRight: "20px",
                color: "#fff",
                cursor: "pointer",
                fontFamily: "Poppins, sans-serif",
                fontWeight: "600",
                fontSize: "16px",
              }}
            />
            <ProfileDetails>
              <ProfileName>
                {first_name} {last_name}
              </ProfileName>

              {/* <ProfileName1>Share your referral code</ProfileName1>
              <ProfileName2>
                Introduce and Jammin.ai with your Friends and Family and earn
                rewards
              </ProfileName2>

              <Container >
                <Text>{textToCopy}</Text>
                <CopyIcon src={ref_copy} onClick={handleCopy}/>
                {copied && <span style={{ color: 'green', marginLeft: '20px' }}>Copied!</span>}
              </Container> */}

              {/* {subscription == null ? (
                <BadgeIcon1 loading="lazy" src={free_user} alt="Icon" />
              ) : (
                <BadgeIcon loading="lazy" src={premium_user_nav} alt="Icon" />
              )} */}
            </ProfileDetails>
          </ProfileSection>
          <Divider />
          <UserInfoSection>
          <Basic>Basic Information</Basic>
            <Rows>
            <New1>
            <SectionTitle>First Name</SectionTitle>
            <TextInput1
              type="text"
              value={first_name}
              editable={false}
              readOnly
            />
            </New1>
            <New1>
            <SectionTitle>Last Name</SectionTitle>
            <TextInput1
              type="text"
              value={last_name}
              editable={false}
              readOnly
            />
            </New1>
            </Rows>
            {/* <SectionTitle>Gender</SectionTitle>
            <TextInput type="text" placeholder="Gender" /> */}
            <SectionTitle>Email Address</SectionTitle>
            <TextInput2 type="email" value={email} editable={false} readOnly />
          </UserInfoSection>
          <Divider />
          <Basic>Invite and Earn</Basic>
          {/* <Basic1>Jam with friends & unlock exclusive rewards!</Basic1>
          <Basic2>Benefits :</Basic2> */}
          {/* <Rows3>
          <img
                    src={orange_tick}
                    alt="Tick"
                    style={{
                      display: "inline",
                      marginRight: "8px",
                      marginTop: "12px",
                      width: "15px",
                      height: "15px",
                    }}
                    /> */}
            <Basic3>For every subscriber you refer, you get 1 week of Jammin Premium for FREE</Basic3>
          {/* </Rows3> */}
        
          <Basic4>Tap below to share your referral code 👇🏻 </Basic4>

          <Content>
          <Text>{textToCopy}</Text>
                <CopyIcon src={ref_copy} onClick={handleCopy}/>
               
          </Content>
          
        </MainContent>
      </ContentWrapper>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
      <ToastContainer />
    </PageWrapper>
  );
}

const Rows3= styled.div`
flex-direction:row;
display:flex;
`;


const Container = styled.div`
margin-top:5px;
  display: flex;
  align-items: center;
 
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 8px;
  
  width:160px;
`;

// const Text = styled.span`
//   font: 700 12px Source Sans Pro, sans-serif;
//   color: white;
//   margin-left: 10px;
//   line-height:20px;
//   margin-top:15px;
  
// `;

// const CopyIcon = styled.img`
//  cursor: pointer;
//   width: 24px;
//   height: 24px;
//    margin-top:15px;
// `;


const Logo1 = styled.img`
  fill: #fff;
  height: 10px;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const Sidebar = styled.div`
  width: 200px;
  background-color: #312f2f;
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    width: 100%;
    flex-direction: row;
    padding: 0px;
    display: flex;
  }
`;

const TextInput = styled.input`
  border: 1px solid #ececec;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 10px;
  padding: 15px; /* Adjusted padding */
  color: #212529;
  font-size: 14px;
  font: 400 12px/22px Poppins, sans-serif;
  width: 40%; /* Adjusted width */
`;

const TextInput1 = styled.input`
  border: 1px solid #ececec;
  border-radius: 4px;
  background-color: #fff;
  color: grey;
  margin-top: 10px;
  padding: 12px;
  font-size: 14px;
  font: 400 12px/22px Poppins, sans-serif;
  width: 90%; /* Ensure all inputs take full width of their container */
  box-sizing: border-box; /* Ensures padding is included in the width */
  @media (max-width: 991px) {
    width: 100%; /* Ensures responsiveness */
    padding: 10px;
  }
`;

const TextInput2 = styled.input`
  border: 1px solid #ececec;
  border-radius: 4px;
  background-color: #fff;
  color: grey;
  margin-top: 10px;
  padding: 12px;
  font-size: 14px;
  font: 400 12px/22px Poppins, sans-serif;
  width: 44%; /* Ensure all inputs take full width of their container */
  box-sizing: border-box; /* Ensures padding is included in the width */
  @media (max-width: 991px) {
    width: 100%; /* Ensures responsiveness */
    padding: 10px;
  }
`;

const NavItem = styled.div`
  margin-bottom: 10px;
  cursor: pointer;
  color: ${(props) => (props.selected ? "white" : "white")};
  background-color: ${(props) => (props.selected ? "#3b82f6" : "transparent")};
  padding: 10px;
  border-radius: 0px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
`;
const MainContent = styled.div`
  flex: 1;
  position: relative;
  overflow: auto;
  background-color: #1e1e1e;
  padding: 20px;
  @media (max-width: 991px) {
    padding: 20px;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 999; /* Ensure it's above other content */
  display: ${(props) => (props.loading ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
`;

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileImage = styled.img`
  width: 108px;
  height: 108px;
  margin-right: 20px;
`;

const ProfileDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0px;

  height: 80px;
`;

const ProfileName = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: white;
  margin-right: 0px;
    margin-top:20px;
`;

const Basic = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: white;
  margin-right: 0px;
    margin-top:10px;
`;

const Basic1 = styled.div`
  font-size: 14px;
  
  color: white;

    margin-top:8px;
`;
const Basic2 = styled.div`
  font-size: 14px;
  font-weight:500;
  
  color: white;

    margin-top:10px;
`;
const Basic3 = styled.div`
  font-size: 14px;
  
  
  color: white;

    margin-top:10px;
`;
const Basic4 = styled.div`
  font-size: 12px;
  
  
  color: white;

    margin-top:10px;
`;

const Content = styled.div`
  width: 200px;
  height: 60px;
  background-color: #3A3A3A;
  margin-top: 10px;
  display: flex; /* Add display flex */
  align-items: center; /* Align items vertically in the center */
  justify-content: space-between; /* Space between text and icon */
  padding: 0 10px; /* Optional: Add some padding for better spacing */
`;

const Text = styled.span`
  font: 700 14px Source Sans Pro, sans-serif;
  color: white;
  margin-left: 10px;
  line-height: 20px;
`;

const CopyIcon = styled.img`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;


const ProfileName1 = styled.div`
  font: 500 16px Source Sans Pro, sans-serif;
  color: white;
  margin-right: 0px;
  margin-top: 5px;
`;

const ProfileName2 = styled.div`
  font: 300 12px Source Sans Pro, sans-serif;
  color: white;
  margin-right: 0px;
  margin-top: 5px;
`;

const BadgeIcon = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 0px;
  margin-left: 0px;
`;
const BadgeIcon1 = styled.img`
  width: 90px;
  height: 90px;
  margin-right: 0px;
  margin-left: 0px;
`;

const BadgeText = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: white;
  padding: 5px 10px;
  border-radius: 114px;
  font: 400 12px/22px Poppins, sans-serif;
`;

const Divider = styled.div`
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 20px 0;
  margin-top:20px;
`;

const UserInfoSection = styled.div`
  color: white;
   width: 100%;
`;

const Rows = styled.div`
  display: flex;
  gap: 20px; /* Adds space between First and Last Name */
  margin-top: 10px;
  width: 100%; /* Ensure container takes full width */
`;

const New1 = styled.div`
  flex: 1; /* Ensures equal space for both First Name and Last Name */
`;

const SectionTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  font: 400 12px/22px Poppins, sans-serif;
`;

const SaveChangesButton = styled.button`
  border-radius: 10px;
  padding: 15px; /* Adjusted padding */
  width: 40%; /* Adjusted width */
  background-color: #3b82f6;
  color: white;
  font-size: 14px; /* Adjusted font size */
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  width: 20%; /* Adjusted width */
  &:hover {
    background-color: #2563eb;
  }
`;

const FooterWrapper = styled.div`
  background-color: #312f2f;
  color: white;
  padding: 20px;
`;

export default Myaccount;
