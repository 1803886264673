import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { landing_translate_bg, landing_translate_left,landing_translate_btn } from "../../../assets/images";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../utils";
import Login from "../../login/login";

const StyledSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; /* Changed from 100vw to 100% */
  min-height: 65vh;
  background-image: url(${landing_translate_bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 36px 56px;
  color: white;
  box-sizing: border-box; /* Add box-sizing to include padding in width calculation */
  position: relative;

  @media (max-width: 991px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const LeftImage = styled.img`
  max-width: 30%;
  height: auto;
  object-fit: contain;
  margin-left:30px;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-bottom: 20px; /* Add some spacing for mobile layout */
  }
`;

const Button = styled.img`
cursor:pointer;
`;

const ContentContainer = styled.div`
  width: 55%; /* Slightly reduced to ensure proper spacing */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
    font-family: Poppins, 
Source Sans Pro;
margin-left:10px;
margin-right:20px;
  

  h2 {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 24px;
  font-weight: 700;
  }

  p {
    margin-top: 0;
    font-size: 20px;
  font-weight: 400;
   line-height: 32px;
  }

  @media (max-width: 800px) {
    width: 100%;
    text-align: center;
    align-items: center;
    p {
    margin-top: 0;
    font-size: 16px;
  font-weight: 400;
   line-height: 32px;
  }
  }
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
`;



const Translate = () => {
   const [isLoginPopupVisible, setLoginPopupVisible] = useState(false);
      const token = getToken();
      const navigate = useNavigate();
      const handleChordsClick = () => {
        if(token){
          window.scrollTo(0, 0);
          navigate("/user");
        }else{
         
          setLoginPopupVisible(true);
        }
        };
  return (
    <StyledSection>
       {isLoginPopupVisible && (
              <PopupOverlay>
              <Login onClose={() => setLoginPopupVisible(false)} />
               </PopupOverlay>
            )}
      <LeftImage src={landing_translate_left} alt="Left side image" />
      <ContentContainer>
        <h2>Translate your favorite songs</h2>
        <p>Music is universal, and so is Jammin.ai. Our platform recognizes chord patterns regardless of language or musical tradition. Upload songs in any of the 80+ supported languages and get accurate chord transcriptions instantly. Whether you're into K-pop, Latin ballads, French chanson, or Arabic folk music — Jammin.ai has you covered.</p>
        <Button onClick={handleChordsClick} src={landing_translate_btn}/>
      </ContentContainer>
    </StyledSection>
  );
};

export default Translate;