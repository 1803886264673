import React, { useEffect, useRef, useImperativeHandle } from "react";
import _ from "lodash";

const VideoPlayer = ({ videoUrl, onTimeUpdate, playerRef, isPlaying, playerRef2 }) => {
  const iframeRef = useRef(null);
  const intervalRef = useRef(null);
  const lastReportedTimeRef = useRef(null);

  const extractVideoId = (url) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?youtu(?:\.be|be\.com)\/(?:watch\?v=|embed\/|v\/)?([^&?\/\s]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };
  
  // Create a throttled version of onTimeUpdate to limit how often it's called
  const throttledTimeUpdate = useRef(
    _.throttle((time) => {
      if (onTimeUpdate && (lastReportedTimeRef.current === null || Math.abs(time - lastReportedTimeRef.current) >= 0.1)) {
        lastReportedTimeRef.current = time;
        onTimeUpdate(time);
      }
    }, 200) // Call at most once every 200ms
  ).current;
  
  const handleStateChange = (event) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      isPlaying(true);
      
      // Clear any existing interval
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
  
      // Check time less frequently - once per second is usually enough
      intervalRef.current = setInterval(() => {
        if (playerRef.current && typeof playerRef.current.getCurrentTime === "function") {
          const currentTime = playerRef.current.getCurrentTime();
          const roundedTime = Math.round(currentTime * 10) / 10; // Round to 1 decimal place
          
          // Only report time if it's meaningful
          throttledTimeUpdate(roundedTime);
        }
      }, 700); // Check only once per second
    } else if (
      event.data === window.YT.PlayerState.PAUSED ||
      event.data === window.YT.PlayerState.ENDED
    ) {
      isPlaying(false);
      clearInterval(intervalRef.current); // Stop the interval when paused or ended
    }
  };
  
  const initializePlayer = () => {
    if (playerRef.current) {
      playerRef.current.destroy();
    }
    playerRef.current = new window.YT.Player(iframeRef.current, {
      videoId: extractVideoId(videoUrl),
      events: {
        onStateChange: handleStateChange,
      },
    });
  };

  useEffect(() => {
    if (window.YT && window.YT.Player) {
      // API is already loaded, initialize the player
      initializePlayer();
    } else {
      // Dynamically load the YouTube IFrame API
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      document.body.appendChild(tag);

      window.onYouTubeIframeAPIReady = () => {
        initializePlayer();
      };
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.destroy();
      }
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      // Clean up the throttled function
      throttledTimeUpdate.cancel();
    };
  }, [videoUrl]);

  // Expose restartVideo function to the parent using useImperativeHandle
  useImperativeHandle(playerRef2, () => ({
    restartVideo: () => {
      if (playerRef.current && typeof playerRef.current.seekTo === "function") {
        playerRef.current.seekTo(0); // Seek the video to the start
        playerRef.current.playVideo(); // Play the video from the start
      }
    },
    seekTo: (time) => {
      if (playerRef.current && typeof playerRef.current.seekTo === "function") {
        playerRef.current.seekTo(time);
      }
    }
  }));

  return (
    <div className="video-player">
      <style>
        {`
          .video-player {
            position: relative;
            width: 100%;
            height: 300px;
            overflow: hidden;
          }
          .video-player iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: none;
            margin: 0;
            padding: 0;
          }
        `}
      </style>
      <div
        ref={iframeRef}
        id="youtube-iframe"
        style={{ width: "100%", height: "100%" }}
      ></div>
    </div>
  );
};

export default React.forwardRef((props, ref) => 
  <VideoPlayer {...props} playerRef2={ref} />
);