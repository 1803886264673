import React from "react";
import styled from "styled-components";
import Pre_nav from "../navigation/premium_nav";
import Pre_nav_offline from "../navigation/pre_nav_offline";
import Footer from "../footer_lan";
import { getToken } from "../utils";
import { useNavigate } from "react-router-dom";


const faqData = [
  {
    question: "1. What is Jammin.ai?",
    answer: "Jammin.ai is an innovative platform that instantly generates chords and lyrics for any song linked from YouTube, in any language. It's perfect for musicians of all levels who want to learn new songs, explore musical possibilities, or simply jam along to their favorites."
  },
  {
    question: "2. How do I get started with Jammin.ai?",
    answer: "Jammin.ai is an innovative platform that instantly generates chords and lyrics for any song linked from YouTube, in any language. It's perfect for musicians of all levels who want to learn new songs, explore musical possibilities, or simply jam along to their favorites."
  },
  {
    question: "3. Do I need a paid subscription to use Jammin.ai?",
    answer: "Jammin.ai offers a free tier that provides access to a limited number of songs. We also have a premium subscription that unlocks unlimited access to songs and allows for unlimited PDF downloads."
  },
  {
    question: "4. What songs can I find chords and lyrics for on Jammin.ai?",
    answer: "You can find chords and lyrics for a wide variety of songs across different genres and languages by using YouTube links."
  },
  {
    question: "5. How do I upgrade to a premium subscription?",
    answer: "You can easily upgrade your account to a premium subscription within the Jammin.ai app or on our website."
  },
  {
    question: "6. Can I cancel my subscription?",
    answer: "Yes, you can cancel your subscription at any time through your account settings."
  },
  {
    question: "7. How many songs can I access with the free subscription?",
    answer: "With the free plan, you can access up to 5 songs, but PDF downloads are not included."
  },
  {
    question: "8. Does Jammin.ai support songs in multiple languages?",
    answer: "Yes, Jammin.ai provides lyrics and chords for songs in multiple languages based on the YouTube links provided."
  },
  {
    question: "9. Can I download PDF files of chords and lyrics for YouTube songs?",
    answer: "Yes, with a premium subscription, you can download PDFs of chords and lyrics."
  },
  {
    question: "10. What happens to my songs if I cancel my subscription?",
    answer: "If you cancel your subscription, you will still have access to the songs you have purchased, but you will no longer be able to get lyrics and chords for new songs."
  },
  {
    question: "11. Can I use Jammin.ai offline?",
    answer: "While the app requires an internet connection to generate chords and lyrics, you can download PDFs of your songs for offline access with a premium subscription."
  }
];

function FAQSection() {
  const navigate = useNavigate();
  const contact = () => {
    window.scrollTo(0, 0);
    navigate("/contact");
  };
  const token = getToken();
  return (
    <MainContainer>
      {token ? <Pre_nav /> : <Pre_nav_offline />}
     
      <ContentWrapper>
        <FAQContainer>
          <FAQTitle>Frequently Asked Questions</FAQTitle>
          <FAQDescription>
            If you got any any questions about Jammin.ai read on the below for answers to some of the most common ones we've already been asked. If you can't find an answer  
            <EmailLink onClick={contact}> contact us</EmailLink>
          </FAQDescription>
          <Divider />
          {faqData.map((item, index) => (
            <FAQItem key={index} question={item.question} answer={item.answer} />
          ))}
          <FAQItem
            question="12. How can I get help using Jammin.ai?"
            answer={
              <>
                You can contact our friendly support team via email at{' '}
                <EmailLink onClick={contact}>contact us</EmailLink>.
              </>
            }
          />
        </FAQContainer>
      </ContentWrapper>
      <Footer />
    </MainContainer>
  );
}

function FAQItem({ question, answer }) {
  return (
    <>
      <FAQQuestion>{question}</FAQQuestion>
      <FAQAnswer>{answer}</FAQAnswer>
      <Divider />
    </>
  );
}

const MainContainer = styled.main`
 
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #121212;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
  margin-bottom:30px;
  box-sizing: border-box; /* Ensure padding is included in width calculation */
`;

const FAQQuestion = styled.h2`
  letter-spacing: -0.2px;
  margin-top: 20px;
  width: 100%;
  font: 20px Poppins, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Divider1 = styled.hr`
  min-height: 1px;
  margin-top: 7px;
  width: 100%;
  border: 1px solid #7d7d7d;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
const FAQAnswer = styled.p`
  font-family: Poppins, sans-serif;
  font-weight: 500;
  color: #d4d4d4;
  line-height: 22px;
  margin-top: 8px;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const FAQContainer = styled.section`
  display: flex;
  max-width: 900px;
  flex-direction: column;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  letter-spacing: -0.16px;
  padding: 0 20px;
  margin: 20px 0; /* Optional: to add some space around the FAQ section */
`;

const FAQTitle = styled.h1`
  letter-spacing: -0.32px;
  text-align: center;
  align-self: center;
  font: 32px Poppins, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const FAQDescription = styled.p`
  font-family: Poppins, sans-serif;
  font-weight: 500;
  text-align: center;
  color: #d4d4d4;
  line-height: 22px;
  align-self: center;
  margin-top: 8px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const EmailLink = styled.a`
  cursor:pointer;
  font-weight: 600;
  color: #428bca;
  text-decoration: none;
`;

const Divider = styled.hr`
  align-self: center;
  margin-top: 7px;
  width: 100%;
  border: 1px solid #7d7d7d;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default FAQSection;