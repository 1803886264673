import React, { useState, useEffect, useRef } from "react";
import ReactModal from "react-modal";

import {
  SongCardContainer2,
  Songdate,
  SongTitle4,
  PlaylistContainer,
  AlbumCover3,
  LoadingText2,
  Circle2,
  SectionTitle5,
  Logo,
  SectionTitle2,
  Icon,
  Circle,
  SongGrid1,
  SongGrid_new,
  SongGridall,
  SongCardContainer1,
  ProgressBar,
  SpinnerCircle,
  SpinnerContainer,
  LoadingOverlay1,
  Spinner1,
  SongGrid,
  SongCardContainer,
  AlbumCover2,
  SongInfo2,
  SongTitle2,
  PopupOverlay,
  ErrorToast,
  Overlay,
  Spinner,
  LoadingText,
  LoadingIndicator,
  Section,
  PlaylistContent,
  SectionHeader,
  SectionHeader1,
  SongList1,
  SectionTitle,
  SectionTitle1,
  ViewAllLink,
  ViewAllLink1,
  SongList,
  SongCard,
  SongImage,
  SongInfo,
  SongTitle,
  SongCard1,
  SongImage1,
  SongInfo1,
  SongTitle1,
  ArtistName,
  MainContainer,
  ContentWrapper,
  MainTitle,
  FormContainer,
  InputGroup,
  InputIcon,
  InputField,
  Button,
  Button1,
  UploadButton,
} from "../../assets/styles/premium_user_styled";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { clearToken } from "../utils";
import {
  getToken,
  setLoadingStatus,
  getLoadingStatus,
  removeLoadingStatus,
} from "../utils";
import axios from "axios";
import config from "../../config";
import {
  search,
  RefreshIcon,
  add_icon,
  failed,
  add_plus,
  user_help,
  arrow,
  playlist_image,
  create_play,
  add_new_song_btn,
  del_card_icon,
  white_close, failed_down,
  upload_icon, mp3_thumbnail, close_icon, info_icon, retry_user, new_create_playlist,new_add_new_songs
} from "../../assets/images";
import PreNavSearch from "../navigation/premium_nav_search";
import Footer from "../footer_lan";
import Pricing2 from "./pricing_popup";
import YouTubesearch from "./search_popup";
import Import_playlist_songs from "./import_playlist_songs";
import FeedbackPopup from "./feedback";
import NewPlaylistForm from "../playlist/create_playlist_popup";
import copylink from "../../assets/images/copylink.svg";
import tick_snack_bar from "../../assets/images/tick_snack_bar.svg";
import close_snack_bar from "../../assets/images/close_snack_bar.svg";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaUser } from "react-icons/fa";
import Thankyou from "./thank_you1";
import { getfirstname, getlastname, terms_accepted } from "../utils";
import Terms1 from "./terms_and_consitions_popup";
import SharePage from "../chords/share_receive_popup";
import useWindowWidth from "../global";
import error_icon from "../../assets/images/error_icon.svg";
import Cookies from "js-cookie";
import { cookie } from "../../assets/images";
import { languages } from "../utils";

const CustomSnackbar = styled(Snackbar)(({ theme }) => ({
  "& .MuiSnackbarContent-root": {
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    color: "black",
  },
  "& .MuiSnackbarContent-message": {
    display: "flex",
    alignItems: "center",
  },
}));

function Premium_user() {
  const timeoutRef = useRef(null);
  const fileInputRef = useRef(null);
  const windowWidth = useWindowWidth();

  const isMobile = windowWidth <= 991;

  const navigate = useNavigate();
  const [youtubeLink, setYoutubeLink] = useState("");
  const [loading, setLoading] = useState(true);
  const [chevron, setchevron] = useState("");
  const [progressWidth, setProgressWidth] = useState("0%");
  const [showthank, setshowthank] = useState(false);
  const [songs, setSongs] = useState([]);
  const [disabledRetryLogos, setDisabledRetryLogos] = useState({});
  const [popupVisible, setPopupVisible] = useState(null);
  const [uploadFiles, setUploadFiles] = useState(null);

  const [previewData, setPreviewData] = useState(null);
  const [language, setLanguage] = useState("")
  const [searchTerm, setSearchTerm] = useState("");


  const token = getToken();
  const token1 = "";
  if (token == null) {
    navigate("/");
  }
  const [error, setError] = useState("");
  const [title, settitle] = useState("");
  const [thumb_image, setthumb_image] = useState("");
  const [request_id, setrequest_id] = useState("");

  const [playlists, setPlaylists] = useState([]);
  const [publicsong, setPublic] = useState([]);
  const [popularsong, setPopular] = useState([]);
  const [youSong, setyouSong] = useState([]);
  const [subscription1, setSubscription] = useState(null);
  const [count, setCount] = useState(0);
  const [localLoadingStatus, setLocalLoadingStatus] = useState(
    getLoadingStatus()
  );
  const [failedRequests, setFailedRequests] = useState({});
  const [failedRequests_download, setFailedRequests_download] = useState({});

  const [pricingVisible, setpricingVisible] = useState(false);
  const [searchVisible, setsearchVisible] = useState(false);
  const [showplaylist_popup, setshowplaylist_popup] = useState(false);
  const [viewall, setviewall] = useState("0");
  const [showIframe, setShowIframe] = useState(false);

  // const youtubeLink1 = localStorage.getItem("youtubeLink");
  const playlistadded = localStorage.getItem("playlistadded123");
  const song_added_using_url = localStorage.getItem("song_added_using_url");
  const share_id_added3 = localStorage.getItem("share_id_added_after_login");




  const [showOverlay, setShowOverlay] = useState(true);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [id_snack_bar, setid_snack_bar] = useState("");
  const [url_snack_bar, seturl_snack_bar] = useState("");
  const [vocal_url, setvocal_url] = useState("");

  const [call_back, setcall_back] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [playlist, setplaylist] = useState([]); // Initialize playlist as an empty array
  const [terms1, setterms1] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [selectedSongUrl, setSelectedSongUrl] = useState("");
  const [down, setDown] = useState(false);
  const [delete_failed, setdelete_failed] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showsharePopup, setshowsharePopup] = useState(false);
  const [import_songs, setimport_songs] = useState(false);
  const [manual_upload_popup, setmanual_upload_popup] = useState(false);
  const [manual_retry_songid, setmanual_retry_songid] = useState("");

  // const after_song_finished = Cookies.get('after_song_finished');
  // const last_send_feedback = Cookies.get('last_send_feedback');
  const location = useLocation();
  // Create state to store and control playlistData1
  const [localPlaylistData, setLocalPlaylistData] = useState(null);

  useEffect(() => {
    // Get playlistData1 from location state
    const { playlistData1 } = location.state || {};

    // Set it to local state on initial render
    if (playlistData1 && !localPlaylistData) {
      setLocalPlaylistData(playlistData1);
    }
  }, [location.state]);

  useEffect(() => {
    if (localPlaylistData) {
      // Run your functions
      fetchPlaylists();
      get_playlist();

      // Reset the local state to null after processing
      setLocalPlaylistData(null);

      // If you need to also clear it from location state
      // This depends on your router implementation, but here's a common approach
      const newState = { ...location.state };
      delete newState.playlistData1;

      // Update location without navigating (implementation may vary based on your router)
      window.history.replaceState(
        newState,
        document.title,
        window.location.pathname
      );
    }
  }, [localPlaylistData]);



  const show_manual_popup = (variantName) => {
    setmanual_upload_popup(true);
  };


  const handlePopupSubmit = () => {
    if (popupVisible === "upload") {
      getUploadUrls()
    } else {
      const value = searchTerm;
      const lang = language;
      // onGoClicked(value,lang);
      setSearchTerm("");
      setPreviewData(null);
      setLanguage("en")
    }
    setPopupVisible(null)
  }


  let id_snack_bar1;
  let url_snack_bar1;


  useEffect(() => {
    const isCookieAccepted = Cookies.get("cookieConsent");
    // console.log("isCookieAccepted "+isCookieAccepted);
    if (!isCookieAccepted) {
      setShowPopup(true);
    }
  }, []);

  const handleAccept = () => {
    // Set cookie consent for 1 year
    Cookies.set("cookieConsent", "accepted", { expires: 365 });
    setShowPopup(false);
  };

  const triggerUpload = () => {
    fileInputRef.current.click();
  }

  const fileDownload = async (urls, fileName) => {
    const nameWithoutExt = fileName.substring(0, fileName.lastIndexOf(".")) || fileName;
    try {
      const response = await axios.post(`${config.apiUrl}/url_requests/process_upload`, {
        url: urls.download_url,
        title: nameWithoutExt,
        lang_code: language
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        // onUploadClicked();
        fetchPlaylists();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
    setLanguage("en")
  }

  const fileUpload = async (urls, file) => {
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.put(urls.upload_url, file, {
        headers: {
          'Content-Type': 'audio/mpeg'
        }
      });

      if (response.status === 200) {
        fileDownload(urls, file.name);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
    setLoading(false);
  }

  const getUploadUrls = async () => {

    if (!uploadFiles) {
      return
    }

    try {
      const response = await axios.get(
        `${config.apiUrl}/url_requests/upload_urls`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        fileUpload(response.data, uploadFiles);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
    // fileInputRef.current.target.value = ''
    setPopupVisible(null)
  }

  const CookieConsent = ({ show }) => {
    return (
      <CookiePopup show={show}>
        <CookiePopupContent>
          {/* <CloseBtn onClick={handleClose}>&times;</CloseBtn> */}
          <CookieIcon>
            <img src={cookie} alt="Cookie Icon" />
          </CookieIcon>
          <CookieMessage>
            By continuing to use our website, you consent to the use of
            necessary cookies to enhance your site experience and improve our
            services.
          </CookieMessage>
          <AcceptButton onClick={handleAccept}>Accept</AcceptButton>
        </CookiePopupContent>
      </CookiePopup>
    );
  };

  useEffect(() => {
    if (location.pathname === "/thankyou") {
      setshowthank(true);
    } else {
      setshowthank(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === "/share") {
      const queryParams = new URLSearchParams(window.location.search);

      const id = queryParams.get("share_id");

      if (id) {


        localStorage.setItem("share_id_added", id);
        setshowsharePopup(true);
      }

    }
  }, [location.pathname]);

  useEffect(() => {
    const term = terms_accepted();
    if (term === "") {
      setterms1(true);
    }
  }, []); // No need to add term in the dependency array

  // useEffect(() => {
  //   if (youtubeLink1) {
  //     setYoutubeLink(youtubeLink1);

  //     Get_chords1(youtubeLink1);
  //     localStorage.removeItem("youtubeLink");
  //   }
  // }, [youtubeLink1]);

  useEffect(() => {
    if (share_id_added3) {
      setshowsharePopup(true);
    }
  }, [share_id_added3]);




  useEffect(() => {
    if (playlistadded) {
      localStorage.removeItem("playlistadded123");

      get_playlist();
    }
  }, [playlistadded]);

  useEffect(() => {
    if (song_added_using_url) {
      if (song_added_using_url === "Subscription limit reached!") {
        localStorage.removeItem("song_added_using_url");
        handlepricingClick();
      } else {
        localStorage.removeItem("song_added_using_url");

        fetchPlaylists();
        handleCopyLink2();
      }
    }
  }, [song_added_using_url]);

  const handlepricingClick = () => {
    setpricingVisible(true);
  };

  const handlepricingClose = () => {
    setpricingVisible(false);
  };

  const handleyouClick = () => {
    setsearchVisible(true);
  };

  const handleClick_open_y2mate = () => {
    setShowIframe(true);
  };

  const handleyouClick_playlist = () => {
    setshowplaylist_popup(true);
  };

  const handleyouClick_playlist1 = () => {
    setshowplaylist_popup(false);
  };

  const handleyouClose = () => {
    setsearchVisible(false);
  };

  const handleShowSharePopup = (value) => {
    setshowsharePopup(value);
  };

  const handleyouClose_terms = () => {
    setterms1(false);
  };

  useEffect(() => {
    const storedLoadingStatus = getLoadingStatus();
    setLocalLoadingStatus(storedLoadingStatus);

    Object.keys(storedLoadingStatus).forEach((requestId) => {
      if (storedLoadingStatus[requestId]) {
        checkStatus(requestId);
      }
    });

    const loadData = async () => {
      await Promise.all([
        fetchPlaylists(),
        public_songs(),
        popular_songs(),
        get_playlist(),
        fetchVideoDetails(),
      ]);
      setLoading(false);
    };

    loadData();

    // if(last_send_feedback==null || last_send_feedback==="undefined"|| last_send_feedback===undefined){
    //   setshow_feedback(true);
    // }
  }, []);

  const youtubeLinks = [
    "https://www.youtube.com/watch?v=d52MZJw-hIE&ab_channel=AcousticSelection",
    "https://youtu.be/xFle98bYj4I",
    "https://youtu.be/rdZSptFT5Tg?t=1",
    "https://youtu.be/uSkqSEnGALw",
    "https://youtu.be/qAlyjGrThGo",
    "https://youtu.be/Y2H_0H4buCU",
    "https://youtu.be/uqbTfrbxHAs",
  ];

  const getVideoIdFromUrl = (url) => {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  function formatDate(dateString) {
    // Ensure the input is treated as UTC by appending 'Z' if missing
    const date = new Date(dateString.endsWith("Z") ? dateString : dateString + "Z");

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        return "Invalid Date";
    }

    // Convert to local time zone using toLocaleString
    const localDate = new Date(date.getTime());

    // Extract local date components
    const day = localDate.getDate();
    const month = localDate.getMonth();
    const year = localDate.getFullYear();

    // Month names array
    const monthNames = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    // Format the day with 'st', 'nd', 'rd', 'th'
    const dayWithSuffix =
        day + (
            day % 10 === 1 && day !== 11 ? "st" :
            day % 10 === 2 && day !== 12 ? "nd" :
            day % 10 === 3 && day !== 13 ? "rd" :
            "th"
        );

    // Get hours and minutes in local time
    let hours = localDate.getHours();
    const minutes = localDate.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    hours = hours % 12 || 12; // Convert 0 to 12 (midnight case)

    // Return formatted local time
    return `${monthNames[month]} ${dayWithSuffix} ${year} at ${hours}:${minutes} ${ampm}`;
}




  const delete_failed_cards = (id, e) => {
    e.stopPropagation();
    setdelete_failed(id);
    setDown(true);
  };

  const fetchVideoDetails = async () => {
    const results = await Promise.all(
      youtubeLinks.map(async (url) => {
        const videoId = getVideoIdFromUrl(url);
        const response = await fetch(
          `https://noembed.com/embed?url=https://www.youtube.com/watch?v=${videoId}`
        );
        const data = await response.json();
        return {
          youtube_id: videoId,
          title: data.title,
          image: `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`,
          url,
        };
      })
    );
    setyouSong(results.slice(0, 6));
  };

  const fetchPlaylists = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/playlists/__default_playlist__?sort_direction=desc`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        let hasInProgress = false;
        const mappedRecords = response.data.records.map((record) => {
          const thumbnailUrl = record.thumbnail_url.split("?")[0];
          const song = {
            title: record.title,
            id: record.request_id,
            url: record.url,
            image: thumbnailUrl,
            state: record.state,
            created_at: formatDate(record.created_at),
          };

          if (record.state === "failed") {
            localLoadingStatus[record.request_id] = false;
            failedRequests[record.request_id] = true;
            setDisabledRetryLogos((prev) => ({
              ...prev,
              [record.request_id]: false,
            }));
          } else if (record.state === "failed_download") {
            localLoadingStatus[record.request_id] = false;
            failedRequests_download[record.request_id] = true;
            setDisabledRetryLogos((prev) => ({
              ...prev,
              [record.request_id]: false,
            }));
          }

          else if (record.state === "complete") {
            localLoadingStatus[record.request_id] = false;

            // if(last_send_feedback==null || last_send_feedback==="undefined"|| last_send_feedback===undefined){
            //   setshow_feedback(true);
            // }
          } else if (
            record.state === "pending" ||
            record.state === "in_progress"
          ) {
            localLoadingStatus[record.request_id] = true;
            hasInProgress = true;

            const step = record.processing_step;
            let progressPercentage;
            let text;
            switch (step) {
              case "generating_mp3":
                progressPercentage = 10;
                text = "Generating Mp3";
                break;
              case "generating_midi":
                progressPercentage = 25;
                text = "Generating Midi";
                break;
              case "transcribing_audio":
                progressPercentage = 50;
                text = "Transcribing Audio";
                break;
              case "generating_chords":
                progressPercentage = 75;
                text = "Generating Chords";
                break;
              case "finalizing":
                progressPercentage = 95;
                text = "Finalizing";
                break;
              case "complete":
                progressPercentage = 100;
                text = "Complete";
                break;
              default:
                progressPercentage = 0;
                text = "";
            }

            setProgressWidth((prev) => ({
              ...prev,
              [record.request_id]: progressPercentage,
            }));
            setchevron((prev) => ({ ...prev, [record.request_id]: text }));
          }

          return song;
        });

        setcall_back(hasInProgress);

        setCount(mappedRecords.length);
        setSongs(mappedRecords.slice(0, 16));
        setLocalLoadingStatus((prevStatus) => ({
          ...prevStatus,
          ...localLoadingStatus,
        }));
        setFailedRequests((prev) => ({ ...prev, ...failedRequests }));
        setFailedRequests_download((prev) => ({ ...prev, ...failedRequests_download }));


        if (hasInProgress) {
          timeoutRef.current = setTimeout(fetchPlaylists, 5000);

          // setTimeout(() => fetchPlaylists(), 5000);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Clear timeout when component unmounts or when navigating away
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const public_songs = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/public/`, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const playlistsData = response.data.map((item) => {
          const thumbnailUrl = item.thumbnail_url.split("?")[0];
          return {
            id: item.request_id,
            url: item.url,
            title: item.title,
            image: thumbnailUrl,
            result: item.result,
            youtube_id: item.youtube_id,
          };
        });
        setPublic(playlistsData.slice(0, 6));
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const popular_songs = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/popular/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const playlistsData = response.data.map((item) => {
          const thumbnailUrl = item.thumbnail_url.split("?")[0];
          return {
            id: item.request_id,
            url: item.url,
            title: item.title,
            image: thumbnailUrl,
            result: item.result,
            youtube_id: item.youtube_id,
          };
        });
        setPopular(playlistsData.slice(0, 16));
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const get_playlist = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/playlists/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        if (response.data.length > 0) {
          const playlistsData = response.data.map((item) => {
            return {
              name: item.name,
              song_image_url: item.song_image_url,
              tracks: item.total_songs,
              id: item.playlist_id,
              status: item.status
            };
          });

          setplaylist(playlistsData.slice(0, 5));
          const hasProcessingStatus = playlistsData.some(
            playlist => playlist.status === "processing"
          );

          // If any playlist is processing, schedule another check in 5 seconds
          if (hasProcessingStatus) {
            setTimeout(() => {
              get_playlist();
            }, 8000); // 5000 milliseconds = 5 seconds
          }
        } else {
          setplaylist([]);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const handleInputChange = (event) => {
    setYoutubeLink(event.target.value);
  };

  const View_all = (event) => {
    window.scrollTo(0, 0);

    navigate("/public_songs");
  };

  const View_all_youtube = (event) => {
    window.scrollTo(0, 0);

    navigate("/youtube");
  };

  const View_all_playlist = (event) => {
    window.scrollTo(0, 0);

    navigate("/playlist");
  };

  const View_all_Songs = (event) => {
    window.scrollTo(0, 0);

    navigate("/all_songs");
  };

  const handleSongCardClick = async (requestId, url) => {
    try {
      setLoading(true);
      const encodedRequestId1 = encodeURIComponent(requestId);
      const response_status = await axios.get(
        `${config.apiUrl}/url_requests/${encodedRequestId1}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response_status.status === 200) {
        if (response_status.data.state === "complete") {
          visitchords(
            encodedRequestId1,
            response_status.data,
            response_status.data.youtube_id
          );
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
      // Handle error
    } finally {
      setLoading(false);
    }
  };





  const first_name = getfirstname();
  const last_name = getlastname();
  const initials =
    first_name && last_name ? `${first_name}_${last_name}` : "Unknown";

  const visitchords = async (requestId, responsed, youtube_id) => {
    try {
      const response = await fetch(
        `${config.apiUrl}/playlists/__default_playlist__/${requestId}/visit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        window.scrollTo(0, 0);

        navigate(`/chords/${initials}/youtube/${youtube_id}`, {
          state: { hiddenParam: "user" },
        });
        // navigate("/chords", { state: { result1: responsed } });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  // const visitchords = async (requestId) => {
  //     try {
  //         const encodedRequestId1 = encodeURIComponent(requestId);

  //         const response_status = await axios.post(`${config.apiUrl}/playlists/__default_playlist__/${encodedRequestId1}/visit`, {
  //             headers: {
  //                 "Content-Type": "application/json",
  //                 Authorization: `Bearer ${token}`,
  //               },
  //         });

  //         if (response_status.status === 200) {
  //             //navigate('/chords', { state: { result1: responsed } });
  //         }

  //     } catch (error) {
  //         console.error('Status check failed:', error);
  //         // Handle error
  //     }
  // };

  const handleSongCardClick1 = async (youtube_id) => {
    window.scrollTo(0, 0);

    navigate(`/chords/public/youtube/${youtube_id}`);
  };

  const Get_chords1 = async (youtube) => {
    //setLoading(true);

    try {
      const vocal_url = `${config.apiUrl}/url_requests/`;
      const response = await axios.post(
        vocal_url,
        {
          url: youtube,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 && response.data.request_id) {
        // await checkStatus(response.data.request_id);
        if (call_back === false) {
          fetchPlaylists();
        }

        if (response.data.state === "complete") {
          setid_snack_bar(response.data.request_id);
          seturl_snack_bar(response.data.url);
          id_snack_bar1 = response.data.request_id;
          url_snack_bar1 = response.data.url;
          handleCopyLink();
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {

      } else if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
      // Handle error
    }
  };

  const onSearchResultClicked = async (youtube_id) => {
    try {
      const response = await axios.post(`${config.apiUrl}/songs/${youtube_id}/add_to_my`, {}, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      navigate(`/chords/${initials}/youtube/${youtube_id}`, {
        state: { hiddenParam: "user" },
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  }

  // const Check_sub = async () => {
  //   if (youtubeLink.trim() === "") {
  //     return;
  //   }

  //   Get_chords();
  // };

  const handleSongCardClick_playlist = async (id, name) => {
    const xyz = "user";
    window.scrollTo(0, 0);
    //  navigate("/songs", { state: { result1: id, result2: name, nav1: xyz } });
    navigate(`/playlist/${name}`, { state: { result1: id, result2: name, nav1: xyz } });
  };

  const Get_chords = async (value = '', language = '') => {
    if (value.trim() === '') {
      return
    }

    try {
      setLoading(true);

      const vocal_url = `${config.apiUrl}/url_requests/`;

      const response = await axios.post(
        vocal_url,
        {
          url: value,
          lang_code: language
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const fullRequestId = response.data.request_id;
        setrequest_id(fullRequestId);
        if (call_back === false) {
          fetchPlaylists();
        }

        // await checkStatus(fullRequestId);

        if (response.data.state === "complete") {
          setid_snack_bar(response.data.request_id);
          seturl_snack_bar(response.data.url);
          id_snack_bar1 = response.data.request_id;
          url_snack_bar1 = response.data.url;

          handleCopyLink();
        }
      } else {
        setError(response.data.message || "An error occurred");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {

      } else if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };

  const retry = async (id, e) => {
    e.stopPropagation();
    const encodedRequestId1 = encodeURIComponent(id);

    try {
      setLoading(true);
      setDisabledRetryLogos((prev) => ({ ...prev, [id]: true }));
      const response = await fetch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/retry`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // const fullRequestId = response.data.request_id;

        // setTimeout(() => checkStatus(fullRequestId), 2000);
        setFailedRequests((prevFailedRequests) => {
          const updatedFailedRequests = { ...prevFailedRequests };
          delete updatedFailedRequests[id];
          return updatedFailedRequests;
        });

        setFailedRequests_download((prevFailedRequests) => {
          const updatedFailedRequests = { ...prevFailedRequests };
          delete updatedFailedRequests[id];
          return updatedFailedRequests;
        });

        if (!call_back) {
          fetchPlaylists();
        }
      } else {
        setError(response.data.message || "An error occurred");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError("You have already requested this URL!");
      } else if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    } finally {
      // setLoading(false);
    }
  };

  const checkStatus = async (requestId) => {
    try {
      setShowOverlay(false);
      const encodedRequestId = encodeURIComponent(requestId);
      const response_status = await axios.get(
        `${config.apiUrl}/url_requests/${encodedRequestId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response_status.status === 200) {
        if (response_status.data.state === "complete") {
          fetchPlaylists();
          setLoading(false);
          setLoadingStatus(requestId, false);
          setLocalLoadingStatus((prevStatus) => ({
            ...prevStatus,
            [requestId]: false,
          }));
          removeLoadingStatus(requestId);
        } else if (
          response_status.data.state === "pending" ||
          response_status.data.state === "in_progress"
        ) {
          setLoading(false);
          fetchPlaylists();

          const step = response_status.data.processing_step;
          setchevron((prev) => ({ ...prev, [requestId]: step }));
          settitle((prev) => ({
            ...prev,
            [requestId]: response_status.data.title,
          }));
          setthumb_image((prev) => ({
            ...prev,
            [requestId]: response_status.data.thumbnail_url,
          }));

          let progressPercentage;
          let text;
          switch (step) {
            case "generating_mp3":
              progressPercentage = 10;
              text = "Generating Mp3";
              break;
            case "generating_midi":
              progressPercentage = 25;
              text = "Generating Midi";
              break;
            case "transcribing_audio":
              progressPercentage = 50;
              text = "Transcribing Audio";
              break;
            case "generating_chords":
              progressPercentage = 75;
              text = "Generating Chords";
              break;
            case "finalizing":
              progressPercentage = 95;
              text = "Finalizing";
              break;
            case "complete":
              progressPercentage = 100;
              text = "Complete";
              break;
            default:
              progressPercentage = 0;
              text = "";
          }

          setProgressWidth((prev) => ({
            ...prev,
            [requestId]: progressPercentage,
          }));
          setchevron((prev) => ({ ...prev, [requestId]: text }));

          setTimeout(() => checkStatus(requestId), 1000);
          setLoadingStatus(requestId, true);
          setLocalLoadingStatus((prevStatus) => ({
            ...prevStatus,
            [requestId]: true,
          }));
        } else if (response_status.data.state === "failed") {
          setLoading(false);

          setLoadingStatus(requestId, false);
          setLocalLoadingStatus((prevStatus) => ({
            ...prevStatus,
            [requestId]: false,
          }));
          setFailedRequests((prev) => ({ ...prev, [requestId]: true }));

        } else if (response_status.data.state === "failed_download") {
          setLoading(false);

          setLoadingStatus(requestId, false);
          setLocalLoadingStatus((prevStatus) => ({
            ...prevStatus,
            [requestId]: false,
          }));
          setFailedRequests_download((prev) => ({ ...prev, [requestId]: true }));

        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
      setLoadingStatus(requestId, false);
      setLocalLoadingStatus((prevStatus) => ({
        ...prevStatus,
        [requestId]: false,
      }));
    }
  };

  useEffect(() => {
    const storedLoadingStatus = getLoadingStatus();
    setLocalLoadingStatus(storedLoadingStatus);

    Object.keys(storedLoadingStatus).forEach((requestId) => {
      if (storedLoadingStatus[requestId]) {
        checkStatus(requestId);
      }
    });
  }, []);

  const handleCopyLink = () => {
    // setOpen(true);
    showToast();
  };

  const handleCopyLink2 = () => {
    // setOpen(true);
    showToast3();
  };

  // const handleSongyoutubecard = (url) => {
  //   window.open(url, "_blank"); // Opens the URL in a new tab
  // };

  const handleSongyoutubecard = (url) => {
    setSelectedSongUrl(url); // Set the clicked song's URL
    setModalIsOpen(true); // Open the modal
  };

  const openModal1 = () => {
    setModalIsOpen1(true);
  };

  const closeModal1 = () => {
    setModalIsOpen1(false);
  };

  const closeModal = () => {
    setModalIsOpen(false); // Close the modal
    setSelectedSongUrl(""); // Clear the selected URL when closing
  };
  const handleCopyLink1 = (data) => {
    // setOpen1(true);
    showToast1(data);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // setOpen(false);
    // setOpen1(false);
  };
  const handlebutton = (event, reason) => {
    // setOpen(false);
    // setOpen1(false);
    if (id_snack_bar.text) {
      handleSongCardClick(id_snack_bar, url_snack_bar);
    } else {
      handleSongCardClick(id_snack_bar1, url_snack_bar1);
    }
  };
  const CustomToast = ({ closeToast }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {/* <FaUser style={{ marginRight: '10px' }} /> */}
      <img
        src={tick_snack_bar}
        alt="check icon"
        style={{ marginRight: "8px", width: "24px", height: "24px" }}
      />

      <span style={{ flex: 1 }}>Chord Generated</span>
      <button
        onClick={handlebutton}
        style={{
          marginLeft: "10px",
          backgroundColor: "#F0984B",
          color: "#FFFFFF",
          border: "none",
          borderRadius: "4px",
          padding: "5px 10px",
          cursor: "pointer",
        }}
      >
        Go to Chords
      </button>
    </div>
  );

  const showToast = () => {
    toast(<CustomToast />, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const CustomToast1 = ({ data1 }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={error_icon}
        alt="check icon"
        style={{ marginRight: "8px", width: "24px", height: "24px" }}
      />
      <span style={{ flex: 1 }}>{data1}</span>
    </div>
  );

  const showToast1 = (data) => {
    toast(<CustomToast1 data1={data} />, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const CustomToast3 = ({ data1 }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={tick_snack_bar}
        alt="check icon"
        style={{ marginRight: "8px", width: "24px", height: "24px" }}
      />
      <span style={{ flex: 1 }}>{data1}</span>
    </div>
  );

  const showToast3 = () => {
    toast(<CustomToast3 data1={"Song Added Successfully"} />, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // Helper function to extract YouTube video ID from URL
  const getYoutubeVideoID = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|\/u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  let freeTitle = "";



  function Downgrade({ onClose }) {
    const token = getToken();

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const delete_song = async () => {
      const encodedRequestId1 = encodeURIComponent(delete_failed);
      // setLoading(true);
      // console.log(delete_failed);

      try {
        const response = await axios.delete(
          `${config.apiUrl}/url_requests/${encodedRequestId1}`,

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          onClose();
          setdelete_failed("");
          window.location.reload();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        }
      } finally {
        setLoading(false);
      }
    };

    const ActionButton = ({ text, primary, onClick }) => {
      return (
        <Button10 primary={primary} onClick={onClick}>
          {text}
        </Button10>
      );
    };

    return (
      <Modal>
        <HeaderContainer1>
          <WarningText>Warning</WarningText>
          <CloseIcon
            onClick={onClose}
            loading="lazy"
            src={white_close}
            alt="Close"
          />
        </HeaderContainer1>
        <Content1>Are you sure you want to delete this song?</Content1>
        <ActionButton text="Confirm" primary onClick={delete_song} />
        <ActionButton text="Cancel" onClick={onClose} />
      </Modal>
    );
  }


  const Manual_Upload = ({ onClose }) => {
    const navigate = useNavigate();
    const token = getToken();



    return (
      <FormContainer4>
        <Form4>
          <HeaderContainer4>
            <Title01>Download Error</Title01>
            <CloseButton onClick={onClose}>×</CloseButton>
          </HeaderContainer4>

          <Content4>
            Jammin.ai encountered an error while processing the song from YouTube. You can fix this by downloading the song manually from{' '}
            <a
              href="https://y2mate.lol/en158/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'white', textDecoration: 'underline', textDecorationColor: 'white' }}
            >
              y2mate.com
            </a>{' '}
            and then using the Upload option to upload the mp3.
          </Content4>



          <UploadContainer>

            <UploadButton3 onClick={(e) => retry(manual_retry_songid, e)} src={retry_user} />



            <UploadButton4 onClick={() => {
              setPopupVisible("upload");
              setmanual_upload_popup(false);
            }}>

              <img src={upload_icon} alt="" />
              <span>Upload song</span>
            </UploadButton4>
          </UploadContainer>



        </Form4>
      </FormContainer4>
    );
  };

  const formatCreatedTime = (utcTimeString) => {
    try {
      console.log(utcTimeString)
      // Check if the input is valid
      if (!utcTimeString) {
        return 'Invalid Date';
      }

      // Ensure the date string is in a format that can be parsed
      // Some backends send dates without 'Z' which can cause parsing issues
      const normalizedTimeString = utcTimeString.endsWith('Z')
        ? utcTimeString
        : utcTimeString + 'Z';

      // Create a Date object, explicitly parsing as UTC
      const date = new Date(normalizedTimeString);

      // Check if the date is valid
      if (isNaN(date.getTime())) {
        return 'Invalid Date';
      }

      // Format the date to the desired local time format
      return date.toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      }).replace(',', ' at');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid Date';
    }
  };




  return (

    <MainContainer>


      <PreNavSearch
        onUploadClicked={fetchPlaylists}
        onGoClicked={Get_chords}
        onSearchResultClicked={onSearchResultClicked}
        setLoading={setLoading}
      />
      <Overlay loading={loading}>
        <Spinner />
      </Overlay>

      <ContentWrapper>

        <Verform>

          {!loading && (
            <Rows>
              {count == 0 && (
                <InputIcon2
                  loading="lazy"
                  src={add_new_song_btn}
                  onClick={handleyouClick}
                />
              )}
              {playlist.length == 0 && (
                <InputIcon5
                  loading="lazy"
                  src={create_play}
                  onClick={handleyouClick_playlist}
                />
              )}
            </Rows>
          )}
        </Verform>

        {error && <ErrorToast>{error}</ErrorToast>}
        {pricingVisible && (
          <PopupOverlay>
            <Pricing2 onClose={handlepricingClose} />
          </PopupOverlay>
        )}

        {showthank && (
          <PopupOverlay>
            <Thankyou />
          </PopupOverlay>
        )}


        {popupVisible !== null && <div style={{
          position: "fixed",
          height: "100vh",
          width: "100vw",
          top: "0",
          zIndex: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#00000040",

        }}>
          <UploadPopup>
            <input ref={fileInputRef} type="file" style={{ display: "none" }} onChange={e => { setUploadFiles(e.target.files[0]) }} />
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div></div>
              <div style={{ fontSize: "20px", fontWeight: 600, color: "#fff" }}>{popupVisible === "upload" ? 'Upload Song' : 'Convert Song'}</div>
              <span onClick={() => setPopupVisible(null)} style={{ cursor: "pointer" }}>
                <img src={close_icon} alt="" onClick={() => {
                  setUploadFiles(null);
                  setPreviewData(null);
                }} />
              </span>
            </div>
            <FormContainer45>
              <div>
                <div style={{ fontSize: "16px", fontWeight: 600, color: "#fff" }}>
                  {popupVisible === "upload" ? 'Choose Audio File' : ''}
                </div>
                {popupVisible === "upload" ? <button style={{
                  padding: "14px 24px",
                  borderRadius: "4px",
                  background: "#fff",
                  marginTop: "10px",
                  cursor: "pointer",
                  border: "none",
                  fontSize: "10px",
                  fontStyle: "italic"
                }} onClick={triggerUpload} >
                  {uploadFiles ? uploadFiles.name : 'Choose a file'}
                </button>
                  :
                  <VideoPreview>
                    <img src={previewData.image || mp3_thumbnail} alt="" width="100%" />
                    {/* <div style={{ fontWeight: 600, color: "#fff", fontSize: "12px" }}>{previewData.title}</div> */}
                  </VideoPreview>
                }
              </div>
              <div style={{ padding: '12px 0' }}>
                <div>
                  <div style={{ fontSize: "16px", fontWeight: 600, color: "#fff" }}>Select Language</div>
                  <select style={{
                    width: "100%",
                    height: "40px",
                    background: "#3A3B3B",
                    color: "#fff",
                    border: "none",
                    marginTop: "10px",
                    borderRadius: "4px",
                    padding: "8px"
                  }}
                    value={language}
                    onChange={e => { setLanguage(e.target.value) }}>
                    <option key='0' value='' >Auto Detect</option>
                    {languages.map((item, index) => (
                      <option key={(index + 1).toString()} value={item.code} >
                        {item.language}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  marginTop: "12px"
                }}>
                  <img src={info_icon} alt="" width="12px" />
                  <div style={{ color: "#fff", fontSize: "10px", fontWeight: 400, lineHeight: "18px" }}>Language selection can improve the quality of the lyrics.</div>
                </div>
              </div>
            </FormContainer45>
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <button style={{
                background: "#0072D5",
                padding: "18px 50px",
                borderRadius: "4px",
                border: "none",
                color: "#fff",
                cursor: "pointer",
                fontWeight: 600
              }}
                onClick={handlePopupSubmit} >
                {popupVisible === "upload" ? 'Upload Song' : 'Convert Song'}
              </button>
            </div>
          </UploadPopup>
        </div>
        }



        {manual_upload_popup && (
          <PopupOverlay>
            <Manual_Upload onClose={() => setmanual_upload_popup(false)} />
          </PopupOverlay>
        )}


        {showsharePopup && (
          <PopupOverlay>
            <SharePage onClose={() => handleShowSharePopup(false)} />
          </PopupOverlay>
        )}
        {showPopup && (
          <PopupOverlay1>
            <CookieConsent show={showPopup} />
          </PopupOverlay1>
        )}
      </ContentWrapper>

      <SectionContainer_new>
        <ContentWrapper_new>
          <MainContent_new>
            <HeaderWrapper_new>

           <HeaderContent_new>
              <Horizontal_row>
                <Title_new>Recently Played Songs</Title_new>
                <Play_image onClick={handleyouClick} src={new_add_new_songs}/>
                </Horizontal_row>
                <ViewAllButton_new onClick={View_all_Songs}>View All</ViewAllButton_new>
              </HeaderContent_new>
            </HeaderWrapper_new>

            <GridContainer_new columns={songs.length < 8 ? 2 : 4}>
            {songs.map((song, index) => (
  <GridItem_new key={`${song.title}-${index}`}>
    <CardWrapper_new>
      <CardContent_new onClick={() => handleSongCardClick(song.id, song.url)}>
        <ImageWrapper>
          <AlbumImage_new 
            src={song.image || mp3_thumbnail} 
            alt={`Album art for ${song.title}`}
          />
          {/* Progress Overlay */}
          {(song.state === 'pending' || song.state === 'in_progress') && (
            <ProgressOverlay>
              <svg width="24" height="24" viewBox="0 0 24 24">
                <circle 
                  cx="12" 
                  cy="12" 
                  r="10" 
                  fill="none" 
                  stroke="rgba(255,255,255,0.2)" 
                  strokeWidth="2"
                />
                <circle 
                  cx="12" 
                  cy="12" 
                  r="10" 
                  fill="none" 
                  stroke="white" 
                  strokeWidth="2"
                  strokeDasharray="62.8" 
                  strokeDashoffset={`${62.8 - (progressWidth[song.id] || 0) * 0.628}`}
                  transform="rotate(-90 12 12)"
                />
              </svg>
            </ProgressOverlay>
          )}
        </ImageWrapper>
        <SongInfo_new>
        <SongTitle_new $inProgress={song.state === 'in_progress' || song.state === 'pending'}>
            {song.title.slice(0, 36)}
          </SongTitle_new>
          <CreatedTime_new $inProgress={song.state === 'in_progress' || song.state === 'pending'} >Created : {song.created_at}</CreatedTime_new>
        </SongInfo_new>
      </CardContent_new>
    </CardWrapper_new>
  </GridItem_new>
))}
            </GridContainer_new>

          </MainContent_new>
        </ContentWrapper_new>
      </SectionContainer_new>



      {/* popular songs */}

      <SectionContainer_new>
        <ContentWrapper_new>
          <MainContent_new>
            <HeaderWrapper_new>
              <HeaderContent_new>
                <Title_new>Popular Songs</Title_new>
                <ViewAllButton_new onClick={View_all}>View All</ViewAllButton_new>
              </HeaderContent_new>
            </HeaderWrapper_new>

            <GridContainer_new columns={popularsong.length < 8 ? 2 : 4}>
              {popularsong.map((song, index) => (
                <GridItem_new key={`${song.title}-${index}`}>
                  <CardWrapper_new>
                    <CardContent_new onClick={() => handleSongCardClick1(song.youtube_id)}>
                      <AlbumImage_new src={song.image || mp3_thumbnail} alt={`Album art for ${song.title}`} />
                      <SongInfo_new>
                        <SongTitle_new>{song.title.slice(0, 36)}</SongTitle_new>
                        <CreatedTime_new>{song.created_at}</CreatedTime_new>
                      </SongInfo_new>
                    </CardContent_new>
                  </CardWrapper_new>

                </GridItem_new>
              ))}
            </GridContainer_new>

          </MainContent_new>
        </ContentWrapper_new>
      </SectionContainer_new>






      {/* playlist */}

      <SectionContainer_new>
        <ContentWrapper_new>
          <MainContent_new>
            <HeaderWrapper_new>
              <HeaderContent_new>
                <Horizontal_row>
                <Title_new>My Playlist</Title_new>
                <Play_image onClick={handleyouClick_playlist} src={new_create_playlist}/>
                </Horizontal_row>
                <ViewAllButton_new onClick={View_all_playlist}>View All</ViewAllButton_new>
              </HeaderContent_new>
            </HeaderWrapper_new>

            <GridContainer_new columns={playlist.length < 8 ? 2 : 4}>
              {playlist.map((playlists, index) => (
                <GridItem_new key={`${playlists.name}-${index}`}>
                  <CardWrapper_new>
                    <CardContent_new onClick={playlists.status !== "processing" ?
                      () => handleSongCardClick_playlist(playlists.id, playlists.name) :
                      (e) => e.stopPropagation()}>
                      <AlbumImage_new src={playlists.song_image_url || playlist_image} alt={`Album art for ${playlists.name}`} />
                      <SongInfo_new>
                        <SongTitle_new>{playlists.name.slice(0, 36)}</SongTitle_new>
                        <CreatedTime_new>{playlists.tracks} Tracks</CreatedTime_new>
                      </SongInfo_new>
                    </CardContent_new>
                  </CardWrapper_new>

                </GridItem_new>
              ))}
            </GridContainer_new>

          </MainContent_new>
        </ContentWrapper_new>
      </SectionContainer_new>



       {/* Begineer Friendly songs */}

       <SectionContainer_new>
        <ContentWrapper_new>
          <MainContent_new>
            <HeaderWrapper_new>
              <HeaderContent_new>
                <Title_new>Explore Beginner Friendly Songs</Title_new>
                <ViewAllButton_new onClick={View_all}>View All</ViewAllButton_new>
              </HeaderContent_new>
            </HeaderWrapper_new>

            <GridContainer_new columns={publicsong.length < 8 ? 2 : 4}>
              {publicsong.map((song, index) => (
                <GridItem_new key={`${song.title}-${index}`}>
                  <CardWrapper_new>
                    <CardContent_new onClick={() => handleSongCardClick1(song.youtube_id)}>
                      <AlbumImage_new src={song.image || mp3_thumbnail} alt={`Album art for ${song.title}`} />
                      <SongInfo_new>
                        <SongTitle_new>{song.title.slice(0, 36)}</SongTitle_new>
                        <CreatedTime_new>{song.created_at}</CreatedTime_new>
                      </SongInfo_new>
                    </CardContent_new>
                  </CardWrapper_new>

                </GridItem_new>
              ))}
            </GridContainer_new>

          </MainContent_new>
        </ContentWrapper_new>
      </SectionContainer_new>





      <PlaylistContainer>

        {searchVisible && (
          <PopupOverlay>
            <YouTubesearch onClose={handleyouClose} convertSong={Get_chords} />
          </PopupOverlay>
        )}

        {terms1 && (
          <PopupOverlay>
            <Terms1 onClose={handleyouClose_terms} />
          </PopupOverlay>
        )}

        {down && (
          <PopupOverlay>
            <Downgrade onClose={() => setDown(false)} />
          </PopupOverlay>
        )}



        <ReactModal
          isOpen={modalIsOpen1}
          onRequestClose={closeModal1}
          contentLabel="Y2Mate Video"
          style={{
            content: {
              width: "820px", // adjust width to your desired size
              height: "540px",
              margin: "auto",
              padding: "0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.75)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          }}
        >
          <div style={{ marginTop: "20px" }}>
            <iframe
              src="https://www.y2mate.com.cn/en"
              width="800px"
              height="500px"
              style={{
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
              title="Y2Mate"
            />
          </div>
        </ReactModal>



        {showplaylist_popup && (
          <PopupOverlay>
            <NewPlaylistForm onClose={handleyouClick_playlist1} />
          </PopupOverlay>
        )}

        {/* <Section>
          <SectionHeader1>
            <SectionTitle1>Learn to play the guitar</SectionTitle1>
            <ViewAllLink1 onClick={View_all_youtube}>View all</ViewAllLink1>
          </SectionHeader1>
          {isMobile ? (
            <SongGrid>
              {youSong.map((youSongs) => (
                <SongCardContainer
                  onClick={() => handleSongyoutubecard(youSongs.url)}
                >
                  <AlbumCover2 loading="lazy" src={youSongs.image} />
                  <SongInfo2>
                    <SongTitle4>{youSongs.title}</SongTitle4>
                  </SongInfo2>
                </SongCardContainer>
              ))}
            </SongGrid>
          ) : (
            <SongGrid_new>
              {youSong.map((youSongs) => (
                <SongCardContainer
                  onClick={() => handleSongyoutubecard(youSongs.url)}
                >
                  <AlbumCover2 loading="lazy" src={youSongs.image} />
                  <SongInfo2>
                    <SongTitle4>{youSongs.title}</SongTitle4>
                  </SongInfo2>
                </SongCardContainer>
              ))}
            </SongGrid_new>
          )}
        </Section> */}
        {isMobile ? (
          <ReactModal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="YouTube Video"
            style={{
              content: {
                width: "350px",
                height: "250px",
                margin: "auto",
                padding: "0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.75)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
            }}
          >
            {selectedSongUrl && (
              <iframe
                width="100%" // Adjusts iframe width for mobile
                height="100%"
                src={`https://www.youtube.com/embed/${getYoutubeVideoID(
                  selectedSongUrl
                )}?autoplay=1`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
          </ReactModal>
        ) : (
          <ReactModal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="YouTube Video"
            style={{
              content: {
                width: "860px",
                height: "600px",
                margin: "auto",
                padding: "0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "@media (max-width: 991px)": {
                  width: "20px", // Full width on mobile
                  height: "10px", // Full height on mobile
                },
              },
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.75)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
            }}
          >
            {selectedSongUrl && (
              <iframe
                width="100%" // Adjusts iframe width for mobile
                height="100%"
                src={`https://www.youtube.com/embed/${getYoutubeVideoID(
                  selectedSongUrl
                )}?autoplay=1`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
          </ReactModal>
        )}
      </PlaylistContainer>
      <ToastContainer />

      <CustomSnackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={
          <span
            id="message-id"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={tick_snack_bar}
              alt="check icon"
              style={{ marginRight: "8px", width: "24px", height: "24px" }}
            />
            Chord Generated
          </span>
        }
        action={
          <>
            <Button1
              size="small"
              aria-label="go to chords"
              color="primary"
              onClick={handlebutton}
              variant="contained"
              style={{ marginRight: "8px" }}
            >
              Go to chords
            </Button1>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <img
                src={close_snack_bar}
                alt="close icon"
                style={{ width: "24px", height: "24px" }}
              />
            </IconButton>
          </>
        }
      />
      <CustomSnackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open1}
        autoHideDuration={6000}
        onClose={handleClose}
        message={
          <span
            id="message-id"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={tick_snack_bar}
              alt="check icon"
              style={{ marginRight: "8px", width: "24px", height: "24px" }}
            />
            You have already requested this URL!
          </span>
        }
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <img
              src={close_snack_bar}
              alt="close icon"
              style={{ width: "24px", height: "24px" }}
            />
          </IconButton>
        }
      />
     

      <Footer />
    </MainContainer>
  );
}

const Modal = styled.section`
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  max-width: 428px;
  height: 300px;
  padding: 0 0 0px;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  @media (max-width: 991px) {
    width: 95%;
  }
`;

const Content1 = styled.p`
  color: #3e3635;
  letter-spacing: 0.1px;
  margin-top: 37px;
  font: 500 18px/21px Poppins, sans-serif;
`;
const HeaderContainer1 = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 428px;
  background-color: #428bca;
  max-width: 100%;
  font-size: 24px;
  white-space: nowrap;
  letter-spacing: -0.24px;
  line-height: 130%;
  height: 70px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  position: relative;
`;

const WarningText = styled.h2`
  margin: 0 auto;
  font: 600 24px Poppins, sans-serif;
`;

const CloseIcon = styled.img`
  position: absolute;
  top: 20px;
  right: 10px;
  cursor: pointer;
`;
const Button10 = styled.button`
  font-family: Poppins, sans-serif;
  border-radius: 4px;
  background-color: ${(props) => (props.primary ? "#428bca" : "transparent")};
  align-self: center;
  margin-top: ${(props) => (props.primary ? "15px" : "5px")};
  width: 100%;
  max-width: 296px;
  color: ${(props) => (props.primary ? "#fff" : "#717171")};
  white-space: nowrap;
  padding: 17px 30px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
`;

const Re_Model = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #121212;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 20px;

  @media (max-width: 991px) {
    width: 100%;
    margin-top: 0px;
  }
`;

const SectionTitle9 = styled.div`
  color: #fff;
  text-align: start;
  letter-spacing: -0.24px;
  font: 22px/160% Poppins, sans-serif;
  margin: 0; // Reset margin to avoid unwanted space
  flex: 1; // Allow title to take available space
  @media (max-width: 991px) {
    max-width: 100%;
    text-align: start;
    margin-left: 0px;
    font: 20px/160% Poppins, sans-serif;
  }
`;

const RecentlyPlayedItem = styled.li`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 0px;
  cursor: pointer;
`;

const SongThumbnail = styled.img`
  width: 116px;
  height: 80px;
`;

const SongDetails = styled.div`
  flex-direction: column;
  justify-content: top;
`;

const SongName = styled.p`
  color: #fff;
  font: 600 14px/19px Poppins, sans-serif;
  letter-spacing: -0.2px;
  margin: 5;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const SongGrid_new1 = styled.section`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-start;
  margin-top: 20px;
  overflow-x: auto; /* Allows scrolling if content exceeds width */

  @media (max-width: 991px) {
    gap: 10px;
  }
`;

const DeleteIcon = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 1;
`;

// const ToggleWrapper = styled.div`
//   display: flex;
//   align-items: center;
// `;

// const ToggleLabel = styled.label`
//   margin-right: 10px;
//   font-size: 14px;
// `;

// const ToggleSwitch = styled.div`
//   position: relative;
//   display: inline-block;
//   width: 50px;
//   height: 24px;
// `;

// const ToggleInput = styled.input`
//   opacity: 0;
//   width: 0;
//   height: 0;
// `;

// const Slider = styled.span`
//   position: absolute;
//   cursor: pointer;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: #ccc;
//   transition: .4s;
//   border-radius: 24px;

//   &:before {
//     position: absolute;
//     content: "";
//     height: 16px;
//     width: 16px;
//     border-radius: 50%;
//     left: 4px;
//     bottom: 4px;
//     background-color: white;
//     transition: .4s;
//   }
// `;

// // This will apply styles to the slider when the checkbox is checked
// const ToggleInputChecked = styled(ToggleInput)`
//   &:checked + ${Slider} {
//     background-color: #007bff;
//   }

//   &:checked + ${Slider}:before {
//     transform: translateX(26px);
//   }
// `;

// const InfoIcon = styled.img`
//   margin-left: 10px;
//   width: 24px;
//   height: 24px;
//   cursor: pointer;
// `;

// const SkipSection = styled.div`

//   display: flex;
//   align-items: center;
//   gap: 12px;
//   justify-content: center;
//   margin-top:20px;
//   margin-left:320px;
//   @media (max-width: 991px) {
//     margin-left:0px;
//   }
// `;

// const SkipLabel = styled.label`
//   color:white;
//   align-self: center;
//   font: 600 13px Nunito, sans-serif;
//   white-space: nowrap; /* Prevents text wrapping */
//   overflow: hidden; /* Hides overflow text */
//   text-overflow: ellipsis; /* Adds ellipsis if text overflows */
// `;

// const ToggleSwitch = styled.div`
//   align-self: stretch;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   width: 36px;
//   margin: auto 0;
//   padding: 3px 0;
// `;

// const SwitchTrack = styled.div`
//   border-radius: 360px;
//   background: ${({ isChecked }) => (isChecked ? '#0072D5' : '#b9c8da')}; // Change color based on state
//   display: flex;
//   flex-direction: column;
//   align-items: start;
//   cursor: pointer;
// `;

// const SwitchKnob = styled.div`
//   filter: drop-shadow(-1px 1px 1px rgba(51, 51, 51, 0.25));
//   background-color: #fff;
//   border-radius: 50%;
//   z-index: 10;
//   display: flex;
//   width: 20px;
//   height: 20px;
//   transform: ${({ isChecked }) => (isChecked ? 'translateX(20px)' : 'translateX(0)')}; // Move knob based on state
//   transition: transform 0.3s ease;
// `;

// const InfoIcon = styled.img`
//   aspect-ratio: 1;
//   object-fit: contain;
//   object-position: center;
//   width: 30px;
//   align-self: stretch;
//   margin: auto 0;
// `;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between; /* Aligns children to the left and right */
  align-items: flex-start; /* Aligns items at the start */
`;

const Imagearrow = styled.img`
  width: 20px;
  height: 40px;
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute;
  top: 22px;
  left: 26%;
  transform: rotate(180deg);
`;

const Tooltip = styled.div`
  position: absolute;
  top: 150%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: black;
  border: 2px solid #fff;
  padding: 8px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  z-index: 1;
  font-size: 12px;
`;

const Verform = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputIcon2 = styled.img`
  aspect-ratio: 1;
  width: 161px;
  height: 44px;
  cursor: pointer;
  margin-top: 15px;
  @media (max-width: 991px) {
    width: 131px;
    height: 34px;
  }
`;

const InputIcon5 = styled.img`
  aspect-ratio: 1;
  width: 161px;
  height: 44px;
  cursor: pointer;
  margin-top: 15px;
  @media (max-width: 991px) {
    width: 131px;
    height: 34px;
  }
`;

const Rows = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-left: 320px;
  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;

const InfoButtonWrapper = styled.div`
  position: relative;
  display: inline-block;

  &:hover ${Tooltip} {
    opacity: 1;
  }

  &:hover ${Imagearrow} {
    opacity: 1;
  }
`;

const LeftContent = styled.div`
  flex: 1;
  width: 75%;
`;

const RightContent = styled.div`
  width: 25%;
  margin-top: 60px;
`;

const Head = styled.div`
  display: flex; /* Enables flexbox layout */
  flex-direction: row; /* Aligns children in a row */
  align-items: center; /* Vertically centers items */
  gap: 20px; /* Adds spacing between SectionTitle and FreeTitle */
`;

const FreeTitle = styled.div`
  color: #fff;
  text-align: center; /* Center text horizontally */

  font: 700 14px/160% Poppins, sans-serif;
  margin: 0; /* Ensure no extra margin */

  @media (max-width: 991px) {
    max-width: 100%;
    text-align: start; /* Align text to start for smaller screens */
    margin-left: 8px; /* Optional: left margin for smaller screens */
    font: 700 10px/160% Poppins, sans-serif;
  }
`;

const StyledModalContent = styled.div`
  width: 860px;
  height: 600px;
  margin: auto;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 991px) {
    width: 100%; // Full width on mobile
    height: 100%; // Full height on mobile
  }
`;

const StyledModalOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CookiePopup = styled.div`
  display: ${({ show }) => (show ? "block" : "none")}; /* Use show prop */
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #121212;
  border-radius: 10px;
  padding: 20px;
  width: 320px;
  z-index: 1001; /* Set z-index higher than overlay */
  font-family: Arial, sans-serif;

  @media (max-width: 991px) {
    left: 50%;
    transform: translateX(-50%);
    margin-right: unset;
    margin-left: unset;
    bottom: 20px;
  }
`;

const CookiePopupContent = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
`;

const CloseBtn = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
`;

const CookieIcon = styled.div`
  text-align: center;
  margin-bottom: 20px;

  img {
    width: 90px;
  }
`;

const CookieMessage = styled.p`
  font-size: 14px;
  text-align: center;
  color: white;
  margin-bottom: 20px;
`;

const AcceptButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  text-align: center;

  &:hover {
    background-color: #0056b3;
  }
`;

const PopupOverlay1 = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Dim background */
  z-index: 1000; /* Ensure it's above other content */
`;

const FormContainer4 = styled.main`
  border-radius: 12px;

  display: flex;
  width: 428px;
  flex-direction: column;
  overflow: hidden;
  @media (max-width: 991px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const Form4 = styled.form`
  border-radius: 12px;
  background-color: #312f2f;
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const UploadContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction:row;
  align-items: center;
  gap: 12px; /* Adds space between buttons */
  margin-top: 10px;
  margin-bottom: 30px;
`;


const HeaderContainer4 = styled.header`
  position: relative;
  display: flex;
  min-height: 68px;
  align-items: center;
  justify-content: center;
  padding: 21px 16px;
  color: #fff;
  text-align: center;
  letter-spacing: -0.2px;
  font: 600 20px/1.3 Poppins, sans-serif;
`;

const Title01 = styled.div`
  z-index: 0;
  margin: 0;
  font: 600 25px/1.3 Poppins, sans-serif;
  @media (max-width: 991px) {
    font: 600 23px/1.3 Poppins, sans-serif;
  }
`;
const CloseButton = styled.button`
  position: absolute;
  top: 35px;
  right: 30px;
  background: none;
  border: none;
  color: #fff;
  font-size: 34px;
  cursor: pointer;
`;

const Content4 = styled.p`
  color: white;
  letter-spacing: 0.1px;
  margin-top: 0px;
  font: 500 14px Poppins, sans-serif;
  line-height: 22.4px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
`;

const UploadButton4 = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px;
    border-radius: 4px;
    background-color: #0072D5;
    color: #fff;
    border: none;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    width:110px;
    height:25px;

    img {
        height: 18px;
    }`;


const UploadButton3 = styled.img`
      width:180px;
    height:50px;
     cursor: pointer;
    `;



const UploadPopup = styled.div`
  max-width: 500px;
  width: 450px;
  background: #282828;
  padding: 24px;
  border-radius: 12px;
  max-height: 90vh;
  overflow-y: auto;

  select:focus{
    outline: none;
  }

  @media (max-width: 1240px) and (min-width: 1025px){
    width: 40%;
  }

  @media (max-width: 1024px) and (min-width: 901px){
    width: 45%;
  }

  @media (max-width: 900px) and (min-width: 601px){
    width: 35%;
  }

  @media (max-width: 600px){
    width: 75%;
  }
`
const FormContainer45 = styled.div`
  display: flex;
  padding: 30px 0;
  justify-content: space-between;
  gap: 8px;

  @media (max-width: 900px){
    flex-direction: column;
    gap:24px;

    &>div{
      width: 100%;
    }
  }
`

const VideoPreview = styled.div`
width: 150px;
margin-top: 10px;

@media (max-width:900px){
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  text-align: center;
  width: 100%;

  img{
    width: 70%;
  }
}
`

const FixText = styled.span`
  display: block; /* Moves text to the next line */
  text-decoration: underline;
  font-size: 10px;
  cursor: pointer;
  margin-top: 2px; /* Adds spacing between 'Download Error' and 'Fix this' */
`;



//new UI
const SectionContainer_new = styled.main`
  display: flex;
  align-items: start;
  gap: 12px;
  font-weight: 600;
  justify-content: start;
  margin-top:15px;
  margin-left:15px;
  margin-right:15px;
  
`;

const ContentWrapper_new = styled.div`
  border-radius: 12px;
  background-color: rgba(51, 51, 51, 1);
  display: flex;
  min-width: 240px;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: stretch;
  
  flex: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 20px;
  }
`;

const MainContent_new = styled.div`
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const HeaderWrapper_new = styled.header`
  display: flex;
  width: 100%;
  padding-right: 16px;
  align-items: start;
  gap: 12px;
  font-family: 'Poppins', sans-serif;
  color: rgba(255, 255, 255, 1);
  justify-content: start;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const HeaderContent_new = styled.div`
  display: flex;
  min-width: 240px;
  width: 100%;
  align-items: center;
  gap: 12px;
  justify-content: start;
  flex-wrap: wrap;
  flex: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  justify-content: space-between;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Title_new = styled.h2`
  font-size: 24px;
  line-height: 1.6;
  letter-spacing: -0.24px;
  align-self: stretch;
  margin: 0;
  flex: 1;
  font-family: 'Poppins', sans-serif;
  flex-shrink: 1;
  flex-basis: 0%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;


const Horizontal_row = styled.div`
   display: flex;
   align-items: center;
   gap: 12px;
`;

const Play_image = styled.img`
  cursor:pointer;
`;

const ViewAllButton_new = styled.button`
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: -0.16px;
  text-align: center;
  align-self: stretch;
  margin-top: auto;
  margin-bottom: auto;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 0;
`;

const CardWrapper_new = styled.article`
  background-color: rgba(34, 34, 34, 1);
  display: flex;
  min-width: 240px;
  width: 100%;
  padding: 12px;
  align-items: stretch;
  overflow: hidden;
  justify-content: start;
  flex: 1;
  flex-shrink: 1;
  flex-basis: 0%;
`;

const CardContent_new = styled.div`
  display: flex;
  min-width: 240px;
  width: 100%;
  align-items: stretch;
  gap: 8px;
  justify-content: start;
  height: 100%;
  flex: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  cursor:pointer;
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProgressOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

const ProgressCircle = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
`;

const ProgressBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
`;

const ProgressForeground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid white;
  border-radius: 50%;
  border-top-color: transparent;
  transform: rotate(${props => props.$width * 3.6}deg);
  transition: transform 0.5s ease;
`;

const AlbumImage_new = styled.img`
  aspect-ratio: 1.23;
  object-fit: contain;
  object-position: center;
  width: 64px;
  border-radius: 2px;
  margin-top: auto;
  margin-bottom: auto;
  flex-shrink: 0;
`;

const SongInfo_new = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  flex: 1;
  flex-shrink: 1;
  flex-basis: 0%;
`;

const SongTitle_new = styled.h3`
  color: ${props => 
    props.$inProgress 
      ? 'rgba(255, 255, 255, 0.3)' // Disabled color
      : 'rgba(255, 255, 255, 1)'   // Normal color
  };
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: -0.14px;
  margin: 0;
  text-overflow: ellipsis;
  transition: color 0.3s ease;
`;

const CreatedTime_new = styled.time`
 font-family: 'Poppins', sans-serif;
  font-size: 11px;
   color: ${props => 
    props.$inProgress 
      ? 'rgba(255, 255, 255, 0.3)' // Disabled color
      : 'rgba(255, 255, 255, 1)'   // Normal color
  };
  letter-spacing: -0.11px;
  margin-top: 4px;
`;


const GridContainer_new = styled.section`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  gap: 12px;
  margin-top: 12px;
  width: 100%;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for smaller screens */
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr); /* 1 column for very small screens */
  }
`;


const GridItem_new = styled.div`
  display: flex;
  width: 100%;
`;


export default Premium_user;
