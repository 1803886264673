import * as React from "react";
import styled from "styled-components";
import Footer from '../footer_lan';

function Contact() {
  return (
    <>
      <Main>
        {/* <FormSection> */}
        {/* <FormTitle>Contact Us</FormTitle> */}
        <IframeContainer>
            <Iframe
              src="https://easy-donate.ck.page/jammin"
              frameBorder="0"
              allowFullScreen
            />
          </IframeContainer>
        {/* </FormSection> */}
      </Main>
      <><Footer/></>
      
      
    </>
  );
}



const Main = styled.main`
  display: flex;
  justify-content: center;
  padding: 0px;
  overflow-x: hidden; 
  overflow-y: hidden;
  
 
  background-color: #f0eceb;
  @media (max-width: 991px) {
    padding:  20px;
  }
`;

const FormTitle = styled.h1`
  font-family: 'Montserrat', sans-serif;
  font-size: 32px;
  font-weight: 700;
  color: #020f12;
  text-align: center;
  letter-spacing: -0.32px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  `;

const FormSection = styled.section`
  background-color: #fff;
  border-radius: 8px;
  max-width: 596px;
  width: 100%;
  display: flex;
  flex-direction: column;
  
`;

const IframeContainer = styled.div`
  
  width: 100%;
  height: 1150px;  
  display: flex;
  justify-content: center;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
`;












export default Contact;
