import React from "react";
import styled from "styled-components";
import GoogleSignIn from '../../google'; 
import { explore_popup, lg_white, white_close } from '../../assets/images';

function Explore({ onClose }) {
  return (
    <LoginContainer>
      <BackgroundImage loading="lazy" src={explore_popup} alt="Background" />
      <ContentWrapper>
        <CloseIcon onClick={onClose} loading="lazy" src={white_close} alt="Close" />
        <WelcomeWrapper>
          <Logo loading="lazy" src={lg_white} alt="Jammin.ai Logo" />
          <WelcomeTitle>Welcome to Jammin.ai!</WelcomeTitle>
          <WelcomeText>
            Click "Continue with Google" to explore jammin.ai:
          </WelcomeText>
          <GoogleSignIn />
        </WelcomeWrapper>
        
      </ContentWrapper>
    </LoginContainer>
  );
}


const WelcomeWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  margin-top:20px;
 
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Logo = styled.img`
  aspect-ratio: 3.85;
  object-fit: contain;
  width: 248px;
  max-width: 100%;
  margin-top:40px;
`;

const CloseIcon = styled.img`
  position: absolute;
  top: 50px;
  right: 30px;
  cursor: pointer;
`;

const WelcomeTitle = styled.h1`
  letter-spacing: -0.36px;
  margin-top: 42px;
  font: 700 36px Montserrat, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const WelcomeText = styled.p`
  letter-spacing: 0.2px;
  margin-top: 2px;
  margin-bottom:40px;
  font: 500 18px Montserrat, sans-serif;
  @media (max-width: 991px) {
    max-width: 90%;
  }
`;

const InfoIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  width: 24px;
  margin-top: 12px;
`;

const LoginContainer = styled.main`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  position: relative;
  max-height: 432px;
  max-width: 864px;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  
  @media (max-width: 991px) {
    width:96%;
     border-radius: 12px;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  border-radius:12px;
  max-height: 432px;
  max-width: 864px;
  border-top-right-radius: 12px;  /* Set the desired radius */
  border-bottom-right-radius: 12px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
 
  align-items: center;
  gap: 72px;
  z-index: 1; // Ensures content appears above the background image
  @media (max-width: 991px) {
    max-width: 100%;
    gap: 40px;
  }
`;

export default Explore;
