import axios from 'axios';
import config from '../../config';
import { getToken } from '../utils';



export const checkAdmin = async () => {
    const token = getToken();
    try {
      const response = await axios.get(`${config.apiUrl}/admin/songs`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200) {
        sessionStorage.setItem("isadmin", "true");  // Using sessionStorage
      }
    } catch (error) {
        sessionStorage.setItem("isadmin", "false");
    }
  };
  