import React from "react";
import styled from "styled-components";
import { sixth_back, guitar_man_back, guitar_man } from '../../assets/images';
import useWindowWidth from "../global"
import useWindowHeight from "../global2"



import { useNavigate } from "react-router-dom";
import {
  getToken,
} from "../utils";



const Sixth_Section = () => {
  const windowWidth = useWindowWidth();
  const windowHeight = useWindowHeight();
  
  const isMobile = windowWidth <= 991;
  const istab = windowHeight >= 990;
  let ComponentToRender;

  if (istab) {
    ComponentToRender = Sixth_Section_tab;
  
  } else if (isMobile) {
    ComponentToRender = Sixth_Section_mob;
   
  } else {
    ComponentToRender = Sixth_Section1;
   
  }

  return <ComponentToRender />;
};

const Sixth_Section1 = () => {
  const navigate = useNavigate();
  const token = getToken();

  const let_jam = () => {

    if (!token) {
      window.scrollTo(0, 0);
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      navigate("/user");
    }
  };
  return (
    <MainPageContainer>
      <BackgroundImage loading="lazy" src="https://jammin-stage.s3.amazonaws.com/images/sixth_back.svg" />
      <StyledSection>
        <ImageWrapper>
          <MainImage loading="lazy" src="https://jammin-stage.s3.amazonaws.com/images/guitar_man_back.svg" alt="Main visual" />
          <OverlayImage loading="lazy" src="https://jammin-stage.s3.amazonaws.com/images/guitar_man.svg" alt="Overlay visual" />
        </ImageWrapper>

        <CTAWrapper>
          <ContentWrapper>
            <Heading>
              Start <WhiteText>Jamming today</WhiteText>
            </Heading>
            <Description>
              Get started today and discover the power of music at your fingertips! Join us at{" "}
              <BoldWhiteText>jammin.ai</BoldWhiteText> and let's create a more musical world together!
            </Description>
          </ContentWrapper>
          <CTAButton onClick={let_jam}>Let's Jam</CTAButton>
        </CTAWrapper>

      </StyledSection>
    </MainPageContainer>
  );
};

const Sixth_Section_mob = () => {
  const navigate = useNavigate();
  const token = getToken();

  const let_jam = () => {

    if (!token) {
      window.scrollTo(0, 0);
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      navigate("/user");
    }
  };
  return (
    <MainPageContainer1>
      <BackgroundImage loading="lazy" src="https://jammin-stage.s3.amazonaws.com/images/sixth_mob.svg" />
      <HeroContent>
        {/* <ImageWrapper>
          <MainImage loading="lazy" src="https://jammin-stage.s3.amazonaws.com/images/guitar_man_back.svg" alt="Main visual" />
          <OverlayImage loading="lazy" src="https://jammin-stage.s3.amazonaws.com/images/guitar_man.svg" alt="Overlay visual" />
        </ImageWrapper> */}
        <CTAWrapper1>
          <CTAContent1>
            <Heading1>Start Jamming today</Heading1>
            <Description1>
              Get started today and discover the power of music at your fingertips! Join us at{" "}
              <Highlight1>jammin.ai</Highlight1> and let's create a more musical world together!
            </Description1>
          </CTAContent1>
          <CTAButton1 onClick={let_jam}>Let's Jam</CTAButton1>
        </CTAWrapper1>
      </HeroContent>
      </MainPageContainer1>
  );
};

const Sixth_Section_tab = () => {
  const navigate = useNavigate();
  const token = getToken();

  const let_jam = () => {

    if (!token) {
      window.scrollTo(0, 0);
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      navigate("/user");
    }
  };
  return (
    <MainPageContainer2>
      <BackgroundImage2 loading="lazy" src="https://jammin-stage.s3.amazonaws.com/images/sixth_mob.svg" />
      <HeroContent2>
        <ImageWrapper2>
          <MainImage2 loading="lazy" src="https://jammin-stage.s3.amazonaws.com/images/guitar_man_back.svg" alt="Main visual" />
          <OverlayImage2 loading="lazy" src="https://jammin-stage.s3.amazonaws.com/images/guitar_man.svg" alt="Overlay visual" />
        </ImageWrapper2>
        <CTAWrapper2>
          <CTAContent2>
            <Heading2>Start Jamming today</Heading2>
            <Description2>
              Get started today and discover the power of music at your fingertips! Join us at{" "}
              <Highlight2>jammin.ai</Highlight2> and let's create a more musical world together!
            </Description2>
          </CTAContent2>
          <CTAButton1 onClick={let_jam}>Let's Jam</CTAButton1>
        </CTAWrapper2>
      </HeroContent2>
      </MainPageContainer2>
  );
};
// const mediaQuery500x800 = '@media (min-width: 500px) and (min-height: 800px)';


const CTAWrapper1 = styled.div`
  display: flex;
  margin-top: 48px;
  width: 69%;
  flex-direction: column;
  position:relative;
  color: #fff;
  justify-content: center;
  margin: 0 auto; /* Center the CTAWrapper1 */
  @media (max-width: 991px) {
    width: 100%;
    padding:20px;
    margin-right:0px;
    margin-bottom:20px;
  }
  
`;
const CTAWrapper2 = styled.div`
  display: flex;
  margin-top: 48px;
  width: 69%;
  flex-direction: column;
  position:relative;
  color: #fff;
  justify-content: center;
  margin: 0 auto; /* Center the CTAWrapper1 */
   
  
`;

const Heading1 = styled.h2`
  letter-spacing: -0.2px;
  text-transform: uppercase;
  font: 900 20px/1.2 Poppins, sans-serif;
`;
const Heading2 = styled.h2`
  letter-spacing: -0.2px;
  text-transform: uppercase;
  font: 900 20px/1.2 Poppins, sans-serif;
`;

const Description1 = styled.p`
  letter-spacing: -0.12px;
  margin-top: 12px;
  font: 600 12px/17px Open Sans, sans-serif;
`;
const Description2 = styled.p`
  letter-spacing: -0.12px;
  margin-top: 12px;
  font: 600 12px/17px Open Sans, sans-serif;
`;


const Highlight1 = styled.span`
  font-weight: 700;
  color: #fff;
`;
const Highlight2 = styled.span`
  font-weight: 700;
  color: #fff;
`;

const CTAButton1 = styled.button`
  align-self: center;
  border-radius: 4px;
  background-color: #0072d5;
  margin-top: 26px;
  min-height: 56px;
  width: 187px;
  max-width: 100%;
  text-transform: uppercase;
  padding: 17px 12px;
  font: 700 16px Open Sans, sans-serif;
  border: none;
  color: #fff;
  cursor: pointer;
`;

const CTAContent1 = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: center;
  justify-content:center;
`;
const CTAContent2 = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: center;
  justify-content:center;
`;

const ImageWrapper1 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 31%;
  margin-left: 0;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const PrimaryImage = styled.img`
  aspect-ratio: 1.29;
  object-fit: contain;
  object-position: left;
  width: 129px;
  max-width: 100%;
`;

const HeroContainer = styled.section`
  max-width: 419px;
  background-color: #121212;
`;

const SecondaryImage = styled.img`
  aspect-ratio: 1.27;
  object-fit: contain;
  object-position: left;
  width: 100px;
  border-radius: 12px;

  margin-top: 108px;
  max-width: 100%;
  @media (max-width: 991px) {
    margin-bottom:0px;
  }
`;

const HeroContent = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;  
  align-items: center; 
  @media (max-width: 991px) {
    flex-direction: row;
    align-items: center;
    gap: 0px;
    margin-bottom:0px;
    position: relative;
    zIndex: 2;
  }
   
`;
const HeroContent2 = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;  
  align-items: center; 
  @media (max-width: 991px) {
    flex-direction: row;
    align-items: center;
    gap: 0px;
    margin-bottom:0px;
    position: relative;
    zIndex: 2;
  }
   
`;


const MainPageContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;  /* Center content vertically */
  align-items: center;  /* Center content horizontally */
  overflow: hidden;
  position: relative;
  width: 100%;    
  height: 469px;
  @media (max-width: 991px) {
    padding: 0px;
    width: 100%; 
    height: 350px;
  }
`;

const MainPageContainer1 = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;  /* Center content vertically */
  align-items: center;  /* Center content horizontally */
  overflow: hidden;
  position: relative;
  width: 100%;    
  height: 469px;
  @media (max-width: 991px) {
    height: 350px;
  }
   
`;
const MainPageContainer2 = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;    
  align-items: center;  
  overflow: hidden;
  position: relative;
  width: 100%;    
  height: 100%;
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  height: 469px;
  width: 100%;
  object-fit: cover;
  object-position: center;
  @media (max-width: 991px) {
    position: absolute;
    inset: 0;
    height:350px;
    width:130%;
    object-fit: cover;
    object-position: center;
    zIndex: 1;
  }
   
`;
const BackgroundImage2 = styled.img`
  position: absolute;
  inset: 0;
  height:100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  @media (max-width: 991px) {
    position: absolute;
    inset: 0;
    height:350px;
    width:130%;
    object-fit: cover;
    object-position: center;
    zIndex: 1;
  }
   
`;

const CTAWrapper = styled.div`
  display: flex;
  width: 77%;
  z-index: 10;
  flex-direction: column;
  margin-right:230px;
  margin-bottom:80px;
  color: #fff;
  align-items: center;  
  justify-content: center;  /* Center content vertically */
   @media (max-width: 991px) {
    width: 100%;
    max-width: 100%;
    margin-top: 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: center;
  justify-content: flex-start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Heading = styled.h2`
  letter-spacing: -0.44px;
  text-transform: uppercase;
  font: 900 44px/1.2 Poppins, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const WhiteText = styled.span`
  color: #fff;
`;

const Description = styled.p`
  letter-spacing: -0.2px;
  align-self: center;
  margin-top: 12px;
  width: 756px;
  font: 600 20px/28px Open Sans, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const BoldWhiteText = styled.span`
  font-weight: 700;
  color: #fff;
`;

const CTAButton = styled.button`
  align-self: center;
  border-radius: 4px;
  background-color: #0072d5;
  margin-top: 56px;
  min-height: 64px;
  width: 312px;
  max-width: 100%;
  text-transform: uppercase;
  padding: 21px 12px;
  font: 700 16px Open Sans, sans-serif;
  color: #fff;
  border: none;
  cursor: pointer;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const StyledSection = styled.section`
  display: flex;
  width: 100%;
  height: 469px;
  background-color: #121212;
  justify-content: center;  
  align-items: center;   
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 55%;
  @media (max-width: 991px) {
    width: 60%;
    max-width: 60%;
  }
`;

const ImageWrapper2 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media (min-height:1250px) {
    margin-right:200px;
    }
 `;

const MainImage = styled.img`
  aspect-ratio: 1.27;
  object-fit: contain;
  object-position: center;
  position: absolute;
  top:0;
  left:0;
  width: 380px;
  max-width: 100%;
  transform: rotate(-5deg);  
  transition: transform 0.5s ease;  
  @media (max-width: 991px) {
    width: 100%;
  }
`;
const MainImage2 = styled.img`
  aspect-ratio: 1.27;
  object-fit: contain;
  object-position:start;
  position: absolute;
  top:0;
  left:0;
  width: 200px;
  @media (min-height:1250px) {
    top:0;
    left:0;
    }
   
`;

const OverlayImage = styled.img`
  aspect-ratio: 1.38;
  object-fit: contain;
  object-position: center;
  width: 380px;
  z-index: 10;
  position: absolute;
  bottom:0;
  left:0;
  border-radius: 12px;
  align-self: flex-end;
  margin-top: -138px;
  margin-right:50px;
  max-width: 100%;
  @media(max-width:991px){
    position:
  }
  @media (max-width: 991px) {
    width: 100px; /* Adjust width for mobile screens */
    margin-bottom:40px;  
  }
`; 
const OverlayImage2 = styled.img`
  aspect-ratio: 1.38;
  object-fit: contain;
  object-position: center;
  width:200px;
  z-index: 10;
  position: absolute;
  bottom:0;
  left:0;
  border-radius: 12px;
  `;
export default Sixth_Section;
