import * as React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import config from "../../../config";
import {
  landing_begineer_left
} from "../../../assets/images";
import useWindowSize from "../../useWindowSize"
import { useNavigate } from "react-router-dom";
import { getToken } from "../../utils";
import Login from "../../login/login";

const BeginnerSongs = () => {
  const [publicsong, setPublic] = useState([]);
 
  const { windowWidth, windowHeight, isMobile, isPortrait,isLandscape } = useWindowSize();
  const [isLoginPopupVisible, setLoginPopupVisible] = useState(false);
  const token = getToken();
  const navigate = useNavigate();

  useEffect(() => {
    const public_songs = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/public/`, {
          headers: { "Content-Type": "application/json" },
        });

        if (response.status === 200) {
          const playlistsData = response.data.map((item) => ({
            id: item.request_id,
            url: item.url,
            title: item.title,
            image: item.thumbnail_url.split("?")[0],
            result: item.result,
            youtube_id: item.youtube_id,
          }));
          setPublic(playlistsData.slice(0, 8));
        }
      } catch (error) {
        console.error("Error fetching public songs:", error);
      }
    };

    public_songs();
  }, []);

  const handleChordsClick = () => {
    if(token){
      window.scrollTo(0, 0);
      navigate("/user");
    }else{
     
      setLoginPopupVisible(true);
    }
    };

  return (
    <>
      {isMobile ? (
 <Container>
   {isLoginPopupVisible && (
        <PopupOverlay>
        <Login onClose={() => setLoginPopupVisible(false)} />

        </PopupOverlay>
      )}
 <TitleWrapper>
   <Title>Explore Beginner Friendly Songs</Title>
 </TitleWrapper>
 <ContentWrapper3>
   <MainImage3
     src={landing_begineer_left}
     alt=""
   />
   <ContentSection>
   {publicsong.slice(0, 3).map((song, rowIndex) => (
          <SongListContainer key={rowIndex}>
            
              <SongItem
                key={song.id}
                imageSrc={song.image}
                title={song.title}
                version="Original Version"
              />
          
          </SongListContainer>
        ))}
     <ButtonContainer>
        <ActionButton onClick={handleChordsClick} >Discover</ActionButton>
      </ButtonContainer>
   </ContentSection>
 </ContentWrapper3>
</Container>
) : (
  <>
  <MainSection>
  {isLoginPopupVisible && (
        <PopupOverlay>
        <Login onClose={() => setLoginPopupVisible(false)} />


        </PopupOverlay>
      )}
  <HeaderContainer>
    <MainTitle>Explore Beginner Friendly Songs</MainTitle>
  </HeaderContainer>
  <ContentWrapper>
    <CoverImage>
      <MainImage
        src={landing_begineer_left}
        alt="Cover"
      />
    </CoverImage>
    <SongsContainer>

      <SongsList>
        {publicsong.reduce((acc, song, index) => {
          if (index % 2 === 0) {
            acc.push([song]); // Start a new row
          } else {
            acc[acc.length - 1].push(song); // Add to the current row
          }
          return acc;
        }, []).map((row, rowIndex) => (
          <SongListSection key={rowIndex}>
            {row.map((song) => (
              <SongCard
                key={song.id}
                imageSrc={song.image}
                title={song.title}
                version="Original Version"
              />
            ))}
          </SongListSection>
        ))}
      </SongsList>
      <ButtonContainer>
        <ActionButton onClick={handleChordsClick} >Discover</ActionButton>
      </ButtonContainer>
    </SongsContainer>
  </ContentWrapper>
</MainSection>
</>
)}
    </>
   
  );
};

const ActionButton = ({ children, onClick }) => (
  <StyledActionButton onClick={onClick} tabIndex="0">
    {children}
  </StyledActionButton>
);

const SongItem = ({ imageSrc, title, version }) => {
  return (
    <SongItemContainer>
      <ContentWrapper2>
        <Thumbnail src={imageSrc} alt="" />
        <TextContent>
          <SongTitle2>{title}</SongTitle2>
        
        </TextContent>
      </ContentWrapper2>
    </SongItemContainer>
  );
}

const SongCard = ({ imageSrc, title, version }) => (
  <CardContainer>
    <CardContent>
      <SongImage src={imageSrc} alt={title} />
      <SongInfo>
        <SongTitle>{title}</SongTitle>
        <SongVersion>{version}</SongVersion>
      </SongInfo>
    </CardContent>
  </CardContainer>
);

const Container = styled.main`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
  background-color: #121212;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 56px;
  width: 100%;
`;

const Title = styled.h1`
  width: 100%;
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 800;
  gap: 12px;
`;

const ContentWrapper3 = styled.div`
  display: flex;
  width: 100%;
 
  padding: 0px 8px;
  justify-content: center;
  align-items: center;  // Ensures everything is centered
  gap: 8px;
  flex-direction: row;  // Ensures horizontal alignment
`;


const MainImage3 = styled.img`
  width: 40%;
  flex-shrink: 0;
  border-radius: 12px;
  align-self: center;  // Ensures it is vertically aligned with SongItem
`;

const ContentSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1;
`;

const SongListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
`;

const SongItemContainer = styled.article`
  display: flex;
  height: 40px;
  padding: 0px 4px;
  align-items: center;
  width: 80%;
  margin-right: 10px;  // Added right margin for SongItem
  background-color: #313131;
`;


const ContentWrapper2 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
`;

const Thumbnail = styled.img`
  width: 25px;
  height: 20px;
  border-radius: 2px;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0px;
  flex: 1;
`;

const SongTitle2 = styled.h2`
  width: 137px;
  overflow: hidden;
  color: #fff;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -0.1px;
`;

const Version = styled.p`
  overflow: hidden;
  color: #fff;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 9px;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.09px;
`;

const StyledActionButton = styled.button`
  font-family: "Poppins", sans-serif;
  display: flex;
  font-weight: 500;
  font-size: 16px;
  border: none;
  cursor: pointer;
  justify-content: center;
  border-radius: 4px;
  background-color: #0072d5;
  align-items: center;
  color: #ffffff;
  width: 164px;
  height: 48px;
  white-space: nowrap;
  margin: auto 0;
  padding: 14px 12px;
  @media (max-width: 800px) {
    white-space: normal;
    padding: 0 20px;
    margin-right: 10px;
    height: 40px;
     width: 140px;
  }
`;

const CardContainer = styled.article`
  background-color: rgba(49, 49, 49, 1);
  display: flex;
  min-width: 240px;
  width: 477px;
  padding: 8px;
  align-items: stretch;
  overflow: hidden;
  justify-content: start;
  @media (max-width: 800px) {
    width:auto;
  }
`;

const CardContent = styled.div`
  display: flex;
  min-width: 240px;
  align-items: stretch;
  gap: 8px;
  justify-content: start;
  height: 100%;
`;

const SongImage = styled.img`
  aspect-ratio: 1.23;
  object-fit: contain;
  object-position: center;
  width: 64px;
  border-radius: 2px;
  margin-top: auto;
  margin-bottom: auto;
  flex-shrink: 0;
`;

const SongInfo = styled.div`
  display: flex;
  min-width: 240px;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 348px;
`;

const SongTitle = styled.h2`
  font-size: 14px;
  letter-spacing: -0.14px;
  margin: 0;
  color: white;
`;

const SongVersion = styled.p`
  font-size: 12px;
  letter-spacing: -0.12px;
  margin: 4px 0 0;
  color: white;
`;

const MainSection = styled.section`
  background-color: rgba(18, 18, 18, 1);
  padding: 56px;
  overflow: hidden;
  @media (max-width: 991px) {
    padding: 20px;
  }
`;

const HeaderContainer = styled.header`
  width: 944px;
  max-width: 100%;
`;

const MainTitle = styled.h1`
  font-family:
    Montserrat,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-size: 36px;
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  margin-top: 56px;
  align-items: stretch;
  gap: 32px;
  justify-content: start;

  @media (max-width: 800px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const CoverImage = styled.div`
  min-width: 240px;
  width: 327px;

  @media (max-width: 800px) {
    width: 30%; /* Takes 50% width */
    order: 1;
  }
`;

const MainImage = styled.img`
  aspect-ratio: 1.03;
  object-fit: contain;
  object-position: center;
  width: 100%;
  border-radius: 12px;
`;

const SongsContainer = styled.div`
  align-self: start;
  min-width: 240px;
  font-family:
    Poppins,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
  width: 1015px;
  @media (max-width: 800px) {
    width: 70%; /* Takes 50% width */
    order: 2; /* Ensures it's on the right */
  }
`;

const SongsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px; /* Adds spacing between rows */
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px; /* Adjusted for mobile view */
  }
`;

const SongListSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 16px; /* Adds gap between two songs in a row */

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 12px;
    align-items: center;
  }
`;



const ButtonContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: center;
`;

const DiscoverButton = styled.button`
  border-radius: 4px;
  background-color: rgba(0, 114, 213, 1);
  padding: 20px 16px;
  color: white;
  border: none;
  cursor: pointer;
  font-weight: 600;
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
`;

export default BeginnerSongs;
