import { useState, useEffect } from "react";

const useWindowSize = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    const checkIfMobile = () => {
      const isMobileDevice = window.matchMedia("(max-width: 800px)").matches;
      setIsMobile(isMobileDevice);
    };

    checkIfMobile(); 
    window.addEventListener("resize", handleResize);
    window.addEventListener("resize", checkIfMobile); 

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  const isPortrait = windowHeight > windowWidth;
  const isLandscape = windowWidth > windowHeight;

  return { windowWidth, windowHeight, isMobile, isPortrait, isLandscape };
};

export default useWindowSize;
