import React from "react";
import styled from "styled-components";
import { second_section_one, second_section_mob,  } from '../../assets/images';
import useWindowWidth from "../global"
import image_url from "../../config"


const Second_section = () => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 991;
  return (
    <>
      {isMobile ? (<Second_section_mob />) : (<Second_section1 />)}

    </>
  );
};


const Second_section1 = () => {
  return (
    <Section>
      <ContentWrapper>

        <HeaderWrapper>
          <span className="highlight">More Than Just</span>{" "}
          <span className="highlight">Tech</span>
        </HeaderWrapper>

        <DescriptionText>
          jammin.ai is more than just a tool; it's a community. We're a group of
          creators who believe music should be accessible and enjoyable for
          everyone, regardless of skill level. Whether you're a seasoned guitarist
          or just picking up your first instrument, jammin.ai is here to help you
          discover new musical possibilities, ignite your creativity, and jam along
          to your favorite tunes
        </DescriptionText>


      </ContentWrapper>
      <FeatureImage loading="lazy" src="https://jammin-stage.s3.amazonaws.com/images/music_back.svg" alt="Feature" />
      <BannerImage loading="lazy" src={second_section_one} alt="Banner" />
    </Section>
  );
};


const Second_section_mob = () => {
  return (
    <Section>
      <ContentWrapper>

        <HeaderWrapper>
          <span className="highlight">More Than Just</span>{" "}
          <span className="highlight">Tech</span>
        </HeaderWrapper>

        <DescriptionText>
          jammin.ai is more than just a tool; it's a community. We're a group of
          creators who believe music should be accessible and enjoyable for
          everyone, regardless of skill level. Whether you're a seasoned guitarist
          or just picking up your first instrument, jammin.ai is here to help you
          discover new musical possibilities, ignite your creativity, and jam along
          to your favorite tunes
        </DescriptionText>

        <FeatureImage1 loading="lazy" src={second_section_mob} alt="Feature" />
      </ContentWrapper>


    </Section>
  );
};




const BannerImage = styled.img`
  aspect-ratio: 2.58;
  object-fit: contain;
  object-position: center;
  width: 100%;
  z-index: 0;
  margin-top: 12px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const FeatureImage = styled.img`
  aspect-ratio: 0.75;
  object-fit: contain;
  object-position: center;
  width: 221px;
  position: absolute;
  z-index: 0;
  max-width: 100%;
  right: 138px;
  bottom: 236px;
  height: 297px;
`;

const FeatureImage1 = styled.img`
  aspect-ratio: 0.75;
  object-fit: contain;
  object-position: center;
  width: 100%;
  height:414px;
  
  z-index: 0;
  
`;


const DescriptionText = styled.p`
  color: #3d3d3d;
  letter-spacing: -0.18px;
  margin-top: 12px;
  font: 600 18px/25px Open Sans, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;


const HeaderWrapper = styled.h1`
  color: #020f12;
  font: 700 40px/1.3 Montserrat, sans-serif;
  text-transform: uppercase;
  @media (max-width: 991px) {
     font: 700 20px/1.3 Montserrat, sans-serif;
  }
  .highlight {
    font-family: Poppins, sans-serif;
    font-weight: 800;
    line-height: 48px;
    color: #121212;
  }
`;


const Section = styled.section`
  background-color: #ededed;
  position: relative;
  display: flex;
  min-height: 785px;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  justify-content: flex-start;
  padding: 40px 40px 70px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const ContentWrapper = styled.div`
  z-index: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default Second_section;