import * as React from "react";
import styled from "styled-components";
import { new_logo } from '../assets/images';
import { useNavigate } from 'react-router-dom';




function Footer() {

  const navigate = useNavigate();

  const scroll = (id) => {
    navigate("/");
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      } else {
        // console.warn(`Element with id '${id}' not found.`);
      }
    }, 100); // Adjust the delay as needed
  };

  const contact = () => {
    window.scrollTo(0, 0);
    navigate("/contact");
  };

  const about_us = () => {
    window.scrollTo(0, 0);
    navigate("/about_us");
  };

  const terms = () => {
    window.scrollTo(0, 0);
    navigate("/terms");
  };


  const privacy = () => {
    window.scrollTo(0, 0);
    navigate("/privacy_policy");
  };


  const faq = () => {
    window.scrollTo(0, 0);
    navigate("/faq");
  };



  return (
    <FooterContainer>
      <FooterWrapper>
        <ContentWrapper>
          <FooterContent>
            <FooterGrid>
              <FooterBrandColumn>

                <BrandWrapper>
                  <BrandLogo
                    loading="lazy"
                    src={new_logo}
                    alt="Jammin.ai Logo"
                  />
                  <Copyright1>
                    Made with Your one-stop shop for chords.
                  </Copyright1>
                  <Copyright>
                    © 2024 Jammin.ai. All Rights Reserved.
                  </Copyright>
                  <Version>v1.25_28 Mar</Version>
                </BrandWrapper>

              </FooterBrandColumn>
              <FooterLinksColumn>
                <LinksWrapper>
                  <Navigation>
                    <NavLink onClick={() => scroll('home-section')}>Home</NavLink>
                    {/* <NavLink onClick={() => scroll('pricing-section')}>Pricing</NavLink> */}
                    <NavLink onClick={contact}>Contact Us</NavLink>
                    <NavLink onClick={privacy}>Privacy Policy</NavLink>

                    <NavLink onClick={terms}>Terms and Conditions</NavLink>
                    <NavLink onClick={about_us}>About us</NavLink>
                    <NavLink onClick={faq}>FAQ</NavLink>
                  </Navigation>
                </LinksWrapper>
              </FooterLinksColumn>
            </FooterGrid>
          </FooterContent>

        </ContentWrapper>

      </FooterWrapper>
    </FooterContainer>
  );
}

const Navigation = styled.nav`
  display: flex;
  margin-top: 14px;
  padding-right: 20px;
  gap: 60px;
  font-size: 18px;
  font-style:'Source Sans Pro';
  justify-content: space-between;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    flex-direction: row;
     padding-right: 40px;
    margin-top: 0px;
    gap: 10px;
   
  }
`;
const NavLink = styled.div`
  font-family: 'Source Sans Pro', sans-serif;
  text-decoration: none;
    font: 400 18px'Poppins', sans-serif;
  color: inherit;
  cursor: pointer;
  &:focus {
    outline: 2px solid #fff;
  }
  &:hover {
   
  }
    @media (max-width: 991px) {
    font: 400 14px'Poppins', sans-serif;
   
  }
`;


const LinksWrapper = styled.nav`
  display: flex;
  margin-top: 36px;
  gap: 40px 112px;
  color: rgb(255, 255, 255);
  text-align: center;
  justify-content: start;
  flex-wrap: wrap;
  font: 400 18px Source Sans Pro, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
     margin-bottom: 10px;
     padding-left:20px;
   
  }
`;

const LinkItem = styled.div`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: 2px solid rgb(255, 255, 255);
    outline-offset: 2px;
  }
`;

const BrandWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: start;
  color: rgb(243, 243, 243);
  font: 500 13px Ubuntu, sans-serif;
  @media (max-width: 991px) {
    margin-top: 10px;
    align-items: center; /* Horizontally center items */
    justify-content: center; /* Vertically center items */
  }
`;

const BrandLogo = styled.img`
  aspect-ratio: 3.17;
  object-fit: contain;
  object-position: center;
  width: 178px;
  max-width: 100%;
   @media (max-width: 991px) {
    width: 138px;
  }
`;

const Copyright = styled.div`
  align-self: stretch;
  margin-top: 10px;
    font: 200 14px 'Poppins', sans-serif;
   
   @media (max-width: 991px) {
    font: 200 10px 'Poppins', sans-serif;
    align-items: center; /* Horizontally center items */
    justify-content: center; /* Vertically center items */
    margin-left:10px;
  }
`;

const Copyright1 = styled.div`
  align-self: stretch;
  margin-top: 10px;
   font: 300 16px/140% 'Poppins', sans-serif;
    @media (max-width: 991px) {
    font: 300 12px 'Poppins', sans-serif;
    align-items: center; /* Horizontally center items */
    justify-content: center; /* Vertically center items */
  }
`;


const Version = styled.div`
  margin-top: 8px;
   margin-bottom: 5px;
   @media (max-width: 991px) {
     margin-bottom: 0px;
     font: 200 10px 'Poppins', sans-serif;
     align-items: center; /* Horizontally center items */
    justify-content: center; /* Vertically center items */
  }
`;

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0; /* Remove unwanted padding */
  margin: 0; /* Remove unwanted margin */

`;

const FooterWrapper = styled.div`
  background-color: rgb(49, 47, 47);
  display: flex;
  width: 100%;
  padding: 20px 0 0;
  flex-direction: column;


  @media (max-width: 991px) {
    padding: 10px 0 0;
  }
`;


const ContentWrapper = styled.div`
  display: flex;
  margin-left: 33px;
  
  
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
      margin-left: 0px;
  }
`;

const FooterContent = styled.div`
  
`;

const FooterGrid = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
  
    gap: 0;
  }
`;

const FooterBrandColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 24%;
  margin-left: 0;
  @media (max-width: 991px) {
    width: 100%;
    align-items: center; /* Horizontally center items */
  justify-content: center; /* Vertically center items */
  }
`;

const FooterLinksColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 76%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
      margin-left: 0px;
  }
`;

const FooterTagline = styled.div`
  color: rgb(255, 255, 255);
  letter-spacing: -0.16px;
  text-align: center;
  align-self: center;
  margin: 49px 0 0 101px;
  font: 600 16px/1.4 Poppins, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const FooterDivider = styled.div`
  min-height: 1px;
  margin-top: 22px;
  width: 100%;
  border: 1px solid rgb(222, 227, 235);
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default Footer;
