import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { search, youtube, arrow, user_help, mp3_thumbnail, info_icon, error_icon } from '../../assets/images';
import { useNavigate } from 'react-router-dom';
import Playlist from "./playlist";
import axios from "axios";
import { languages } from "../utils";
import { toast } from "react-toastify";


function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
}

function YouTubesearch({ onClose = () => { }, convertSong = () => { } }) {

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 991;

  const [hasLink, setHasLink] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [language, setLanguage] = useState('');

  const handleConvert = () => {
    convertSong(youtubeUrl, language)
    onClose();
  };

  const handleGoClick = async (e) => {
    e.preventDefault();
    if (!youtubeUrl.trim()) {
      return
    }
    try {
      const url = new URL(youtubeUrl)
      if (url.protocol !== "http:" && url.protocol !== "https:") {
        throw "Invalid Url"
      }
      const { data } = await axios.get(`https://noembed.com/embed?url=${youtubeUrl}`)
      setPreviewData({ title: data.title, image: data.thumbnail_url })
      setHasLink(true)
    } catch (error) {
      toast(<div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={error_icon}
          alt="check icon"
          style={{ marginRight: "8px", width: "24px", height: "24px" }}
        />
        <span style={{ flex: 1 }}>Invalid Url</span>
      </div>, { position: "bottom-left" }
      )
    }
  }

  return (
    <PopupOverlay>
      <Container>
        <CloseButton onClick={onClose}>×</CloseButton>
        <ContentWrapper>
          {hasLink ? <>
            <PreviewContainer>
              <PreviewRow>
                <VideoPreviewWrapper>
                  <VideoPreview>
                    <img src={previewData.image || mp3_thumbnail} alt="" width="100%" />
                    <div style={{ fontWeight: 600, color: "#fff", fontSize: "12px" }}>{previewData.title}</div>
                  </VideoPreview>
                </VideoPreviewWrapper>
                <div>
                  <div>
                    <div style={{ fontSize: "16px", fontWeight: 600, color: "#fff" }}>Select Language</div>
                    <select style={{
                      width: "100%",
                      height: "40px",
                      background: "#3A3B3B",
                      color: "#fff",
                      border: "none",
                      marginTop: "10px",
                      borderRadius: "4px",
                      padding: "8px"
                    }}
                      value={language}
                      onChange={e => { setLanguage(e.target.value) }}>
                      <option key='0' value='' >Auto Detect</option>
                      {languages.map((item, index) => (
                        <option key={index.toString()} value={item.code} >
                          {item.language}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    marginTop: "8px"
                  }}>
                    <img src={info_icon} alt="" width="12px" />
                    <div style={{ color: "#fff", fontSize: "10px", fontWeight: 400, lineHeight: "18px" }}>Language selection can improve the quality of the lyrics.</div>
                  </div>
                </div>
              </PreviewRow>
              <ButtonRow>
                <button style={{ background: "transparent", border: "none", color: 'white', fontSize: '18px' }} onClick={() => { setHasLink(false) }} >
                  Back
                </button>
                <button style={{
                  background: "#0072D5",
                  padding: "18px 50px",
                  borderRadius: "4px",
                  border: "none",
                  color: "#fff",
                  cursor: "pointer",
                  fontWeight: 600
                }}
                  onClick={handleConvert} >
                  Convert Song
                </button>
              </ButtonRow>
            </PreviewContainer>
          </> :
            <>
              <InputIcon1 loading="lazy" src={youtube} />
              <MainTitle> Paste the YouTube URL link
                {isMobile ? <><br /> to find the chords for<br /> your song</> : <> to find the<br /> chords for your song</>}</MainTitle>
              <FormContainer>
                <InputGroup>
                  <InputIcon loading="lazy" src={search} />
                  <InputField type="text" id="youtubeLink" value={youtubeUrl} placeholder="Paste your Youtube link here" onChange={e => { setYoutubeUrl(e.target.value) }} onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleGoClick(e)
                    }
                  }} />
                </InputGroup>
                {/* <SkipSection>
              <SkipLabel>Skip Vocal Separation</SkipLabel>
              <ToggleSwitch onClick={handleToggle}>
              <SwitchTrack isChecked={isChecked}>
                  <SwitchKnob isChecked={isChecked} />
                </SwitchTrack>
              </ToggleSwitch>

              <InfoButtonWrapper>
                <InfoIcon loading="lazy" src={user_help} />
                <Tooltip>
                  Vocal Separation can result in faster<br />
                  processing times althrough the quality<br />
                  may be affected
                  
                  </Tooltip>
                  <Imagearrow src={arrow} alt="Arrow" />
                  </InfoButtonWrapper>
                  </SkipSection> */}
                <Button type="button" onClick={handleGoClick}>
                  Go
                </Button>
              </FormContainer>
            </>
          }
        </ContentWrapper>
      </Container>
    </PopupOverlay>
  );
}

const PopupOverlay = styled.div`
    position: "fixed";
    height: "100vh";
    width: "100vw";
    zIndex: 10;
    display: "flex";
    justifyContent: "center";
    alignItems: "center";
    background: "#00000040";
`

const Container = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background-color: #121212;
  min-height: 464px;
  max-height: 80vh;
  width: 700px;
  padding: 20px;
  overflow-y: auto;

  @media (max-width: 991px) {
    padding: 10px;
    max-width: 85%;
    height:55%;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
`;

const Button = styled.button`
  font-family: Poppins, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #0072D5;
  color: #fff;
  font-weight: 600;
  font-size:16px;
  padding: 14px 12px;
  height:64px;
  width: 160px;
  cursor: pointer;
  border:none;

  @media (max-width: 991px) {
    padding-left: 20px;
    height:54px;
  }
`;


const ContentWrapper = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  overflox: auto;
  &::-webkit-scrollbar{
    display: none;
  }
`;

const MainTitle = styled.h1`
  color: #fff;
  text-align: center;
  letter-spacing: -0.28px;
  align-self: center;
  font: 600 28px/120% Poppins, sans-serif;

  @media (max-width: 991px) {
    font: 600 18px/120% Poppins, sans-serif;
   
  }
`;

const FormContainer = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 14px;
  gap: 24px;
  width:80%;
  

  @media (max-width: 991px) {
    flex-wrap: wrap;
    margin-top: 20px;
    width:100%;
    flex-direction: column;
    margin-left:0px;
   
  }
`;

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
  gap: 10px;
  color: #51606f;
  font-weight: 300;
  padding: 14px 12px;
  width:100%;

  @media (max-width: 991px) {
    display: flex;
    width:90%;
    flex-direction: row;
   
  }
`;

const InputIcon = styled.img`
  aspect-ratio: 1;
  width: 32px;
 
`;

const InputIcon1 = styled.img`
margin-top:20px;
  align-items: center;
  width: 120px;
  @media (max-width: 991px) {
    width: 90px;
   
  }
 
`;



const InputField = styled.input`
  border: none;
 
  outline: none;
  font: inherit;
  color: inherit;
  background: transparent;
  width: 100%;
`;

const SkipSection = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: end;
  margin: auto 0;
`;

const SkipLabel = styled.label`
  color:white;
  align-self: center;
  font: 600 13px Nunito, sans-serif;
  white-space: nowrap; /* Prevents text wrapping */
  overflow: hidden; /* Hides overflow text */
  text-overflow: ellipsis; /* Adds ellipsis if text overflows */
`;

const ToggleSwitch = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 36px;
  margin: auto 0;
  padding: 3px 0;
`;

const SwitchTrack = styled.div`
  border-radius: 360px;
  background: ${({ isChecked }) => (isChecked ? '#0072D5' : '#b9c8da')}; // Change color based on state
  display: flex;
  flex-direction: column;
  align-items: start;
  cursor: pointer;
`;

const SwitchKnob = styled.div`
  filter: drop-shadow(-1px 1px 1px rgba(51, 51, 51, 0.25));
  background-color: #fff;
  border-radius: 50%;
  z-index: 10;
  display: flex;
  width: 20px;
  height: 20px;
  transform: ${({ isChecked }) => (isChecked ? 'translateX(20px)' : 'translateX(0)')}; // Move knob based on state
  transition: transform 0.3s ease;
`;

const InfoIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 30px;
  align-self: stretch;
  margin: auto 0;
`;

const Imagearrow = styled.img`
  width: 20px;
  height: 40px;
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute;
  top: 22px; 
  left: 26%;
  transform: rotate(180deg)
`;

const Tooltip = styled.div`
  position: absolute;
  top: 150%; 
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: black;
  border: 2px solid #fff;
  padding: 8px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none; 
  z-index: 1;
  font-size: 12px;
`;

const InfoButtonWrapper = styled.div`
  position: relative;
  display: inline-block;

  &:hover ${Tooltip} {
    opacity: 1;
  }

  &:hover ${Imagearrow} {
    opacity: 1;
  }
`

const PreviewContainer = styled.div`
 width: 90%;

 @media (max-width: 500px){
  margin-top: 50px;
 }
`

const PreviewRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  gap: 16px;

  @media (max-width: 500px){
    flex-direction: column;
    gap: 32px;
    margin-top: 24px;
  }
`

const VideoPreviewWrapper = styled.div`
  width: 50%;

  @media (max-width: 500px){
    width: 100%;
  }
`

const VideoPreview = styled.div`
width: 100%;
margin-top: 10px;

@media (max-width:500px){
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  text-align: center;
  width: 100%;

  img{
    width: 70%;
  }
}
`
const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 10%;

  @media (max-width: 500px){
    flex-direction: column-reverse;
    gap: 24px;
    margin-bottom: 16px;
  }
`

export default YouTubesearch;