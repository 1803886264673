import React, { useState, useEffect, useRef } from "react";
import { styled, keyframes } from "styled-components";
import { failed, asc, dsc, add_new_songs, next, pre, sort, mp3_thumbnail, del_card_icon, failed_down, white_close,info_icon, upload_icon,close_icon } from "../../assets/images";
import Footer from "../footer_lan";
import Pre_nav from "../navigation/premium_nav";
import axios from "axios";
import { getToken, getLoadingStatus, clearToken } from "../utils";
import config from "../../config";
import { useLocation, useNavigate } from "react-router-dom";
import YouTubesearch from "./search_popup";
import Pricing2 from "./pricing_popup";
import { getfirstname, getlastname } from "../utils";
import { ToastContainer, toast } from "react-toastify";
import tick_snack_bar from "../../assets/images/tick_snack_bar.svg";
import arrow_drop_down from "../../assets/images/arrow_drop_down.svg";
import sort_by_descending from "../../assets/images/sort_by_descending.svg";
import sort_by_ascending from "../../assets/images/sort_by_ascending.svg";
import down_arrow from "../../assets/images/down_arrow.svg";
import right_arrow from "../../assets/images/right_arrow.svg";
import sort_ascending from "../../assets/images/sort_ascending.svg";
import sort_descending from "../../assets/images/sort_descending.svg";
import { languages } from "../utils";


// import ArrowRightIcon from '@rsuite/icons/ArrowRight';

import {
  Circle2,
  SpinnerContainer,
  Logo,
  LoadingOverlay1,
  LoadingText2,
  SongInfo2,
  SongTitle2,
  Spinner,
  Overlay,
} from "../../assets/styles/premium_user_styled";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import close_snack_bar from "../../assets/images/close_snack_bar.svg";

const CustomSnackbar = styled(Snackbar)(({ theme }) => ({
  "& .MuiSnackbarContent-root": {
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    color: "black",
  },
  "& .MuiSnackbarContent-message": {
    display: "flex",
    alignItems: "center",
  },
}));

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
}

const Playlist = () => {

  const fileInputRef = useRef(null);

  const location = useLocation();
  const [disabledRetryLogos, setDisabledRetryLogos] = useState({});
  const [viewall, setviewall] = useState(true);
  const token = getToken();
  const [songs, setSongs] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 991;
  if (token == null) {
    navigate("/");
  }
  const { name_ply } = location.state || {};
  const title_name = name_ply;
  const name = encodeURIComponent(name_ply);
  const [call_back, setcall_back] = useState(false);
  const [localLoadingStatus, setLocalLoadingStatus] = useState(
    getLoadingStatus()
  );
  const [failedRequests, setFailedRequests] = useState({});
  const [failedRequests_download, setFailedRequests_download] = useState({});
  const [showOverlay, setShowOverlay] = useState(false);
  const [chevron, setChevron] = useState({});
  const [progressWidth, setProgressWidth] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [showSortBy, setShowSortBy] = useState("Sort by")

  const [nextKeys, setNextKeys] = useState([]);
  const [open1, setOpen1] = useState(false);

  const [nextKeys1, setNextKeys1] = useState("");

  const [currentPageIndex, setCurrentPageIndex] = useState(-1);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortOrder1, setSortOrder1] = useState("asc");
  const [pre1, setpre] = useState(false);
  const [error, setError] = useState("");
  const [allsongs, setallsongs] = useState("");
  const youtubeLink1 = localStorage.getItem("youtubeLink");
  const [id_snack_bar, setid_snack_bar] = useState("");
  const [url_snack_bar, seturl_snack_bar] = useState("");
  const [pricingVisible, setpricingVisible] = useState(false);
  const [down, setDown] = useState(false);
  const [delete_failed, setdelete_failed] = useState("");

  const [manual_upload_popup, setmanual_upload_popup] = useState(false);
  const [popupVisible, setPopupVisible] = useState(null);
  const [uploadFiles, setUploadFiles] = useState(null);

  const [previewData, setPreviewData] = useState(null);
  const [language, setLanguage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  let id_snack_bar1;
  let url_snack_bar1;

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const toggleDropdown1 = () => {
    setShowDropdown1(!showDropdown1);
  };
  const toggleDropdown2 = () => {
    setShowDropdown2(!showDropdown2);
  };
  useEffect(() => {
    fetchPlaylistSongs();
  }, []);
  useEffect(() => {
    const handlePopState = () => {
      setviewall(false); // Set viewall to false when the back button is clicked
    };

    // Listen for the popstate event
    window.addEventListener("popstate", handlePopState);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);


  const showToast = () => {
    toast(<CustomToast />, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleCopyLink = () => {
    // setOpen(true);
    showToast();
  };

  const delete_failed_cards = (id, e) => {
    e.stopPropagation();
    setdelete_failed(id);
    setDown(true);
  };

  const handlebutton = (event, reason) => {
    // setOpen(false);
    // setOpen1(false);
    if (id_snack_bar.text) {
      handleSongCardClick(id_snack_bar, url_snack_bar);
    } else {
      handleSongCardClick(id_snack_bar1, url_snack_bar1);
    }
  };

  const CustomToast = ({ closeToast }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {/* <FaUser style={{ marginRight: '10px' }} /> */}
      <img
        src={tick_snack_bar}
        alt="check icon"
        style={{ marginRight: "8px", width: "24px", height: "24px" }}
      />

      <span style={{ flex: 1 }}>Chord Generated</span>
      <button
        onClick={handlebutton}
        style={{
          marginLeft: "10px",
          backgroundColor: "#F0984B",
          color: "#FFFFFF",
          border: "none",
          borderRadius: "4px",
          padding: "5px 10px",
          cursor: "pointer",
        }}
      >
        Go to Chords
      </button>
    </div>
  );

  const handlepricingClick = () => {
    setpricingVisible(true);
  };
  const handlepricingClose = () => {
    setpricingVisible(false);
  };

  // useEffect(() => {

  //   if (youtubeLink1) {
  //     localStorage.removeItem("youtubeLink");
  //     Get_chords1(youtubeLink1);
  //     // fetchPlaylistSongs();
  //   }
  // }, [youtubeLink1]);
  const triggerUpload = () => {
    fileInputRef.current.click();
  }

  const fileDownload = async (urls, fileName) => {
    const nameWithoutExt = fileName.substring(0, fileName.lastIndexOf(".")) || fileName;
    try {
      const response = await axios.post(`${config.apiUrl}/url_requests/process_upload`, {
        url: urls.download_url,
        title: nameWithoutExt,
        lang_code: language
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        // onUploadClicked();
        fetchPlaylistSongs();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
    setLanguage("en")
  }

  const fileUpload = async (urls, file) => {
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.put(urls.upload_url, file, {
        headers: {
          'Content-Type': 'audio/mpeg'
        }
      });

      if (response.status === 200) {
        fileDownload(urls, file.name);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
    setLoading(false);
  }

  const handlePopupSubmit = () => {
    if (popupVisible === "upload") {
      getUploadUrls()
    } else {
      const value = searchTerm;
      const lang = language;
      // onGoClicked(value,lang);
      setSearchTerm("");
      setPreviewData(null);
      setLanguage("en")
    }
    setPopupVisible(null)
  }

  const getUploadUrls = async () => {

    if (!uploadFiles) {
      return
    }

    try {
      const response = await axios.get(
        `${config.apiUrl}/url_requests/upload_urls`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        fileUpload(response.data, uploadFiles);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
    // fileInputRef.current.target.value = ''
    setPopupVisible(null)
  }

  const Get_chords1 = async (url = '', lang_code = '') => {
    //setLoading(true);
    try {
      const response = await axios.post(
        `${config.apiUrl}/url_requests/`,
        {
          url,
          lang_code
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );



      if (response.status === 200 && response.data.request_id) {
        if (call_back === false) {
          fetchPlaylistSongs();
        }

        if (response.data.state === "complete") {
          setid_snack_bar(response.data.request_id);
          seturl_snack_bar(response.data.url);
          id_snack_bar1 = response.data.request_id;
          url_snack_bar1 = response.data.url;

          handleCopyLink();
          localLoadingStatus[response.data.request_id] = false;
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
      if (error.response && error.response.status === 400) {
        // setError('You have already requested this URL!');

        if (error.response.data.message == "Subscription limit reached!") {
          handlepricingClick();
        }
        handleCopyLink1(error.response.data.message);

        // Handle error
      }
    }
  };

  const handleCopyLink1 = (data) => {
    // setOpen1(true);
    showToast1(data);
  };

  const showToast1 = (data) => {
    toast(<CustomToast1 data1={data} />, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const CustomToast1 = ({ data1 }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={tick_snack_bar}
        alt="check icon"
        style={{ marginRight: "8px", width: "24px", height: "24px" }}
      />

      <span style={{ flex: 1 }}>{data1}</span>
    </div>
  );



  function formatDate(dateString) {
    // Split the date string by '/' and create a Date object
    const [day, month, year] = dateString.split('/');
    const date = new Date(year, month - 1, day); // Month is 0-indexed in JavaScript

    // Array of month names
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    // Format the day with 'th', 'st', 'nd', 'rd'
    const dayWithSuffix = day + (day.endsWith('1') && day !== '11' ? 'st' :
      day.endsWith('2') && day !== '12' ? 'nd' :
        day.endsWith('3') && day !== '13' ? 'rd' : 'th');

    // Return the formatted date string
    return `${monthNames[month - 1]} ${dayWithSuffix} ${year}`;
  }

  const fetchPlaylistSongs = async () => {
    // setLoading(true);
    try {
      const response = await axios.get(
        `${config.apiUrl}/playlists/__default_playlist__?sort_direction=desc`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        let hasInProgress = false;
        const nextPageKey = response.headers["x-next-page-key"] || null;
        setNextKeys((prevState) => [...prevState, nextPageKey]);
        setNextKeys1(nextPageKey);
        setallsongs(response.data.playlist.total_songs);
       
        const mappedRecords = response.data.records.map((record) => {
          const thumbnailUrl = record.thumbnail_url.split("?")[0];
          const song = {
            title: record.title,
            requestId: record.request_id,
            url: record.url,
            thumbnail_url: thumbnailUrl,
            state: record.state,
            created_at: formatDate(record.created_at),
          };

          if (record.state === "failed") {
            localLoadingStatus[record.request_id] = false;
            failedRequests[record.request_id] = true;
            setDisabledRetryLogos((prev) => ({ ...prev, [record.request_id]: false }));

          } else if (record.state === "failed_download") {
            localLoadingStatus[record.request_id] = false;
            failedRequests_download[record.request_id] = true;
            setDisabledRetryLogos((prev) => ({
              ...prev,
              [record.request_id]: false,
            }));
          } else if (record.state === "complete") {
            localLoadingStatus[record.request_id] = false;
            // Don't set call_back here because we need to check other songs first

          } else if (record.state === "pending" || record.state === "in_progress") {
            localLoadingStatus[record.request_id] = true;
            hasInProgress = true;  // Mark that a song is in progress

            const step = record.processing_step;
            let progressPercentage;
            let text;
            switch (step) {
              case "generating_mp3":
                progressPercentage = 10;
                text = "Generating Mp3";
                break;
              case "generating_midi":
                progressPercentage = 25;
                text = "Generating Midi";
                break;
              case "transcribing_audio":
                progressPercentage = 50;
                text = "Transcribing Audio";
                break;
              case "generating_chords":
                progressPercentage = 75;
                text = "Generating Chords";
                break;
              case "finalizing":
                progressPercentage = 95;
                text = "Finalizing";
                break;
              case "complete":
                progressPercentage = 100;
                text = "Complete";
                break;
              default:
                progressPercentage = 0;
                text = "";
            }

            setProgressWidth((prev) => ({
              ...prev,
              [record.request_id]: progressPercentage,
            }));
            setChevron((prev) => ({ ...prev, [record.request_id]: text }));
          }

          return song;
        });
        setcall_back(hasInProgress);
        setSongs(mappedRecords);
        setLocalLoadingStatus((prevStatus) => ({
          ...prevStatus,
          ...localLoadingStatus,
        }));
        setFailedRequests((prev) => ({ ...prev, ...failedRequests }));
        setFailedRequests_download((prev) => ({ ...prev, ...failedRequests_download }));
        if (hasInProgress) {

          setTimeout(() => fetchPlaylistSongs(), 5000);

        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSongCardClick = async (requestId, url) => {
    // setLoading(true);
    try {
      const encodedRequestId = encodeURIComponent(requestId);
      const response_status = await axios.get(
        `${config.apiUrl}/url_requests/${encodedRequestId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response_status.status === 200) {
        if (response_status.data.state === "complete") {
          setLoading(true);
          visitchords(encodedRequestId, response_status.data, response_status.data.youtube_id);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };

  const first_name = getfirstname();
  const last_name = getlastname();
  const initials = first_name && last_name ? `${first_name}_${last_name}` : 'Unknown';

  const visitchords = async (requestId, responsed, youtube_id) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${config.apiUrl}/playlists/__default_playlist__/${requestId}/visit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        window.scrollTo(0, 0);
        // navigate("/chords", { state: { result1: responsed } });
        navigate(`/chords/${initials}/youtube/${youtube_id}`, { state: { hiddenParam: 'all_songs' } });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };

  const retry = async (id, e) => {
    e.stopPropagation();
    const encodedRequestId1 = encodeURIComponent(id);

    try {
      setLoading(true);


      setDisabledRetryLogos((prev) => ({ ...prev, [id]: true }));



      const response = await fetch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/retry`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setFailedRequests(prevFailedRequests => {
          const updatedFailedRequests = { ...prevFailedRequests };
          delete updatedFailedRequests[id];
          return updatedFailedRequests;
        });
        setFailedRequests_download((prevFailedRequests) => {
          const updatedFailedRequests = { ...prevFailedRequests };
          delete updatedFailedRequests[id];
          return updatedFailedRequests;
        });
        if (call_back === false) {
          fetchPlaylistSongs();
        }
        // fetchPlaylistSongs();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };


  const next_page = async (pageKey) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${config.apiUrl}/playlists/__default_playlist__?sort_direction=desc`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "x-page-key": pageKey,
          },
        }
      );

      if (response.status === 200) {

        const nextPageKey = response.headers["x-next-page-key"] || null;
        setNextKeys((prevState) => [...prevState, nextPageKey]);
        setNextKeys1(nextPageKey);
        const mappedRecords = response.data.records.map((record) => {
          const thumbnailUrl = record.thumbnail_url.split("?")[0];
          return {
            title: record.title,
            requestId: record.request_id,
            url: record.url,
            thumbnail_url: thumbnailUrl,
            created_at: formatDate(record.created_at),
          };
        });

        setSongs(mappedRecords);
        // adv_next_page(nextPageKey);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };

  const adv_next_page = async (pageKey) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${config.apiUrl}/playlists/__default_playlist__`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "x-page-key": pageKey,
          },
        }
      );

      if (response.status === 200) {
        const nextPageKey = response.headers["x-next-page-key"] || null;
        setNextKeys1(nextPageKey);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };

  const nextPage = () => {
    if (currentPageIndex < nextKeys.length - 1) {
      const nextPageKey = nextKeys[currentPageIndex + 1];
      setCurrentPageIndex((prevIndex) => prevIndex + 1);
      setpre(true);
      next_page(nextPageKey);
    }
  };

  const previousPage = () => {
    if (currentPageIndex > 0) {
      const prevPageKey = nextKeys[currentPageIndex - 1];
      setCurrentPageIndex((prevIndex) => prevIndex - 1);
      next_page(prevPageKey);
    } else {
      setCurrentPageIndex(-1);
      if (call_back === false) {
        fetchPlaylistSongs();
      }
      // fetchPlaylistSongs();
      setpre(false);
    }
  };



  const Dropdown = ({ showDropdown, toggleDropdown }) => {
    const [searchVisible, setsearchVisible] = useState(false);
    const [showCreatedSubDropdown, setShowCreatedSubDropdown] = useState(false);
    const [showPlayedSubDropdown, setShowPlayedSubDropdown] = useState(false);

    const recent_play = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${config.apiUrl}/playlists/__default_playlist__?&sort_by=visited_timestamp`,
          {
            params: { sort_direction: "asc" },
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          const mappedRecords = response.data.records.map((record) => {
            const thumbnailUrl = record.thumbnail_url.split("?")[0];
            return {
              title: record.title,
              requestId: record.request_id,
              url: record.url,
              thumbnail_url: thumbnailUrl,
              created_at: formatDate(record.created_at),
            };
          });

          setSongs(mappedRecords);
          setSortOrder("asc");
          setShowSortBy("Recently Played Ascending");
          setShowDropdown(false);
          // if (sortOrder1 == "asc") {
          //   setSortOrder1("desc");
          //   setShowSortBy("recent play descending");
          // } else if (sortOrder1 == "desc"){
          //   setSortOrder1("asc");
          //   setShowSortBy("recent play ascending");
          // }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        }
      } finally {
        setLoading(false);
      }
    };

    const recent_play1 = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${config.apiUrl}/playlists/__default_playlist__?&sort_by=visited_timestamp`,
          {
            params: { sort_direction: "desc" },
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          const mappedRecords = response.data.records.map((record) => {
            const thumbnailUrl = record.thumbnail_url.split("?")[0];
            return {
              title: record.title,
              requestId: record.request_id,
              url: record.url,
              thumbnail_url: thumbnailUrl,
              created_at: formatDate(record.created_at),
            };
          });

          setSongs(mappedRecords);
          setSortOrder("desc");
          setShowSortBy("Recently Played Descending");
          setShowDropdown(false);
          // if (sortOrder1 == "asc") {
          //   setSortOrder1("desc");
          //   setShowSortBy("recent play descending");
          // } else if (sortOrder1 == "desc"){
          //   setSortOrder1("asc");
          //   setShowSortBy("recent play ascending");
          // }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        }
      } finally {
        setLoading(false);
      }
    };

    // Fetch Created Date Sorted Songs
    const created_date = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${config.apiUrl}/playlists/__default_playlist__?&sort_by=sk`,
          {
            params: { sort_direction: "asc" },
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          const mappedRecords = response.data.records.map((record) => {
            const thumbnailUrl = record.thumbnail_url.split("?")[0];
            return {
              title: record.title,
              requestId: record.request_id,
              url: record.url,
              thumbnail_url: thumbnailUrl,
              created_at: formatDate(record.created_at),
            };
          });
          setSongs(mappedRecords);
          setSortOrder("asc");
          setShowSortBy("Created Date Ascending");
          setShowDropdown(false);

          // setSortOrder(sortOrder === "asc" ? "desc" : "asc");
          // if (sortOrder1 == "asc"){
          //   setSortOrder1("desc");
          //   setShowSortBy("Date ascending");
          // } else if (sortOrder1 == "desc"){
          //   setSortOrder1("asc");
          //   setShowSortBy("Date descending");
          // }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        }
      } finally {
        setLoading(false);
      }
    };
    const created_date1 = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${config.apiUrl}/playlists/__default_playlist__?&sort_by=sk`,
          {
            params: { sort_direction: "desc" },
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          const mappedRecords = response.data.records.map((record) => ({
            title: record.title,
            requestId: record.request_id,
            url: record.url,
            thumbnail_url: record.thumbnail_url.split("?")[0],
            created_at: record.created_at,
          }));
          setSongs(mappedRecords);
          setSortOrder("desc");
          setShowSortBy("Created Date Descending");
          setShowDropdown(false);
          //   setShowSortBy("Date ascending");
          // setSortOrder(sortOrder === "asc" ? "desc" : "asc");
          // if (sortOrder1 == "asc") {
          //   setSortOrder1("desc");
          //   setShowSortBy("Date ascending");
          // } else if (sortOrder1 == "desc"){
          //   setSortOrder1("asc");
          //   setShowSortBy("Date descending");
          // }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        }
      } finally {
        setLoading(false);
      }
    };

    const handleyouClick = () => setsearchVisible(true);
    const handleyouClose = () => setsearchVisible(false);

    const toggleCreatedSubDropdown = () => setShowCreatedSubDropdown(!showCreatedSubDropdown);
    const togglePlayedSubDropdown = () => setShowPlayedSubDropdown(!showPlayedSubDropdown);

    const id = "playlist";

    return (
      <DropdownContainer>

        <StyledIcon onClick={toggleDropdown}>
          {sortOrder == "asc" ? <IconDis loading="lazy" src={sort_by_ascending} alt="sort" /> :
            <IconDis loading="lazy" src={sort_by_descending} alt="sort" />}

          <SortByText> {showSortBy} </SortByText>
          <Icon loading="lazy" src={down_arrow} alt="sort" />
        </StyledIcon>

        <DropdownContent show={showDropdown}>
          {/* Main Dropdown Item for Created Date */}
          <DropdownItem onClick={toggleCreatedSubDropdown}>
            Created Date <Icon loading="lazy" src={right_arrow} alt="Dropdown Icon" />
          </DropdownItem>

          {/* Sub-dropdown for Created Date Sorting */}
          {showCreatedSubDropdown && (
            <DropdownContent1 show={true}>
              <DropdownItem onClick={created_date}><Icon loading="lazy" src={sort_ascending} alt="sort" />Sort by Ascending&nbsp;&nbsp;</DropdownItem>
              <DropdownItem onClick={created_date1}><Icon loading="lazy" src={sort_descending} alt="sort" />Sort by Descending</DropdownItem>
            </DropdownContent1>
          )}
          {/* Main Dropdown Item for Recently Played */}
          <DropdownItem onClick={togglePlayedSubDropdown}>
            Recently Played <Icon loading="lazy" src={right_arrow} alt="Dropdown Icon" />
          </DropdownItem>
          {/* Sub-dropdown for Recently Played Sorting */}
          {showPlayedSubDropdown && (
            <DropdownContent1 show={true}>
              <DropdownItem onClick={recent_play}><Icon loading="lazy" src={sort_ascending} alt="sort" />Sort by Ascending&nbsp;&nbsp;</DropdownItem>
              <DropdownItem onClick={recent_play1}><Icon loading="lazy" src={sort_descending} alt="sort" />Sort by Descending</DropdownItem>
            </DropdownContent1>
          )}
        </DropdownContent>
        {/* Conditional Popup for YouTube Search */}
        {searchVisible && (
          <PopupOverlay>
            <YouTubesearch onClose={handleyouClose} convertSong={Get_chords1} />
          </PopupOverlay>
        )}

        {/* Add new song button */}
        <Newsong src={add_new_songs} onClick={handleyouClick} />
      </DropdownContainer>
    );
  };



  function Downgrade({ onClose }) {
    const token = getToken();

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const delete_song = async () => {
      const encodedRequestId1 = encodeURIComponent(delete_failed);
      // setLoading(true);
      // console.log(delete_failed);

      try {
        const response = await axios.delete(
          `${config.apiUrl}/url_requests/${encodedRequestId1}`,

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          onClose();
          setdelete_failed("");
          window.location.reload();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        }
      } finally {
        setLoading(false);
      }
    };

    const ActionButton = ({ text, primary, onClick }) => {
      return (
        <Button10 primary={primary} onClick={onClick}>
          {text}
        </Button10>
      );
    };

    return (
      <Modal>
        <HeaderContainer1>
          <WarningText>Warning</WarningText>
          <CloseIcon
            onClick={onClose}
            loading="lazy"
            src={white_close}
            alt="Close"
          />
        </HeaderContainer1>
        <Content1>Are you sure you want to delete this song?</Content1>
        <ActionButton text="Confirm" primary onClick={delete_song} />
        <ActionButton text="Cancel" onClick={onClose} />
      </Modal>
    );
  }


  const Manual_Upload = ({ onClose }) => {
    const navigate = useNavigate();
    const token = getToken();



    return (
      <FormContainer4>
        <Form4>
          <HeaderContainer4>
            <Title01>Download Error</Title01>
            <CloseButton onClick={onClose}>×</CloseButton>
          </HeaderContainer4>

          <Content4>
            Jammin.ai encountered an error while processing the song from YouTube. You can fix this by downloading the song manually from{' '}
            <a
              href="https://y2mate.lol/en158/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'white', textDecoration: 'underline', textDecorationColor: 'white' }}
            >
              y2mate.com
            </a>{' '}
            and then using the Upload option to upload the mp3.
          </Content4>



          <UploadContainer>
            <UploadButton4 onClick={() => {
              setPopupVisible("upload");
              setmanual_upload_popup(false);
            }}>

              <img src={upload_icon} alt="" />
              <span>Upload song</span>
            </UploadButton4>
          </UploadContainer>



        </Form4>
      </FormContainer4>
    );
  };


  const ImageComponent = ({ next, pre }) => (
    <ImageContainer>
      {pre1 && <NavImage src={pre} alt="Previous" onClick={previousPage} />}
      {nextKeys1 !== null && (
        <NavImage src={next} alt="Next" onClick={nextPage} />
      )}
    </ImageContainer>
  );

  return (
    <AppContainer>
      <Pre_nav />
      <Overlay loading={loading}>
        <Spinner />

      </Overlay>
      <SongListContainer>
        <Header>
          <Vertical_row>
          <MainTitle>My Music</MainTitle>
          <MainTitle1>{allsongs} songs</MainTitle1>
          </Vertical_row>

          <Dropdown
            showDropdown={showDropdown}
            toggleDropdown={toggleDropdown}
          />
        </Header>
       

        {error && <ErrorToast>{error}</ErrorToast>}
        {pricingVisible && (
          <PopupOverlay>
            <Pricing2 onClose={handlepricingClose} />
          </PopupOverlay>
        )}

        {down && (
          <PopupOverlay>
            <Downgrade onClose={() => setDown(false)} />
          </PopupOverlay>
        )}


        {popupVisible !== null && <div style={{
          position: "fixed",
          height: "100vh",
          width: "100vw",
          top: "0",
          zIndex: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#00000040",

        }}>
          <UploadPopup>
            <input ref={fileInputRef} type="file" style={{ display: "none" }} onChange={e => { setUploadFiles(e.target.files[0]) }} />
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div></div>
              <div style={{ fontSize: "20px", fontWeight: 600, color: "#fff" }}>{popupVisible === "upload" ? 'Upload Song' : 'Convert Song'}</div>
              <span onClick={() => setPopupVisible(null)} style={{ cursor: "pointer" }}>
                <img src={close_icon} alt="" onClick={() => {
                  setUploadFiles(null);
                  setPreviewData(null);
                }} />
              </span>
            </div>
            <FormContainer45>
              <div>
                <div style={{ fontSize: "16px", fontWeight: 600, color: "#fff" }}>
                  {popupVisible === "upload" ? 'Choose Audio File' : ''}
                </div>
                {popupVisible === "upload" ? <button style={{
                  padding: "14px 24px",
                  borderRadius: "4px",
                  background: "#fff",
                  marginTop: "10px",
                  cursor: "pointer",
                  border: "none",
                  fontSize: "10px",
                  fontStyle: "italic"
                }} onClick={triggerUpload} >
                  {uploadFiles ? uploadFiles.name : 'Choose a file'}
                </button>
                  :
                  <VideoPreview>
                    <img src={previewData.image || mp3_thumbnail} alt="" width="100%" />
                    {/* <div style={{ fontWeight: 600, color: "#fff", fontSize: "12px" }}>{previewData.title}</div> */}
                  </VideoPreview>
                }
              </div>
              <div style={{ padding: '12px 0' }}>
                <div>
                  <div style={{ fontSize: "16px", fontWeight: 600, color: "#fff" }}>Select Language</div>
                  <select style={{
                    width: "100%",
                    height: "40px",
                    background: "#3A3B3B",
                    color: "#fff",
                    border: "none",
                    marginTop: "10px",
                    borderRadius: "4px",
                    padding: "8px"
                  }}
                    value={language}
                    onChange={e => { setLanguage(e.target.value) }}>
                    <option key='0' value='' >Auto Detect</option>
                    {languages.map((item, index) => (
                      <option key={(index + 1).toString()} value={item.code} >
                        {item.language}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  marginTop: "12px"
                }}>
                  <img src={info_icon} alt="" width="12px" />
                  <div style={{ color: "#fff", fontSize: "10px", fontWeight: 400, lineHeight: "18px" }}>Language selection can improve the quality of the lyrics.</div>
                </div>
              </div>
            </FormContainer45>
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <button style={{
                background: "#0072D5",
                padding: "18px 50px",
                borderRadius: "4px",
                border: "none",
                color: "#fff",
                cursor: "pointer",
                fontWeight: 600
              }}
                onClick={handlePopupSubmit} >
                {popupVisible === "upload" ? 'Upload Song' : 'Convert Song'}
              </button>
            </div>
          </UploadPopup>
        </div>
        }



        {manual_upload_popup && (
          <PopupOverlay>
            <Manual_Upload onClose={() => setmanual_upload_popup(false)} />
          </PopupOverlay>
        )}

        <SongGrid>
          {songs.map((song) => (
            <SongCardContainer
              key={song.requestId}
              onClick={() => handleSongCardClick(song.requestId, song.url)}
            >
              <AlbumCover
                loading="lazy"
                src={song.thumbnail_url || mp3_thumbnail}
                alt={`Album cover for ${song.title}`}
              />
              {localLoadingStatus[song.requestId] && (
                <LoadingOverlay3 show={true}>
                  <SpinnerContainer>
                    <Circle2 progress={progressWidth[song.requestId]}></Circle2>
                  </SpinnerContainer>
                  <LoadingText>{chevron[song.requestId]}</LoadingText>
                </LoadingOverlay3>
              )}
              {failedRequests[song.requestId] && !disabledRetryLogos[song.requestId] && (
                <LoadingOverlay3>
                  <DeleteIcon
                    src={del_card_icon}
                    onClick={(e) => delete_failed_cards(song.id, e)}
                  />
                  <SpinnerContainer>
                    <Logo
                      loading="lazy"
                      src={failed}
                      alt="Logo"
                      onClick={(e) => retry(song.requestId, e)}
                    />
                  </SpinnerContainer>
                  <LoadingText2>Failed</LoadingText2>
                </LoadingOverlay3>
              )}

              {failedRequests_download[song.requestId] &&
                !disabledRetryLogos[song.requestId] && (
                  <LoadingOverlay3>
                    <DeleteIcon
                      src={del_card_icon}
                      onClick={(e) => delete_failed_cards(song.requestId, e)}
                    />
                    <SpinnerContainer>
                      <Logo
                        loading="lazy"
                        src={failed_down}
                        alt="Logo"

                      />
                    </SpinnerContainer>
                    <LoadingText2 >
                      Download Error
                      <FixText onClick={() => setmanual_upload_popup(true)}>Fix this</FixText>
                    </LoadingText2>
                  </LoadingOverlay3>
                )}

              <SongInfo2>
                <SongTitle2>{song.title}</SongTitle2>
                <Songdate>Created date :  {song.created_at}</Songdate>
              </SongInfo2>
            </SongCardContainer>
          ))}
        </SongGrid>

      </SongListContainer>
      <ImageComponent next={next} pre={pre} />
      <ToastContainer />
      <Footer />
    </AppContainer>
  );
};

export default Playlist;

const Songdate = styled.h5`
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  margin: 0;
  margin-top:7px;
  font-size: 13px; 
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2; 
  max-height: calc(1.2em * 2); 
 
  
  @media (max-width: 991px) {
    font-size: 12px;
  }
`;

const PopupOverlay = styled.div`
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
background: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
z-index: 1000; /* Ensure it's above other content */
`;


const AppContainer = styled.div`
  display: flex;
  flex-direction: column;

`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #020f12;
  width: 100%;
  padding: 10px 0;
   @media (max-width: 991px) {
     padding: 10px 0;
  }
`;

const NavImage = styled.img`
  margin: 0 10px;
  cursor: pointer;
  margin-bottom:30px;
   @media (max-width: 991px) {
      margin-bottom:10px;
  }
`;
const Newsong = styled.img`
  
  cursor: pointer;
  object-fit: contain;
  width:190px;
  @media (max-width: 991px) {
    width: 110px;
  }

`;
// Styles (should be in the same file or imported from '../../assets/styles/premium_user_styled')
const LoadingOverlay3 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 88.5%;
  border-radius:8px;
  background: rgba(0, 0, 0, 0.8);
  display: ${(props) => (props.show ? "flex" : "none")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 2;
   @media (max-width: 991px) {
      width: 100%;
      height: 100%;
  }
  
`;

const StyledSortButton = styled.button`
  justify-content: space-between;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(29, 46, 54, 0.08),
    0 1px 6px 0 rgba(214, 228, 235, 0.4);
  background-color: var(--material-theme-white, #fff);
  display: flex;
  gap: 10px;
  font-size: 16px;
  color: var(--material-theme-ref-secondary-secondary40, #51606f);
  font-weight: 400;
  letter-spacing: 0.25px;
  margin: auto 0;
  height: 40px;
  padding: 8px 32px;
  border: 1px solid rgba(222, 227, 235, 1);
  cursor: pointer;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  justify-content: end;
  font-size: 24px;
  color: white;
`;
const IconDis = styled.img`
  width: 30px;
  height: 30px;
  justify-content: end;
  font-size: 24px;
  color: white;
   @media (max-width: 991px) {
    width:15px;
    height:15px;
  }
`;

const StyledSortButton1 = styled.div`

 
  cursor: pointer;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const StyledSortButton2 = styled.div`
  justify-content: space-between;
  
  height: 40px;
 
  cursor: pointer;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const StyledText = styled.span`
  font-family: Gilroy-SemiBold, sans-serif;
`;

const StyledIcon = styled.div`
  width: auto;
  cursor: pointer;
  height: 55px; /* Corrected typo */
  padding: 0px 10px ;
  background-color: white;
  display: flex;  
  justify-content:space-around;  
  align-items: center; 
  border-radius:8px;
  @media (max-width: 991px) {
    width: auto;
    height:30px;
    border-radius:4px;

  }
`;

const SortByText = styled.h4`
  color: black;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  margin: 0 10px;
  white-space: nowrap; /* Ensures text doesn't wrap */
  overflow: hidden; /* Optional: hides overflow if the text is too long */
  text-overflow: ellipsis; /* Optional: shows "..." if text overflows */
  
  @media (max-width: 991px) {
    font-size: 8px;
  }
`;

const StyledIcon1 = styled.div`
  width: 186px;
  background-color:#fffff;
  object-fit: contain;
  cursor: pointer;
  @media (max-width: 991px) {
    width: 110px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:12px;
`;

const Vertical_row = styled.div`
  display: flex;
  flex-direction: column;
`;

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;  // Align items vertically in the center
  gap: 10px;  
  position: relative;          // Optional: Adjust the gap between items
`;

const DropdownButton = styled.button`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  width: 50px;
  height: 50px;
`;

const DropdownContent = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  position: absolute;
  top: 100%; /* Directly below the StyledIcon */
  left: 0; /* Aligned with the StyledIcon */
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10;
  width: 200px;

  @media (max-width: 991px) {
    width: auto;
  }
`;

const DropdownContent1 = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  position: absolute;
  top: 0; /* Aligned with DropdownContent */
  left: 100%; /* Positioned to the right of DropdownContent */
  background-color: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10;
  width: 200px;

  @media (max-width: 991px) {
    width: 150px;
  }

`;


const DropdownContent2 = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  position: absolute;
  top: 50px;
  left: 150px;
  background-color: #FFFFFF;  
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10;

  @media (max-width: 991px) {
    top: 56px;
    right: 100px; 
    background-color: #FFFFFF;  
  }
`;

const DropdownItem = styled.div`
  padding: 12px 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between; /* Aligns elements at the left and right corners */
  align-items: center; /* Ensures the elements are vertically aligned */
  &:hover {
    background-color: #f1f1f1;
  }
  @media (max-width: 991px) {
    font-size:10px; 
  }
`;

const SongListContainer = styled.main`
  display: flex;
  flex-direction: column;
   min-height: 80vh;
  font-size: 16px;
  color: #020f12;
  letter-spacing: -0.16px;
  line-height: 140%;
  padding: 20px 32px;
  background-color: #020f12;
  height: 100%;
  @media (max-width: 991px) {
     padding: 0px;
     width:100%
  }
`;

const MainTitle = styled.h1`
  color: #fff;
  text-align: left;
  letter-spacing: -0.24px;
  font: 600 24px/160% "Poppins", -apple-system, Roboto, Helvetica, sans-serif;
  @media (max-width: 991px) {
   letter-spacing: -0.24px;
   font: 600 18px/160% "Poppins", -apple-system, Roboto, Helvetica, sans-serif;
  }
`;
const MainTitle1 = styled.div`
  color: #fff;
  text-align: left;
  letter-spacing: -0.24px;
  font: 500 14px "Poppins", -apple-system, Roboto, Helvetica, sans-serif;
  @media (max-width: 991px) {
   letter-spacing: -0.24px;
   font: 500 12px "Poppins", -apple-system, Roboto, Helvetica, sans-serif;
  }
`;

const SongGrid = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-left:12px;
  justify-content: flex-start;
  margin-top: 20px;
  @media (max-width: 991px) {
    gap: 5px;
  }
`;

const SongCardContainer = styled.article`
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 212px;
  height: 258px;
  position: relative;
  @media (max-width: 991px) {
    width: calc(33.33% - 10px);
    height: auto;
    margin-bottom: 10px;
  }
`;

const AlbumCover = styled.img`
  aspect-ratio: 1.27;

  width: 100%;
  border-radius: 8px 8px 0 0;
  @media (max-width: 991px) {
    width: 100%;
    height: auto;
  }
`;

const SongArtist = styled.p`
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  text-align: center;
  margin: 10px 0 0;
  @media (max-width: 991px) {
    font-size: 10px;
  }
`;

// const Overlay = styled.div`
//   display: ${({ loading }) => (loading ? "block" : "none")};
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: rgba(255, 255, 255, 0.8);
//   z-index: 999;
// `;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// const Spinner = styled.div`
//   border: 4px solid rgba(255, 255, 255, 0.3);
//   border-top: 4px solid white;
//   border-radius: 50%;
//   width: 50px;
//   height: 50px;
//   animation: ${spin} 1s linear infinite;
// `;

const LoadingText = styled.div`
  text-align: center;
  color: #fff;
`;

const DeleteIcon = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 1;
`;
const FixText = styled.span`
  display: block; /* Moves text to the next line */
  text-decoration: underline;
  font-size: 10px;
  cursor: pointer;
  margin-top: 2px; /* Adds spacing between 'Download Error' and 'Fix this' */
`;

export const ErrorToast = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 18px;
  margin-left: 38px;
`;

const Button10 = styled.button`
  font-family: Poppins, sans-serif;
  border-radius: 4px;
  background-color: ${(props) => (props.primary ? "#428bca" : "transparent")};
  align-self: center;
  margin-top: ${(props) => (props.primary ? "15px" : "5px")};
  width: 100%;
  max-width: 296px;
  color: ${(props) => (props.primary ? "#fff" : "#717171")};
  white-space: nowrap;
  padding: 17px 30px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
`;

const Modal = styled.section`
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  max-width: 428px;
  height: 300px;
  padding: 0 0 0px;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  @media (max-width: 991px) {
    width: 95%;
  }
`;

const HeaderContainer1 = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 428px;
  background-color: #428bca;
  max-width: 100%;
  font-size: 24px;
  white-space: nowrap;
  letter-spacing: -0.24px;
  line-height: 130%;
  height: 70px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  position: relative;
`;

const WarningText = styled.h2`
  margin: 0 auto;
  font: 600 24px Poppins, sans-serif;
`;

const CloseIcon = styled.img`
  position: absolute;
  top: 20px;
  right: 10px;
  cursor: pointer;
`;

const Content1 = styled.p`
  color: #3e3635;
  letter-spacing: 0.1px;
  margin-top: 37px;
  font: 500 18px/21px Poppins, sans-serif;
`;
const FormContainer4 = styled.main`
  border-radius: 12px;

  display: flex;
  width: 428px;
  flex-direction: column;
  overflow: hidden;
  @media (max-width: 991px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const Form4 = styled.form`
  border-radius: 12px;
  background-color: #312f2f;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const HeaderContainer4 = styled.header`
  position: relative;
  display: flex;
  min-height: 68px;
  align-items: center;
  justify-content: center;
  padding: 21px 16px;
  color: #fff;
  text-align: center;
  letter-spacing: -0.2px;
  font: 600 20px/1.3 Poppins, sans-serif;
`;

const Title01 = styled.div`
  z-index: 0;
  margin: 0;
  font: 600 25px/1.3 Poppins, sans-serif;
  @media (max-width: 991px) {
    font: 600 23px/1.3 Poppins, sans-serif;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 35px;
  right: 30px;
  background: none;
  border: none;
  color: #fff;
  font-size: 34px;
  cursor: pointer;
`;

const Content4 = styled.p`
  color: white;
  letter-spacing: 0.1px;
  margin-top: 0px;
  font: 500 14px Poppins, sans-serif;
  line-height: 22.4px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
`;

const UploadButton4 = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px;
    border-radius: 8px;
    background-color: #0072D5;
    color: #fff;
    border: none;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    width:120px;
    height:25px;

    img {
        height: 18px;
    }`;



const UploadPopup = styled.div`
  max-width: 500px;
  width: 450px;
  background: #282828;
  padding: 24px;
  border-radius: 12px;
  max-height: 90vh;
  overflow-y: auto;

  select:focus{
    outline: none;
  }

  @media (max-width: 1240px) and (min-width: 1025px){
    width: 40%;
  }

  @media (max-width: 1024px) and (min-width: 901px){
    width: 45%;
  }

  @media (max-width: 900px) and (min-width: 601px){
    width: 35%;
  }

  @media (max-width: 600px){
    width: 75%;
  }
`
  ;

const UploadContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Adjust spacing */
  margin-bottom:20px;
`;

const FormContainer45 = styled.div`
  display: flex;
  padding: 30px 0;
  justify-content: space-between;
  gap: 8px;

  @media (max-width: 900px){
    flex-direction: column;
    gap:24px;

    &>div{
      width: 100%;
    }
  }
`;

const VideoPreview = styled.div`
width: 150px;
margin-top: 10px;

@media (max-width:900px){
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  text-align: center;
  width: 100%;

  img{
    width: 70%;
  }
}
`;


