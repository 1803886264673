import styled, { keyframes,css } from 'styled-components';

export const PlaylistContainer = styled.main`
    display: flex;
    flex-direction: column;
    background-color:#121212;
    overflow:hidden;
    margin-top:0px;
    margin-bottom:20px;
    
    @media (max-width: 991px) {
      width: 100%;
      margin-top:0px;
      
    }
  `;

  export const Section = styled.div`
  margin-left:30px;
  margin-right:30px;
  
  
   @media (max-width: 991px) {
     margin-left:10px;
     margin-right:10px;
      
    }
`;

export const ErrorToast = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 18px;
  margin-left: 38px;
`;

export const Overlay = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
z-index: 999; /* Ensure it's above other content */
display: ${props => (props.loading ? 'flex' : 'none')};
flex-direction: column;
justify-content: center;
align-items: center;
color: white;
font-size: 1.5em;
`;

export const spin = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`;


export const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

export const LoadingText = styled.div`
  text-align: center;
  color:#ffffff;
`;

export const LoadingText2 = styled.div`
  text-align: center;
  color:#ffffff;
  margin-top:10px;
  font-weight: 600;
`;
export const LoadingIndicator = styled.div`
width: 50px;
height: 50px;
border: 5px solid #fff;
border-radius: 50%;
margin: 20px auto;
animation: spin 1s infinite linear;

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
`;


 
  
  export const PlaylistContent = styled.section`
    display: flex;
    margin-top: 8px;
    width: 100%;
    flex-direction: column;
    padding: 20px 32px;
    margin-left:0px;
    margin-right:10px;
    
    @media (max-width: 991px) {
      max-width: 100%;
      padding: 0 0px;
    }
  `;
  
  export const SectionHeader = styled.div`
    justify-content: space-between;
    display: flex;
    gap: 12px;
    font-weight: 600;
    margin-top:20px;
    @media (max-width: 991px) {
      max-width: 100%;
      flex-wrap: wrap;
      flex-direction: row;
      padding:0px;
      gap: 0px;
      margin-top:10px;
    }
  `;
  export const SectionHeader1 = styled.div`
  justify-content: space-between;
  display: flex;
  gap: 12px;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom:10px;

  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    align-items:end;  
    padding:5px;
  }
`;
  
  export const SectionTitle = styled.h2`
    color: #fff;
    text-align: center;
    letter-spacing: -0.24px;
    font: 24px/160% Poppins, sans-serif;
    @media (max-width: 991px) {
      max-width: 100%;
      text-align:start;
      
      font: 20px/160% Poppins, sans-serif;
    }
  `;
  export const SectionTitle1 = styled.h2`
  color: #fff;
  text-align:start;
  letter-spacing: -0.24px;
  font: 24px/160% Poppins, sans-serif;
  margin: 0; // Reset margin to avoid unwanted space
  flex: 1; // Allow title to take available space
  @media (max-width: 991px) {
    max-width: 100%;
    text-align: start;
    margin-left: 0px;
     font: 20px/160% Poppins, sans-serif;
  }
`;

  export const SectionTitle2 = styled.h2`
    color: #fff;
    text-align: left;
    letter-spacing: -0.24px;
    margin-top:0px;
    font: 14px/100% Poppins, sans-serif;
    @media (max-width: 991px) {
      max-width: 100%;
      text-align: left;
      margin-left:8px;
    }
  `;
  
  export const ViewAllLink = styled.a`
    color: #0072D5;
    margin: auto 0;
    font: 16px Poppins, sans-serif;
  
    cursor: pointer;
    @media (max-width: 991px) {
     
      flex-direction: row;
    }
  `;
  export const ViewAllLink1 = styled.a`
  color: #0072D5;
  margin: auto 0;
  font: 16px Poppins, sans-serif;
  
  cursor: pointer;
  @media (max-width: 991px) {
    margin-right: 15px;
    flex-direction: row;
  }
`;
  
  export const SongList = styled.div`
  overflow-x: auto; /* Allow horizontal scrolling */
  display: flex;
  margin-top: 26px;
  gap: 19px;
  font-size: 16px;
  color: #020f12;
  letter-spacing: -0.16px;
  line-height: 140%;
  @media (max-width: 991px) {
    gap: 9px;
    width: 100%;
  }
`;

export const SongList1 = styled.div`
  overflow-x: auto; /* Allow horizontal scrolling */
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping to new lines */
  margin-top: 26px;
  gap: 19px;
  font-size: 16px;
  color: #020f12;
  letter-spacing: -0.16px;
  line-height: 140%;
  @media (max-width: 991px) {
    gap: 9px;
    margin-left: 15px;
    margin-right: 15px;
    width: 2000px;
    margin-bottom:15px;
  }
`;
  
  export const SongCard = styled.a`
   cursor: pointer;
    text-decoration: none;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width:212px;
    overflow: hidden;
    
   
   
    @media (max-width: 991px) {
      height:165px;
      width:140px;
      
      overflow-x: auto;
      scrollbar-width: none;
    
    }
  `;


  export const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
`;
  
  export const SongImage = styled.img`
    aspect-ratio: 1.25;
    object-fit: auto;
    object-position: center;
    width: 100%;
    align-self: center;
    @media (max-width: 991px) {
      height:112px;
     
    }
  `;
  
  export const SongInfo = styled.div`
    border: 1px solid rgba(222, 227, 235, 1);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 6px 0 8px;
    @media (max-width: 991px) {
      padding: 0px 0px 0px 0px;
     
    }
  `;
  
  export const SongTitle = styled.h3`
    text-align: center;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    @media (max-width: 991px) {
      text-align: center;
      font-family: Poppins, sans-serif;
       font-weight: 600;
       font-size:15px;
       margin-top:2px;
       margin-bottom:0px;
     
    }
  `;

  export const SongCard1 = styled.a`
  cursor: pointer;
    text-decoration: none;
     &:hover {
      transform: translateY(-5px);
    }
    color: inherit;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width:212px;
    transition: transform 0.2s ease-in-out;
   
   
    @media (max-width: 991px) {
      height:165px;
      width:140px;
      
      overflow-x: auto;
      scrollbar-width: none;
    
    }
  `;
  
  export const SongImage1 = styled.img`
    aspect-ratio: 1.25;
    object-fit: auto;
    object-position: center;
    width: 100%;
    align-self: center;
    @media (max-width: 991px) {
      height:112px;
     
    }
  `;
  
  export const SongInfo1 = styled.div`
    border: 1px solid rgba(222, 227, 235, 1);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 16px 0 18px;
    @media (max-width: 991px) {
      padding: 0px 0px 0px 0px;
     
    }
  `;
  
  export const SongTitle1 = styled.h3`
    text-align: center;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    @media (max-width: 991px) {
      text-align: center;
      font-family: Poppins, sans-serif;
       font-weight: 600;
       font-size:15px;
       margin-top:2px;
       margin-bottom:0px;
     
    }
  `;
  
  export const ArtistName = styled.p`
    font-family: Poppins, sans-serif;
    font-weight: 400;
    align-self: center;
    margin-top: 10px;
     @media (max-width: 991px) {
      font-family: Poppins, sans-serif;
     
      align-self: center;
       margin-top: 0px;
       font-weight: 400;
       font-size:12px;
     
    }
  `;

//search
export const MainContainer = styled.main`
  height: 100%;
  background-color:#121212;
  min-height: 100vh;

  @media (max-width: 991px) {
    padding: 0 0px;
    max-width: 100%;
    margin-right:0px;
  }
`;

export const ContentWrapper = styled.section`
  display: flex;
  width: 837px;
  width: 100%;
  flex-direction: column;
  
  
`;

export const MainTitle = styled.h1`
  color: #fff;
  text-align: center;
  letter-spacing: -0.28px;
  
  align-self: center;
  font: 600 28px/120% Poppins, sans-serif;

  @media (max-width: 991px) {
   
    font: 600 20px/120% Poppins, sans-serif;
    margin-left:8px;
    margin-right:8px;
     margin-top:15px;
    
  }
`;

export const SongInputForm = styled.form`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 12px;
  font-size: 14px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
  gap: 10px;
  color: #51606f;
  font-weight: 300;
  padding: 14px 12px;
  width:65%;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

export const YoutubeIcon = styled.img`
  width: 28px;
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
`;

export const YoutubeLinkInput = styled.input`
  font-family: Nunito, sans-serif;
  font-weight: 300;
  border: none;
  outline: none;
  background: transparent;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const SubmitButton = styled.button`
  font-family: Poppins, sans-serif;
  font-weight: 600;
  padding: 14px 12px;
  border: none;
  border-radius: 4px;
  background-color: #428bca;
  color: #fff;
  cursor: pointer;

  @media (max-width: 991px) {
    padding: 14px 20px;
  }
`;

export const VisuallyHiddenLabel = styled.label`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

export const FormContainer = styled.form`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;
  gap: 12px;
  margin-left:0px;

  @media (max-width:991px){
   margin: 0 10px;
   gap: 16px;
  }

 
`;

export const InputGroup = styled.div`
  display: flex;
  align-items: center;
 border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  background-color: #fff;
  justify-content: center; 
  gap: 10px;
  color: #51606f;
  font-weight: 300;
  padding-left: 12px;
  width:45%;

 
  @media (max-width:991px){
    width:70%;
    justify-content:space-around;

  }
`;

export const InputIcon = styled.img`
  aspect-ratio: 1;
  width: 32px;
 
`;



export const InputField = styled.input`
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: transparent;
  width: 100%;
    @media (max-width: 991px) {
    font-size:14px;
   

  }
`;

export const Button = styled.button`
  font-family: Poppins, sans-serif;
  display: flex;
  border:none;
  justify-content: center;
  align-items: center;
   border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
     border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

  background-color: #0072D5;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  padding: 14px 12px;
  height:64px;
  width: 160px;
  cursor: pointer;

  @media (max-width: 991px) {
    padding-left: 0px; /* Removes left padding */
    margin-left: 0px;
    margin-right: 0px;
    height: 100%;
    justify-content: center;
    align-items: center;
     border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
     border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
   

  }
`;

export const Button1 = styled.button`
  font-family: Poppins, sans-serif;
  display: flex;
  border:none;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #428BCA;
  color: #fff;
  font-weight: 100;
  font-size:12px;
  padding: 14px 12px;
  height:44px;
  width: 100px;
  cursor: pointer;

  @media (max-width: 991px) {
    padding-left: 20px;
    height:54px;
  }
`;

export const UploadButton = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px;
    border-radius: 8px;
    background-color: #0072D5;
    color: #fff;
    border: none;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;

    img {
        height: 18px;
    }
`

export const CreatePlaylistButton = styled.button`
  // Your styles here
`;


export const SongGrid = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
  
  @media (max-width: 991px) {
    gap: 10px;
  }
`;


export const SongGrid_new = styled.section`
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  justify-content: flex-start;
  margin-top: 15px;
  overflow-x: auto; /* Optional: allows scrolling if content exceeds width */
  
  @media (max-width: 991px) {
    gap: 10px;
  }
`;

// export const SongGrid1 = styled.section`
//   display: flex;
//   flex-wrap: wrap;
//   gap: 20px;
//   justify-content: flex-start;
//   margin-top: 20px;
//   @media (max-width: 991px) {
//     gap: 10px;
//   }
// `;

export const SongGridall = styled.section`
  display: flex;
  flex-direction: row;
  gap: 20px; // Add a gap between the grids if needed
  @media (max-width: 991px) {
    flex-direction: column; // Stack vertically on small screens
    gap: 13px;
  }
`;

export const SongCardContainer = styled.article`
cursor: pointer;
border-radius: 8px;
display: flex;
flex-direction: column;
width: 212px;
position: relative;
@media (max-width: 991px) {
  width: calc(33.33% - 7px);
  height: auto;
  margin-bottom: 10px;
}
`;

// export const SongCardContainer1 = styled.article`
// position: relative;
//   cursor: pointer;
//   border-radius: 8px;
//   background-color:#060605;
//   display: flex;
//   flex-direction: column;
//   width: 212px;
//    height: auto;
//   @media (max-width: 991px) {
//     width: calc(33.33% - 10px);
//     height: auto;
//     margin-bottom: 10px;
//   }
// `;

export const AlbumCover2 = styled.img`
  aspect-ratio: 1.27;
 
  width: 100%;
  border-radius: 8px 8px 0 0;
  @media (max-width: 991px) {
    width: 100%;
    height: auto;
  }
`;

// export const AlbumCover3 = styled.div`
  
//   width: 100%;
//   height:225px;
//   border-radius: 0px;
//   @media (max-width: 991px) {
//     width: 100%;
//     height: auto;
//   }
// `;


export const SongGrid1 = styled.section`
  
  gap: 20px;
  margin-top:0px;
  @media (max-width: 991px) {
    gap: 10px;
  }
`;



export const SongCardContainer1 = styled.article`
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #060605;
  width: 212px;
  @media (max-width: 991px) {
    width: calc(33.33% - 10px);
    height: auto;
    margin-bottom: 10px;
  }
`;

export const AlbumCover3 = styled.div`
  width: 100%;
  height: 225px;
  
  @media (max-width: 991px) {
    width: 100%;
    height: auto;
  }
`;

export const Icon = styled.img`
  width: 40px;
  height: 40px;
  justify-content: center;
  font-size: 24px;
  color: white;
  @media (max-width: 991px) {
     width: 20px;
  height: 20px;
  }
`;

export const SectionTitle5 = styled.div`
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  @media (max-width: 991px) {
    font-size: 12px;
  }
`;

export const SongInfo2 = styled.div`
  border: 1px solid rgba(222, 227, 235, 1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 10px 0 10px;
   border-radius: 0 0 8px 8px;

`;



export const SongTitle2 = styled.h3`
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin: 0;
  font-size: 14px; // Ensure the font size is appropriate
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2; // Adjust line height if needed
  max-height: calc(1.2em * 2); // Line height * number of lines
  
  @media (max-width: 991px) {
    font-size: 12px;
  }
`;
export const SongTitle4 = styled.h3`
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin-top: 10px;
  font-size: 14px; // Ensure the font size is appropriate
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2; // Adjust line height if needed
  max-height: calc(1.2em * 2); // Line height * number of lines
  
  @media (max-width: 991px) {
    font-size: 12px;
  }
`;


export const Songdate = styled.h5`
text-align: center;
font-family: 'Poppins', sans-serif;
font-weight: 500;
margin: 0;
margin-top:7px;
font-size: 13px; 
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
line-height: 1.2; 
max-height: calc(1.2em * 2); 


@media (max-width: 991px) {
  font-size: 10px;
}
`;


const spin1 = (percentage) => keyframes`
  0% { transform: rotate(0deg); }
  ${percentage}% { transform: rotate(360deg); }
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingOverlay1 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius:8px;
  background: rgba(0, 0, 0, 0.8);
  display: ${(props) => (props.show ? "flex" : "none")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 2;
   @media (max-width: 991px) {
      width: 100%;
      height: 100%;
  }`;

export const LoadingText1 = styled.div`
  margin-top: 10px;
  font-size: 16px;
`;

export const ProgressBar = styled.div`
  width: 0;
  height: 4px;
  background-color: #3498db; /* Blue progress bar */
  margin-top: 8px; /* Space between spinner and progress bar */
  transition: width 0.3s ease; /* Smooth transition for width changes */
`;

export const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Full width for the progress bar */
  
`;

export const Logo = styled.img`
  
  
  object-position: center;
  width: 64px;
  height:64px;
  fill: #000000;
  
  @media (max-width: 991px) {
    width: 44px;
     height:44px;
  }
`;

export const Circle = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid white;
  background: conic-gradient(
    rgb(0, 0, 0) ${props => props.percentage * 3.6}deg,
    #ffffff ${props => props.percentage * 3.6}deg
  );
  display: flex;
  justify-content: center;
  align-items: center;
`;


export const Circle2 = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 64px;  
height: 64px;  
border-radius: 50%;
background-color: #f3f3f3;
position: relative;
margin-bottom: 10px;
border: 8px solid ${({ progress }) => (progress === 100 ? '#4CAF50' : 'transparent')};

${({ progress }) => progress < 100 && css`
  &::after {
    content: '';
    position: absolute;
    top: -8px;
    left: -8px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: conic-gradient(
      #4CAF50 ${progress}%, 
      #f3f3f3 ${progress}% 100%
    );
    -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - 5px), #000 calc(100% - 5px));
    mask: radial-gradient(farthest-side, transparent calc(100% - 5px), #000 calc(100% - 5px));
  }

  @media (max-width: 991px) {
    &::after {
      width:60px;
      height:60px;
    }
  }
`}

&::before {
  content: ${({ progress }) => (progress === 100 ? '"✓"' : `"${progress}%"`)};
  position: absolute;
  color: ${({ progress }) => (progress === 100 ? 'white' : 'black')};
  font-size: 18px;
  font-weight: bold;
}
  @media (max-width: 991px) {
    width: 44px;
    height:44px;
  }
  
`;

export const Circle3 = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 64px;  
height: 64px;  
border-radius: 50%;
background-color: #f3f3f3;
position: relative;
margin-bottom: 10px;
border: 8px solid ${({ progress }) => (progress === 100 ? '#4CAF50' : 'transparent')};

${({ progress }) => progress < 100 && css`
  &::after {
    content: '';
    position: absolute;
    top: -8px;
    left: -8px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: conic-gradient(
      #4CAF50 ${progress}%, 
      #f3f3f3 ${progress}% 100%
    );
    -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - 5px), #000 calc(100% - 5px));
    mask: radial-gradient(farthest-side, transparent calc(100% - 5px), #000 calc(100% - 5px));
  }
    @media (max-width: 991px) {
    width: 44px;
     height:44px;
  }
`}

&::before {
  content: ${({ progress }) => (progress === 100 ? '"✓"' : `"${progress}%"`)};
  position: absolute;
  color: ${({ progress }) => (progress === 100 ? 'white' : 'black')};
  font-size: 18px;
  font-weight: bold;
}
  @media (max-width: 991px) {
    width: 44px;
     height:44px;
  }
`;

const increase = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const SpinnerCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 64px;
  height: 64px;
  margin: -32px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  transform: rotate(${props => props.progress}deg);
  transition: transform 0.3s ease;
`;

export const SongCardContainer2 = styled.article`
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #060605;
  width: 212px;
  @media (max-width: 991px) {
    width: calc(33.33% - 10px);
    height: auto;
    margin-bottom: 10px;
    padding-top:20px;
    padding-bottom:20px;
  }
`;

export const Spinner1 = styled.div`
border: 4px solid rgba(255, 255, 255, 0.3);
border-top-color: #fff;
border-radius: 50%;
width: 40px;
height: 40px;
animation: ${rotate} 1s linear infinite;
`;